import React, { useContext, useState } from 'react';

import { Auth, Firestore, Loom } from '@contexts';
import { ViewDialog as PCDialog } from '@loom/characters/pc';
import { ViewDialog as NPCDialog } from '@loom/characters/npc';
import { ListTab, Box } from '@loom/ui';
import { CharacterIcon, NPCIcon } from '@loom/ui/icons';

const List = ({ 
	pcs,
	npcs,
	inheritedPCs,
	inheritedNPCs,
	context,
 }) => {
	const { user } = useContext(Auth);
	const { db, addExtraContent } = useContext(Firestore);
	const { characters } = useContext(Loom);
	const [ character, setCharacter ] = useState(false);
	const [ npc, setNPC ] = useState(false);

	const allPCs = [
		...pcs ?? [],
		...inheritedPCs ?? [],
	]
	addExtraContent('characters', allPCs);

	const allNPCs = {
		...(npcs ?? {}),
		...(inheritedNPCs ?? {})
	}

	return (
		<Box title="Characters">
			<ListTab
				items={[
					...allPCs
						.filter((id) => characters[id] !== undefined)
						.map((id) => ({
							id:id,
							label:characters[id].name,
							locked:characters[id].owner !== user?.uid,
							unremovable: inheritedPCs?.contains?.(id)
						})),
					...Object.entries(allNPCs)
						.map(([id, npc]) => ({
							id: id,
							label: npc.name,
							locked: context.data.owner !== user?.uid,
							unremovable: !Boolean(npcs?.[id]),
						}))
				]}
				icon={ (c) => characters[c.id] ? <CharacterIcon /> : <NPCIcon /> }
				onClick={ (id) => {
					if (characters[id]) setCharacter(id);
					else if (context.data.npcs?.[id]) setNPC(id);
				}}
				onRemove={ (_, item) => {
					console.log(pcs, item.id);
					if (pcs?.includes?.(item.id)) {
						db.collection(context.collection).doc(context.id).update({
							characters: context.data.characters.filter((c) => c !== item.id)
						})
					} else {
						const { [item.id]: _, ...remaining } = context.data.npcs;
						db.collection(context.collection).doc(context.id).update({
							npcs: remaining,
						})
					}
				}}
				sorted
			/>
			<PCDialog characterId={ character } onClose={ () => setCharacter(null) } />
			<NPCDialog
				open={ Boolean(npc) }
				data={ allNPCs[npc] }
				onClose={ () => setNPC(null) }
				onChange={ (data) => {
					db.collection(context.collection).doc(context.id).update({
						npcs: {
							...(context.data.npcs ?? {}),
							[npc]: data,
						}
					});
				}}
			/>
		</Box>
	)
};

export default List;

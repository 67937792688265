import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

import { CharmDialog } from '@loom/help';

import './markdown.less';
import { getAllCharms } from '@loom/characters/functions';

const Markdown = ({ source }) => {
	const [ charm, setCharm ] = useState();

	if (source) {
		if (Array.isArray(source)) source = source.join('\n\n')
		source = source.replace(/\[([^\]]+)\]\(([^)]+)\)/g, (match, title, link, offset, string) => {
			if (link.startsWith('charm:')) return '[' + title + '](#charm:' + encodeURIComponent(link.substr('charm:'.length)) + ')'
			return '[' + title + '](#help/' + encodeURIComponent(link) + ')'
		})
	}

	const LinkRenderer = (props) => {
		if (props.href.startsWith('#charm:')) {
			const charm = decodeURIComponent(props.href.substring('#charm:'.length))
			return <button
				href={ charm }
				onClick={ (e) => {
					setCharm({
						name: charm,
						...(getAllCharms()[charm])
					});
					e.preventDefault();
				}}
			>{ props.children }</button>
		} else {
			return <a href={decodeURIComponent(props.href)}>{props.children}</a>
		}
	}

	return <>
		<div className="markdown"><ReactMarkdown
			remarkPlugins={[gfm]}
			components={{ a: LinkRenderer }}
		>{ source }</ReactMarkdown></div>
		<CharmDialog
			charm={ charm }
			onClose={ () => setCharm() }
		/>
	</>;
}

export default Markdown

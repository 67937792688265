import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { Auth, Firestore, Loom } from '@contexts';
import { Box, ListTab } from '@loom/ui';
import { OrganisationIcon } from '@loom/ui/icons';

const List = ({ orgs, inheritedOrgs, context }) => {
	const { organisations } = useContext(Loom);
	const { user } = useContext(Auth);
	const { addExtraContent, db } = useContext(Firestore);
	const { pathname: path } = useLocation();

	addExtraContent('organisations', [ ...orgs ?? [], ...inheritedOrgs ?? [] ]);

	return (
		<Box title="Organisations">
			<ListTab
				items={ ([
					...orgs ?? [],
					...inheritedOrgs ?? []
				])
					.filter((id) => organisations[id])
					.map((id) => ({
						id:id,
						link: `${path}/organisation/${id}`,
						label:organisations[id].name,
						locked:organisations[id].owner !== user?.uid,
					}))
				}
				icon={ <OrganisationIcon /> }
				onRemove={ (i, org) => {
					db.collection(context.collection).doc(context.id).update({
						organisations:context.data.organisations.filter((id) => org.id !== id)
					})
				}}
				sorted
			/>
		</Box>
	);
}

export default List
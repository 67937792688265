import React, { Component } from 'react'

import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material';

class EditStoryDialog extends Component {
	render() {
		const { open, onClose, story, onChange, onSave, fullScreen } = this.props
		
		return (
			<Dialog
				fullScreen={ fullScreen }
				open={ open }
				onClose={ onClose }
			>
				<DialogContent>
					<TextField
						label="Name"
						value={ story.name }
						onChange={ onChange('name') }
						fullWidth
					/>
					<TextField 
						label="Description"
						value = { story.description }
						onChange={ onChange('description') }
						multiline
						fullWidth
					/>
					<TextField 
						label="Storyteller's Notes"
						value={ story.notes }
						onChange={ onChange('notes') }
						multiline
						fullWidth
					/>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={ onClose }
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={ onSave }
					>
						Ok
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

export default EditStoryDialog;

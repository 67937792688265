import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { Loom, Firestore } from '@contexts';
import { UI, Tab } from '@loom/ui';
import { CharacterIcon, CharmIcon, DetailsIcon, MeritIcon, BattleIcon, SocialIcon, CraftIcon } from '@loom/ui/icons';
import { getMerits, isSorcerer, normalize } from '@loom/characters/functions';

import ViewContext from './context';
import GeneralTab from './general'
import TraitsTab from './traits'
import CharmsTab from './charms'
import MeritsTab from './merits'
import CombatTab from './combat'
import SocialTab from './social'
import ProjectsTab from './projects'
import Actions from './actions';

const PC = ({
	characterId
}) =>{
	const { addExtraContent } = useContext(Firestore);
	const { pcs, hearthstones } = useContext(Loom);
	const character = normalize(pcs[characterId], hearthstones);
	const { pathname: path } = useLocation();

	const merits = Object.values(getMerits(character));
	addExtraContent('artifacts', merits.filter((m) => m.key === 'Artifact').map((m) => m.details?.artifact).filter((a) => Boolean(a)));
	addExtraContent('hearthstones', merits.filter((m) => ['Hearthstone', 'Manse'].includes(m.key) && m.hearthstone).map((m) => m.details?.hearthstone ?? m.details).filter((hs) => Boolean(hs)));

	return (
		<ViewContext.Provider value={{ character, characterId }}>
			<UI
				splat={ character.splat }
				back={ path.split('/').slice(0,-3).join('/') }
				actions={ <Actions character={ character } /> }
			>
				<Tab value="general" label="General" icon={ <CharacterIcon /> } render={ <GeneralTab /> } />
				<Tab value="traits" label="Traits" icon={ <DetailsIcon /> } render={ <TraitsTab /> } />
				<Tab value="charms" label="Charms" icon={ <CharmIcon /> } render={ <CharmsTab /> } />
				<Tab value="merits" label="Merits" icon={ <MeritIcon /> } render={ <MeritsTab /> } />
				<Tab value="combat" label="Combat" icon={ <BattleIcon /> } render={ <CombatTab /> } />
				<Tab value="social" label="Social" icon={ <SocialIcon /> } render={ <SocialTab /> } />
				{ ((character.crafts && character.crafts.length > 0) || isSorcerer(character)) &&  <Tab value="projects" label="Projects" icon={ <CraftIcon /> } render={ <ProjectsTab character={ character } /> } /> }
			</UI>
		</ViewContext.Provider>
	);
};

export default PC;

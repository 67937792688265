import React, { useState } from 'react'

import { ListItemText, MenuList, MenuItem } from '@mui/material';

import { Box } from '@loom/ui';
import { GenericDialog } from '@loom/help';

const RitualList = ({
	character
}) => {
	const [ ritual, setRitual ] = useState();

	return (character.rituals && <>
		<Box title="Sorcerous Rituals">
			<MenuList>
				{ Object.keys(character.rituals).map((ritual, i) => (
					<MenuItem
						key={ i }
						onClick={ () => setRitual(ritual) }
					>
						<ListItemText>{ ritual }</ListItemText>
					</MenuItem>
				))}
			</MenuList>
		</Box>
		<GenericDialog
			open={ Boolean(ritual) }
			title={ ritual + ' Shaping Ritual' }
			content={ character?.rituals[ritual] }
			onClose={ () => setRitual(null) }
		/>
	</>);
};

export default RitualList;
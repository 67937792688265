import React, { useContext } from 'react';
import { useSnackbar } from 'notistack';

import data from '@data';
import { xplog, getScore, startTraining, getSplatProp } from '@loom/characters/functions';
import { Box, Dots, Grid } from '@loom/ui';

import EditContext from '../context';
import { isCaste, isFavoured, isSupernal, inTraining } from './utils';

const Skills = () => {
	const { character, onChange } = useContext(EditContext);
	const { enqueueSnackbar } = useSnackbar();

	return (
		<Box title="Abilities">
			<Grid size={ 250 }>
				{ Object.keys(data.abilities).filter((abil) => ['craft', 'martial arts'].indexOf(abil) < 0).map((abil) => (
					<div
						key={ abil }
						className={ `
							ability
							${isCaste(character, abil) ? 'caste' : ''}
							${isFavoured(character, abil) ? 'favoured' : ''}
							${isSupernal(character, abil) ? 'supernal' : ''}
						`}
					>
						<div
							className={`
								name
								${character.splat && getSplatProp(character, 'favours-type', 'ability') === 'ability' && !character.experienced ? 'interactable' : '' }
							`}
							onClick={ () => {
								if (!character.splat || getSplatProp(character, 'favours-type', 'ability') !== 'ability') return
								if (character.experienced) return
								if (isSupernal(character, abil)) {
									onChange('supernal')(null);
								} else if (isFavoured(character, abil)) {
									if (isCaste(character, abil) && getSplatProp(character, 'supernal', false)) {
										onChange()({
											supernal: abil,
											favoured: [...character.favoured.filter((a) => a !== abil)],
										})
									} else {
										onChange('favoured')([ ...(character.favoured ?? []).filter((a) => a !== abil) ])
									}
								} else if (!isCaste(character, abil) || !getSplatProp(character, 'favoursAllCaste', false)) {
									onChange('favoured')([...(character.favoured ?? []), abil])
								}
							}}
						>{ abil }</div>
						<Dots
							value={ getScore(character, abil) }
							max={ 5 }
							temp={ inTraining(character, 'ability', abil) }
							onChange={(e) => {
									if (character.experienced) {
										startTraining(character, xplog(character, 'ability', abil, getScore(character, abil), e), onChange, enqueueSnackbar)
									} else {
										onChange(abil)(e)
									}
								}
							}
							variant={ (isFavoured(character, abil) && !isSupernal(character, abil)) ? 'light' : 'dark' }
							editable
						/>
					</div>
				))}
			</Grid>
		</Box>
	)
}

export default Skills
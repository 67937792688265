import firebase from 'firebase';
import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";

export function sendBug(title, description) {
	const user = firebase.auth().currentUser;
	fetch('https://createbug-dbgqr2eowa-uc.a.run.app', {
		method: 'POST',
		mode: 'cors',
		headers: {
			'Content-Type': 'application/json'
		},
		redirect: 'follow',
		body: JSON.stringify({
			"title": title,
			"body": `${description}\n\nURL: ${document.location.href}\n\n[${user.displayName}](mailto:${user.email}?subject=${encodeURIComponent('Re: ' + title)}&body=${encodeURIComponent('Reply<hr />' + description)})`
		})
	}).catch((error) => console.log('Bug report failed', error))
}

export function ErrorDialog({ onClose, ...props }) {
	const [bug, setBug] = useState({});

	function close() {
		setBug({ });
		onClose();
	}

	return (
		<Dialog {...props} maxWidth="lg" onClose={close}>
			<DialogTitle>Report Bug</DialogTitle>
			<DialogContent style={{ width: '800px' }}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							value={bug.title ?? ''}
							onChange={(e) => setBug({ ...bug, title: e.target.value })}
							label="Subject" fullWidth
							/>
					</Grid><Grid item xs={12}>
						<TextField
							value={bug.description ?? ''}
							onChange={(e) => setBug({ ...bug, description: e.target.value })}
							label="Description" multiline fullWidth
						/>
					</Grid>
				</Grid>
				</DialogContent>
			<DialogActions>
				<Button onClick={() => {
					sendBug(bug.title, bug.description);
					close();
				}}>Submit</Button>
			</DialogActions>
		</Dialog>
	)
}

export class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { error: false };
	}

	componentDidCatch(error, errorInfo) {
		if (window.location.host.startsWith('localhost:')) return;
		sendBug('Automated bug report from crash', `${error.message} \n\n${error.stack}`);
	}
  
	static getDerivedStateFromError(error) {
		return { error };
	}
  
	render() {
		if (this.state.error) {
			return (<>
				<ErrorDialog open={ true } onClose={ () => window.location.reload() } />
				<h1>Something went wrong</h1>
				<h2>{ this.state.error.name }</h2>
				<p>{ this.state.error.message }</p>
				<pre>{ this.state.error.stack }</pre>
			</>);
		}
  
		return this.props.children; 
	}
}
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { Firestore } from '@contexts';

import Hearthstone from './view';

const View = () => {
	const { id } = useParams();
	const { addExtraContent } = useContext(Firestore);

	addExtraContent('hearthstones', id);

	return (
		<Hearthstone hearthstoneId={ id } />
	);
};

export { View }
export default View;
import React, { useContext } from 'react';

import { Auth, Loom } from '@contexts';
import { ListTab } from '@loom/ui';
import { ChronicleIcon } from '@loom/ui/icons';

const List = () => {
	const { chronicles } = useContext(Loom);
	const { user } = useContext(Auth);

	if (!chronicles) return null;

	return (
		<div>
			<ListTab
				items={
					Object.keys(chronicles).filter((chron) => (
						chronicles[chron].owner === user.uid || (
							user?.profile?.favourites?.chronicles?.indexOf(chron) >= 0
						)
					)).map((id) => ({
						id: id,
						link: '/chronicle/' + id,
						label: chronicles[id].name,
						locked: chronicles[id].owner !== user.uid,
					}))
				}
				icon={ <ChronicleIcon /> }
				sorted
			/>
		</div>
	)
}

export default List;

import React, { useContext, useState } from 'react'

import { MenuItem } from '@mui/material';

import { Loom } from '@contexts';
import { Box, Grid, Markdown, UI } from '@loom/ui';
import CharmDialog from '@loom/help/charm'

import Actions from './actions'

const Hearthstone = ({ hearthstoneId }) => {
	const [ charm, setCharm ] = useState(false);
	const { hearthstones } = useContext(Loom);
	const hearthstone = hearthstones[hearthstoneId];

	if (!hearthstone) return null;
		
	return (
		<UI
			actions={ <Actions hearthstone={ hearthstone } hearthstoneId={ hearthstoneId } /> }
		>
			<Grid size="large">
				{ hearthstone.description && (
					<Box title={ hearthstone.name }>
						<Markdown source={ hearthstone.description } />
					</Box>
				)}{ hearthstone?.evocations?.length > 0 && 
					<Box title="Evocations">{
						(hearthstone.evocations ?? []).map((evoc, i) => (
							<MenuItem
								key={ i }
								onClick={ () => setCharm(evoc) }
							>
								{ evoc.name }
							</MenuItem>
						))
					}</Box>
				}
			</Grid>
			<CharmDialog
				key={ charm }
				charm={ charm }
				onClose={ () => setCharm(null) }
			/>	
		</UI>
	)
}

export default Hearthstone;
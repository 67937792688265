import React, { useState } from 'react'

import data from '@data'
import { MenuItem, TextField } from '@mui/material';
import { NameValue } from '@loom/ui';
import { GenericDialog } from '@loom/help';

export const Edit = ({
	merit,
    onChange
}) => {
    return (
		<div className="flex">
			<TextField
				label={ merit.key }
				value={ merit.details?.language ?? merit.details ?? '' }
				onChange={ (e) => onChange(e.target.value) }
				select
			>{
				Object.keys(data.languages)
                    .sort()
                    .map((l) => (
    					<MenuItem key={ l } value={ l } className="caps">{ l }</MenuItem>
				    ))
			}</TextField>
        </div>
    );
};

export const View = ({
	merit
}) => {
	const [ viewing, setViewing ] = useState(false);

    return (<>
		<NameValue
			name={<>
				<strong>{ merit.key }</strong>
                { ' '}
				{ merit.details.language }
			</> }
			value={ merit.value }
			onClick={ () => setViewing(true) }
		/>
		<GenericDialog
			open={ viewing }
			title={ `Language: ${merit.details.language}` }
			content={ data.languages[merit.details.language] }
			onClose={ () => setViewing(false) }
		/>
	</>)
};
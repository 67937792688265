import React, { useContext, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom';

import BottomNavigationAction from '@mui/material/BottomNavigationAction' 

import { Firestore, Loom } from '@contexts';
import { AddDialog as AddCharacterDialog } from '@loom/characters'
import { ConfirmDialog, ActionBase } from '@loom/ui'
import { CharacterIcon, DeleteIcon, EditIcon,EndRoundIcon } from '@loom/ui/icons'

import EditBattleDialog from './edit';

const BattleActions = ({ battleId }) => {
	const [ adding, setAdding ] = useState(false);
	const [ deleting, setDeleting ] = useState(false);
	const [ editing, setEditing ] = useState(false);
	const { battles, pcs, npcs } = useContext(Loom);
	const { db } = useContext(Firestore);
	const history = useHistory();
	const { pathname } = useLocation();

	if (!battles || !battles[battleId]) return null
	
	const battle = battles[battleId];

	const getInitiative = (participant) => {
		if (pcs[participant.character]) {
			return pcs[participant.character].initiative ?? 0
		}
		return isNaN(participant.initiative) ? 0 : participant.initiative;
	}

	const updateBattle = (transform) => {
		console.log(
			battle.participants
		);
		db.collection('battles').doc(battleId).update({
			...battle,
			...transform(battle),
		});
	}

	const endRound = () => {
		updateBattle((battle) => ({
			participants: battle.participants
				.sort((a, b) =>  getInitiative(b) - getInitiative(a))
		}))
	};
	
	return (
		<div>
			<ActionBase>
				<BottomNavigationAction
					label="Edit"
					icon={ <EditIcon /> }
					onClick={ () => setEditing(true) }
				/>
				<BottomNavigationAction
					label="Add Character"
					icon={ <CharacterIcon /> }
					onClick={ () => setAdding(true) }
				/>
				<BottomNavigationAction
					label="End Round"
					icon={ <EndRoundIcon /> }
					onClick={ () => endRound(db, battle, battle.characters) }
				/>
				<BottomNavigationAction
					label="Delete"
					icon={ <DeleteIcon /> }
					onClick={ () => setDeleting(true) }
				/>
			</ActionBase>
			<EditBattleDialog
				open={ editing }
				battle={ battle }
				onClose={() => {
					setEditing(false);
				}}
				onChange={ (name) => (event) => {
					updateBattle((battle) => ({
						...battle,
						[name]: event.target.value,
					}));
				}}
				onSave={ () => {
					db.collection('battles').doc(battleId).update(battle);
					setEditing(false);
				}}
			/>
			<AddCharacterDialog
				open={ Boolean(adding) }
				characters={{
					...npcs,
					...pcs,
				}}
				onClose={ () => setAdding(false) }
				onDone= { (character) => {
					if (!character) {
						updateBattle((battle) => ({
							participants:[
								...(battle.participants || []),
								{character:null, name: 'Placeholder', onslaught: 0, initiative:0}
							]
						}))
					} else {
						updateBattle((battle) => ({
							participants: [
								...(battle.participants || []),
								{ character, name: pcs[character]?.name ?? npcs[character]?.name, onslaught: 0, initiative:0 }
							]
						}))
					}
					setAdding(false);
				}}
				allowPlaceholders
			/>
			<ConfirmDialog
				open={ Boolean(deleting) }
				onClose={ () => setDeleting(false) }
				onConfirm={() => {
					history.push(pathname.split('/').slice(0, -2).join('/'));
					db.collection('battles').doc(battleId).delete();
					setDeleting(false);
				}}
				text={ battle ? 'Do you want to delete the battle "' + battle.name + '"?' : null }
			/>
		</div>
	)
}

export default BattleActions

import React, { useContext, useState } from "react";

import { Firestore, Loom } from "@contexts";
import { Select, NameValue } from "@loom/ui";
import { GenericDialog } from "@loom/help";

export const Edit = ({ merit, onChange }) => {
	const { hearthstones } = useContext(Loom);

	console.log(hearthstones);

	return (
		<div className="flex">
			<Select
				label={merit.key}
				value={merit.details?.hearthstone ?? ""}
				onChange={(value) => onChange({ hearthstone: value })}
				options={Object.entries(hearthstones)
					.sort(([_a, a], [_b, b]) => a.name.localeCompare(b.name))
					.map(([key, value]) => ({ id: key, label: value.name }))}
			/>
		</div>
	);
};

export const View = ({ merit }) => {
	const [viewing, setViewing] = useState(false);
	const { hearthstones } = useContext(Loom);
	const { addExtraContent } = useContext(Firestore);
	const name = hearthstones[merit.details?.hearthstone]?.name ?? "";

	addExtraContent("hearthstones", merit.details?.hearthstone);

	return (
		<>
			<NameValue
				name={
					<>
						<strong>{merit.key}</strong>
						{` ${name}`}
					</>
				}
				value={merit.value}
				onClick={() => setViewing(true)}
			/>
			<GenericDialog
				open={viewing}
				title={`Hearthstone: ${name}`}
				content={hearthstones[merit.details?.hearthstone]?.description ?? ""}
				onClose={() => setViewing(false)}
			/>
		</>
	);
};

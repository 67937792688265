import React, { useEffect, useState } from 'react';

import { Tab, Tabs, Toolbar, useMediaQuery } from '@mui/material';

const TabContainer = ({
    tabs: children,
    children: renderFunc,
    tab: initialTab,
    onChange,
}) => {
    const [ tab, setTab ] = useState();
    const mobile = useMediaQuery('(max-width:600px)', { noSsr: true });

    const tabs = children.filter ? (
        children
            .filter((c) => c?.props?.__identifier === 'TAB')
            .map((t) => {
                const { render, ...props } = t.props;
                return <Tab { ...props } key={ t.props.value } />
            })
    ) : []

	useEffect(() => {
		const tabVals = children.filter ? (
            children
                .filter((t) => t).map((t) => t.props.value)
        ) : [];

		if (!tab) setTab(tabVals.includes(initialTab) ? initialTab : tabVals[0]);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ initialTab, children ])

    const content = children.filter && children
        .filter((c) => c?.props?.__identifier !== 'TAB' || c?.props?.value === tab)
        .map((c, idx) => (
            <React.Fragment key={ c?.props?.value || idx }>{ c?.props?.__identifier === 'TAB' ? c.props.render : c }</React.Fragment>
        ))
    if (!tab || tabs.length === 0) return renderFunc(null, children);
   
    return renderFunc(
        <Toolbar className="tabs">
            <Tabs
                value={ tab }
                onChange={ (e, value) => {
                    setTab(value);
                    if (onChange) onChange(e, value);
                }}
                indicatorColor="primary"
                centered={ mobile }
                variant={ mobile ? 'scrollable' : undefined }
                allowScrollButtonsMobile
            >
                { tabs }
            </Tabs>
        </Toolbar>, content
    )
}

export default TabContainer;
/* eslint-disable no-unused-vars */
import firebase from 'firebase/app';
import { useState } from 'react';

const Migrate = () => {
    const db = firebase.firestore();

    const [ done, setDone ] = useState([]);
    const [ from, setFrom ] = useState('');
    const [ to, setTo ] = useState('');

    const getCharacter = async (id) => {
        if (!id) return;

        try {
            const qs = await db.collection('characters').where('__name__', '==', id).get()
            return await qs.docs[0].data();
        } catch(e) {
            return;
        }
    }

    return <>
        <p>
            Migrating all content from&nbsp;
            <input value={ from } onChange={(e) => setFrom(e.target.value)} />
        </p>
        <button onClick={ async () => {
            const cqs = await db.collection('characters').get();
            const cdata = cqs.docs.map((d) => [d.id, d.data()])
            await Promise.all(cdata.map(([id, p]) => p));
            const characters = Object.fromEntries(cdata);

            db.collection('characters').where('type', '==', 'pc').get().then((qs) => {
                qs.docs.forEach(async (doc) => {
                    const docdata = doc.data();
                    if (!docdata.merit_notes) return
                    if (docdata.owner === 'L6SlwrT3KtVWw94GFGvkcAkIMIv1') return

                    const meritChanges = await Promise.all(
                        (docdata.merits ?? []).map(async (m) => {
                            const { id, key, value, ...extra } = m;

                            const data = { id, key, value  }
                                        
                            if (key === 'Manse') {
                                data.details = {
                                    name: extra.details ?? '',
                                    hearthstone: extra.hearthstone ?? null,
                                    description: extra.description ?? '',
                                }
                            } else if (key === 'Hearthstone') {
                                data.details = {
                                    hearthstone: docdata.merit_notes?.[id],
                                }
                            } else if (['Allies', 'Command', 'Retainers', 'Followers', 'Mentor', 'Familiar'].includes(key)) {
                                const char = await getCharacter(docdata.merit_notes?.[id]);
                                data.details = { character: char ?? {} }
                            } else if (key === 'Language') {
                                data.details = { language: docdata.merit_notes?.[id] }
                            } else if (key === 'Artifact') {
                                data.details = { artifact: docdata.merit_notes?.[id] ?? null }
                            } else if (key === 'Custom') {
                                data.details = {
                                    name: extra.details ?? '',
                                    description: extra.description ?? '',
                                }
                            } else if (docdata.merit_notes?.[id]) {
                                data.details = { description: docdata.merit_notes?.[id] ?? '' }
                            }
                            return [ id, data ];
                        })
                    );
                    const newMerits = Object.fromEntries(meritChanges);
                    db.collection('characters').doc(doc.id).update({
                        merits: newMerits,
                        merit_notes: firebase.firestore.FieldValue.delete()
                    })
                })
            })
            
            db.collection('chronicles').get().then((qs) => {
                qs.docs.forEach((doc) => {
                    const chronicle = doc.data();
                    if (chronicle.owner === 'L6SlwrT3KtVWw94GFGvkcAkIMIv1') return

                    const pcs = [];
                    const npcs = {};

                    for (const char of chronicle?.characters ?? []) {
                        if (characters[char]?.type === 'npc') {
                            npcs[char] = characters[char];
                        } else {
                            pcs.push(char);
                        }
                    }

                    db.collection('chronicles').doc(doc.id).update({
                        pcs: pcs,
                        npcs: npcs,
                    })
                });
            })
            db.collection('stories').get().then((qs) => {
                qs.docs.forEach((doc) => {
                    const story = doc.data();
                    if (story.owner === 'L6SlwrT3KtVWw94GFGvkcAkIMIv1') return
                    const pcs = [];
                    const npcs = {};

                    for (const char of story?.characters ?? []) {
                        if (characters[char]?.type === 'npc') {
                            npcs[char] = characters[char];
                        } else {
                            pcs.push(char);
                        }
                    }

                    db.collection('stories').doc(doc.id).update({
                        pcs: pcs,
                        npcs: npcs,
                    })
                });
            })            
            db.collection('locales').get().then((qs) => {
                qs.docs.forEach((doc) => {
                    const locale = doc.data();
                    if (locale.owner === 'L6SlwrT3KtVWw94GFGvkcAkIMIv1') return
                    const pcs = [];
                    const npcs = {};

                    for (const char of locale?.characters ?? []) {
                        if (characters[char]?.type === 'npc') {
                            npcs[char] = characters[char];
                        } else {
                            pcs.push(char);
                        }
                    }

                    db.collection('locales').doc(doc.id).update({
                        pcs: pcs,
                        npcs: npcs,
                    })
                });
            })
            db.collection('characters').where('type', '==', 'npc').get().then((qs) => {
                qs.docs.forEach(async (doc) => {
                    const data = await doc.data();
                    if (data.owner === 'L6SlwrT3KtVWw94GFGvkcAkIMIv1') return
                    if (!data.public) {
                        doc.ref.delete();
                    }
                });
            });
        }}>Go</button>
        <ul>{ done.map((d) => (
            <li key={ d[1] }>{ d[0] } - { d[1] }</li>
        ))}</ul>
    </>;
}

export default Migrate
import React, { Component } from 'react'

import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';

class TextFieldDialog extends Component {
	constructor(props) {
		super(props)
		this.state = {
			value: props.value
		}
	}
	
	render() {
		const { open, fullScreen, onClose, onDone, text, label } = this.props

		return (
			<Dialog
				open={ open }
				fullScreen={ fullScreen }
				onClose={ onClose }
			>
				<DialogContent>
					<DialogContentText>{ text }</DialogContentText>
					<TextField
						label={ label }
						value={ this.state.value ? this.state.value : '' }
						onChange={(e) => { this.setState({value:e.target.value}) }}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						color="primary"
						onClick={ () => { onDone(this.state.value); onClose() } }
					>Done</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

export default TextFieldDialog;

import React, { Component } from 'react'

import TextField from '@mui/material/TextField'

class EditLocale extends Component {
	
	render() {
		const { locale, onChange } = this.props
		
		return (
			<div>
				<TextField
					label="Name"
					value={ locale.name }
					onChange={ onChange('name') }
					fullWidth
				/>
				<TextField 
					label="Description"
					value = { locale.description }
					onChange={ onChange('description') }
					multiline
					fullWidth
				/>
				<TextField 
					label="Introduced Facts"
					value={ locale.facts }
					onChange={ onChange('facts') }
					multiline
					fullWidth
				/>
				<TextField 
					label="Storyteller's Notes"
					value={ locale.notes }
					onChange={ onChange('notes') }
					multiline
					fullWidth
				/>
			</div>
		)
	}
}

export default EditLocale

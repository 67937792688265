import React, { useContext } from 'react'

import { Box, Grid, Markdown } from '@loom/ui'

import ViewContext from './context';

const DescriptionTab = ({
	extratext
}) => {
	const { character } = useContext(ViewContext);
	return (
		<Grid>
			{ character.portrait && (
				<Box title={ character.name } nopad>
					<img src={ character.portrait } alt="Portrait" />
				</Box>
			)}
			<Box title={ !character.portrait ? character.name : 'Description' }>
				<Markdown source={ (extratext ? extratext + '\n\n---\n\n' : '') + character.description } />
			</Box>
		</Grid>
	)
};

export default DescriptionTab

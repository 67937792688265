import React, { useEffect, useMemo, useState } from 'react';
import debounce from 'debounce';
import centroid from 'centroider';

import './map.less';

const maxWidth = 2890;
const minZoom = 300;

const Map = ({ width: initialWidth, mode, markers, center : initialCenter, maxZoom, zoom: initialZoom, onMarkerPlaced, onZoomChanged, scrollable }) => {
	const map = React.createRef()
	const [ zoom, setZoom ] = useState(initialZoom || maxZoom || 54.5);
    const [ zooming, setZooming ] = useState(false);
	const [ line, setLine ] = useState([]);
    const [ center, setCenter ] = useState();

	const circleRadius = 10;
	const width = initialWidth || maxWidth;
	const mapSize = useMemo(() => ({
		width,
		height: width * 0.647058824,
	}), [width]);

    useEffect(() => {
        if (initialCenter) setCenter(initialCenter);
        else if (markers && markers.length > 0) {
			const c = centroid(markers.map((m) => [m.x, m.y]))
			setCenter({x: c[0], y: c[1]});
		}
        else setCenter(mapSize.width / 2, mapSize.height / 2);
    }, [initialCenter, markers, mapSize, setCenter]);

    useEffect(() => {
        const mouseup = () => setZooming(false);
        window.addEventListener('mouseup', mouseup );
        return () => window.removeEventListener('mouseup', mouseup);
    }, [])

    useEffect(() => {
        const viewport = map.current.parentNode;
        const viewportBounds = viewport.getBoundingClientRect();
        if (!viewport || !center) return;
        viewport.scrollLeft = center.x * (zoom / 100) - (viewportBounds.width / 2);
        viewport.scrollTop = center.y * (zoom / 100) - (viewportBounds.height / 2);
    }, [map, center, zoom])

	useEffect(() => {
		const drawCircle = (point, color) => {
			let context = map.current.getContext('2d');
			context.lineWidth = 2;
			context.setLineDash([])
			context.beginPath();
			context.arc(point.x * (zoom / 100), point.y * (zoom / 100), circleRadius, 0, 2 * Math.PI, false);
			context.fillStyle = color ? color : '#00FF00';
			context.fill();
			context.strokeStyle = '#003300';
			context.stroke();
		}
		
		map.current.setAttribute('width', mapSize.width * (zoom / 100));
		map.current.setAttribute('height', mapSize.height * (zoom / 100));

		const context = map.current.getContext('2d');
		context.clearRect(0, 0, mapSize.width * (zoom / 100), mapSize.height * (zoom / 100));

		context.font = '900 18px "Arial"';
		context.strokeStyle = '#FFFFFF';
		context.fillStyle = '#444444';
		
		if (markers) {
			markers.forEach((marker) => {
				const metrics = context.measureText(marker.label)
				var x = (marker.x - ((metrics.width / (zoom / 100)) / 2)) * (zoom / 100)
				var y = marker.y * (zoom / 100) - (circleRadius + 10)
				context.lineWidth = 4;
				context.strokeText(marker.label, x, y);
				context.fillText(marker.label, x, y);
			})
			markers.forEach((marker) => {
				drawCircle({x:marker.x, y:marker.y}, '#FF0000')
			})
		}

		context.lineWidth = 2;
		if (line.length > 1) {
			context.beginPath();
			context.strokeStyle = '#000000';
			context.setLineDash([])
			for (var i in line) {
				console.log(line[i]);
				if (i > 0) {
					context.moveTo(line[i - 1].x * (zoom / 100), line[i - 1].y * (zoom / 100));
					context.lineTo(line[i].x * (zoom / 100), line[i].y * (zoom / 100));
					context.stroke();
				}
			}
			context.moveTo(line[0].x * (zoom / 100), line[0].y * (zoom / 100));
			context.lineTo(line[line.length - 1].x * (zoom / 100), line[line.length - 1].y * (zoom / 100));
			context.setLineDash([5,5])
			context.stroke();
		}
		line.map((p) => drawCircle(p))
	}, [ map, line, markers, zoom, mapSize ])
	
	const transformPoint = (x, y) => {
		const getOffset = (element) => {
			if (!element.getClientRects().length) {
				return { top: 0, left: 0 };
			}

			let rect = element.getBoundingClientRect();
			let win = element.ownerDocument.defaultView;
			return ({
				top: rect.top + win.pageYOffset,
				left: rect.left + win.pageXOffset
			});   
		}
		
		let offset = getOffset(map.current)
		let zfactor = 100 / zoom
		return {x:(x - offset.left) * zfactor, y:(y - offset.top) * zfactor};
	}

    return (
		<div className="map"
            onMouseMove={ (e) => debounce((e) => {
                if (zooming) {
                    if (!map.current) return;
                    const viewport = map.current.parentNode;
                    const viewBounds = viewport.getBoundingClientRect();
                    const distance = e.clientY - viewBounds.top;
                    const zoomPerPixel = (minZoom - maxZoom) / viewBounds.height;
                    console.log(maxZoom, distance, zoomPerPixel);
                    const zoom = Math.min(minZoom, maxZoom + distance * zoomPerPixel);
					if (Number.isNaN(zoom)) return;
                    setZoom(zoom);
                    if (onZoomChanged) onZoomChanged(zoom)
                }
            }, 100)(e) }
        >
			<div className={ `viewport ${scrollable ? 'scrollable' : ''}` }>
				<canvas
					ref={ map }
					onClick={ (e) => {
						if (mode === 'place-marker') {
							console.log('BOOP', onMarkerPlaced)
							if (onMarkerPlaced) onMarkerPlaced(transformPoint(e.pageX, e.pageY));
						} else {
							setLine([...line, transformPoint(e.pageX, e.pageY)]);
						}
					}}
					onContextMenu={ (e) => {
						e.preventDefault();
						setLine([]);
					}}
				></canvas>
				<div className="zoom">
					<div className="handle"
                        style={{ top: 'calc(' + ((1 / ((minZoom - maxZoom) / zoom)) * 100) + '% - 16px)' }}
						onMouseDown={ (e) => {
                            setZooming(true);
                            e.preventDefault();
						}}
					/>
				</div>
			</div>
		</div>
	)
}

export default Map
export { maxWidth }

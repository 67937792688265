import React, { useContext, useEffect, useState } from 'react'

import { Loom } from '@contexts';
import { Autocomplete, Button, DialogActions, DialogContent, TextField } from '@mui/material'

import { Dialog } from '@loom/ui';

const getFilteredCharacters = (characters, exclude, filter) => {
	if (filter === undefined) filter = '';
	if (!exclude) exclude = [];

	return Object.keys(characters)
		.filter((i) => (
			characters[i].name.toLowerCase().includes(filter.toLowerCase()) &&
			(exclude === undefined || exclude.indexOf(i) < 0)
		))
		.map((i) => ({label: characters[i].name, value:i}))
}

const AddCharacterDialog = ({
	characters: allCharacters, exclude, open, onClose, onDone
}) => {
	const [ characters, setCharacters ] = useState();
	const [ character, setCharacter ] = useState();
	const { pcs, npcs } = useContext(Loom);

	useEffect(() => {
		setCharacters(getFilteredCharacters({...allCharacters ?? { ...pcs, ...npcs }}, exclude));
	}, [ allCharacters, pcs, npcs, exclude ]);
	
	if (!characters) return null;

	return (
		<Dialog
			open={ open }
			onClose={ onClose }
			maxWidth="xs"
			fullWidth
		>
			<DialogContent>
				<Autocomplete
					options={ characters }
					onChange={ (e, opt) => setCharacter(opt?.value || null) }
					renderInput={ ({ ...params }) => <TextField {...params} label="Select a Character" disabled={ characters.length === 0 } fullWidth /> }
				/>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={ onClose }
				>Cancel</Button>
				<Button
					variant="contained"
					color="primary"
					disabled={ !character }
					onClick={() => { 
						onDone(character)
						onClose()
					}}
				>Add</Button>
			</DialogActions>
		</Dialog>
	);
};

export default AddCharacterDialog;

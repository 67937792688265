import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import DefaultLogin from './login';

const AuthListener = ({
    context: Context,
    requireAuth,
    children,
    login: Login,
}) => {
    const [ user, setUser ] = useState(null);

    useEffect(() => {
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
              setUser(user);
            } else {
              setUser(null);
            }
        });
    });

    if (!user && requireAuth) {
        return (
            (Login ? <Login /> : <DefaultLogin />)
	    );
    }

    return (
        <Context.Provider value={{ user }}>{ children }</Context.Provider>
    );
}

export default AuthListener;
import React from 'react';

const Loom = React.createContext({
    pcs: [],
    npcs: [],
    artifacts: [],
    hearthstones: [],
	organisations: [],
	battles: [],
    setTitle: (title) => document.title = `Loom: ${title || 'Character and Campaign Management for Exalted 3E'}`,
});

export default Loom;

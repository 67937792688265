import React, { useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import { BottomNavigationAction } from "@mui/material";

import BasePage from "@loom/pages";
import Chronicle from "@loom/chronicles/view";
import { ChronicleIcon } from "@loom/ui/icons";

import { Auth, Firestore, Loom } from "@contexts";

const List = () => {
	const { db } = useContext(Firestore);
	const { user } = useContext(Auth);
	const { setTitle } = useContext(Loom);
	const history = useHistory();

	setTitle();

	return (
		<BasePage
			actions={
				<BottomNavigationAction
					label="Add Chronicle"
					icon={<ChronicleIcon />}
					onClick={async () => {
						const doc = await db
							.collection("chronicles")
							.add({ name: "<Unnamed Chronicle>", owner: user.uid });
						history.push(`/chronicle/${doc.id}`);
					}}
				/>
			}
		/>
	);
};

const View = () => {
	const { id } = useParams();
	const { addExtraContent } = useContext(Firestore);
	const { chronicles, setTitle } = useContext(Loom);

	useEffect(() => {
		addExtraContent("chronicles", id);
	}, [addExtraContent, chronicles, id]);

	if (!chronicles) return null;

	setTitle(chronicles[id]?.name);

	return <Chronicle chronicleId={id} />;
};

export { View, List };
export default View;

import React, { useContext, useEffect, useState } from 'react';

const query = ({ 
	query: _queryset,
	transform,
	setData
}) => {
	const queryset = Array.isArray(_queryset) ? _queryset : [_queryset];
	const clean = queryset.map((q) => {
		return q.onSnapshot((snapshot) => {
			let newData = {}
			snapshot.forEach((doc) => {
				newData[doc.id] = doc.data()
			})
			if (transform) {
				newData = transform(newData);
			}
			setData((prev) => {
				return {
					...(prev || {}),
					...(newData || {}),
				};
			});
		});
	});
	return () => { clean.forEach((c) => c()) }
};

const QueryListener = ({
	context: Context,
	queries: _queries,
	transform,
	children,
}) => {
	const [ data, setData ] = useState({});
	const originalData = useContext(Context);

	useEffect(() => {
		const unsubs = Object.entries(_queries).map(([key, query]) => {
			const queries = Array.isArray(query) ? query : [query];
			return queries.filter((v) => Boolean(v)).map((query) => (
				query.onSnapshot((qs) => {
					const removed = [];
					const newData = {}
					qs.docChanges().forEach((change) => {
						if (change.type === 'removed') {
							removed.push(change.doc.id);
						} else {
							newData[change.doc.id] = change.doc.data()
						}
					});
					const rawData = {
						[key]: newData
					};
					if (transform && transform[key]) {
						Object.entries(transform[key]).forEach(([newKey, t]) => {
							rawData[newKey] = t({ ...rawData });
						});
					}
					setData((prev) => {
						return Object.fromEntries(
							[...new Set([
								...Object.keys(prev || {}),
								...Object.keys(rawData || {})
							])]
							.map((key) => {
								return (
									[key, Object.fromEntries(
										Object.entries({
											...(prev[key] || {}),
											...(rawData[key] || {}),
										})
										.filter(([key, obj]) => !removed.includes(key))
									)]
								)
							})
						)
					})
				})
			))
		});
		return () => {
			unsubs.forEach((uns) => {
				uns.forEach((u) => u());
			})
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [_queries]);

	return (
		<Context.Provider value={{ ...originalData, ...data }}>
			{ children }
		</Context.Provider>
	);
};

export default QueryListener;
export { query, QueryListener };
import React, { useContext, useState } from 'react'

import { AppBar, Button, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import { CharacterIcon, DetailsIcon, BattleIcon, SocialIcon } from '@loom/ui/icons';
import { Dialog, Tab, TabContainer } from '@loom/ui';
import { Loom } from '@contexts';

import ViewContext from './context';
import DescriptionTab from './description';
import GeneralTab from './general';
import CombatTab from './combat';
import SocialTab from './social';

import EditDialog from '../edit/dialog';

import './dialog.less';

const UI = ({ children, onClose }) => {
	const [ tab, setTab ] = useState('general');
	const [ editing, setEditing ] = useState(false);
	const { character, onChange } = useContext(ViewContext);
	
	return (
		<TabContainer
			tab={ tab }
			tabs={ children }
			onChange={ (e,value) => { setTab(value) }}
		>{
			(tabs, content) => (<>
					<Dialog open={ true } maxWidth="xl" fullWidth>
						<DialogTitle>
							{ tabs && <>
								<AppBar color="secondary" className="toolbar" position="static">
									{ tabs }
								</AppBar>
							</>}
						</DialogTitle>
						<DialogContent>{
							content
						}</DialogContent>
						<DialogActions>
							<Button onClick={ onClose }>Close</Button>
							<Button color="primary" variant="contained" onClick={ () => setEditing(true) }>Edit</Button>
						</DialogActions>
					</Dialog>
				<EditDialog
					open={ editing }
					character={ editing ? character : false }
					onClose={ () => setEditing(false) }
					onSave={ (data) => {
						onChange(data);
					}}
				/>
			</>)
		}</TabContainer>
	)
}

const NPCDialog = ({
	open,
	characterId,
	data,
	onChange,
	onClose
}) => {
	const { npcs } = useContext(Loom);

	if (!open) return null;

	console.log({
		...(npcs[characterId] ?? {}),
		...data,
	});

	return (
		<ViewContext.Provider value={{
			character: {
				...(npcs[characterId] ?? {}),
				...data,
			},
			onChange
		}}>
			<UI onClose={ onClose }>
				<Tab value="description" label="Description" icon={ <CharacterIcon /> } render={ <DescriptionTab key="description" /> } />
				<Tab value="general" label="General" icon={ <DetailsIcon /> } render={ <GeneralTab key="general" /> } />
				<Tab value="combat" label="Combat" icon={ <BattleIcon /> } render={ <CombatTab key="combat" /> } />
				<Tab value="social" label="Social" icon={ <SocialIcon /> } render={ <SocialTab key="social" /> } />
			</UI>
		</ViewContext.Provider>
	);
};

export default NPCDialog;

import React, { useContext, useState } from 'react';

import { Button, DialogContent, DialogTitle, TextField, MenuItem, InputAdornment } from '@mui/material';

import data from '@data';
import { Box, Grid, Dialog, DialogActions } from '@loom/ui';

import EditContext from '../context';
import { getSplatProp } from '@loom/characters/functions';

const Training = () => {
	const { character, onChange } = useContext(EditContext);
	const [ log, setLog ] = useState(false);
	const [ costs, setCosts ] = useState();
	const [ training, setTraining ] = useState();
	const [ completed, setCompleted ] = useState();

	if (!character.experienced || character.xplog?.length <= 0) return null;

	const save_log = (item, costs, training, complete) => {
		onChange('xplog', item._index)({
			costs:{
				regular:costs.regular ? parseInt(costs.regular, 10) : 0,
				specialty:costs.specialty ? parseInt(costs.specialty, 10) : 0,
				training:costs.training
			},
			training: training ? parseInt(training, 10) : 0
		})
		
		if (complete) {
			var log = character.completed_training ? character.completed_training : []
			onChange()({
				xplog: character.xplog.filter((_,i) => i !== item._index),
				completed_training: [...log, item]
			})
		}
	}

	return (
		<Box
			title="Training Log"
			actions={[
				[ 'Train All', () => onChange('xplog')(character.xplog.map((log) => ({ ...log, training: Math.min(log.training + 1, log.costs.training) }))) ]
			]}
		>
			<div className="training">
				{ character.xplog && character.xplog.map((item, i) => ({...item, _index:i})).reverse().map((item, i) => (
					<MenuItem
						key={ i }
						onClick={() => {
							setLog(item);
							setCosts(item.costs);
							setTraining(item.training);
							setCompleted(item.completed);
						}}
					>
						<div>{ item.description }</div>
						<div>{ item.completed ? 'Training Complete' :  (item.costs.training - item.training) + ' Days' }</div>
						<div>
							{ item.costs.regular ? item.costs.regular + ' XP' : '' }
							{ item.costs.specialty && item.costs.regular ? ', ' : '' }
							{ item.costs.specialty ? item.costs.specialty + ' ' + getSplatProp(character, 'xpsymbol', 'XXP') : '' }
						</div>
						<div>{
							item.costs.training - item.training <= 0 ?
								<Button
									variant="contained"
									color="primary"
									onClick={(e) => {
										e.stopPropagation()
										save_log(item, item.costs, item.training, true)
									}}
								>Complete</Button>
							: (
								<Button
									variant="contained"
									onClick={(e) => {
										e.stopPropagation()
										save_log(item, item.costs, item.training ? item.training + 1 : 1)
									}}
								>Train</Button>
							)
						}</div>
					</MenuItem>
				))}
			</div>
			<Dialog open={ Boolean(log) } maxWidth="sm" fullWidth>
				<DialogTitle>
					Training Details
				</DialogTitle>
				<DialogContent>
					<p>{ log ? log.description : '' }</p>
					<Grid size="extra-small">
						<TextField
							label="Training Time"
							value={ training ? training : 0 }
							className="training-time"
							onChange={ (e) => setTraining(e.target.value) }
							InputProps={{
								endAdornment: <InputAdornment position="end">{ `of ${costs?.training ?? 0} Days` }</InputAdornment>
							}}
						/>
						<TextField
							label="Regular XP"
							value={ costs?.regular ? costs.regular : 0 }
							onChange={ (e) => setCosts({...costs, regular: e.target.value}) }
							fullWidth
						/>
						<TextField
							label={ getSplatProp(character, 'xpname', 'Splat') + ' XP' }
							value={ costs?.specialty ? costs?.specialty : 0 }
							onChange={ (e) => setCosts({ ...costs, specialty: e.target.value }) }
							fullWidth
						/>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						color="error"
						onClick={() => {
							if (log.type === 'willpower') {
								onChange('willpower')(log.data.oval);
							} else {
								onChange(log.data.name)(log.data.oval);
							}
							onChange('xplog')(character.xplog.filter((_, i) => i !== log._index));
							setLog(null);
						}}
					>{ completed ? 'Revert' : 'Remove' }</Button>
					<div>
						<Button
							onClick={() => {
								setLog(null)
							}}
						>Cancel</Button>
						<Button
							variant="contained"
							onClick={() => {
								save_log(log, costs, training);
								setLog(null);
							}}
						>Update</Button>
						{ parseInt(training, 10) >= (costs?.training || 0) ?
							<Button
								color="primary"
								variant="contained"
								onClick={() => {
									save_log(log, costs, training, true)
									setLog(null)
								}}
							>Complete</Button>
						: '' }
					</div>
				</DialogActions>
			</Dialog>
		</Box>
	);
}

export default Training;
import React from 'react'

import { MenuList } from '@mui/material';

import { Box } from '@loom/ui';
import { getMerits } from '@loom/characters/functions';
import { View as MeritView } from '@loom/characters/pc/merits/merit';

const MeritList = ({
	character
}) => {
	return <>
		<Box title="Merits">
			<MenuList>{
				Object.entries(getMerits(character))
					.filter(([_, { key }]) => key !== 'Language')
					.sort(([_a, a], [_b, b]) => a?.key?.localeCompare?.(b?.key))
					.map(([id, merit]) => {
						return <MeritView key={ id } meritId={ id } merit={ merit } />
					})
			}</MenuList>
		</Box>
	</>
}

export default MeritList;
import React, { useContext } from 'react'

import { Loom } from '@contexts';

import PCDialog from './pc/view/dialog'
import NPCDialog from './npc/view/dialog'

const CharacterDialog = (props) => {
	const { characters } = useContext(Loom);

	if (!props.characterId) return null;

	if (characters[props.characterId].type === 'pc') return <PCDialog { ...props } />
	return <NPCDialog { ...props } />
}

export default CharacterDialog;

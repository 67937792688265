import React, { useContext } from 'react';
import { useSnackbar } from 'notistack';

import { Button, List, ListItem, TextField } from '@mui/material';

import { getCraftScore, startTraining, xplog, getSplatProp } from '@loom/characters/functions';
import { Box, Dots } from '@loom/ui';
import { RemoveIcon } from '@loom/ui/icons';

import EditContext from '../context';
import { isFavoured } from './utils';

const Crafts = () => {
	const { character, onChange } = useContext(EditContext);
	const { enqueueSnackbar } = useSnackbar();
	
	return (
		<Box
			title="Crafts"
			actions={<>
				{ character.supernal !== 'craft' &&
					<Button
						color={ isFavoured(character, 'craft') ? 'primary' : 'secondary' }
						variant="contained"
						onClick={() => {
							if (isFavoured(character, 'craft')) {
								onChange('favoured')([ ...(character.favoured ? character.favoured : []).filter((a) => a !== 'craft') ])
							} else {
								onChange('favoured')([...(character.favoured ? character.favoured : []), 'craft'])
							}
						}}
					>Favoured</Button>
				}
				{ getSplatProp(character, 'favours', []).indexOf?.('craft') >= 0 &&
					<Button
						color={ character.supernal === 'craft' ? 'primary' : 'secondary' }
						variant="contained"
						onClick={ () => onChange('supernal')(character.supernal === 'craft' ? '' : 'craft') }
					>
					Supernal</Button>
				}
				<Button
					color="secondary"
					variant="contained"
					onClick={ () => {
						onChange('crafts')([ ...(character.crafts ? character.crafts : []), {name:'', value:0} ])
					}}
				>Add</Button>
			</>}
			>
			<List className="grid extra-large">
				{ character.crafts && character.crafts.map((craft, i) => (
					<ListItem className="columns key-value-delete" key={ i }>
						<TextField
							value={ craft.name }
							onChange={(event) => {
								onChange('crafts', i)({...craft, name:event.target.value})
							}}
						/>
						<Dots
							value={ getCraftScore(character, craft.name) }
							max="5"
							className="text-dots"
							onChange={(val) => {
								if (character.experienced) {
									startTraining(character, xplog(character, 'craft', craft.name, craft.value, val, null, null, null, craft.name), onChange, enqueueSnackbar)
								} else {
									onChange('crafts', i)({...craft, value:val})
								}
							}}
							editable
						/>
						<RemoveIcon
							className="text-remove"
							onClick={ () => {
								if (character.experienced) {
									onChange('xplog')([...(character.xplog ? character.xplog : []), xplog(character, 'craft', craft.name, craft.value, null)])
								}
								onChange('crafts')(character.crafts.filter((_, j) => i !== j))
							}}
						/>
					</ListItem>
				))}
			</List>
		</Box>
	)
}

export default Crafts;
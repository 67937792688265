import React, { useContext, useEffect, useState } from 'react'
import { Button, Checkbox, Chip, DialogTitle, DialogContent, FormControlLabel, MenuItem, TextField } from '@mui/material';

import { Auth, Firestore, Loom } from '@contexts';
import data from '@data'
import { Box, Dialog, DialogActions, Grid, EditCharmList } from '@loom/ui';

const EditArtifact = ({ onClose, artifactId, open }) => {
	const [ artifact, setArtifact ] = useState();
	const { db } = useContext(Firestore);
	const { user } = useContext(Auth);
	const { artifacts } = useContext(Loom);

	useEffect(() => {
		setArtifact(artifacts[artifactId]);
	}, [artifacts, artifactId, setArtifact])

	if (!artifact) return null;

	return (
		<Dialog
			open={ open }
			onClose={ onClose }
			maxWidth="md"
			fullWidth
		>
			<DialogTitle>{ artifact.name }</DialogTitle>
			<DialogContent>
				<Grid size="large" nopad>
					<Box title="Details" nopad >
						<Grid size="medium" centered>
							<TextField 
								label="Name"
								value={ artifact.name }
								onChange={ (e) => setArtifact({ ...artifact, name: e.target.value })}
								fullWidth
							/>
							<TextField
								label="Type"
								value={ artifact.type ? artifact.type : '' }
								onChange={ (e) => setArtifact({ ...artifact, type: e.target.value })}
								fullWidth select
							>
								<MenuItem value="armour">Armour</MenuItem>
								<MenuItem value="weapon">Weapon</MenuItem>
								<MenuItem value="misc">Miscellaneous</MenuItem>
							</TextField>
							{ (artifact.type === 'weapon' || artifact.type === 'armour') && (
								<TextField
									label="Category"
									value={ artifact.category ? artifact.category : '' }
									onChange={ (e) => setArtifact({ ...artifact, category: e.target.value })}
									fullWidth select
								>
									{ Object.keys(data[artifact.type].categories.artifact).map((cat, i) => (
										<MenuItem value={ cat } key={ i }>{ cat }</MenuItem>
									))}
								</TextField>
							)}
							<TextField 
								label="Attunement Cost"
								type="number"
								value={ artifact.attunement ? artifact.attunement : 0 }
								onChange={ (e) => setArtifact({ ...artifact, attunement: parseInt(e.target.value, 10) })}
								fullWidth
							/>
							<TextField 
								label="Hearthstone Sockets"
								type="number"
								value={ artifact.sockets ? artifact.sockets : 0 }
								onChange={ (e) => setArtifact({ ...artifact, sockets: parseInt(e.target.value, 10) })}
								fullWidth
							/>
							{ data[artifact.type] && (<>
								<TextField
									label="Tags"
									value=""
									onChange={ (e) => setArtifact({
										...artifact,
										tags: [
											...(artifact.tags ? artifact.tags : []),
											e.target.value,
										]
									})}
									fullWidth select
								>
									{ data[artifact.type].tags.map((tag, i) => (
										<MenuItem value={ tag } key={ i }>{ tag }</MenuItem>
									))}
								</TextField>
								<div>
									{ artifact.tags ?
										artifact.tags.map((tag, i) => (
											<Chip
												key={ i }
												label={ tag }
												onDelete={() => {
													setArtifact({
														...artifact,
														tags: artifact.tags.filter((_,j) => j !== i)
													})
												}}
											/>
										))
									: '' }
								</div>
							</>)}
						</Grid>
						<Grid>
							<TextField 
								label="Description"
								value={ artifact.text }
								onChange={ (e) => setArtifact({ ...artifact, text: e.target.value })}
								multiline fullWidth
							/>
							<TextField 
								label="Attunement Benefit"
								value={ artifact.attunementBenefit }
								onChange={ (e) => setArtifact({ ...artifact, attunementBenefit: e.target.value })}
								multiline fullWidth
							/>
						</Grid>
					</Box>
					<EditCharmList
						title="Evocations"
						charms={ artifact.evocations ? artifact.evocations: [] }
						onAdd={ () => (value) => {
							setArtifact({
								...artifact,
								evocations: [ ...artifact.evocations ?? [], value ]
							})
						}}
						onUpdate={ (i) => (value) => {
							setArtifact({
								...artifact,
								evocations: artifact.evocations.map((e, idx) => idx === i ? value : e)
							});
						}}
						onDelete={ (i) => () => {
							setArtifact({
								...artifact,
								evocations: artifact.evocations.filter((_, idx) => idx !== i)
							})
						}}
					/>
				</Grid>
				{ user?.uid === 'L6SlwrT3KtVWw94GFGvkcAkIMIv1' && (
					<FormControlLabel control={
						<Checkbox value={ artifact.public } onChange={ (e) => setArtifact({ ...artifact, public: e.target.value }) } />
					} label="Public" />
				)}
			</DialogContent>
			<DialogActions>
				<div>
					<Button onClick={ onClose } >
						Cancel
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							db.collection('artifacts').doc(artifactId).update(artifact)
							onClose()
						}}
					>
						Ok
					</Button>
				</div>
			</DialogActions>
		</Dialog>
	)
}

export default EditArtifact;
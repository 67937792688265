import { useParams } from 'react-router-dom';

import Organisation from './view';

const View = () => {
	const { chronicle, id } = useParams();

	return (
		<Organisation
			chronicleId={ chronicle }
			organisationId={ id }
		/>
	);
};

export { View }
export default View
import React, { useEffect, useState } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@mui/material";

import data from "@data";
import {
	getAllCharms,
	getCasteOrFavoured,
	groupCharms,
	sorceryCharms,
	getSplatProp,
} from "@loom/characters/functions";

import "./chargen.less";

const Chargen = ({ character: initialCharacter }) => {
	const [dialog, setDialog] = useState();
	const [character, setCharacter] = useState({});

	useEffect(() => {
		setCharacter({ ...initialCharacter });
	}, [setCharacter, initialCharacter]);

	const chargen =
		character.splat && character.chargen
			? getSplatProp(character, 'chargen', {})[character.chargen]
			: null;
	if (!chargen) return "";

	const costs = getSplatProp(character, 'costs', {}).bp;
	const spent = {};

	const attribs = {};
	Object.keys(data.attributes).map((k) => {
		attribs[k] = Object.keys(data.attributes[k]).reduce(
			(o, v) => o + (character[v] ? character[v] : 1),
			0
		);
		return k;
	});
	const attrib_groups = Object.keys(attribs).sort(
		(a, b) => attribs[b] - attribs[a]
	);

	const casteOrFavoured = getCasteOrFavoured(character);
	spent.attributes = {};
	Object.keys(data.attributes).forEach((o) => {
		var group;
		if (o === attrib_groups[0]) group = "primary";
		else if (o === attrib_groups[1]) group = "secondary";
		else group = "tertiary";
		Object.keys(data.attributes[o]).forEach((a) => {
			if (!spent.attributes[group])
				spent.attributes[group] = { favoured: 0, nonfavoured: 0 };
			if (casteOrFavoured.includes(a))
				spent.attributes[group].favoured += character[a] ? character[a] : 1;
			else
				spent.attributes[group].nonfavoured += character[a] ? character[a] : 1;
		});
	});
	Object.keys(spent.attributes).map(
		(g) =>
			(spent[g] = Object.values(spent.attributes[g]).reduce(
				(o, v) => o + v,
				-3
			))
	);

	spent.colors = {
		attributes: "background",
		abilities: "background",
		charms: "background",
		merits: "background",
		bp: "background",
	};

	if (character.splat && character.subtype) {
		spent.caste =
			(getSplatProp(character, 'favours', []))
			.filter((abil) => (character.favoured ?? []).includes(abil))
				.length + (character.supernal ? 1 : 0);
		spent.favoured =
			(character.favoured ?? []).length + (character.supernal ? 1 : 0) -
			(getSplatProp(character, 'favoursAllCaste', false)
				? 0
				: Math.min(spent.caste, chargen.caste));
		spent.caste = Math.min(spent.caste, chargen.caste);
		if (character.merits) {
			spent.merits = Object.values(character.merits).reduce(
				(r, merit) => r + merit.value,
				0
			);
		} else {
			spent.merits = 0;
		}
		const favoured = casteOrFavoured;
	
		spent.favabilities = Object.keys(data.abilities)
			.filter((abil) => favoured.includes(abil) || character.supernal === abil)
			.reduce(
				(r, abil) => r + Math.min(character[abil] ? character[abil] : 0, 3),
				0
			);
		spent.nonfavabilities = Object.keys(data.abilities)
			.filter((abil) => !favoured.includes(abil) && character.supernal !== abil)
			.reduce(
				(r, abil) => r + Math.min(character[abil] ? character[abil] : 0, 3),
				0
			);
		spent.favhighdots = Object.keys(data.abilities)
			.filter((abil) => favoured.indexOf(abil) >= 0)
			.reduce(
				(r, abil) => r + Math.max(character[abil] ? character[abil] - 3 : 0, 0),
				0
			);
		spent.nonfavhighdots = Object.keys(data.abilities)
			.filter((abil) => favoured.indexOf(abil) < 0)
			.reduce(
				(r, abil) => r + Math.max(character[abil] ? character[abil] - 3 : 0, 0),
				0
			);
		if (character.crafts) {
			spent[
				favoured.indexOf("craft") >= 0 ? "favabilities" : "nonfavabilities"
			] += character.crafts.reduce((r, v) => r + Math.min(v.value, 3), 0);
			spent[
				favoured.indexOf("craft") >= 0 ? "favhighdots" : "nonfavhighdots"
			] += character.crafts.reduce((r, v) => r + Math.max(v.value - 3, 0), 0);
		}
		if (character.martialarts) {
			spent[
				favoured.includes("ma") ? "favabilities" : "nonfavabilities"
			] += character.martialarts.reduce((r, v) => r + Math.min(v.value, 3), 0);
			spent[
				favoured.includes("ma") ? "favhighdots" : "nonfavhighdots"
			] += character.martialarts.reduce(
				(r, v) => r + Math.max(v.value - 3, 0),
				0
			);
		}
		spent.specialties = character.specialties
			? character.specialties.length
			: 0;

		spent.abilities = spent.favabilities + spent.nonfavabilities;
		const all = getAllCharms(character);
		const charms = groupCharms(
			character,
			character.charms ? character.charms : []
		);
		const evocations = character.evocations ? character.evocations : {};
		spent.excellencies = character.charms
			? character.charms.filter((c) =>
					(all[c]?.keywords ?? []).includes("excellency")
				).length
			: 0;
		spent.subcharms = character.subcharms
			? Object.keys(character.subcharms).reduce(
					(i, sc) => i + character.subcharms[sc].length,
					0
				)
			: 0;
		spent.availableControl = character.charms
			? character.charms.filter(
					(c) => Object.values(sorceryCharms).indexOf(c) >= 0
				).length
			: 0;
		spent.spells = character.spells ? character.spells.length : 0;
		spent.control = Object.keys(character.controlSpells || {}).length;
		spent.martialarts = Object.values(character.maCharms ?? {}).reduce(
			(acc, cur) => acc + cur.length,
			0
		);
		spent.evocations = Object.keys(evocations ? evocations : {}).reduce(
			(i, name) => i + evocations[name].length,
			0
		);
		spent.custom = Object.keys(character.custom_charms ?? {}).length;

		spent.bp = {};
		spent.bp.attributes = {};
		const group_map = { primary: 0, secondary: 1, tertiary: 2 };
		Object.keys(spent.attributes).map((g) => {
			if (!spent.bp.attributes[g])
				spent.bp.attributes[g] = { favoured: 0, nonfavoured: 0 };
			var rem =
				spent.attributes[g].nonfavoured - chargen.attributes[group_map[g]] - 3;
			if (rem >= 0) {
				spent.bp.attributes[g].nonfavoured += rem;
				spent.bp.attributes[g].favoured = spent.attributes[g].favoured;
			} else if (spent.attributes[g].favoured - rem > 0) {
				spent.bp.attributes[g].favoured = spent.attributes[g].favoured + rem;
			}
			return g;
		});

		spent.bp.specialties = character.specialties
			? Math.max(character.specialties.length - chargen.specialties, 0)
			: 0;
		spent.bp.attributes =
			spent.bp.attributes.primary.favoured * costs.attribute_favoured[0] +
			spent.bp.attributes.secondary.favoured * costs.attribute_favoured[1] +
			spent.bp.attributes.tertiary.favoured * costs.attribute_favoured[2] +
			spent.bp.attributes.primary.nonfavoured * costs.attribute_nonfavoured[0] +
			spent.bp.attributes.secondary.nonfavoured *
				costs.attribute_nonfavoured[1] +
			spent.bp.attributes.tertiary.nonfavoured * costs.attribute_nonfavoured[2];

		spent.bp.abilities =
			spent.favhighdots * costs.ability_favoured +
			spent.nonfavhighdots * costs.ability_nonfavoured;

		if (spent.abilities > chargen.abilities) {
			if (spent.nonfavabilities > chargen.abilities) {
				spent.bp.abilities +=
					(spent.nonfavabilities - chargen.abilities) *
						costs.ability_nonfavoured +
					spent.favabilities * costs.ability_favoured;
			} else {
				spent.bp.abilities +=
					(spent.abilities - chargen.abilities) * costs.ability_favoured;
			}
		}

		spent.bp.merits = Math.max(spent.merits - chargen.merits, 0) * costs.merit;
		spent.bp.willpower =
			((character.willpower ? character.willpower : chargen.willpower) -
				chargen.willpower) *
			costs.willpower;
		spent.bp.charms = 0;
		spent.favCharms = 0;
		spent.unfavCharms = 0;
		spent.favCharmlikes = spent.evocations;
		spent.unfavCharmlikes = 0;
		spent.favExcellencies = 0;
		spent.unfavExcellencies = 0;

		spent[
			casteOrFavoured.includes('occult') || casteOrFavoured.includes('intelligence')
				? "favCharmlikes"
				: "unfavCharmlikes"
		] += spent.spells;
		spent[
			casteOrFavoured.includes("ma")
				? "favCharmlikes"
				: "unfavCharmlikes"
		] += spent.martialarts;
		spent.favExcellencies += Object.keys(charms).reduce(
			(i, abil) =>
				i +
				(casteOrFavoured.includes(abil))
					? charms[abil].filter((c) =>
							(all[c]?.keywords ?? []).includes("excellency")
						).length
					: 0,
			0
		);
		spent.unfavExcellencies += Object.keys(charms).reduce(
			(i, abil) =>
				i +
				(!casteOrFavoured.includes(abil))
					? charms[abil].filter((c) =>
							(all[c]?.keywords ?? []).includes("excellency")
						).length
					: 0,
			0
		);
		if (
			!chargen.excellencies ||
			spent.favExcellencies + spent.unfavExcellencies > chargen.excellencies
		) {
			if (
				!chargen.excellencies ||
				spent.unfavExcellencies > chargen.excellencies
			) {
				spent.unfavCharms +=
					spent.unfavExcellencies -
					(chargen.excellencies ? chargen.excellencies : 0);
				spent.favCharms += spent.favExcellencies;
			} else {
				spent.favCharms +=
					spent.favExcellencies +
					spent.unfavExcellencies -
					(chargen.excellencies ? chargen.excellencies : 0);
			}
		}
		spent.favCharms += Object.keys(charms).reduce(
			(i, abil) =>
				i +
				(casteOrFavoured.includes(abil)
					? charms[abil].filter((c) =>
						!(all[c].keywords ?? []).includes("excellency")
					).length
					: 0
				),
			0
		);
		spent.unfavCharms += Object.keys(charms).reduce(
			(i, abil) =>
				i +
				(!casteOrFavoured.includes(abil)
					? charms[abil].filter((c) =>
							!(all[c]?.keywords ?? []).includes("excellency")
						).length
					: 0
				),
			0
		);
		spent.unfavCharms += spent.custom;
		spent.favCharmlikes += spent.favCharms;
		spent.unfavCharmlikes += spent.unfavCharms;
		if (spent.favCharmlikes + spent.unfavCharmlikes > chargen.charms) {
			if (spent.unfavCharms > chargen.charms) {
				spent.bp.charms +=
					(spent.unfavCharmlikes - chargen.charms) * costs.charm_unfavoured;
				spent.bp.charms += spent.favCharmlikes * costs.charm_favoured;
			} else {
				spent.bp.charms +=
					(spent.favCharmlikes + spent.unfavCharmlikes - chargen.charms) *
					costs.charm_favoured;
			}
		}

		spent.charmlike =
			spent.favCharms +
			spent.unfavCharms +
			spent.spells +
			spent.martialarts +
			spent.evocations +
			spent.excellencies +
			spent.custom;

		spent.bp.charms += Object.keys(
			character.subcharms ? character.subcharms : {}
		).reduce(
			(i, scg) =>
				(character.subcharms[scg].length - all[scg].subcharmCost.free) *
				all[scg].subcharmCost.bp,
			0
		);

		spent.bp.total =
			spent.bp.attributes +
			spent.bp.abilities +
			spent.bp.specialties +
			spent.bp.merits +
			spent.bp.willpower +
			spent.bp.charms;

		if (
			character.splat &&
			getSplatProp(character, 'favours-type', 'ability') === "attribute" &&
			spent.favoured > chargen.favoured
		)
			spent.colors.attributes = "error";

		else if (
			spent.primary > chargen.attributes[0] ||
			spent.secondary > chargen.attributes[1] ||
			spent.tertiary > chargen.attributes[2]
		) {
			spent.colors.attributes = "primary";
		} else if (
			spent.primary === chargen.attributes[0] &&
			spent.secondary === chargen.attributes[1] &&
			spent.tertiary === chargen.attributes[2]
		) {
			spent.colors.attributes = "success";
		}

		if (
			character.splat &&
			getSplatProp(character, 'favours-type', 'ability') === "ability" &&
			spent.favoured > chargen.favoured
		)
			spent.colors.abilities = "error";
		else if (
			spent.abilities > chargen.abilities ||
			spent.specialties > chargen.specialties ||
			spent.favhighdots + spent.nonfavhighdots > 0
		)
			spent.colors.abilities = "primary";
		else if (
			spent.abilities === chargen.abilities &&
			spent.specialties === chargen.specialties
		)
			spent.colors.abilities = "success";
		
		if (
			spent.charmlike >
			chargen.charms + (chargen.excellencies ?? 0)
		)
			spent.colors.charms = "primary";
		else if (
			spent.charmlike ===
			chargen.charms + (chargen.excellencies ?? 0)
		)
			spent.colors.charms = "success";

		if (spent.merits > chargen.merits) spent.colors.merits = "primary";
		else if (spent.merits === chargen.merits) spent.colors.merits = "success";

		if (spent.bp.total > chargen.bp) spent.colors.bp = "error";
		else if (spent.bp.total === chargen.bp) spent.colors.bp = "success";
	}

	const favoured_cats = [["Favoured", spent.favoured, chargen.favoured]];
	if (!getSplatProp(character, 'favoursAllCaste', false))
		favoured_cats.push([
			getSplatProp(character, 'subtype'),
			spent.caste,
			chargen.caste,
		]);

	return (
		<>
			<Button
				variant="contained"
				color={spent.colors.attributes}
				onClick={() => {
					setDialog({
						title: "Attributes",
						traits: (character.splat &&
						getSplatProp(character, 'favours-type', 'ability') === "attribute"
							? favoured_cats
							: []
						).concat([
							["Primary", spent.primary, chargen.attributes[0]],
							["Secondary", spent.secondary, chargen.attributes[1]],
							["Tertiary", spent.tertiary, chargen.attributes[2]],
						]),
					});
				}}
			>
				Attributes
			</Button>
			<Button
				variant="contained"
				color={spent.colors.abilities}
				onClick={() => {
					setDialog({
						title: "Abilities",
						traits: (character.splat &&
						getSplatProp(character, 'favours-type', 'ability') === "ability"
							? favoured_cats
							: []
						).concat([
							["Specialties", spent.specialties, chargen.specialties],
							[
								"1-3 Dot",
								spent.favabilities + spent.nonfavabilities,
								chargen.abilities,
							],
							["4-5 Dot, Favoured", spent.favhighdots],
							["4-5 Dot, Non-Favoured", spent.nonfavhighdots],
						]),
					});
				}}
			>
				Abilities
			</Button>
			<Button
				variant="contained"
				color={spent.colors.merits}
				onClick={() => {
					setDialog({
						title: "Merits",
						traits: [["Merits", spent.merits, chargen.merits]],
					});
				}}
			>
				Merits
			</Button>
			<Button
				variant="contained"
				color={spent.colors.charms}
				onClick={() => {
					setDialog({
						title: "Charms",
						traits: [
							["Favoured Charms", spent.favCharms],
							["Unfavoured Charms", spent.unfavCharms],
							["Excellencies", spent.excellencies],
							["Subcharms", spent.subcharms],
							["Martial Arts", spent.martialarts],
							["Control Spells", spent.control, spent.availableControl],
							["Other Spells", spent.spells],
							["Evocations", spent.evocations],
							["Custom Charms", spent.custom],
							[
								"Total",
								spent.charmlike,
								chargen.charms +
									(chargen.excellencies ? chargen.excellencies : 0),
							],
						],
					});
				}}
			>
				Charms
			</Button>
			<Button
				variant="contained"
				color={spent.colors.bp}
				onClick={() => {
					setDialog({
						title: "Bonus Points",
						traits: [
							["Attributes", spent.bp.attributes],
							["Abilities", spent.bp.abilities],
							["Specialties", spent.bp.specialties],
							["Merits", spent.bp.merits],
							["Charms", spent.bp.charms],
							["Willpower", spent.bp.willpower],
							["Total", spent.bp.total, chargen.bp],
						],
					});
				}}
			>
				Bonus
			</Button>
			<Dialog open={Boolean(dialog)} onClose={() => setDialog(null)}>
				<DialogTitle disableTypography>
					{dialog ? dialog.title : ""}
				</DialogTitle>
				<DialogContent>
					<table className="details">
						<tbody>
							{dialog &&
								dialog.traits.map((trait, i) => (
									<tr key={i}>
										<th>{trait[0]}:</th>
										<td>
											{trait[1]}
											{trait[2] ? " / " + trait[2] : ""}
										</td>
									</tr>
								))}
						</tbody>
					</table>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setDialog(null)}>Close</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default Chargen;

import React, { useContext } from 'react';
import { Button, DialogTitle, DialogContent, MenuItem, TextField } from '@mui/material'

import { Firestore as FirestoreContext } from '@contexts';
import { Dialog, DialogActions, Grid } from '@loom/ui';

import { Context as ViewContext } from '@loom/characters/pc/view';

const ValueDialog = ({
    index,
    commitment,
    setCommitment
}) => {
    const { db } = useContext(FirestoreContext);
    const { character, characterId } = useContext(ViewContext);

    return (
        <Dialog open={ Boolean(commitment) }>
            <DialogTitle disableTypography>Commitment</DialogTitle>
            <DialogContent>
                <Grid>
                    <TextField
                        label="Subject"
                        value={ commitment ? commitment.subject : null }
                        onChange={ (event) => {
                            setCommitment({
                                ...commitment,
                                subject: event.target.value
                            })
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid size="extra-small">
                    <TextField
                        label="Motes"
                        inputProps={{type:"number"}}
                        value={ commitment?.motes || 0 }
                        onChange={ (event) => {
                            setCommitment({
                                ...commitment,
                                motes: parseInt(event.target.value, 10)
                            })
                        }}
                        fullWidth
                    />
                    <TextField
                        label="Pool"
                        value={ commitment?.pool || 'peripheral' }
                        onChange={ (event) => {
                            setCommitment({
                                ...commitment,
                                pool: event.target.value
                            });
                        }}
                        fullWidth select
                    >
                        <MenuItem value="personal">Personal</MenuItem>
                        <MenuItem value="peripheral">Peripheral</MenuItem>
                    </TextField>
                </Grid>
            </DialogContent>
            <DialogActions>
                { index != null && (
                    <Button
                        color="error"
                        variant="contained"
                        onClick={() => {
                            const doc = db.collection('characters').doc(characterId)
                            doc.update({
                                commitments: character.commitments.filter((com, i) => i !== index)
                            });
                            setCommitment(null);
                        }}
                    >Remove</Button>
                )}
                <div>
                    <Button
                        onClick={() => setCommitment(null) }
                    >Cancel</Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            const doc = db.collection('characters').doc(characterId)
                            if (index != null) {
                                doc.update({
                                    commitments: character.commitments.map((com, i) => i === index ? commitment : com)
                                })
                            } else {
                                doc.update({
                                    commitments:[
                                        ...(character.commitments || []),
                                        commitment
                                    ]
                                })
                            }
                            setCommitment(null);
                        }}
                    >{ index == null ? 'Add' : 'Update' }</Button>
                </div>
            </DialogActions>
        </Dialog>
    )
}

export default ValueDialog;
import React, { useContext } from 'react';
import capitalize from 'capitalize';

import { IconButton, TextField } from '@mui/material';

import { Box, Grid, EditCharmList, EditMeritList } from '@loom/ui';
import { CloseIcon } from '@loom/ui/icons';

import DialogContext from './context';

import './combat.less';

const CombatTab = () => {
	const { character, onChange } = useContext(DialogContext);
		
	return (<div className="edit-combat">
		<Grid size="small">
			<Box title="Health">
				<Grid size="extra-small" nopad>
					{ [0,1,2,4].map((l) => (
						<TextField
						 	key={ l }
							type="number"
							label={ ([1,2,4].indexOf(l) >= 0 ? '-' : '') + l + ' Levels' }
							inputProps={{ min: "0" }}
							value={ character.health && character.health[l] ? character.health[l] : 0 }
							onChange={ onChange('health', l) }
						/>
					))}
				</Grid>
			</Box>
			<Box title="Combat Dicepools">
				<Grid size="small" nopad>
					<TextField label="Join Battle" type="number"
						inputProps={{ min: "0" }}
						value={ character.joinBattle }
						onChange={ onChange('joinBattle') }
					/>
					<TextField  label="Movement" type="number"
						inputProps={{ min: "0" }}
						value={ character.combatMovement ? character.combatMovement: 0 }
						onChange={ onChange('combatMovement') }
					/>
					<TextField label="Grapple Control" type="number"
						inputProps={{ min: "0" }}
						value={ character.grappleControl }
						onChange={ onChange('grappleControl') }
					/>
				</Grid>
			</Box>
			<Box title="Defences">
				<Grid size="small" nopad>
					{[ 'parry', 'evasion', 'soak', 'hardness' ].map((f) => (
						<TextField
							key={ f }
							label={ capitalize(f) }
							type="number"
							inputProps={{ min: "0" }}
							value={ character[f] ? character[f] : 0 }
							onChange={ onChange(f) }
						/>
					))}
				</Grid>
			</Box>
		</Grid>
		<Grid size="extra-large">
			<Box
				title="Attacks"
				className="attacks"
				actions={[
					[ 'Add Attack', () => onChange('attacks')([...(character.attacks ? character.attacks : []), {}]) ]
				]}
				nopad
			>
				{ [...Object.keys(character.attacks ? character.attacks : {})].map((i) => (
					<div key={ i }>
						<TextField
							label="Name"
							value={ character.attacks[i].name }
							onChange={ onChange('attacks', i, 'name') }
							fullWidth
						/>
						<TextField
							label="Accuracy"
							type="number"
							inputProps={{ min: "0" }}
							value={ character.attacks[i].accuracy }
							onChange={ onChange('attacks', i, 'accuracy') }
							fullWidth
						/>
						<TextField
							label="Damage"
							type="number"
							inputProps={{ min: "0" }}
							value={ character.attacks[i].damage }
							onChange={ onChange('attacks', i, 'damage') }
							fullWidth
						/>
						<TextField
							label="Overwhelming"
							type="number"
							inputProps={{ min: "0" }}
							value={ character.attacks[i].overwhelming }
							onChange={ onChange('attacks', i, 'overwhelming') }
							fullWidth
						/>
						<IconButton
							style={{ flex: 0 }}
							onClick={ () => onChange('attacks')(character.attacks.filter((_,j) => j !== parseInt(i, 10))) }
						><CloseIcon /></IconButton>
					</div>
				))}
			</Box>
		</Grid>
		<Grid size="medium">
			<EditCharmList
			 	title="Combat Abilities"
				charms={ character.combatAbilities }
				onAdd={ () => (value) => onChange('combatAbilities')([...(character.combatAbilities ? character.combatAbilities : []), value ]) }
				onUpdate={ (i) => (value) => onChange('combatAbilities', i)(value) }
				onDelete={ (i) => () => onChange('combatAbilities')(character.combatAbilities.filter((_, j) => j !== i)) }
			/>
			<EditMeritList
			 	title="Combat Merits"
				merits={ character.combatMerits }
				onAdd={ () => (value) => onChange('combatMerits')([...(character.combatMerits ? character.combatMerits : []), value ]) }
				onUpdate={ (i) => (value) => onChange('combatMerits', i)(value) }
				onDelete={ (i) => () => onChange('combatMerits')(character.combatMerits.filter((_, j) => j !== parseInt(i, 10))) }
			/>
		</Grid>
	</div>)
}	

export default CombatTab

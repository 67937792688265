import React, { Component } from 'react'

import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';

import EditOrganisation from './'

class EditOrganisationDialog extends Component {
	render() {
		const { open, onClose, organisation, onChange, onSave, fullScreen } = this.props
		
		return (
			<Dialog
				fullScreen={ fullScreen }
				open={ open }
				onClose={ onClose }
			>
				<DialogContent>
					<EditOrganisation organisation={ organisation } onChange={ onChange } />
				</DialogContent>
				<DialogActions>
					<Button
						onClick={ onClose }
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={ onSave }
					>
						Ok
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

export default EditOrganisationDialog;

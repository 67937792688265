import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { Firestore } from '@contexts';

import Artifact from './view';

const View = () => {
	const { id } = useParams();
	const { addExtraContent } = useContext(Firestore);

	addExtraContent('artifacts', id);

	return (
		<Artifact artifactId={ id } />
	);
};

export { View }
export default View;
import React from 'react';

import './dice.less';

const Dice = ({ value }) => {
	return (
		<div className="dice">{
			Number.isNaN(value) || !value || value === '-'
				? '-' 
				: `${ value } Di${ value !== 1 ? 'ce' : 'e'}`
		}</div>
	)
}

export default Dice;
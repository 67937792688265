
import React, { useEffect, useState } from 'react'

import { Button, DialogActions, DialogTitle, DialogContent, AppBar } from '@mui/material';

import { Dialog, Tab, TabContainer, change as changeHandler } from '@loom/ui';
import { CharacterIcon, BattleIcon, SocialIcon } from '@loom/ui/icons';

import GeneralTab from './general'
import CombatTab from './combat'
import SocialTab from './social'
import Context from './context';

const EditDialog = ({
    onClose, onSave, open, fullScreen, character, children
}) => {
	return (
		<Dialog
			open={ open }
			onClose={ onClose }
			fullScreen={ fullScreen }
			className="npc edit maxed"
		>
			<TabContainer tabs={ children }>{
				(tabs, content) => (<>
					<DialogTitle className="fullwidth">
						<AppBar position="static" color="secondary">{  tabs }</AppBar>
					</DialogTitle>
					<DialogContent>
						{ content }
					</DialogContent>
					<DialogActions>
						<div style={{ whiteSpace: 'nowrap' }}>
							<Button
								onClick={ onClose }
							>
								Cancel
							</Button>
							<Button
								variant="contained"
								color="primary"
								onClick={ () => {
									onSave(character);
									onClose();
								}}
							>
								Save
							</Button>
						</div>
					</DialogActions>
				</>)
			}</TabContainer>
		</Dialog>
	)
};

const EditNPCDialog = ({
	character: initialCharacter,
	onClose,
	...props
}) => {
    const [ character, setCharacter ] = useState();

	useEffect(() => {
		setCharacter({...initialCharacter})
	}, [ initialCharacter ]);

	if (!character) return null;
	const handleChange = changeHandler(character, setCharacter);

	return (
		<Context.Provider value={{
			character: character,
			onChange: handleChange,
		}}>
			<EditDialog character={ character } { ...props }
				onClose={ () => {
					onClose();
					setCharacter({...initialCharacter});
				}}
			>
				<Tab value="general" label="General" icon={ <CharacterIcon /> } render={ <GeneralTab key="general" /> } />
				<Tab value="combat" label="Combat" icon={ <BattleIcon /> } render={ <CombatTab key="combat" /> } />
				<Tab value="social" label="Social" icon={ <SocialIcon /> } render={ <SocialTab /> } key="social" />
			</EditDialog>
		</Context.Provider>
	)
}

export default EditNPCDialog;

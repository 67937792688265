import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { BottomNavigationAction } from '@mui/material'

import BasePage from '@loom/pages';
import { ArtifactIcon, HearthstoneIcon, NPCIcon } from '@loom/ui/icons';

import { Auth, Firestore, Loom } from '@contexts';

const List = () => {
	const { db } = useContext(Firestore);
	const { user } = useContext(Auth);
	const { setTitle } = useContext(Loom);
	const history = useHistory();

	setTitle();

	return (
		<BasePage actions={
			<>
				<BottomNavigationAction
					label="Add Artifact"
					icon={<ArtifactIcon />}
					onClick={async () => {
						const doc = await db.collection('artifacts').add({name:'<Unnamed Artifact>', owner:user.uid})
						history.push(`/homebrew/artifact/${doc.id}`);
					}}
					showLabel
				/>
				<BottomNavigationAction
					label="Add Hearthstone"
					icon={<HearthstoneIcon />}
					onClick={async () => {
						const doc = await db.collection('hearthstones').add({name:'<Unnamed Hearthstone>', owner:user.uid})
						history.push(`/homebrew/hearthstone/${doc.id}`);
					}}
					showLabel
				/>
				<BottomNavigationAction
					label="Add Quick Character"
					icon={<NPCIcon />}
					onClick={async () => {
						const doc = await db.collection('characters').add({name:'<Unnamed Character>', type: 'npc', owner:user.uid})
						history.push(`/homebrew/npc/${doc.id}`);
					}}
					showLabel
				/>
			</>
		} />
	);
}

export {  List }
export default List
import React, { useContext, useState } from 'react';

import { Firestore, Loom } from '@contexts';
import { NameValue, Select } from '@loom/ui';
import { ArtifactDialog } from '@loom/help';

export const Edit = ({
	merit,
    onChange
}) => {
	const { artifacts } = useContext(Loom);

    return (
		<Select
			label={ merit.key }
			value={ merit.details?.description?.artifact ?? merit.details?.artifact ?? '' }
			onChange={ (id) => {
				onChange({ artifact: id })
			}}
			options={
            	Object.entries(artifacts)
				.sort(([_a, a], [_b, b]) => a.name.localeCompare(b.name))
            	.map(([key, value]) => (
					{ id: key, label: value.name }
				))
			}
		/>
    );
};

export const View = ({
	merit
}) => {
	const { artifacts } = useContext(Loom);
	const { addExtraContent } = useContext(Firestore);
	const [ viewing, setViewing ] = useState(false);

	addExtraContent('artifacts', merit.details?.description?.artifact ?? merit.details?.artifact);
	
	const artifact = artifacts[merit.details?.description?.artifact ?? merit.details?.artifact];

	return (<>
		<NameValue
			name={<>
				<strong>Artifact</strong>
				{ ' ' }
				{ artifact?.name }
			</> }
			value={ merit.value }
			onClick={ () => setViewing(true) }
		/>
		<ArtifactDialog
			open={ viewing }
			title={ artifact?.name ?? '' }
			artifact={ viewing ? artifact : false }
			onClose={ () => setViewing(false) }
		/>
	</>)
};
import React, { useContext, useState } from 'react';
import capitalize from 'capitalize';

import { Firestore, Loom } from '@contexts';
import { NameValue, Select } from '@loom/ui';
import NPCDialog from '@loom/characters/npc/view/dialog';
import { Context as ViewContext } from '@loom/characters/pc/view';

export const name = 'Character';

export const Edit = ({
	merit, onChange
}) => {
    const { npcs } = useContext(Loom);
    
    return (
        <Select
            label={ merit.key }
            value={ 'current' }
            onChange={ (id) => {
                onChange({ character: npcs[id] });
            }}
            options={[
                { id: 'current', label: merit.details?.character?.name ?? 'None', group: 'Current Value' },
                ...Object.entries(npcs)
                    .map(([k, v]) => ({ id: k, label: v.name, group: 'Templates' }))
                    .sort(({ label: a }, { label: b }) => a.localeCompare(b))
            ]}
        />
    )
}

export const View = ({
    merit, meritId
}) => {
	const [ viewing, setViewing ] = useState(false);
    const { character, characterId } = useContext(ViewContext);
    const { db } = useContext(Firestore);

    return (<>
        <NameValue
            name={<>
                <strong>{ capitalize(merit.key) }</strong>
                { ' ' }
                { merit.details?.character?.name ?? '' }
            </> }
            value={ merit.value }
            onClick={ () => setViewing(true) }
        />
        <NPCDialog editable
            open={ viewing }
            extratext={ merit?.description ?? '' }
            onClose={ () => setViewing(false) }
            onChange={ (data) => {
                if (character?.merits[meritId]) {
                    const merits = character?.merits;
                    character.merits[meritId].details.character = {
                        ...merits[meritId].details.character || {},
                        ...data
                    }
                    db.collection('characters').doc(characterId).update({ merits });
                } else {
                    const idx = character.completed_training.map((m) => m.index).indexOf(merit.id);
                    const training = character.completed_training;
                    if (!training[idx]?.data) training[idx].data = {};
                    training[idx].data.details = {
                        ...training[idx]?.data?.details ?? {},
                        ...data
                    }
                    db.collection('characters').doc(characterId).update({ completed_training: training });
                }
            }}
            data={ merit?.details?.character ?? {} }
        />    
    </>)
}
import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom';

import BottomNavigationAction from '@mui/material/BottomNavigationAction' 

import { Firestore } from '@contexts';
import { ActionBase, ConfirmDialog } from '@loom/ui';
import { EditIcon, DeleteIcon } from '@loom/ui/icons';
import { EditDialog } from '@loom/hearthstones';

const HearthstoneActions = ({ hearthstone, hearthstoneId }) => {
	const [ deleting, setDeleting ] = useState(false);
	const [ edit, setEdit ] = useState(false);
	const { db } = useContext(Firestore);
	const history = useHistory();

	return (<>
		<ActionBase>
			<BottomNavigationAction
				label="Edit"
				icon={ <EditIcon /> }
				onClick={() => setEdit(true) }
			/>
			<BottomNavigationAction
				label="Delete"
				icon={ <DeleteIcon /> }
				onClick={ () => setDeleting(true) }
			/>
		</ActionBase>
		<EditDialog
			open={ edit }
			onClose={ () => setEdit(false) }
			hearthstoneId={ hearthstoneId }
		/>
		<ConfirmDialog
			open={ deleting }
			onClose={ () => setDeleting(false) }
			onConfirm={() => {
				db.collection('hearthstones').doc(hearthstoneId).delete()
				setDeleting(false);
				history.push('/homebrew');
			}}
			text={ `Do you want to delete the hearthstone "${hearthstone.name}"?` }
		/>
	</>)
}

export default HearthstoneActions;

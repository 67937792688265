import React, { useContext } from 'react';

import { Auth, Loom } from '@contexts';
import { Grid, Box, ListTab } from '@loom/ui'
import { ArtifactIcon, HearthstoneIcon, NPCIcon } from '@loom/ui/icons'

const List = () => {
	const { user } = useContext(Auth);
	const { artifacts, characters, hearthstones } = useContext(Loom);

	if (!user) return null;
	
	return (
		<Grid size="large">
			<Box title="Artifacts">
				<ListTab
					items={
						Object.keys(artifacts ? artifacts : {}).filter((art) => (
							!artifacts[art].public && (
								artifacts[art].owner === user.uid ||
								user?.profile?.favourites?.artifacts?.indexOf?.(art) >= 0
							)
						)).map((id) => ({
							id: id,
							link: '/homebrew/artifact/' + id,
							label: artifacts[id].name,
							locked: artifacts[id].owner !== user.uid,
							icon: <ArtifactIcon />,
						}))
					}
					icon={ (item) => item.icon }
					sorted
				/>
			</Box>
			<Box title="Hearthstones">
				<ListTab
					items={
						Object.keys(hearthstones ? hearthstones : {}).filter((hs) => (
							!hearthstones[hs].public && (
								hearthstones[hs].owner === user.uid ||
								user?.profile?.favourites?.hearthstones?.indexOf?.(hs) >= 0
							)
						)).map((id) => ({
							id: id,
							link: '/homebrew/hearthstone/' + id,
							label: hearthstones[id].name,
							locked: hearthstones[id].owner !== user.uid,
							icon: <HearthstoneIcon />,
						}))	
					}
					icon={ (item) => item.icon }
					sorted
				/>
			</Box>
			<Box title="Quick Characters">
				<ListTab
					items={
						Object.keys(characters ? characters : {}).filter((c) => (
							!characters[c].public && characters[c].type === 'npc' && (
								characters[c].owner === user.uid ||
								user?.profile?.favourites?.characters?.indexOf?.(c) >= 0
							)
						)).map((id) => ({
							id: id,
							link: '/homebrew/npc/' + id,
							label: characters[id].name,
							locked: characters[id].owner !== user.uid,
							icon: <NPCIcon />,
						}))	
					}
					icon={ (item) => item.icon }
					sorted
				/>
			</Box>
		</Grid>
	)
}

export default List;

import React, { Component } from 'react'

import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';

import EditLocale from './edit'

class EditLocaleDialog extends Component {
	render() {
		const { open, onClose, locale, onChange, onSave, fullScreen } = this.props
		
		return (
			<Dialog
				fullScreen={ fullScreen }
				open={ open }
				onClose={ onClose }
			>
				<DialogContent>
					<EditLocale locale={ locale } onChange={ onChange } />
				</DialogContent>
				<DialogActions>
					<Button
						onClick={ onClose }
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={ onSave }
					>
						Ok
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

export default EditLocaleDialog

import { getSplatProp } from '@loom/characters/functions';

const isCaste = (character, abil) => {
	return getSplatProp(character, 'favours', []).includes?.(abil) ?? false
}

const isFavoured = (character, abil) => {
	return character.favoured && character.favoured.indexOf(abil) >= 0
}

const isSupernal = (character, abil) => {
	return character.supernal === abil
}

const inTraining = (character, type, name) => {
	if (!character.xplog) return []
	return character.xplog.reduce((tot, item) => {
		if (!item.completed && item.data.type === type && item.data.name === name) tot += (item.data.nval - item.data.oval)
		return tot
	}, 0)
}


export { isCaste, isFavoured, isSupernal, inTraining }
import React, { useContext } from 'react';
import { FormControlLabel, Switch } from '@mui/material';

import { Grid } from '@loom/ui';

import EditContext from '../context';
import Experience from './experience';
import Expenditure from './expenditure';
import Training from './training';

import './experience.less';

const ExperienceTab = () => {
	const { character, onChange } = useContext(EditContext);

	return (
		<div className="experience-tab">
			<Grid size="extra-large" nopad>
				<div>
					<FormControlLabel
						control={
							<Switch
								color="primary"
								checked={ character.experienced }
								onChange={ (event) => onChange('experienced')(event.target.checked) }
							/>
						}
						label="Experience Mode"
					/>
				</div>
				<Experience />
				<Training />
				<Expenditure />
			</Grid>
		</div>
	);
};

export default ExperienceTab;

import React, { useContext, useState } from 'react';

import Actions from './actions';
import { MenuItem } from '@mui/material';

import { Loom } from '@contexts';
import { Box, Grid, Markdown, UI } from '@loom/ui'
import CharmDialog from '@loom/help/charm'
	
const ArtifactView = ({ artifactId }) => {
	const [ charm, setCharm ] = useState();
	const { artifacts } = useContext(Loom);

	const artifact = artifacts[artifactId];

	if (!artifact) return null;

	return (
		<UI
			actions={ <Actions artifact={ artifact } artifactId={ artifactId } /> }
		>
			<Grid size="large">
				{ artifact.text && (
					<Box title={ artifact.name }>
						<Markdown source={ artifact.text } />
					</Box>
				)}{ artifact.attunementBenefit && (
					<Box title="Attunement Benefit">
						<Markdown source={ artifact.attunementBenefit } />
					</Box>
				)}{ artifact.evocations && artifact.evocations.length && (
					<Box title="Evocations">
						<Grid size="medium" nopad nogap>{
							artifact.evocations && artifact.evocations.map((evoc, i) => (
								<MenuItem
									key={ i }
									onClick={ () => setCharm(evoc) }
								>
									{ evoc.name }
								</MenuItem>
							))
						}</Grid>
					</Box>
				)}
			</Grid>
			<CharmDialog key={charm} charm={charm} peers={artifact.evocations} onClose={ () => setCharm() } />
		</UI>
	);
}

export default ArtifactView

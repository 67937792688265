import React from 'react'

import './health-level.less';

const levels = [null, 'bashing', 'lethal', 'aggravated']

const HealthLevel = ({
	damage, disabled, onClick, ...props
}) => {
	return (
		<span
			className={ `health-level ${damage || ''} ${disabled ? 'disabled' : ''}` }
			onClick={ (e) => {
				if (!disabled) onClick(e) }
			}
			{ ...props }
		/>
	)
}

export default HealthLevel;
export { levels }

import React from 'react';

import Spinner from '@loom/ui/spinner';

import './dots.less';

const Dots = ({
	size: _size,
	valid: _valid,
	temp: _temp,
	min: _min,
	max: _max,
	variant: _variant,
	value: _value,
	onChange,
	editable,
	overflow,
	nomax,
	className,
}) => {
	const variant = _variant ? _variant : 'dark'
	const size = Math.max(
		_max ? parseInt(_max, 10) : 0,
		_size ? _size : 0,
		...(_valid ? _valid : [])
	)
	const valid = _valid ? _valid : true;
	const min = _min ? parseInt(_min, 10) : 0
	const max = _max ? parseInt(_max, 10) : parseInt(_size, 10)
	const temp = _temp ? parseInt(_temp, 10) : 0
	const value = isNaN(_value) ? min : (valid === true || valid.indexOf(_value) >= 0 ? _value : min)

	return (
		<div className={ `dots-root ${className} ${variant}` }>
			<span className="dots">{
				[ ...Array(isNaN(size) ? 1 : size + 1).keys() ].slice(1).map((i) => {
					var cs = [ "dot" ]
					if (editable && (i < max || overflow) && (valid === true || valid.indexOf(i) >= 0)) cs.push("selectable");
					if (i <= value) {
						if (i > max) cs.push("excess")
						cs.push("selected")
					} else if (i > value && i <= max) {
						cs.push("temporary")
					}
					return (<span
						key={ i }
						className={ cs.join(' ') }
						onClick={ () => {
							if (editable) {
								var newval = (value === i ? i - 1 : i )
								if (nomax || valid === true || valid.indexOf(newval) >= 0) {
									onChange(newval)
								}
							}
						}}
					><span></span></span>)
				})}
			</span>&nbsp;
			{(value >= size && overflow ?
				<Spinner
					className="excess"
					value={ value - size }
					min="0"
					onChange={(newval) => {
						onChange(parseInt(newval, 10) + size)
					}}
				/>
			: '')}
		</div>
	);
};

export default Dots;

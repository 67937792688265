import React from 'react';

import { Button, InputAdornment, TextField } from '@mui/material';

import './spinner.less';

const Spinner = ({
	label, min, max, value, onChange, onClick, disabled, ...extraProps
}) => {
	const valueInt = isNaN(parseInt(value, 10)) ? '' : parseInt(value, 10);
	const maxInt = max ? parseInt(max, 10) : Number.POSITIVE_INFINITY;
	const minInt = min ? parseInt(min, 10) : Number.NEGATIVE_INFINITY;
	
	if (extraProps.className) extraProps.className += ' spinner root';
	else extraProps.className = 'spinner';
	if (max === undefined) extraProps.className += ' no-max';

	return (
		<div {...extraProps }>
			<Button
				className="button"
				variant="contained"
				color="secondary"
				onClick={ () => onChange(isNaN(valueInt) ? minInt : (min === undefined || valueInt > minInt ? valueInt - 1 : valueInt)) }
				disabled={ disabled }
			>-</Button>
			<TextField
				label={ label }
				value={ valueInt }
				className="value"
				onChange={ (e) => onChange(e.target.value === '' ? '' : parseInt(e.target.value, 10)) }
				onBlur={ () => onChange(Math.max(Math.min(valueInt, maxInt), minInt)) }
				disabled={ disabled }
				InputProps={{
					endAdornment: max !== undefined ? <InputAdornment position="end">{ ' / ' + max }</InputAdornment> : null,
				}}
			/>
			<Button
				className="button"
				variant="contained"
				color="secondary"
				onClick={ () => onChange(isNaN(valueInt) ? minInt : (max === undefined || valueInt < maxInt ? valueInt + 1 : valueInt)) }
				disabled={ disabled }
			>+</Button>
		</div>
	);
};

export default Spinner

import React, { useContext, useEffect, useState } from 'react';

import { Button, DialogTitle, DialogContent, MenuItem, TextField } from '@mui/material';

import { Firestore, Loom } from '@contexts';
import { Dialog, DialogActions, EditCharmList, Grid } from '@loom/ui';

const EditHearthstone = ({ onClose, hearthstoneId, open }) => {
	const [ hearthstone, setHearthstone ] = useState();
	const { hearthstones } = useContext(Loom);
	const { db } = useContext(Firestore);

	useEffect(() => {
		setHearthstone(hearthstones[hearthstoneId]);
	}, [ hearthstones, hearthstoneId, setHearthstone ]);

	if (!hearthstone) return null;
		
	return (
		<Dialog
			open={ open }
			onClose={ onClose }
			maxWidth="md"
			fullWidth
		>
			<DialogTitle>{ hearthstone.name }</DialogTitle>
			<DialogContent>
				<Grid size="large">
					<TextField 
						label="Name"
						value={ hearthstone.name }
						onChange={ (e) => setHearthstone({ ...hearthstone, name: e.target.value }) }
						fullWidth
					/>
					<Grid size="medium" nopad>
						<TextField
							label="Rating"
							value={ hearthstone.rating ? hearthstone.rating : '' }
							onChange={ (e) => setHearthstone({ ...hearthstone, tier: e.target.value }) }
							fullWidth select
						>
							<MenuItem value="standard">Standard</MenuItem>
							<MenuItem value="greater">Greater</MenuItem>
						</TextField>
						<TextField
							label="Aspect"
							value={ hearthstone.aspect ? hearthstone.aspect : '' }
							onChange={ (e) => setHearthstone({ ...hearthstone, aspect: e.target.value }) }
							fullWidth select
						>
							<MenuItem value="air">Air</MenuItem>
							<MenuItem value="earth">Earth</MenuItem>
							<MenuItem value="fire">Fire</MenuItem>
							<MenuItem value="water">Water</MenuItem>
							<MenuItem value="wood">Wood</MenuItem>
							<MenuItem value="lunar">Lunar</MenuItem>
							<MenuItem value="solar">Solar</MenuItem>
							<MenuItem value="sidereal">Sidereal</MenuItem>
							<MenuItem value="abyssal">Abyssal</MenuItem>
						</TextField>
					</Grid>
					<Grid size="large" nopad>
						<TextField 
							label="Description"
							value={ hearthstone.description }
							onChange={ (event) => setHearthstone({ ...hearthstone, description: event.target.value }) }
							multiline fullWidth
						/>
					</Grid>
					<EditCharmList
						title="Evocations"
						charms={ hearthstone.evocations ?? [] }
						onAdd={ () => (value) => {
							setHearthstone({
								...hearthstone,
								evocations: [
									...(hearthstone.evocations ?? []),
									value
								],
							});
						}}
						onUpdate={ (i) => (value) => {
							setHearthstone({
								...hearthstone,
								evocations: (hearthstone.evocations ?? []).map((evoc, idx) => idx === i ? value : evoc),
							});
						}}
						onDelete={ (i) => () => {
							setHearthstone({
								...hearthstone,
								evocations: (hearthstone.evocations ?? []).filter((_, idx) => idx !== i),
							});
						}}
					/>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={ onClose }
				>
					Cancel
				</Button>
				<Button
					variant="contained"
					color="primary"
					onClick={() => {
						db.collection('hearthstones').doc(hearthstoneId).update(hearthstone);
						onClose();
					}}
				>
					Ok
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default EditHearthstone;

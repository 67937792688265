import { useParams } from 'react-router-dom';
import { View as Battle } from '@loom/battle';

const View = () => {
	const { id } = useParams();

	return (
		<Battle battleId={ id } />
	);
};

export { View }
export default View
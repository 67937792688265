import React, { useContext } from 'react';

import { List as CharacterList } from '@loom/characters';
import { List as OrganisationList } from '@loom/organisations';
import { Box, Grid, UI, Markdown, Notes } from '@loom/ui';

import { Auth, Firestore, Loom } from '@contexts';

import Actions from './actions'

const Organisation = ({
	organisationId
}) => {
	const { user } = useContext(Auth);
	const { addExtraContent } = useContext(Firestore);
	const { organisations } = useContext(Loom);

	const organisation = organisations?.[organisationId];
	addExtraContent('organisations', organisationId);
	addExtraContent('organisations', organisations?.pcs || []);
	if (organisation?.owner === user?.uid) addExtraContent('notes', organisationId);
	
	if (!organisation) return <UI />;

	const context = {
		collection: 'organisations',
		id: organisationId,
		data: organisation,
	}
	
	return (
		<UI
			actions={ <Actions organisationId={ organisationId } organisation={ organisation } /> }
		>
			<Grid>
				<Box title={ organisation.name }>
					<Markdown className="copy" source={ organisation.description } />
				</Box>
				<Box title="Introduced Facts">
					<Markdown className="copy" source={ organisation?.facts } />
				</Box>
				<Notes context={ context } />
			</Grid>
			<Grid>
				<CharacterList pcs={ organisation.characters } npcs={ organisation.npcs } context={ context } />
				<OrganisationList orgs={ organisation.organisations } context={ context } />
			</Grid>
		</UI>
	);
}

export default Organisation


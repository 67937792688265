import { useContext, useState } from 'react';
import capitalize from 'capitalize';
import { useSnackbar } from 'notistack';
import {
	Button, Table, TableHead, TableBody, TableRow, TableCell, DialogActions, DialogTitle, DialogContent,
} from '@mui/material/'

import data from '@data';
import { Firestore as FirestoreContext } from '@contexts';
import { getAvailableCraftSlots, getCostForCraftSlot } from '@loom/characters/functions';
import { Box, Dialog, Spinner } from '@loom/ui';
import { CraftIcon } from '@loom/ui/icons';
import { Context as ViewContext } from '@loom/characters/pc/view';

import CraftDialog from './craft-dialog';

const CraftTable = () => {
    const { db } = useContext(FirestoreContext);
    const { character, characterId } = useContext(ViewContext);
    const [ changeXp, setChangeXp ] = useState();
    const [ project, setProject ] = useState();
    const { enqueueSnackbar } = useSnackbar();

    const processProjectCosts = (project) => {
        const cost = {};
        const pdata = data.craftrules.types[project.type] || {}
        if (getAvailableCraftSlots(character, project.type) > 0) {
            if (!project.consumingSlot) project.consumingSlot = {}
            project.consumingSlot[project.type] = (project.consumingSlot[project.type] ? project.consumingSlot[project.type] : 0) + 1
        } else {
            if (pdata.slots && pdata.slots.cost_base) {
                Object.keys(pdata.slots.cost_base).forEach((xp) => cost[xp] = (cost[xp] ? cost[xp] : 0) + pdata.slots.cost_base[xp])
            }
            if (pdata.slots && pdata.slots.cost_slots) {
                var slotcost = getCostForCraftSlot(character, project.type, project.dots ? project.dots : 2)
                if (slotcost.slots) {
                    if (!project.consumingSlot) project.consumingSlot = {}
                    Object.keys(slotcost.slots).forEach((slot) => project.consumingSlot[slot] = (project.consumingSlot[slot] ? project.consumingSlot[slot] : 0) + slotcost.slots[slot])
                    delete slotcost.slots
                }
                Object.keys(slotcost).map((xp) => cost[xp] = (cost[xp] ? cost[xp] : 0) + slotcost[xp])
            }
        }
        return [project, cost];
    }

    const calculateReward = (project) => {
        const reward = {};
        const pdata = data.craftrules.types[project.type] || {};
        if (pdata.reward_objective && project.objectives) {
            Object.keys(pdata.reward_objective).map((xp) => (
                reward[xp] = (reward[xp] ? reward[xp] : 0) + (pdata.reward_objective[xp] * project.objectives.length)
            ))
        }
        if (pdata.reward_exceptional_objective && project.exceptional && project.objectives) {
            Object.keys(pdata.reward_exceptional_objective).map((xp) => (
                reward[xp] = (reward[xp] ? reward[xp] : 0) + (pdata.reward_exceptional_objective[xp] * project.objectives.length)
            ))
        }
        if (pdata.reward_objective_once && project.objectives && project.objectives.length > 0) {
            Object.keys(pdata.reward_objective_once).map((xp) => (
                reward[xp] = (reward[xp] ? reward[xp] : 0) + (pdata.reward_objective_once[xp])
            ))
        }
        if (pdata.reward_objective_rating_once && project.objectives && project.objectives.length > 0) {
            Object.keys(pdata.reward_objective_rating_once[project.dots ? project.dots : 2]).map((xp) => (
                reward[xp] = (reward[xp] ? reward[xp] : 0) + (pdata.reward_objective_rating_once[project.dots ? project.dots : 2][xp])
            ))
        }
        if (pdata.reward_excess_interval_multiplier && project.terminus > project.rollsmade) {
            Object.keys(pdata.reward_excess_interval_multiplier).map((xp) => (
                reward[xp] = (reward[xp] ? reward[xp] : 0) + (pdata.reward_excess_interval_multiplier[xp] * (project.terminus - project.rollsmade))
            ))
        }
        if (pdata.reward_excellency) {
            Object.keys(pdata.reward_excellency.sux).map((xp) => (
                reward[xp] = (reward[xp] ? reward[xp] : 0) + (pdata.reward_excellency.sux[xp] * (project.rewardexsux ? project.rewardexsux : 0))
            ))
            Object.keys(pdata.reward_excellency.fail).map((xp) => (
                reward[xp] = (reward[xp] ? reward[xp] : 0) + (pdata.reward_excellency.fail[xp] * (project.rewardexfail ? project.rewardexfail : 0))
            ))
        }
        return reward;
    }

    const saveProject = (project, index, failed) => {
        const pdata = data.craftrules.types[project?.type] || {}
        const cxp = character.craftxp || {}
        let cost = {}
        let reward = {}
        const update = {}
        
        if (!project.started) {
            [ project, cost ] = processProjectCosts(project, cost);
            project.started = true
        }
        
        if (project.complete) {
            if (pdata.cost_base) Object.keys(pdata.cost_base).map((xp) => cost[xp] = (cost[xp] ? cost[xp] : 0) + pdata.cost_base[xp])
            if (!failed) {
                reward = calculateReward(project);
            }
        }
    
        Object.keys(reward).map((xp) => cxp[xp] = (cxp[xp] ? cxp[xp] : 0) + reward[xp] )
        Object.keys(cost).map((xp) => cxp[xp] = (cxp[xp] ? cxp[xp] : 0) - cost[xp])
        if (Object.keys(cost).length > 0) enqueueSnackbar('Spent ' + Object.keys(cost).map((xp) => cost[xp] + ' ' + xp + ' xp').join(', '))
        if (Object.keys(reward).length > 0) enqueueSnackbar('Gained ' + Object.keys(reward).map((xp) => reward[xp] + ' ' + xp + ' xp').join(', '))
        
        if (project.complete && !failed) {
            if (index >= 0) {
                update.projects = character.projects.filter((p,i) => i !== index)
            }
        } else {
            const projects = character.projects ? [...character.projects] : []
            if (index >= 0) projects[index] = project
            else projects.push(project)
            
            update.projects = projects
        }
        update.craftxp = cxp
        
        db.collection('characters').doc(characterId).update(update);
    }

    return (<>
        <Box
            title="Crafting Projects"
            actions={<>
                { data.craftrules.xp.map((xp) => (
                    <span
                        key={ xp }
                        className={ `${xp} interactable`  }
                        onClick={ () => setChangeXp([xp, (character?.craftxp || {})[xp] || 0])
                    }>
                        <CraftIcon />
                        { (character.craftxp && character.craftxp[xp] ? character.craftxp[xp] : 0) }
                    </span>
                ))}
                <Button color="secondary" variant="contained" onClick={ () => setProject([{}]) }>Begin Project</Button>
            </>}
        >
            { character.projects && (
                <Table>
                    <TableHead>
                        <TableRow><TableCell>Name</TableCell><TableCell>Type</TableCell><TableCell>Slots</TableCell><TableCell>Remaining</TableCell></TableRow>
                    </TableHead>
                    <TableBody>{ character.projects.map((proj, index) => (
                        <TableRow key={ index } className="interactable"
                            onClick={ () => { setProject([proj, index]); }}
                        >
                            <TableCell>{ proj.name }</TableCell>
                            <TableCell>{ proj.type }</TableCell>
                            <TableCell>
                                { proj.consumingSlot && Object.keys(proj.consumingSlot).filter((slot) => proj.consumingSlot[slot] > 0).map((slot) => proj.consumingSlot[slot] + ' ' + slot).join(', ') }
                                { !proj.consumingSlot || Object.keys(proj.consumingSlot).filter((slot) => proj.consumingSlot[slot] > 0).length === 0 ? 'Temporary' : '' }
                            </TableCell>
                            <TableCell>{ 
                                !proj.completeable ? (
                                    ((proj.timereq ? proj.timereq : 1) - (proj.timespent ? proj.timespent : 0)) + ' days'
                                ) : !proj.complete ? (
                                    ((proj.goalnum ? proj.goalnum : 1) - (proj.goalacc ? proj.goalacc : 0)) + ' successes'
                                ) : 'None'
                            }</TableCell>
                        </TableRow>
                    ))}</TableBody>
                </Table>
            )}
        </Box>
        <CraftDialog
            project={ project && project[0] }
            onChange={(proj, failed) => { saveProject(proj, project[1], failed) }}
            onClose={ () => setProject(null) }
            onDelete={ () => {
                db.collection('characters').doc(characterId).update({
                    projects: character.projects.filter((p, idx) => idx !== project[1])
                })
            }}
        />
        { changeXp && (
            <Dialog open={ true } onClose={() => setChangeXp()}>
                <DialogTitle>Set { capitalize(changeXp[0]) } Total</DialogTitle>
                <DialogContent>
                    <Spinner value={ changeXp[1] } onChange={ (val) => setChangeXp([changeXp[0], val]) } />
                </DialogContent>
                <DialogActions>
                    <Button onClick={ () => setChangeXp() }>Cancel</Button>
                    <Button variant="contained" color="primary"
                        onClick={ () => {
                            db.collection('characters').doc(characterId).update({
                                craftxp: {
                                    ...character.craftxp,
                                    [changeXp[0]]: changeXp[1]
                                }
                            });
                            setChangeXp();
                        }}
                    >Update</Button>
                </DialogActions>
            </Dialog>
        )}
    </>)
}

export default CraftTable;


import React, { useContext } from 'react';

import { Auth, Loom, Firestore } from '@contexts';
import { Box, Markdown } from '@loom/ui';

const List = ({ context }) => {
	const { notes } = useContext(Loom);
	const { user } = useContext(Auth);
	const { addExtraContent } = useContext(Firestore);

	addExtraContent('notes', context.id);

	if (
		context.data.owner !== user?.uid ||
		!notes ||
		!notes[context.id]?.text
	) return null;

	return (
		<Box title="Storyteller's Notes">
			<Markdown className="copy" source={ notes[context.id].text } />
		</Box>
	);
}


export default List;
import React, { useContext, useState } from "react";
import { useSnackbar } from "notistack";
import { v4 as uuid } from "uuid";

import {
	MenuItem,
	Paper,
	List,
	ListSubheader,
	ListItem,
	TextField,
	Button,
} from "@mui/material";

import data from "@data";
import { Loom } from "@contexts";
import { Box, ConfirmDialog } from "@loom/ui";
import { EditIcon, RemoveIcon } from "@loom/ui/icons";
import {
	getSplatProp,
	getAvailableCharms,
	getAvailableMA,
	getCharms,
	getMACharms,
	getMAStyles,
	getSpells,
	groupCharms,
	isSorcerer,
	isMartialArtist,
	getEvocations,
	getArtifacts,
	sorceryCharms,
	getSubcharms,
	startTraining,
	getHearthstones,
	xplog,
	getCustomCharms,
	getAvailableEvocations,
} from "@loom/characters/functions";

import EditContext from "./context";
import "./charms.less";
import EditCharmDialog from "@loom/ui/charm-list/dialog";

const CharmsTab = () => {
	const [group, setGroup] = useState();
	const [removing, setRemoving] = useState();
	const [editingCustomCharm, setEditingCustomCharm] = useState(false);
	const [type, setType] = useState("charm");
	const { artifacts: allArtifacts, hearthstones: allHearthstones } =
		useContext(Loom);
	const { character, onChange } = useContext(EditContext);

	const { enqueueSnackbar } = useSnackbar();
	const charms = groupCharms(
		character,
		getAvailableCharms(character, {
			artifacts: allArtifacts,
			hearthstones: allHearthstones,
		})
	);
	const grouped_charms = {};
	Object.keys(charms).map((abil) => {
		grouped_charms[abil] = {};
		charms[abil].map((c) => {
			var group = getSplatProp(character, 'charms', {})[c].group;
			if (!group) group = "";
			if (!grouped_charms[abil][group]) grouped_charms[abil][group] = [];
			grouped_charms[abil][group].push(c);
			return c;
		});
		return abil;
	});
	const subcharms = getSubcharms(character);
	const charCharms = groupCharms(character, getCharms(character));
	const artifacts = getArtifacts(character, allArtifacts);
	const hearthstones = getHearthstones(character, allHearthstones);
	const availableEvocations = getAvailableEvocations(character, { artifacts: allArtifacts, hearthstones: allHearthstones });

	const hasEvocations = Object.keys(availableEvocations).reduce(
		(acc, cur) => (cur.length > 0 ? true : acc),
		false
	);

	if (!hasEvocations && type === "evocation") setType("charm");
	if (!isSorcerer(character) && type === "spell") setType("charm");

	const customCharms = getCustomCharms(character);
	const groups = customCharms.reduce((groups, charm) => {
		if (!groups.includes(charm.group)) return [...groups, charm.group];
		return groups;
	}, []);

	return (
		<div className="charms-tab">
			<div className="selectors">
				<TextField
					fullWidth
					select
					label="Type"
					value={type ? type : ""}
					onChange={(event) => {
						setType(event.target.value);
					}}
				>
					<MenuItem value="charm">Charms</MenuItem>
					{Object.keys(subcharms).length > 0 && (
						<MenuItem value="subcharm">Subcharms</MenuItem>
					)}
					{hasEvocations && <MenuItem value="evocation">Evocations</MenuItem>}
					{character.martialarts?.length > 0 && (
						<MenuItem value="martialarts">Martial Arts</MenuItem>
					)}
					{isSorcerer(character) && <MenuItem value="spell">Spells</MenuItem>}
					<MenuItem value="custom">Custom</MenuItem>
				</TextField>
				<TextField
					fullWidth
					select
					label="Group"
					value={group ? group : ""}
					onChange={(event) => {
						setGroup(event.target.value);
					}}
				>
					{type === "charm" &&
						Object.keys(grouped_charms)
							.sort()
							.map((abil) => (
								<MenuItem value={abil} key={abil}>
									<span className="caps">{abil}</span>
								</MenuItem>
							))}
					{type === "spell" &&
						Object.keys(data.sorcery.spells)
							.filter(
								(circle) =>
									getCharms(character).indexOf(sorceryCharms[circle]) >= 0
							)
							.map((circle) => (
								<MenuItem value={circle} key={circle}>
									<span className="caps">{circle}</span>
								</MenuItem>
							))}
					{type === "subcharm" &&
						Object.keys(subcharms).map((base) => (
							<MenuItem value={base} key={base}>
								{base}
							</MenuItem>
						))}
					{type === "evocation" &&
						Object.keys(availableEvocations).map((parent) => {
							const parentData = [...artifacts, ...hearthstones];
							const idx = parentData.map((a) => a.id).indexOf(parent);
							console.log(parentData, idx, parent);
							return (
								<MenuItem value={parentData[idx].id} key={parentData[idx].id}>
									<span className="caps">{parentData[idx]?.name}</span>
								</MenuItem>
							);
						})}
					{type === "martialarts" &&
						character.martialarts?.map?.((martialart) => (
							<MenuItem value={martialart.style} key={martialart.style}>
								<span className="caps">
									{data.martialarts[martialart.style]?.name}
								</span>
							</MenuItem>
						))}
					{type === "custom" &&
						groups
							.filter((g) => Boolean(g))
							.map((g) => <MenuItem value={g}>{g}</MenuItem>)}
				</TextField>
				<TextField
					fullWidth
					select
					label="Power"
					value=""
					onChange={(event) => {
						const charm = event.target.value;
						if (type === "charm") {
							if (character.experienced) {
								startTraining(
									character,
									xplog(character, "charm", event.target.value, false, true),
									onChange,
									enqueueSnackbar
								);
							} else {
								onChange("charms")([
									...(character.charms ? character.charms : []),
									charm,
								]);
							}
						} else if (type === "subcharm") {
							if (character.experienced) {
								startTraining(
									character,
									xplog(
										character,
										"subcharm",
										group,
										false,
										true,
										null,
										null,
										null,
										charm
									),
									onChange,
									enqueueSnackbar
								);
							} else {
								onChange("subcharms")({
									...(character.subcharms ? character.subcharms : {}),
									[group]: [
										...(character.subcharms && character.subcharms[group]
											? character.subcharms[group]
											: []),
										charm,
									],
								});
							}
						} else if (type === "spell") {
							if (character.experienced) {
								startTraining(
									character,
									xplog(character, "spell", charm, false, true),
									onChange,
									enqueueSnackbar
								);
							} else {
								onChange("spells")([
									...(character.spells ? character.spells : []),
									charm,
								]);
							}
						} else if (type === "evocation") {
							if (character.experienced) {
								startTraining(
									character,
									xplog(character, "evocation", charm, false, true, {
										artifact: group,
									}),
									onChange,
									enqueueSnackbar
								);
							} else {
								onChange(
									"evocations",
									group
								)([
									...(character.evocations
										? character.evocations[group]
											? character.evocations[group]
											: []
										: []),
									charm,
								]);
							}
						} else if (type === "martialarts") {
							if (character.experienced) {
								startTraining(
									character,
									xplog(character, "macharm", charm, false, true),
									onChange,
									enqueueSnackbar
								);
							} else {
								onChange(
									"maCharms",
									group
								)([
									...(character.maCharms && character.maCharms[group]
										? character.maCharms[group]
										: []),
									charm,
								]);
							}
						} else if (type === "custom") {
							setEditingCustomCharm(true);
						}
					}}
				>
					{type === "charm" &&
						grouped_charms[group] !== undefined &&
						Object.keys(grouped_charms[group]).map((grp, i) => [
							grp ? (
								<ListSubheader className="primary">{grp}</ListSubheader>
							) : null,
							grouped_charms[group][grp].map((name, j) => (
								<MenuItem value={name} key={j}>
									<span className="caps">{name}</span>
								</MenuItem>
							)),
						])}
					{type === "subcharm" &&
						subcharms[group] !== undefined &&
						subcharms[group].charms
							.filter(
								(name, i) =>
									!character.subcharms ||
									!character.subcharms[group] ||
									character.subcharms[group].indexOf(name) < 0
							)
							.map((name, i) => (
								<MenuItem value={name} key={i}>
									<span className="caps">{name}</span>
								</MenuItem>
							))}
					{type === "martialarts" &&
						group &&
						getAvailableMA(character, group).map((name, i) => (
							<MenuItem value={name} key={i}>
								<span className="caps">{name}</span>
							</MenuItem>
						))}
					{type === "spell" &&
						data.sorcery.spells[group] !== undefined &&
						Object.keys(data.sorcery.spells[group])
							.filter((spell) => getSpells(character).indexOf(spell) < 0)
							.map((spell, i) => (
								<MenuItem value={spell} key={i}>
									<span className="caps">{spell} </span>
								</MenuItem>
							))}
					{type === "evocation" &&
						availableEvocations[group] &&
						availableEvocations[group].map((evoc, i) => (
							<MenuItem value={evoc} key={i}>
								<span className="caps">{evoc}</span>
							</MenuItem>
						))}
					{type === "custom" && <MenuItem value="new">Add New Charm</MenuItem>}
				</TextField>
			</div>
			{Object.keys(charCharms).map((group) => (
				<Paper className="box" key={group}>
					<h3 className="caps">{group}</h3>
					<List>
						{charCharms[group].map((charm, i) => {
							return (
								<ListItem key={charm}>
									<TextField
										fullWidth
										key={i}
										label={charm}
										className="field"
										value={
											character.charm_notes ? character.charm_notes[charm] : ""
										}
										onChange={onChange("charm_notes", charm)}
										multiline
									/>
									<RemoveIcon
										className="interactable"
										onClick={() => setRemoving(["charm", charm])}
									/>
								</ListItem>
							);
						})}
					</List>
				</Paper>
			))}
			{Object.keys(character.subcharms ? character.subcharms : {}).map(
				(group) => (
					<Paper className="box" key={group}>
						<h3 className="caps">{group}</h3>
						<List className="grid-large">
							{character.subcharms[group].map((charm, i) => (
								<ListItem key={i}>
									<TextField
										fullWidth
										key={i}
										label={charm}
										className="field"
										value={
											character.charm_notes ? character.charm_notes[charm] : ""
										}
										onChange={onChange("charm_notes", charm)}
										multiline
									/>
									<RemoveIcon
										className="interactable"
										onClick={() => setRemoving(["subcharm", group, charm])}
									/>
								</ListItem>
							))}
						</List>
					</Paper>
				)
			)}
			{[...artifacts, ...hearthstones]
				.filter(
					(parent) =>
						getEvocations(character, artifacts, hearthstones)[parent.id] &&
						getEvocations(character, artifacts, hearthstones)[parent.id]
							.length > 0
				)
				.map((parent) => (
					<Paper className="box" key={parent.id}>
						<h3>{parent.name}</h3>
						<List className="grid-large">
							{getEvocations(character, artifacts, hearthstones)[parent.id].map(
								(evocation, i) => (
									<ListItem key={evocation}>
										<TextField
											fullWidth
											key={i}
											label={evocation}
											className="field"
											value={
												character.charm_notes
													? character.charm_notes[evocation]
													: ""
											}
											onChange={onChange("charm_notes", evocation)}
											multiline
										/>
										<RemoveIcon
											className="interactable"
											onClick={() =>
												setRemoving(["evocation", parent.id, evocation])
											}
										/>
									</ListItem>
								)
							)}
						</List>
					</Paper>
				))}
			{getMAStyles(character).map((ma) => (
				<Paper className="box" key={ma}>
					<h3>{ma}</h3>
					<List className="grid-large">
						{getMACharms(character, ma).map((charm, i) => (
							<ListItem key={i}>
								<TextField
									fullWidth
									key={i}
									label={charm}
									className="field"
									value={
										character.charm_notes ? character.charm_notes[charm] : ""
									}
									onChange={onChange("charm_notes", charm)}
									multiline
								/>
								<RemoveIcon
									className="interactable"
									onClick={() => setRemoving(["martialarts", ma, charm])}
								/>
							</ListItem>
						))}
					</List>
				</Paper>
			))}
			{getSpells(character).length > 0 && (
				<Paper className="box">
					<h3>Spells</h3>
					<List className="grid-large">
						{getSpells(character)
							.filter(
								(s) =>
									Object.values(character.controlSpells || {}).indexOf(s) < 0
							)
							.map((spell, i) => (
								<ListItem key={i}>
									<TextField
										fullWidth
										key={i}
										label={spell}
										className="field"
										value={
											character.charm_notes ? character.charm_notes[spell] : ""
										}
										onChange={onChange("charm_notes", spell)}
										multiline
									/>
									<RemoveIcon
										className="interactable"
										onClick={() => setRemoving(["spell", spell])}
									/>
								</ListItem>
							))}
					</List>
				</Paper>
			)}
			{groups.map((group) => (
				<Box title={group ?? "Custom"}>
					<List>
						{customCharms
							.filter((c) => group === c.group)
							.map((charm, i) => (
								<ListItem key={charm.id}>
									<TextField
										fullWidth
										key={charm.id}
										label={charm.name}
										className="field"
										value={
											character.charm_notes
												? character.charm_notes[charm.id]
												: ""
										}
										onChange={onChange("charm_notes", charm.id)}
										multiline
									/>
									<Button onClick={() => setEditingCustomCharm(charm)}>
										<EditIcon />
									</Button>
									<RemoveIcon
										className="interactable"
										onClick={() => setRemoving(["customCharm", charm.id])}
									/>
								</ListItem>
							))}
					</List>
				</Box>
			))}
			<ConfirmDialog
				text={
					"Do you want to remove " +
					(removing?.[0] === "customCharm"
						? customCharms.find(({ id }) => id === removing[1]).name
						: removing?.[removing.length - 1]) +
					"?"
				}
				open={Boolean(removing)}
				onClose={() => setRemoving(null)}
				onConfirm={() => {
					const changes = {
						completed_training: (character.completed_training || []).filter(
							(ct) =>
								ct.data.type !== removing[0] ||
								(ct.data.name !== removing[1] &&
									ct.data.name !== removing[2]) ||
								ct.data.nval !== true
						),
					};
					if (removing[0] === "charm") {
						changes.charms = character.charms.filter((c) => c !== removing[1]);
					} else if (removing[0] === "subcharm") {
						changes.subcharms = {
							...character.subcharms,
							[removing[1]]: character.subcharms[removing[1]].filter(
								(rm) => rm !== removing[2]
							),
						};
					} else if (removing[0] === "spell") {
						changes.spells = character.spells.filter(
							(rs) => removing[1] !== rs
						);
					} else if (removing[0] === "evocation") {
						changes.evocations = character.evocations[removing[1]].filter(
							(evoc) => evoc !== removing[2]
						);
					} else if (removing[0] === "martialarts") {
						changes.maCharms = {
							...character.maCharms,
							[removing[1]]: (character.maCharms?.[removing[1]] ?? []).filter(
								(rm) => rm !== removing[2]
							),
						};
					} else if (removing[0] === "customCharm") {
						delete character.custom_charms[removing[1]];
						changes.custom_charms = character.custom_charms;
					}
					onChange()(changes);
					setRemoving(null);
				}}
			/>
			<EditCharmDialog
				charm={editingCustomCharm}
				groups={groups.filter((g) => Boolean(g))}
				isCustom={true}
				onClose={() => setEditingCustomCharm(false)}
				onAdd={(charm) => {
					charm.id = uuid();
					if (character.experienced) {
						startTraining(
							character,
							xplog(character, "custom-charm", charm.name, null, charm),
							onChange,
							enqueueSnackbar
						);
					} else {
						onChange("custom_charms")({
							...(character.custom_charms ?? {}),
							[charm.id]: charm,
						});
					}
				}}
				onUpdate={(charm) => {
					const { id, ...charmData } = charm;
					if (character.custom_charms?.[id]) {
						onChange("custom_charms")({
							...(character.custom_charms ?? {}),
							[id]: charmData,
						});
					} else {
					}
				}}
				onDelete={() => {
					delete character.custom_charms[editingCustomCharm.id];
					onChange("custom_charms")(character.custom_charms);
				}}
			/>
		</div>
	);
};

export default CharmsTab;

import React, { useState, useContext } from 'react'

import data from '@data'
import { getCraftScore, getMAScore, getExcellencies, getScore, getSpecialties } from '@loom/characters/functions';
import { Box, Dice, Grid, Group, NameValue } from '@loom/ui';

import { GenericDialog, CharmListDialog } from '@loom/help';

import ViewContext from './context';
import './traits.less';

const TraitsTab = () => {
	const [ dialog, setDialog ] = useState();
	const [ charms, setCharms ] = useState();
	const { character } = useContext(ViewContext);
	const excellencies = getExcellencies(character);
	
	return (<>
		<Grid size="extra-large">
			<Box title="Attributes">
				<Grid size="small" nopad>
					{ Object.keys(data.attributes).map((group) => (
						<Group
							key={ group }
							title={ group }
						>
							{ Object.keys(data.attributes[group]).map((attr) => (
								<NameValue
									key={ attr }
									onClick={ () => setCharms(attr) }
									name={ attr }
									value={ <Dice value={ getScore(character, attr, 1) } /> }
									className={ excellencies.indexOf(attr) >= 0  && 'excellency' }
								/>
							))}
						</Group>
					))}
				</Grid>
			</Box>
			<Box title="Abilities">
				<Grid size="small" nogap>
					{ Object.keys(data.abilities).filter((abil) => ['craft', 'martial arts'].indexOf(abil) < 0).map((abil) => (
						<NameValue
							key={ abil }
							onClick={ () => setCharms(abil) }
							name={ abil }
							value={ <Dice value={ getScore(character, abil, 0) } /> }
							className={ [
								'caps',
								(excellencies.indexOf(abil) >= 0 && 'excellency'),
								(character.supernal === abil && 'supernal')
							].join(' ') }
						/>
					))}
				</Grid>
			</Box>
		</Grid>
		<Grid>
			{ getSpecialties(character).length > 0 &&
				<Box title="Specialties">
					<Grid size="medium" nogap nopad>
						{ getSpecialties(character).map((spec, i) => (
							<NameValue name={ spec.ability } value={ spec.area } condensed />
						))}
					</Grid>
				</Box>
			}{ character.crafts && character.crafts.length > 0 && (
				<Box title={ <div className="interactable" onClick={ () => setCharms('craft')}>Crafts</div> }>
					<Grid size="small">
						{ character.crafts.map((craft, i) => (
							<NameValue name={ craft.name } value={ <Dice value={ getCraftScore(character, craft.name) } /> } condensed={ character.crafts.length === 1 }  />
						))}
					</Grid>
				</Box>
			)}{ character.martialarts && character.martialarts.length > 0 &&
				<Box title="Martial Arts">
					{ character.martialarts.map((ma, i) => (
						<NameValue
							key={ i }
							onClick={ () => setCharms(ma.style) }
							name={ data.martialarts[ma.style] ? data.martialarts[ma.style].name : ma.style }
							value={ <Dice value={ getMAScore(character, ma.style) } /> }
						/>
					))}
				</Box>
			}
		</Grid>
		<GenericDialog
			title={ dialog ? dialog.title : '' }
			content={ dialog ? dialog.content : '' }
			onClose={ () => setDialog() }
		/>
		<CharmListDialog
			character={ character }
			category={ charms }
			onClose={ () => setCharms() }
		/>
	</>)
}

export default TraitsTab

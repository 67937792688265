import React, { useState } from 'react';
import capitalize from 'capitalize';
import { Button, DialogTitle, DialogContent, DialogActions, MenuItem } from '@mui/material';

import data from '@data'
import { Box, Dialog, Grid, Group, Markdown } from '@loom/ui'
import { groupCharms, getSplatProp } from '@loom/characters/functions'

import CharmDialog from './charm';
import SpellDialog from './spell';

const CharmListDialog = ({
	onClose, character, category
}) => {
	const [ charm, setCharm ] = useState();
	let charms, text;
	let grouped_charms = {};

	if (category === 'spells') {
		grouped_charms = Object.fromEntries(
			Object.entries(data.sorcery.spells).map(([circ, spells]) => [
				capitalize(circ) + ' Circle',
				{
					'': Object.keys(spells)
						.map((name) => ({
							...spells[name],
							name,
							isSpell: true,
						}))
				}
			])
		);
	} else {
		if (data.martialarts[category]) {
			text = data.martialarts[category].description
			charms = Object.keys(data.martialarts[category]?.charms ?? {}).map((name) => ({ ...data.martialarts[category].charms?.[name], name:name }))
		} else {
			charms = character.splat ? getSplatProp(character, 'charms', {}) : []
			text = data.abilities[category] || Object.keys(data.attributes).reduce((acc, val) => ({ ...acc, ...(data.attributes[val]) }), {})[category];
			const grouped = groupCharms(character)
			charms = category && grouped[category] ? grouped[category].map((name) => ({ ...charms[name], name:name })) : []
		}
		charms = charms
			.sort((a,b) => (a?.reqs?.essence || 0) - (b?.reqs?.essence || 0))
			.reduce((r, charm) => {
				let ess = 'Essence ' + charm.reqs.essence
				if (r[ess] === undefined) r[ess] = []
				r[ess].push(charm)
				return r
			}, {});
			Object.keys(charms).map((e) => {
				if (!grouped_charms[e]) grouped_charms[e] = {}
				charms[e].map((c) => {
					if (!c.group) c.group = ''
					if (!grouped_charms[e][c.group]) grouped_charms[e][c.group] = []
					grouped_charms[e][c.group].push(c)
					return c
				})
				return e
			})
	}
		
	return (
		<Dialog
			open={ Boolean(category) }
			onClose={ onClose }
			maxWidth="md"
		>
			<DialogTitle className="caps" disableTypography>
				{ category ? category : '' }
			</DialogTitle>
			<DialogContent>
				{ <Markdown source={ text } /> }
				<Grid size="extra-large">
					{ Object.keys(grouped_charms).map((ess) => (
						<Box title={ ess } key={ess}>
							<Grid size="large" nopad>
								{ Object.keys(grouped_charms[ess]).map((g, j) => (
									<Group key={ j } title={ g }>
										<Grid size="medium" nopad nogap>{ grouped_charms[ess][g].map((charm, i) => (
											<MenuItem
												key={ i }
												onClick={ () => setCharm(charm) }
											>{ charm.name }</MenuItem>
										))}</Grid>
									</Group>
								))}
							</Grid>
						</Box>
					))}
				</Grid>
				<CharmDialog
					charm={ charm && !charm.isSpell ? charm : null }
					character={ character }
					onClose={ () => setCharm(null) }
				/>
				<SpellDialog
					spell={ charm && charm.isSpell ? charm : null }
					onClose={ () => setCharm(null) }
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={ onClose }>Close</Button>
			</DialogActions>
		</Dialog>
	)
}

export default CharmListDialog;
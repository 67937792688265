import core_1 from './core/terrestrial.json';
import core_2 from './core/celestial.json';
import core_3 from './core/solar.json';
import db_1 from './db/terrestrial.json';
import db_2 from './db/celestial.json';
import lunar_1 from './lunar/terrestrial.json';
import lunar_2 from './lunar/celestial.json';
import sidereal_2 from './sidereal/celestial.json';
import abyssal_1 from './abyssal/terrestrial.json';
import abyssal_2 from './abyssal/celestial.json';
import abyssal_3 from './abyssal/solar.json';

const sorcery = {
	terrestrial: {
		...core_1,
		...db_1,
		...lunar_1,
		...abyssal_1
	},
	celestial: {
		...core_2,
		...db_2,
		...lunar_2,
		...sidereal_2,
		...abyssal_2
	},
	solar: {
		...core_3,
		...abyssal_3
	},
}

export default sorcery

import React, { useState } from 'react'

import { MenuList,  MenuItem, ListItemText } from '@mui/material'

import { Box, Grid } from '@loom/ui';
import Dialog from './dialog';

const MeritList = ({
	title,
	merits,
	onAdd,
	onUpdate,
	onDelete
}) => {
	const [ merit, setMerit ] = useState();

	return (
		<Box
			title={ title }
			actions={[
				[ 'Add Merit', () => setMerit(true) ]
			]}
		>
			<MenuList>
				<Grid size="small" nogap nopad>
				{ (merits ? merits : []).map((c, i) => {
					return (
						<MenuItem
							key={ i }
							onClick={ () => setMerit(i) }
						>
							<ListItemText>{ c.name }</ListItemText>
						</MenuItem>
					)
				})}
				</Grid>
			</MenuList>
			<Dialog
				merit={ merit === true ? true : merits?.[merit] }
				onClose={ () => setMerit(null) }
				onAdd={ (data) => onAdd(merit)(data) }
				onUpdate={ (data) => onUpdate(merit)(data) }
				onDelete={ () => onDelete(merit)() }
			/>
		</Box>
	);
};

export default MeritList;

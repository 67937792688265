import React, { Component } from 'react'
import { withRouter } from 'react-router'

import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

import { ReadOnlyIcon, RemoveIcon } from './icons'
import ConfirmDialog from './confirm'

import './list-tab.less';

class ListTab extends Component {

	state = {
		redirect: null,
		removing: null,
	}

	render() {
		const { items, icon, onClick, onRemove, history, staticContext, sorted, filters, className, location, match, ...props } = this.props

		if (sorted) {
			if (typeof sorted === 'function') items.sort(sorted)
			else {
				items.sort((a, b) => a.label > b.label ? 1 : (a.label < b.label ? -1 : 0))
			}
		}

		return (
			<div className={ `${(className || '')} list-tab` } { ...props }>{
				filters && <div className="filters">{ filters }</div>
			}
				<List> {
					items.map((obj, i) => {
						return (
							<ListItem
								key={ obj.id }
								onClick={() => {
									if (obj.link) {
										history.push(obj.link)
									} else {
										onClick(obj.id)
									}
								}}
								button
							>
								<ListItemIcon>{ icon instanceof Function ? icon(obj) : icon }</ListItemIcon>
								<ListItemText>{ obj.label }{ obj.locked ? <ListItemIcon><ReadOnlyIcon /></ListItemIcon> : null }</ListItemText>
								{ onRemove && !obj.unremovable ? 
									<ListItemIcon
										onClick={ (e) => {
											e.stopPropagation()
											this.setState({removing: i})
										}}
									><RemoveIcon /></ListItemIcon>
								: '' }
							</ListItem>
						)
					})
				}
				</List>
				<ConfirmDialog
					open={ this.state.removing !== null }
					onClose={() => { this.setState({removing:null}) } }
					onConfirm={() => {
						onRemove(this.state.removing, items[this.state.removing])
						this.setState({removing:null})
					}}
					text={ 'Do you want to remove "' + (items[this.state.removing] ? items[this.state.removing].label : '') +  '?' }
				/>
			</div>
		)
	}
}

export default withRouter(ListTab)

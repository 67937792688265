import React, { Component } from 'react'

import TextField from '@mui/material/TextField'

class EditOrganisation extends Component {
	
	render() {
		const { organisation, onChange } = this.props

		return (
			<div>
				<TextField
					label="Name"
					value={ organisation.name }
					onChange={ onChange('name') }
					fullWidth
				/>
				<TextField 
					label="Description"
					value = { organisation.description }
					onChange={ onChange('description') }
					multiline
					fullWidth
				/>
                <TextField 
					label="Introduced Facts"
					value={ organisation.facts }
					onChange={ onChange('facts') }
					multiline
					fullWidth
				/>
				<TextField 
					label="Storyteller's Notes"
					value={ organisation.notes }
					onChange={ onChange('notes') }
					multiline
					fullWidth
				/>				                
            </div>
		)
	}
}

export default EditOrganisation

import React, { useContext, useState, forwardRef } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import arrayMove from "array-move";

import { List, ListItem, MenuItem, TextField } from "@mui/material";
import { useSnackbar } from "notistack";

import data from "@data";
import ImageSelector from "@components/ImageSelector";
import { Box, Dots, ConfirmDialog, Grid } from "@loom/ui";
import { HandleIcon, RemoveIcon } from "@loom/ui/icons";
import { xplog, getWillpower, startTraining, getSplatProp } from "@loom/characters/functions";

import EditContext from "./context";
import "./general.less";
import { Auth, Firestore } from "@contexts";

const Intimacy = forwardRef(
	({ intimacy, index, onChange, onRemove, placeholder, ...props }, ref) => {
		return (
			<ListItem ref={ref} {...props}>
				<HandleIcon />
				<TextField
					value={intimacy.strength ? intimacy.strength : ""}
					onChange={onChange("intimacies", index, "strength")}
					fullWidth
					select
				>
					<MenuItem value="defining">Defining</MenuItem>
					<MenuItem value="major">Major</MenuItem>
					<MenuItem value="minor">Minor</MenuItem>
				</TextField>
				<TextField
					value={intimacy.nature ? intimacy.nature : ""}
					onChange={onChange("intimacies", index, "nature")}
					fullWidth
					select
				>
					<MenuItem value="tie">Tie</MenuItem>
					<MenuItem value="principle">Principle</MenuItem>
				</TextField>
				<TextField
					value={intimacy.target ? intimacy.target : ""}
					onChange={onChange("intimacies", index, "target")}
					fullWidth
				/>
				<RemoveIcon className="interactable" onClick={() => onRemove()} />
				{placeholder}
			</ListItem>
		);
	}
);

const GeneralTab = () => {
	const [removing, setRemoving] = useState();
	const { storage } = useContext(Firestore);
	const { onChange, character, characterId } = useContext(EditContext);
	const { enqueueSnackbar } = useSnackbar();
	const { user } = useContext(Auth);

	console.log('char', character);

	const startingWillpower =
		getSplatProp(character, 'chargen', {})[character.chargen]?.willpower ?? 0;

	return (
		<div className="general">
			<Grid size="medium" nopad>
				<div className="details">
					<TextField
						label="Name"
						value={character.name ? character.name : ""}
						onChange={(event) => onChange("name")(event.target.value)}
						fullWidth
					/>
					<TextField
						label="Exaltation"
						value={character.splat ? character.splat : ""}
						onChange={(event) => onChange("splat")(event.target.value)}
						fullWidth
						select
					>
						{Object.keys(data.splats).map((i) => (
							<MenuItem key={i} value={i}>
								{data.splats[i].name}
							</MenuItem>
						))}
					</TextField>
					{character.splat ? (
						<TextField
							label={data.splats[character?.splat]?.subtype ?? "Subtype"}
							value={character.subtype ?? ""}
							onChange={(event) => onChange("subtype")(event.target.value)}
							fullWidth
							select
						>
							{Object.keys(data.splats[character?.splat]?.subtypes ?? {}).map(
								(i) => (
									<MenuItem key={i} value={i} className="capitalize">
										{i}
									</MenuItem>
								)
							)}
						</TextField>
					) : (
						""
					)}
					<TextField
						label="Character Generation"
						value={character.chargen ? character.chargen : ""}
						onChange={onChange("chargen")}
						fullWidth
						select
					>
						{
							Object.keys(getSplatProp(character, 'chargen', {})).map(
								(name, i) => (
									<MenuItem key={i} value={name}>
										{name}
									</MenuItem>
								)
							)}
					</TextField>
					{character.splat &&
						Object.entries(getSplatProp(character, 'extra', {})).map(
							([name, type]) => {
								if (type === "text")
									return (
										<TextField
											key={name}
											label={name}
											value={
												character.extra && character.extra[name]
													? character.extra[name]
													: ""
											}
											onChange={(event) =>
												onChange("extra", name)(event.target.value)
											}
											fullWidth
											multiline
										/>
									);
								if (data[type]) {
									let values = data[type];
									if (type === 'attributes') values = Object.values(data.attributes).flat().reduce((cur, val) => ({ ...cur, ...val}), {});

									return (
										<TextField
											label={name}
											value={character?.extra?.[name] ?? ""}
											onChange={(event) =>
												onChange("extra", name)(event.target.value)
											}
											fullWidth
											select
										>
											{Object.keys(values).map((i) => (
												<MenuItem key={i} value={i}>
													{i}
												</MenuItem>
											))}
										</TextField>
									);
								}
								return null;
							}
						)}
					<TextField
						label="Iconic Anima"
						value={character.iconic ? character.iconic : ""}
						onChange={(event) => onChange("iconic")(event.target.value)}
						fullWidth
						multiline
					/>
				</div>
				<ImageSelector
					path={`/${user.uid}/portraits/${characterId}`}
					storage={storage}
					value={character.portrait}
					onChange={onChange("portrait")}
				/>
			</Grid>
			<TextField
				label="Biography"
				value={character.description ? character.description : ""}
				onChange={(event) => onChange("description")(event.target.value)}
				fullWidth
				multiline
			/>
			<Box title="Willpower">
				<Dots
					value={getWillpower(character)}
					temp={
						(character.xplog ? character.xplog : []).reduce(
							(acc, cur) =>
								cur.data.type === "willpower" ? cur.data.nval : acc,
							0
						) - getWillpower(character)
					}
					min={startingWillpower}
					max={10}
					onChange={(e) => {
						if (character.experienced) {
							startTraining(
								character,
								xplog(character, "willpower", null, getWillpower(character), e),
								onChange,
								enqueueSnackbar
							);
						} else {
							onChange("willpower")(e);
						}
					}}
					editable
				/>
			</Box>
			<Box
				title="Intimacies"
				actions={[
					[
						"Add",
						() =>
							onChange("intimacies")([
								...(character.intimacies ? character.intimacies : []),
								{},
							]),
					],
				]}
			>
				<DragDropContext
					onDragEnd={(e) => {
						onChange("intimacies")(
							arrayMove(
								character.intimacies,
								e.source.index,
								e.destination.index
							)
						);
					}}
				>
					<Droppable droppableId="intimacies">
						{(provided, snapshot) => (
							<List
								className="intimacies"
								{...provided.droppableProps}
								ref={provided.innerRef}
							>
								{(character.intimacies || []).map((intimacy, index) => (
									<Draggable
										draggableId={`intimacy-${index}`}
										key={index}
										index={index}
									>
										{(provided, snapshot) => (
											<Intimacy
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												intimacy={intimacy}
												index={index}
												onChange={onChange}
												onRemove={() => setRemoving(index)}
												placeholder={provided.placeholder}
											/>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</List>
						)}
					</Droppable>
				</DragDropContext>
				<ConfirmDialog
					text={
						removing
							? "Do you want to delete " +
							  character.intimacies[removing].nature +
							  ": " +
							  character.intimacies[removing].target +
							  "?"
							: ""
					}
					open={Boolean(removing)}
					onConfirm={() => {
						onChange("intimacies")([
							...character.intimacies.filter((_, i) => i !== removing),
						]);
						setRemoving(null);
					}}
					onClose={() => setRemoving(null)}
				/>
			</Box>
		</div>
	);
};

export default GeneralTab;

import solar from "./splats/solar";
import dragonblooded from "./splats/dragonblooded";
import lunar from "./splats/lunar";
import sidereal from "./splats/sidereal";
import * as martialarts from "./martialarts";
import sorcery from "./sorcery/";
import merits from "./merits";
import npcs from "./npcs";
import exigents from "./splats/exigents";
import mortal from "./splats/mortal";
import abyssal from "./splats/abyssal";

const data = {
	splats: {
		solar,
		dragonblooded,
		lunar,
		sidereal,
		exigents,
		mortal,
		abyssal,
	},
	attributes: {
		physical: {
			strength:
				"Strength represents a character’s raw physical power, the sheer might of what he can achieve by muscle alone. In combat, Strength helps determine the damage a character can deal with his withering attacks, as well as how well he grapples foes. It’s also used when a character wishes to lift, break, or heave any objects or obstacles that might stand in his way.",
			dexterity:
				"Dexterity is grace, agility, and coordination. In combat, Dexterity determines both the accuracy of a character’s attacks and his faculty to defend against enemy attacks, as well as being used for movement that relies on swift or nimble maneuvering across terrain. It’s also used for actions that require precise control of physical motions, such as hiding from a patrolling sentry or picking a merchant prince’s pocket.",
			stamina:
				"Stamina is a measure of vitality. In combat, having a high Stamina rating reduces the damage of withering attacks. It’s also used to fight off poison or disease, and more generally to endure the effects of anything that might weaken or assail the body, be it lengthy physical exertion, sleep deprivation, suffocation, exposure to the elements, or starvation.",
		},
		mental: {
			intelligence:
				"Intelligence measures a character’s ability to think logically and rationally. It covers analytical thinking, drawing connections between disparate pieces of information, recalling pieces of knowledge or useful memories at opportune moments, and making judgments based on rigid logic. It is the province of strategoi, sorcerers, and savants.",
			perception:
				"Perception rates both the raw quality of a character’s senses and his faculty for comprehending what he perceives. Perception is used for noticing details, whether it is a strange plume of smoke over a far-off village, a black-clad assassin lying in wait, a subtle tell in a courtier’s manner of speech, or a fragmentary clue at the scene of a crime.",
			wits: "Wits rates a character’s intuition, common sense, and ability to react quickly to new circumstances or while under fire. In combat, Wits is used for the Join Battle roll that determines a character’s starting Initiative, and it also factors into a character’s capacity to remain unmoved by persuasion that goes against his intuitive understanding of the world, contributing to his Resolve rating.",
		},
		social: {
			appearance:
				"Appearance is a measure of a character’s striking looks and his ability to use them. Generally, this means a measure of how attractive the character is, but for certain characters, Appearance measures how intimidatingly unpleasant the character’s looks are. Appearance is used when a character wishes to influence others through looks, beauty, overwhelming presence, or first impressions rather than reasoned debate or emotional manipulation",
			charisma:
				"Charisma represents a character’s ability to express and articulate his beliefs or commands, convincing others to see things his way or follow his orders. It’s used primarily in social influence when a character wishes to make a sincere argument that he genuinely believes in, but can also be used in combat to lead armies from the front with rallying, inspiring speeches",
			manipulation:
				"Manipulation is a character’s innate talent for deception, passing off lies as the truth, or simply telling people what he knows they want to hear. It’s used primarily in social influence when a character wishes to make a false argument, whether through outright lying or more complicated verbal misdirection, as well as any argument where the character is purely trying to evoke a desired response rather than to express his own feelings or outlook. It also represents a character’s ability to remain composed and not reveal his true feelings, contributing to his Guile rating",
		},
	},
	abilities: {
		archery:
			"Archery is the discipline of using ranged weapons. The bow is the dominant form of ranged weapon in Creation in all its different varieties, from the simple wooden self bows used by subsistence hunters to the composite bows carried by the Realm’s archers. However, Archery also applies to the use of more exotic weapons. In the South, weapons fueled by a reagent called firedust shoot streamers of flame rather than arrows, and in the North, the nation-states of the Haslanti League favor the crossbow.",
		athletics:
			"Athletics is the discipline of physical fitness and acrobatic maneuverability. It is used in performing feats of strength, such as lifting up a fallen cart or bending the metal bars of a prison cage. It is also used for movement both in and out of combat—racing towards a distant enemy, jumping over a gaping chasm, climbing a brick wall, and swimming against a powerful tide are all uses of Athletics.",
		awareness:
			"Awareness is the practiced discipline of alertness and wariness of one’s surroundings. It is used for rolls to notice or pick up on sensory information, but is also important in combat, adding to the Join Battle roll that determines a character’s starting Initiative and allowing her to notice hidden enemies.",
		brawl:
			"Brawl is used by unarmed combatants who fight based on instinct and a natural talent for violence, rather than any particular discipline or style. It’s used for punches, kicks, head-butts, shoulder thrusts, grappling, and all similar methods of using one’s own body as a weapon. Brawl also conveys an aptitude for brawling aids such as brass knuckles and improvised weaponry such as chairs and broken bottles. In addition to covering attacks, Brawl can also be used to calculate a character’s Parry rating in combat, although parrying a weapon capable of inflicting lethal damage with one’s bare hands requires a stunt.",
		bureaucracy:
			"Bureaucracy represents a character’s acumen in organizational, mercantile, and legal endeavors. Bureaucracy is used to assess and evaluate the resources—both wealth and manpower—available to one’s self or one’s rivals, as well as to understand the legal codes of a society or the bureaucratic structure of an organization",
		craft:
			"Craft is used to create or repair objects through skilled labor, whether forging a sword, cutting the gem for a merchant prince’s brooch, or planning and overseeing the construction of a temple. The Exalted and other supernatural beings are capable of using this Ability to create artifacts, treasures of legendary quality that possess powerful magic of their own.",
		dodge:
			"Dodge is the skill of evading one’s enemies, deftly maneuvering out of the way of attacks or managing to avoid the dangers of fighting on hazardous terrain. In combat, a character’s Dodge rating contributes to her Evasion, but is also used to escape from close-quarter conflicts with enemies or to take cover from the attacks of far-off foes.",
		integrity:
			"Integrity is the Ability to remain resolute in one’s beliefs, principles, and decisions when they are challenged, an active discipline of self-assurance and refuting opposing arguments. It contributes to a character’s Resolve, which is used to resist the persuasion of socially skilled characters.",
		investigation:
			"Investigation is used to uncover hidden or obscured details through close surveillance or examination, whether it is searching a magistrate’s chamber for a hidden letter, unearthing clues at the scene of a murder, or reconstructing the details of a crime from the evidence presented before a magistrate at court.",
		larceny:
			"Larceny covers a range of talents that fall under the general penumbra of criminal activity. Pickpocketing, lock picking, and sleight of hand are all uses of Larceny that rely on physical deftness, but it also applies to more intellectual tasks such as creating an effective disguise, hiding evidence at the scene of a crime, or navigating a society’s criminal underworld.",
		linguistics:
			"Linguistics is the Ability used to compose works of writing—literary epics, poetic verse, propaganda pamphlets, or even a simple conversational letter. It can be used to influence the thoughts and feelings of characters who read the work using social actions. A character must have at least one dot of Linguistics to be able to read.",
		lore: "Lore represents a character’s understanding of the world, covering academic disciplines of history, mathematics, geography, literature, science, philosophy, and similar scholarly pursuits. It can be used both to recall useful pieces of academic knowledge, as well as to perform scholarly research in a library or archive. It is also important in the forging of artifacts and the raising of manses. Players must note the subject of their character’s education when selecting Lore, as this impacts the scope of what the Ability may cover for them (see p. 237 for more details on using Lore)— Lore 5 alone makes the character a world-class savant, but doesn’t mean she knows everything. Lore areas of expertise can be expanded with specialties and roleplaying.",
		"martial arts":
			"Martial Arts covers formal study of martial systems, such as the styles detailed in Chapter Seven. In addition to providing mastery of unarmed techniques, Martial Arts also facilitates the use of certain exotic weapons, such as the staff, the rope dart, sais, and hook swords. Like Brawl, it can be used to calculate a character’s Parry Defense.",
		medicine:
			"Medicine is used to diagnose and treat wounds, sickness, poisoning, and other ailments. In the Age of Sorrows, the state of Creation’s medical practice is primitive, with bone saws, sutures, and scalpels representing the most advanced medical technology a physician might have access to. In some of Creation’s cultures, methods of folk medicine such as herbal treatments, acupuncture, or therapeutic yoga take the place of traditional surgical implements, and can be just as effective, if not more so, in treating certain ailments.",
		melee:
			"Melee is the Ability of hand-to-hand weaponry, covering swords, knives, hammers, axes, lances, and any other weapon used in close quarters. It is used both to attack with these weapons and to calculate a character’s Parry rating when she blocks enemy attacks",
		occult:
			"Occult represents an instinctive facility for dealing with Creation’s mystical and otherworldly elements. It describes a rapport with the uncanny and things normally held beyond mortal ken. This includes understanding of the nature, powers, and ways of gods, ghosts, and demons, but also a sense of those things beyond the ken of Heaven and the gods. Occult is vital to the exorcist and the shaman, whose arts cannot be taught to those lacking proper sensitivity, and also to the sorcerer, whose senses pierce the veils of the universe.",
		performance:
			"Performance is the Ability of influencing others through creative or artistic displays, covering both traditional forms of artistic expression such as music, dance, and poetry, as well as rhetorical speaking through prepared speeches or oratory. It can be used to influence the thoughts and feelings of other characters through social actions",
		presence:
			"Presence is the Ability to effectively lead, intimidate, or persuade through force of personality. It can be used to influence the thoughts and feelings of other characters through social actions. When a hot-blooded general rallies her troops with an inspiring battle cry, a witty conversationalist charms those around her over the course of a party, or a thief blurts a convincing explanation for how she came into possession of a carved jade idol, all of these characters are using Presence",
		resistance:
			"Resistance is the learned Ability of coping with pain, disease, fatigue, and other forms of physical weakness. In combat, it is useful for fighting through the deleterious effects of poisons used by dishonorable foes, or weathering the perils of fighting over hazardous terrain.",
		ride: "Ride is used to ride on a horse, ox, or more exotic mount, as well as to maneuver in vehicles such as chariots or carts that are led by horses or beasts of burden. In combat, the Ride skill is used for moving across the battlefield while mounted.",
		sail: "Sail is used to captain a seafaring vessel through the great oceans and rivers that make up much of Creation. It is used both for maneuvering a vessel and for plotting a course using sea charts and navigational instruments. While the vast majority of vehicles in the Second Age are ocean-faring ships, the Sail Ability can also be used to pilot more exotic vessels, such as the hot-air balloon skyships used by the Haslanti League or many of the ancient, wondrous vehicles lost with the First Age.",
		socialize:
			"Socialize is a character’s understanding of the social context in which she acts, encompassing both knowledge of decorum and etiquette as well as how to use them to one’s advantage in persuasion or manipulation. In formal social situations such as an aristocratic court or a ritualized ceremony, a character can use Socialize to influence the thoughts and feelings of other characters with social actions. Socialize is also used both to read the emotional tells or body language of other characters to discern their inner thoughts, and to conceal such tells on one’s self, contributing to a character’s Guile rating.",
		stealth:
			"Stealth is used to avoid being noticed, evading the sight of one’s pursuers through physical deftness and cunning. In combat, it can be used to catch foes unawares with sneak attacks or to hide from threatening enemies. Outside of combat, it can be used for covert surveillance, silently infiltrating a guarded building, or blending into a crowd after committing a crime.",
		survival:
			"Survival is the Ability used to traverse and survive in wilderness environments. It covers the basic rudiments of outdoor survival, such as finding shelter, finding fresh water, and gathering food, as well as tracking, navigation, and handling animals.",
		thrown:
			"Thrown is the Ability used for weapons designed to be thrown at an enemy, rather than those which fire projectiles. Throwing knives, shuriken, javelins, chakrams, and nets are all used with this Ability",
		war: "War is the Ability of understanding strategy and tactics. It is used both to direct the long-term progress of a war, planning battles to gain the upper hand over enemy forces, as well as to command the soldiers or warriors that serve a character directly in battle.",
	},
	merits: merits,
	languages: {
		Riverspeak:
			"The language of the Scavenger Lands. The Guild has promoted this loanword-filled tongue as the unofficial 'trade language' of the Threshold; it is probably the most widely-understood language in Creation. Its written form employs a simple glyphic alphabet whose characters can easily be shaped with a quill, stylus, or even a stick of charcoal.",
		"Low Realm":
			"The tongue of the Realm's commoners, generally constituting local bastardizations of High Realm drifted into near-incomprehensibility to outsiders. It shares the written stylings of High Realm, but often uses simplified character depictions which can be rendered without brushes or careful calligraphy.",
		"High Realm":
			"The formal language of Dynasts and patricians of the Blessed Isle, as well as their servants. All official business of the Realm is conducted in High Realm. Its written form is a phonetic alphabet of complex brush work characters.",
		"Old Realm":
			"The native language of the spirits and those that created them, as well as of the Fair Folk. It was widely spoken in the First Age, especially by savants and sorcerer-engineers, and used for many official documents. Characters must have Lore 1+ or Occult 1+ to learn this language. There exist several styles for writing Old Realm, the most extravagant of which is a complex glyphic system where symbol arrangement is as important as symbol choice, and the same phrase might be read in several ways, often as a deliberate choice by the writer intended to impart subtle and layered meaning.",
		Skytongue:
			"A language family spoken in the North. Its written form employs a simple runic alphabet.",
		"Forest-tongue":
			"A language family spoken in the East, beyond the bounds of the Scavenger Lands. Its written form consists of a wide array of branching ideograms, connected vertically and diagonally.",
		Flametongue:
			"A language family spoken in the South. Its written form employs a sophisticated alphabet of flowing lines and curves; meaning can be greatly influenced by the manner in which characters connect to one another.",
		Seatongue:
			"A language family spoken in the West. Its written form consists of a simple alphabet of lines and dots whose connection to one another can be exceedingly complex.",
		"Guild Cant":
			"A secret language spoken only by members of the Guild. Its written form is composed of simple horizontal and vertical lines, designed to be able to be written quickly and unobtrusively when necessary.",
		Dragontongue:
			"Derived from recovered elements of apriestly language that was lost during the shogunate, luminaries of House Mnemon birthed and spread this language over the course of two centuries. It is a mix of Old Realm and High Realm, with elements of a lost shogunate tongue, and excludes the mind from the wider, more dangerous concepts inherent to Old Realm, keeping a person in mind of the Dragons, the Poles, the natural world and the Perfected Hierarchy. It is a beautiful language more than a scholarly one, and even in satrapies which have been thoroughly suppressed by the Realm, there is a rush by savants and poets to learn this language of poets and princes. Its written form utilizes very challenging yet beautiful brushstrokes.",
		"Local Dialects":
			'Creation contains countless languages spoken by specific "barbarian" tribes, insular ethnic enclaves, isolated island-dwellers, and various other small groups. Four such languages may be learned with a single Merit purchase.',
	},
	hearthstone: {
		keywords: {
			Linked:
				"Hearthstones of this type channel their mystical benefits from their parent manse. If the manse is destroyed or disrupted, the hearthstone becomes inert and ceases to function. An inert hearthstone crumbles to ash within a matter of days. Linked hearthstones can only be grown in a manse, and never occur naturally in demesnes.",
			Steady:
				"Hearthstones of this type sustain their own miraculous power. Disrupting their parent manse or demesne has no effect upon them.",
			Dependent:
				"The complex magic of a hearthstone with this keyword only kindles and activates when socketed into an attuned artifact that already contains another hearthstone in one of its other sockets, which must lack this keyword. For example, the Hierophant’s Eye could grant its benefit if placed in the socket of a daiklave with another socket already filled by the Freedom Stone, but not if simply placed in a single-socket daiklave or if the Freedom Stone were absent.",
			"Manse-born":
				"Hearthstones of this type can only be grown in a manse, and never occur in the wild.",
			"Wild-born":
				"Hearthstones of this type can only form naturally in demesnes—no geomantic architect has ever learned to duplicate them in a manse. All wild-born hearthstones are also steady.",
		},
	},
	charm: {
		keywords: {
			aggravated:
				"The Health Track damage inflicted by this Charm cannot be healed magically, nor can magic be used to speed up the natural process of healing it.",
			bridge:
				"A Charm with this keyword can be purchased with alternate prerequisites from another Ability. If all the prerequisites used to buy a Bridge Charm enjoy a Caste/Favored cost discount, so does the Bridge Charm. No non-Integrity Charm can act as a prerequisite for more than one Bridge Charm, and Integrity Charms can never serve as an alternate Bridge prerequisite. If Integrity is Caste or Favored, the character may buy in via half the listed number Bridge prerequisites (round up, or round down if Supernal).",
			clash:
				"Cannot be used simultaneously with or in response to a Charm with the Counterattack keyword.",
			counterattack:
				"Cannot be used in reaction to a Charm with the Counterattack or Clash keyword.",
			"decisive-only":
				"If it's an attack Charm, the Charm can only be used with a decisive attack. If it is a defensive Charm, it can only be used to defend against a decisive attack.",
			dual: "This Charm has two different functions, one for withering and one for decisive.",
			mute: "This Charm's cost will not add to the Exalt's anima level unless she wants it to.",
			pilot:
				"The character must be the captain or the helmsman of the sailing vessel to use this Charm.",
			psyche:
				"A power with this keyword is an unnatural, hypnotic, or sorcerous power that magically influences, controls, or cripples an opponent's thoughts or feelings.",
			perilous:
				"Be cautious about your reliance on this Charm! Charms with this keyword cannot be used in Initiative Crash.",
			salient:
				"This keyword indicates that the Charm's cost requires silver, gold, and white points for major, superior, and legendary craft projects, respectively.",
			stackable: "This Charm's effects can stack.",
			uniform:
				"This Charm has the same function for both withering and decisive attacks or defenses.",
			"withering-only":
				"If it's an attack Charm, the Charm can only be used with a withering attack. If it is a defensive Charm, it can only be used to defend against a withering attack.",
			"written-only":
				"A Charm with this keyword can only be used to enhance, supplement, or create written social influence.",
			balanced:
				"Balanced Charms don't interfere with the Dragon-Blood's Aura even if they don't match its aspect - they won't prevent her from entering an Aura at the end of her turn if used with Charms of another element, and never end her Aura. If she uses only Balanced Charms on her turn, she may enter the Aura of any of the elements whose Charms she used.",
			excellency: "This keyword indicates Dragon-Blooded Excellencies.",
			aura: "Aura Charms can only be used while the Dragon-Blood is in the Elemental Aura that matches its aspect. An Aura Charm can't be used in the same instant with Charms of other elements, unless those Charms have the Balanced keyword.",
			signature: "This keyword marks an Ability's five elemental Signatures.",
			air: "If the only charms used during your combat turn have this keyword, or are balanced, you enter the Air Aura at the end of your turn.",
			earth:
				"If the only charms used during your combat turn have this keyword, or are balanced, you enter the Earth Aura at the end of your turn.",
			fire: "If the only charms used during your combat turn have this keyword, or are balanced, you enter the Fire Aura at the end of your turn.",
			water:
				"If the only charms used during your combat turn have this keyword, or are balanced, you enter the Water Aura at the end of your turn.",
			wood: "If the only charms used during your combat turn have this keyword, or are balanced, you enter the Wood Aura at the end of your turn.",
			mastery:
				"This keyword denotes Charms that have a greater effect when used by those Exalted who are masters of mortal Abilities: the Solar and Abyssal Exalted. The Sidereal Exalted, peerless masters of the martial arts, have their own esoteric methods for accessing these effects.",
			terrestrial:
				"This keyword denotes that a Martial Arts Charm grants a lesser or restricted effect when used by Dragon-Blooded martial artists. Certain masters of the Immaculate Order have developed spiritual initiations that allow them to overcome these limitations.",
			divination:
				"Divination Charms are a Sidereal’s powers of foresight, prophecy, and prediction. They’re limited in how much information they can provide on enemies of fate. Their presence and actions can be divined, but not their appearance, identity, or specific nature; they are indistinct, featureless voids within prophetic visions. Note that non-Sidereal Charms that predict the future, like God-King’s Shrike, aren’t Divination Charms.",
			shaping:
				"Shaping effects transforms characters’ bodies, minds, souls, or fates. They can be defended against with magic like Destiny-Manifesting Method (Exalted, p. 304). Versatile: Archery, Brawl, Melee, and Thrown Charms with this keyword can enhance attacks and parries with Martial Arts if the Sidereal uses a weapon compatible with that Ability.",
			versatile:
				"Charms from multiple Abilities can’t enhance the same action.",
		},
	},
	weapon: {
		categories: {
			mortal: {
				"Light Melee": {
					accuracy: 4,
					damage: 7,
					defence: 0,
					overwhelming: 1,
					commit: 0,
				},
				"Medium Melee": {
					accuracy: 2,
					damage: 9,
					defence: 1,
					overwhelming: 1,
					commit: 0,
				},
				"Heavy Melee": {
					accuracy: 0,
					damage: 11,
					defence: -1,
					overwhelming: 1,
					commit: 0,
				},
				"Light Ranged": {
					range: [-2, 4, 2, 0, -1],
					damage: 7,
					overwhelming: 1,
					commit: 0,
				},
				"Medium Ranged": {
					range: [-2, 4, 2, 0, -1],
					damage: 9,
					overwhelming: 1,
					commit: 0,
				},
				"Heavy Ranged": {
					range: [-2, 4, 2, 0, -1],
					damage: 11,
					overwhelming: 1,
					commit: 0,
				},
				"Light Thrown": {
					range: [4, 3, 2, -1, -3],
					damage: 7,
					overwhelming: 1,
					commit: 0,
				},
				"Medium Thrown": {
					range: [4, 3, 2, -1, -3],
					damage: 9,
					overwhelming: 1,
					commit: 0,
				},
			},
			artifact: {
				"Light Melee": {
					accuracy: 5,
					damage: 10,
					defence: 0,
					overwhelming: 3,
					commit: 5,
				},
				"Medium Melee": {
					accuracy: 3,
					damage: 12,
					defence: 1,
					overwhelming: 4,
					commit: 5,
				},
				"Heavy Melee": {
					accuracy: 1,
					damage: 14,
					defence: 0,
					overwhelming: 5,
					commit: 5,
				},
				"Light Ranged": {
					range: [-1, 5, 3, 1, -1],
					damage: 10,
					overwhelming: 3,
					commit: 5,
				},
				"Medium Ranged": {
					range: [-1, 5, 3, 1, -1],
					damage: 12,
					overwhelming: 4,
					commit: 5,
				},
				"Heavy Ranged": {
					range: [-1, 5, 3, 1, -1],
					damage: 14,
					overwhelming: 5,
					commit: 5,
				},
				"Light Thrown": {
					range: [5, 4, 3, 0, -2],
					damage: 10,
					overwhelming: 3,
					commit: 5,
				},
				"Medium Thrown": {
					range: [5, 4, 3, 0, -2],
					damage: 12,
					overwhelming: 4,
					commit: 5,
				},
				"Heavy Thrown": {
					range: [5, 4, 3, 0, -2],
					damage: 14,
					overwhelming: 5,
					commit: 5,
				},
			},
		},
		tags: [
			"Balanced",
			"Bashing",
			"Chopping",
			"Concealable",
			"Crossbow",
			"Curring",
			"Disarming",
			"Flame",
			"Flexible",
			"Grappling",
			"Improvised",
			"Lethal",
			"Mounted",
			"Natural",
			"One-Handed",
			"Piercing",
			"Poisonable",
			"Powerful",
			"Range (Short)",
			"Range (Medium)",
			"Range (Long)",
			"Range (Extreme)",
			"Reaching",
			"Shield",
			"Slow",
			"Smashing",
			"Special",
			"Subtle",
			"Two-Handed",
			"Worn",
		],
	},
	armour: {
		categories: {
			mortal: {
				Light: { soak: 3, hardness: 0, penalty: 0, commit: 0 },
				Medium: { soak: 5, hardness: 0, penalty: 1, commit: 0 },
				Heavy: { soak: 7, hardness: 0, penalty: 2, commit: 0 },
			},
			artifact: {
				Light: { soak: 5, hardness: 4, penalty: 0, commit: 4 },
				Medium: { soak: 8, hardness: 7, penalty: 1, commit: 5 },
				Heavy: { soak: 11, hardness: 10, penalty: 2, commit: 6 },
			},
		},
		tags: ["Bouyant", "Concealable", "Silent"],
	},
	craftrules: {
		types: {
			basic: {
				cost_base: {},
				reward_objective: { silver: 2 },
				reward_exceptional_objective: { silver: 1 },
			},
			major: {
				slots: { initial: 3, cost_base: { silver: 5 } },
				cost_base: { silver: 10 },
				time_manual: true,
				reward_objective: { gold: 2, silver: 1 },
				reward_exceptional_objective: { gold: 1 },
				manual_fail: true,
			},
			superior: {
				dotted: true,
				requirements: { lore: 3, occult: 3, craft: { artifacts: 1 } },
				time_rating: { 2: 42, 3: 84, 4: 427, 5: 854 },
				roll: {
					difficulty: 5,
					terminus: 6,
					goal_number_rating: { 2: 30, 3: 50, 4: 75, 5: 100 },
				},
				cost_base: { gold: 10 },
				slots: {
					initial: 0,
					cost_slots: { major: 3 },
					cost_rating: { gold: 1 },
				},
				reward_objective_rating_once: {
					2: { white: 3 },
					3: { white: 5 },
					4: { white: 7 },
					5: { white: 9 },
				},
				reward_excess_interval_multiplier: { gold: 2 },
			},
			legendary: {
				requirements: { lore: 5, occult: 5, craft: { artifacts: 5 } },
				slots: { initial: 0, cost_base: { white: 5 } },
				time_base: 4270,
				roll: { difficulty: 5, terminus: 6, goal_number_base: 200 },
				cost_base: { white: 10 },
				reward_objective_once: { white: 10 },
				reward_excellency: { sux: { gold: 1 }, fail: { silver: 1 } },
			},
		},
		objectives: [
			"Another character gained an intimacy",
			"You gained a clear in-game benefit",
			"One of your intimacies was upheld, furthered or protected",
		],
		xp: ["silver", "gold", "white"],
	},
	martialarts: {},
	sorcery: {
		spells: sorcery,
		workings: {
			circles: {
				terrestrial: { 1: 5, 2: 10, 3: 20 },
				celestial: { 1: 25, 2: 30, 3: 35 },
				solar: { 1: 40, 2: 50, 3: 75 },
			},
			finesse: [1, 3, 5],
			ambitions: { 1: 2, 2: 4, 3: 8 },
			cost: { 1: 2, 2: 4, 3: 8 },
			underxp: 2,
			overxpfactor: 2,
			defaultTerminus: 6,
		},
	},
	npcs: npcs,
	constellations: {
		"The Captain":
			"The Captain rules journeys made possible by leadership. It’s the sign of ship’s officers, professional soldiers, work gang bosses, and stern parents. Its positive aspects include determination, discipline, organization, strength of will, measured use of force, and prudence in using one’s power and authority. Its negative aspects include the selfish or irrational pursuit of authority, the paranoia of the powerful, abuses of authority, and bureaucratic inertia.",
		"The Gull":
			"The Gull rules journeys made for their own sake and unmotivated by desire for profit, except perhaps a day’s wage. It’s the sign of stray dogs, homeless people, and itinerant monks. The Gull encompasses journeys of self-examination and change as well as literal travel. In its positive aspect, travel bring the freedom of the road, opportunities to share outsiders’ perspectives, and unexpected rewards, while self-examination leads to growth, self-understanding, and the resolution of inner turmoil. In its negative aspect, travelers are thoughtlessly irresponsible or go without the respite of home, while introspection proves shallow and self-serving or simply isn’t done.",
		"The Mast":
			"The Mast rules journeys made possible by the physical strength and exertion. It’s the sign of ditchdiggers, domestic servants, soldiers, scribes, and insects. In its positive aspect, strength is turned unquestioningly to vital tasks and supports the efforts and endeavors of others. In its negative aspect, strength is used unthinkingly, and the mighty are unwilling to question their orders and unable to communicate.",
		"The Messenger":
			"The Messenger rules journeys made for the sake of duty or obligation. It’s the sign of couriers, aides, diplomats, and service to one’s family. In its positive aspect, travelers are brave, professional, and skillful, overcoming great odds to meet their obligations. In its negative aspect, travelers perform good service for bad ends, thoughtlessly abuse their power, and abdicate moral responsibility.",
		"The Ship's Wheel":
			"The Ship’s Wheel rules journeys made in the face of great hardship and the persistence of those who defyall obstacles. Its name harkens to steersmen lashing themselves to the wheel so their ships may weather the storm. It’s the sign of furnace stokers, steersmen, those made foolish by love, and the Solar Exalted. In its positive aspect, endeavors weather all adversities and perseverance is rewarded. In its negative aspect, people pursue doomed causes, boondoggles, and quixotic obsessions that lead only to their ruin.",
		"The Ewer":
			"The Ewer rules relationships based on love and affection. It’s the sign of courtship, star-crossed romances, idealism, and revolutionaries. In its positive aspect, relationships come about from flirtation, courtship, or seduction or arise from friendship, shared dreams, or enduring affection. In its negative aspect, would-be suitors are thoughtless, obsessive, or doomed to failure.",
		"The Lovers":
			"The Lovers rule imbalanced and uneven relationships. It’s the sign of debt, slavery, poverty, and sexual intimacy. In its positive aspect, such relationships prove beneficial, or at least a mixed bag, like the best relationships one can hope for between parent and child, teacher and student, employer and employee, or landlord and tenant. In its negative aspect, the relationship’s power imbalance leads to exploitation, manipulation, or, physical, emotional, or financial abuse.",
		"The Musician":
			"The Musician rules contentment in life and sensual pleasure. It’s the sign of joy, desire, and art. In its positive aspect, people indulge in luxury and leisure and return from their idylls renewed. In its negative aspect, pleasure leads to the neglect of one’s responsibilities, greed and pointless materialism conquerreason, and people compromise their principles in pursuit of pleasure.",
		"The Peacock":
			"The Peacock rules relationships entered into for pragmatic reasons. It’s the sign of sex workers, heads of households, and Dragon-Blooded families. In its positive aspect, these relationships are mutually beneficial: practical alliances, well-considered partnerships, thoughtfully arranged marriages. In its negative aspect, they’re entered into out of necessity and barely functional: forced alliances, loveless marriages, and friendships born entirely out of proximity.",
		"The Pillar":
			"The Pillar rules relationships tested by time. It’s the sign of parenthood, sovereignty, and platonic relationships: family, friends, coworkers, business partners, and acquaintances as casual as drinking buddies. In its positive aspects, these relationships prove stable and steady in the face of challenges. In its negative aspects, they are tested and found wanting.",
		"The Banner":
			"The Banner rules storied deeds, heroic reputation, and battles won through fear. It’s the sign of gossip, renown, and self-sacrifice. In its positive aspect, leadership overcomes great obstacles, fame is recognized and rewarded, and glory is won on the battlefield. In its negative aspect, leadership proves uninspiring, infamous deeds are committed, panic overwhelms the mind, and baseless rumor passes for truth, terrorism, uninspiring leadership, and those who live off their reputation.",
		"The Gauntlet":
			"The Gauntlet rules choices between bad and worse, decisions made under pressure, and battles won through ruthlessness. It’s the sign of sergeants, disciplinarians, practical parents, surgeons, judges, and those who enforce laws through violence. In its positive aspect, difficult choices are made with the courage and conviction to do what’s necessary no matter the cost. In its negative aspect, such choices are made callously, ruthlessly, and by those willing to justify the whims of the moment as necessity.",
		"The Quiver":
			"The Quiver rules versatility, cunning, and battles won through ingenuity. It’s the sign of the quick-witted, duelists, know-it-alls, socialites, generals, and urbane boors. In its positive aspect, victory comes from ingenuity, adaptability, a cool head, a diversity of ideas, or trust in the expertise of others. In its negative aspect, groupthink, ignorance, and irrationality prevail, while bad ideas are explained well.",
		"The Shield":
			"The Shield rules courage, the willingness to endure and inflict pain, and battles fought without regard for victory. It’s the sign of warriors who fight outside of military ranks, assassins, scouts, and the Lunar Exalted. Its positive aspect governs valor in the face of danger, warriors who fight to protect their comrades in arms, and heroes who dare impossible odds. In its negative aspect, it governs headstrong foolishness, excessive self-sacrifice, thoughtless violence, and a dangerous willingness to die.",
		"The Spear":
			"The Spear governs martial prowess, military discipline, and battles won through skill. It’s the sign of veterans, skilled militias, mercenaries, bodyguards, arms instructors, and competition. In its positive aspect, skill and discipline are used for good causes and prevail over undisciplined opposition. In its negative aspect, experts and decision-makers succumb to professional myopia, refuse to consider unconventional approaches, and emphasize the appearance of professionalism over substance.",
		"The Guardians":
			"The Guardians rule practical wisdom and knowledge gained through experience. It’s the sign of shamans, midwives, those selling talismans and other wonders, snake-oil peddlers, and incompetence. In its positive aspect, such knowledge is useful even if it isn’t entirely true, conveyed by wise elders, self- trained experts, and those who live by simple, honest labor. In its negative aspect, this knowledge is falsehood, quackery, and superstition spoken by con men, doomsayers, and the gullible.",
		"The Key":
			"The Key rules scholarly knowledge, secrets revealed by intellectual efforts, and tasks accomplished through cleverness. It’s the sign of physicians, scavenger princes, tinkers, junk sellers, and primates. In its positive aspect, experimentation, inquiry, and sharp thinking lead to useful insights and new solutions. In its negative aspect, such efforts are pointless, wasteful, or delve into matters best left unknown.",
		"The Mask":
			"The Mask rules secret wisdom and concealed knowledge. It’s the sign of spies, priests, and archivists. In its positive aspect, it conceals trade secrets, religious mysteries, information held in confidence, and rites of initiation. In its negative aspect, knowledge is hidden because of greed, fear, doubt, or self-superiority. It is the sign in which the Jade Prison was sealed after the Solar Purge, giving rise to the Sidereals’ arcane fate.",
		"The Sorcerer":
			"The Sorcerer rules mystical wisdom and the secrets of power. It’s the sign of the Exalted, gods, sorcery, necromancy, thaumaturgy, artifice, and geomancy. In its positive aspect, supernatural power is wielded wisely and justly, accomplishing miracles and forging wonders for the world’s betterment. In its negative aspect, such power is used selfishly or for misguided reasons, or is sought from untrustworthy sources like ghosts and demons.",
		"The Treasure Trove":
			"The Treasure Trove rules revealed wisdom, shared knowledge, and divination. It also encompasses ordeals overcome through personal prowess and rewards won thereby. It’s the sign of savants, teachers, fortune-tellers, and revolutionaries seeking popular support. In its positive aspect, education flourishes, intellectuals turn their erudition to the betterment of society, and new ideas change the world. In its negative aspect, intellectual life is choked by elitism and pointless cerebration, trivia is prized over wisdom, and intellectuals pursue petty, self-gratifying goals without any sense of duty to others",
		"The Corpse":
			"The Corpse rules the end of lives and ways of life. It’s the sign of quick and painless deaths, butterflies, reincarnation, the deathbed, young affianced couples, and those forced to change careers in middle age. In its positive aspect, endings are met with planning, dignity, and grace. In its negative aspect, endings are agonizing, overwhelming, and traumatic.",
		"The Crow":
			"The Crow rules the end of delusions and denial. It is the sign of slow but painless deaths, death by old age, inevitability and the recognition thereof, ancestor worship, young widows, and the elderly. In its positive aspect, those who face their mortality take solace in gallows humor, find comfort in the thought of death, or willingly face near-certain death for what they believe in. In its negative aspect, the inevitability of death drives those who confront it to despair, depression, apathy, and cynicism.",
		"The Haywain":
			"The Haywain rules ambiguous endings that are difficult to describe and the gradual decline and failure of systems with fuzzy boundaries and imprecise definitions. It’s the sign of mysterious deaths, death by sorcery, faltering alliances, bureaucratic mismanagement, corrupt institutions, family troubles, the difficulties of old age, and divinity. In its positive aspect, these endings are the beginning of something new. In its negative aspect, creeping decay and gradual collapse leads to stagnation and inaction.",
		"The Rising Smoke":
			"The Rising Smoke rules the endings of journeys both literal and figurative. While the Corpse holds sway over changes in the circumstances of one’s life, the Rising Smoke governs changes to one’s self, those that unfold over time with experience and self-understanding. It’s the sign of quick but painful deaths, youth, apprenticeships, lawsuits, and bodies on their way to be buried. In its positive aspect, journeys end in growth, transformation, and fulfillment, as with the end of a student’s time in school. In its negative aspect, journeys prove fruitless or lead only to suffering.",
		"The Sword":
			"The Sword rules the endings of hopes and dreams. It’s the sign of slow and painful deaths, long-lasting suffering, despair, ghosts, has-beens, and expecting parents. In its positive aspect, hopes end once they’ve been fulfilled. In its negative aspects, hopes wither and die, leaving people broken, crushed, and worn down.",
	},
	deathlords: {
		"The Bishop of the Chalcedony Thurible":
			"The Bishop preaches a doctrine of oblivion, teaching that the annihilating embrace of the void is the only escape from the suffering of existence. His deathknights are evangelists, crusaders, philosophers, and inquisitors.",
		"The Black Heron":
			"The preeminent killer among the Deathlords’ ranks, a legendary assassin who hides behind the façade of a charming ghost-queen. Centuries ago, her forces suffered a devastating defeat from which she’s still recovering. She now holds court in Stygia, courting allies and rebuilding her power.",
		"The Dowager of the Irreverent Vulgate in Unrent Veils":
			"The Dowager is the Neverborn’s most faithful servant among the Deathlords, though she’s by far the least lucid among their ranks. Peering into the Well of Udr, she dredges forth nightmares and monsters to unleash against the living. Her deathknights have broad leeway in following her cryptic instructions, though they must occasionally hunt down her horrors when they rampage out of control.",
		"Eye and Seven Despairs":
			" A master of necromancy and artifice, the Eye toils ceaselessly at creating undead abominations and world-killing weapons. Their deathknights assist them in their workshops and laboratories, act as intermediaries to those seeking the Eye’s horrific creations, and retrieve esoteric knowledge, exotic materials, and lost wonders of the Underworld.",
		"The First and Forsaken Lion":
			" A grim warlord eternally imprisoned in cursed armor by the Neverborn, the Lion’s true ambition is to conquer the Underworld. Their deathknights are the greatest of the Deathlord’s generals and champions among the Legion Sanguinary, the Underworld’s paramount fighting force.",
		"The Lover Clad in the Raiment of Tears":
			" The Lover is a cruel witch-queen, driving heroes to despair so they may learn the folly of their attachments and desires. Her deathknights share her cruel enlightenment with the living, lead her forces in war, and subvert mortal societies.",
		"The Mask of Winters":
			" A statesman and spymaster, the Mask of Winters is notorious for his conquest the Thorns. He plays politics with mortal princes, turning kingdoms and nations against each other and poisoning them from within. His deathknights are ambassadors, generals, bureaucrats, and spies.",
		"The Silver Prince":
			" The Silver Prince reigns over the Skullstone Archipelago, where zombie servants toil. The Prince dreams of cultural domination, believing Skullstone is a perfect society that will one day assimilate all Creation. His deathknights live as royalty, serving as diplomats, privateers, priests, and cultural luminaries.",
		"The Walker in Darkness":
			" The Walker in Darkness is a figure of dark temptation, promising princes and queens their heart’s desire in bargains he seems to be true. He leads the Company of Martial Sinners, an elite band of mercenaries, selling their services to plunge the realms of the living into war. His deathknights lead the Company’s forces , collect on the Deathlord’s bargains , and speak on the Walker’s behalf in foreign courts.",
		"Another Deathlord":
			"There may be Deathlords who reign over the Underworld; you can work with your Storyteller and playgroup to invent them and explore their themes and ambitions.",
		"No Deathlord":
			"Some Abyssals abandon the service of the Deathlord who chose them or refuse to ever enter it. These renegades are sometimes called deathknights-errant, choosing who — if anyone",
	},
};
Object.keys(martialarts).map(
	(o) => (data.martialarts[martialarts[o].name] = martialarts[o])
);

for (var splat of Object.keys(data.splats)) {
	for (var charm of Object.keys(data.splats[splat].charms ?? {})) {
		if (
			data.splats[splat].charms[charm].reqs &&
			data.splats[splat].charms[charm].reqs.charms
		) {
			for (var req of data.splats[splat].charms[charm].reqs.charms) {
				if (!data.splats[splat].charms[req]) {
					console.log(
						"Missing req charm " + req + " for " + charm + " in " + splat
					);
				}
			}
		}
	}
}

export default data;

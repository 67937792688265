import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { Auth, Firestore, Loom } from '@contexts';
import { Box, ListTab } from '@loom/ui';
import { LocaleIcon } from '@loom/ui/icons';

const List = ({ context, locales }) => {
	const { user } = useContext(Auth);
	const { addExtraContent, db } = useContext(Firestore);
	const { locales: localeData } = useContext(Loom);
	const { pathname: path } = useLocation();

	addExtraContent('locales', locales);

	if (!localeData) return null;

	return (
		<Box title="Locales">
			<ListTab
				items={
					(locales ?? [])
						.filter((id) => localeData[id] !== undefined)
						.map((id) => ({
							id:id,
							link: `${path}/locale/${id}`,
							label:localeData[id].name,
							locked:localeData[id].owner !== user?.uid,
						}))
				}
				icon={ <LocaleIcon /> }
				onRemove={ (i, loc) => {
					db.collection(context.collection).doc(context.id).update({
						locales:context.data.locales.filter((id) => loc.id !== id)
					})
				}}
				sorted
			/>
		</Box>
	);
}

export default List;
import React, { useContext, useState } from 'react'

import { AppBar, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import { normalize, isSorcerer } from '@loom/characters/functions';
import { Tab, TabContainer } from '@loom/ui'
import { CharacterIcon, DetailsIcon, BattleIcon, SocialIcon, CharmIcon, MeritIcon, CraftIcon } from '@loom/ui/icons'
import ViewContext from '@loom/characters/pc/view/context';
import { Loom, UI as UIContext } from '@contexts';

import GeneralTab from './general'
import TraitsTab from './traits'
import CharmsTab from './charms'
import MeritsTab from './merits'
import CombatTab from './combat'
import SocialTab from './social'
import ProjectsTab from './social'
import './dialog.less';

const UI = ({ splat, children, onClose }) => {
	const [ tab, setTab ] = useState('general');
	
	return (
		<TabContainer
			tab={ tab }
			tabs={ children }
			onChange={ (e,value) => { setTab(value) }}
		>{
			(tabs, content) => (
				<div className={ `ui ${splat || ''}` } >
					<Dialog open={ true } maxWidth="lg" className="character-dialog">
						<DialogTitle disableTypography>
							{ tabs && <>
								<AppBar color="secondary" className="toolbar" position="static">
									{ tabs }
								</AppBar>
							</>}
						</DialogTitle>
						<DialogContent>
							<UIContext.Provider value={{ splat: splat }}>{
								content
							}</UIContext.Provider>
						</DialogContent>
						<DialogActions>
							<Button onClick={ onClose }>Close</Button>
						</DialogActions>
					</Dialog>
				</div>
			)
		}</TabContainer>
	)
}

const PCDialog = ({
	characterId,
	onClose
}) =>{
	const { pcs, hearthstones } = useContext(Loom);
	if (!characterId) return null;

	const character = normalize(pcs[characterId], hearthstones);

	return (
		<ViewContext.Provider value={{ character, characterId }}>
			<UI splat={ character.splat } onClose={ onClose }>
				<Tab value="general" label="General" icon={ <CharacterIcon /> } render={ <GeneralTab key="general" character={ character } /> } />
				<Tab value="traits" label="Traits" icon={ <DetailsIcon /> } render={ <TraitsTab key="traits" character={ character } /> } />
				<Tab value="charms" label="Charms" icon={ <CharmIcon /> } render={ <CharmsTab key="charms" character={ character } /> } />
				<Tab value="merits" label="Merits" icon={ <MeritIcon /> } render={ <MeritsTab key="merits" character={ character } /> } />
				<Tab value="combat" label="Combat" icon={ <BattleIcon /> } render={ <CombatTab key="combat" character={ character } /> } />
				<Tab value="social" label="Social" icon={ <SocialIcon /> } render={ <SocialTab key="social" character={ character } /> } />
				{ ((character.crafts && character.crafts.length > 0) || isSorcerer(character)) &&  <Tab value="projects" label="Projects" icon={ <CraftIcon /> } render={ <ProjectsTab key="projects" character={ character } /> } /> }
			</UI>
		</ViewContext.Provider>
	);
};

export default PCDialog;

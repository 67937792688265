import React from 'react'
import { useHistory, useLocation  } from 'react-router-dom';

import BottomNavigation from '@mui/material/BottomNavigation' 
import BottomNavigationAction from '@mui/material/BottomNavigationAction' 

import HelpDialog from '@loom/help'

import { HelpIcon } from './icons'

const ActionBase = ({
	children
}) => {
	const history = useHistory();
	const location = useLocation();

	return (
		<div>
			<BottomNavigation showLabels>
				{ children }
				<BottomNavigationAction
					label="Help" icon={<HelpIcon /> }
					onClick={ () => history.push({ ...location, hash: '/' }) }
				/>
			</BottomNavigation>
			 <HelpDialog
				onClose={ () => history.push({ ...location, hash: null }) }
			/>
		</div>
	)
}

export default ActionBase

import React from 'react';

import { isSorcerer } from '@loom/characters/functions'
import { Grid } from '@loom/ui';

import CraftTable from './craft-table';
import WorkingTable from './working-table';

import './projects.less';

const ProjectsTab = ({
	character
}) => {
	return (
		<div className="projects">
			<Grid size="large">
				{ character?.crafts?.length > 0 && (
					<CraftTable character={ character } />
				)}
				{ isSorcerer(character) && (
					<WorkingTable character={ character } />
				)}
			</Grid>
		</div>
	)
};

export default ProjectsTab;

import React, { useContext } from 'react';

import data from '@data';
import { Box, Grid, Spinner } from '@loom/ui';

import EditContext from '../context';
import { getSplatProp } from '@loom/characters/functions';

const Experience = () => {
	const { character, onChange } = useContext(EditContext);

	if (!character.experienced) return null;

	return (
		<Box title="Experience">
			<Grid size="medium">
				<Spinner
					label="XP"
					min="0"
					value={ character.experience && character.experience.regular ? character.experience.regular : 0  }
					onChange={ onChange('experience', 'regular') }
				/>
				<Spinner
					label={ getSplatProp(character, 'xpname', "special") + " XP" }
					min="0"
					value={ character.experience && character.experience.specialty ? character.experience.specialty : 0 }
					onChange={ onChange('experience', 'specialty') }
				/>
			</Grid>
		</Box>
	);
}

export default Experience
import React, { useEffect, useState } from 'react'

import { Button, DialogActions, DialogTitle, DialogContent, AppBar } from '@mui/material';

import { Dialog, Tab, TabContainer, change as changeHandler } from '@loom/ui';
import { CharacterIcon, DetailsIcon, MeritIcon, CharmIcon, ExperienceIcon } from '@loom/ui/icons';
import { getXPAvailable, getSplatProp } from '@loom/characters/functions';

import GeneralTab from './general';
import AbilitiesTab from './abilities';
import MeritsTab from './merits';
import CharmsTab from './charms';
import ExperienceTab from './experience';
import Chargen from './chargen';
import Context from './context';

import './dialog.less';

const EditDialog = ({
    onClose, onSave, open, character, children
}) => {
	const xp = getXPAvailable(character);

	return (
		<Dialog
			open={ open }
			onClose={ onClose }
			maxWidth="xl"
			fullWidth
		>
			<TabContainer tabs={ children }>{
				(tabs, content) => (<>
					<DialogTitle className="fullwidth">
						<AppBar position="static" color="secondary">{  tabs }</AppBar>
					</DialogTitle>
					<DialogContent>
						{ content }
					</DialogContent>
					<DialogActions>
						{ character.experienced
							? <div>
								XP: { xp.regular }&nbsp;
								{ getSplatProp(character, 'xpsymbol', 'XXP') }: { xp.specialty }
							</div>
							: <Chargen character={ character } />
						}
						<div style={{ whiteSpace: 'nowrap' }}>
							<Button
								onClick={ onClose }
							>
								Cancel
							</Button>
							<Button
								variant="contained"
								color="primary"
								onClick={ () => onSave(character) }
							>
								Ok
							</Button>
						</div>
					</DialogActions>
				</>)
			}</TabContainer>
		</Dialog>
	)
};

const TabbedDialog = ({
	character: initialCharacter,
	characterId,
	onClose,
	...props
}) => {
    const [ character, setCharacter ] = useState();

	useEffect(() => {
		setCharacter({...initialCharacter})
	}, [ initialCharacter ]);

	if (!character) return null;
	const handleChange = changeHandler(character, setCharacter);

	return (
		<Context.Provider value={{
			character,
			characterId,
			onChange: handleChange,
		}}>
			<EditDialog character={ character } { ...props }
				onClose={ () => {
					onClose();
					setCharacter({...initialCharacter});
				}}
			>
				<Tab value="general" label="General" icon={ <CharacterIcon /> } render={ <GeneralTab key="general" /> } />
				<Tab value="abilities" label="Abilities" icon={ <DetailsIcon /> } render={ <AbilitiesTab key="abilities" /> } />
				<Tab value="merits" label="Merits" icon={ <MeritIcon /> } render={ <MeritsTab /> } key="merits" />
				<Tab value="charms" label="Powers" icon={ <CharmIcon /> } render={ <CharmsTab /> } key="charms" />
				<Tab value="experience" label="Experience" icon={ <ExperienceIcon /> } render={ <ExperienceTab /> } key="experience" />
			</EditDialog>
		</Context.Provider>
	)
}

export default TabbedDialog

import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { BottomNavigationAction } from '@mui/material';

import { Auth as AuthContext, Firestore as FirestoreContext, Loom as LoomContext } from '@contexts';
import { ActionBase, ConfirmDialog } from '@loom/ui';
import { DeleteIcon, DuplicateIcon, EditIcon, FavouriteIcon, UnfavouriteIcon, ExportIcon } from '@loom/ui/icons';
import { EditDialog as EditPCDialog } from '@loom/characters/pc';

import ViewContext from './context';
import { normalizeForExport } from '@loom/characters/functions';

const Actions = () => {
	const history = useHistory();
	const { character, characterId } = useContext(ViewContext);
	const { user, profile } = useContext(AuthContext);
	const { db } = useContext(FirestoreContext);
	const [ deleting, setDeleting ] = useState(false);
	const [ editing, setEditing] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const { npcs, artifacts, hearthstones } = useContext(LoomContext);

	if (!character) return null;

	return (
		<ActionBase>
			{ character.owner === user?.uid && (
				<BottomNavigationAction label="Edit" icon={ <EditIcon /> } onClick={() => setEditing(true)} />
			)}
			{ profile?.favourites?.characters?.includes(characterId) ? (
				<BottomNavigationAction label="Unfavourite" icon={ <FavouriteIcon /> } onClick={() => {
					db.collection('users').doc(user.uid).update({
						favourites: {
							...(profile.favourites ? profile.favourites : {}),
							characters: [
								...(profile.favourites && profile.favourites.characters
									? profile.favourites.characters
									: []
								).filter((i) => i !== characterId)
							],
						},
					})
				}} />
			) : (
				<BottomNavigationAction label="Favourite" icon={ <UnfavouriteIcon /> } onClick={() => {
					db.collection('users').doc(user.uid).update({
						favourites: {
							...(profile?.favourites ?? {}),
							characters: [
								...(profile?.favourites?.characters ?? []),
								characterId
							],
						}
					})
				}} />
			)}
			{user && <BottomNavigationAction label="Duplicate" icon={<DuplicateIcon />} onClick={() => {
				db.collection('characters').add({
					...character,
					name: 'Copy of ' + character.name,
					owner: user.uid,
				}).then((doc) => {
					history.push(`/pc/${doc.id}`);
					enqueueSnackbar(`Now viewing duplicate of "${character.name}"`)
				})
			}} />
			}{user && <BottomNavigationAction label="Export" icon={<ExportIcon />} onClick={() => {
				const url = window.URL.createObjectURL(
					new Blob([JSON.stringify(normalizeForExport(character, npcs, artifacts, hearthstones))])
				);

				const link = document.createElement('a');
				link.href = url;
				link.setAttribute(
					'download',
					`${character.name}.json`,
				);

				document.body.appendChild(link);
				link.click();
				setTimeout(() => { document.body.removeChild(link); window.URL.revokeObjectURL(url); }, 200);
			}} />
			}{character.owner === user?.uid && (
					<BottomNavigationAction label="Delete" icon={ <DeleteIcon /> }
						onClick={ () => setDeleting(true) }
					/>
				)
			}
			<EditPCDialog
				open={ editing }
				character={character}
				characterId={characterId}
				onClose={() => setEditing(false) }
				onSave={ (c) => {
					console.log(c);
					db.collection('characters').doc(characterId).set(c);
					setEditing(false);
				}}
			/>
			<ConfirmDialog
				open={ deleting }
				onClose={ () => setDeleting(false) }
				onConfirm={() => {
					console.log(characterId);
					db.collection('characters').doc(characterId).delete()
					history.push('/pc/')
					setDeleting(false);
				}}
				text={ 'Do you want to delete the character "' + character.name + '"?' }
			/>		
		</ActionBase>
	);
};

export default Actions;

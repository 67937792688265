const changeHandler = (obj, setter) => (...keys) => (data) => {
	if (typeof(data) !== 'object' || Array.isArray(data)) {
		data = {[keys[keys.length - 1]]:data}
		keys = keys.slice(0,-1)
	} else if (data?.target?.value !== undefined) {
		data = {[keys[keys.length - 1]]:data.target.value}
		keys = keys.slice(0,-1)
	}
	
	let root = { ...obj }
	var o = keys.reduce(
		(r, v) => {
			if (r[v] === undefined || typeof r[v] === 'string') r[v] = {}
			return r[v]
		}, root
	)
	Object.assign(o, data)

	setter({...root});
}

export default changeHandler

import React, { useEffect, useState } from 'react';

import { Button, DialogContent, TextField } from '@mui/material';

import { ConfirmDialog, Dialog, DialogActions, Grid } from '@loom/ui';

const EditMeritDialog = ({ merit: meritProp, onClose, onAdd, onUpdate, onDelete }) => {
	const [ merit, setMerit ] = useState({});
	const [ deleting, setDeleting ] = useState(false);

	useEffect(() => {
		setMerit(meritProp ?? {});
	}, [ meritProp, setMerit ])

	return (
		<Dialog open={ Boolean(meritProp) } onClose={ onClose }>
			<DialogContent>
				<Grid>
					<TextField
						label="Name"
						value={ merit.name ?? '' }
						onChange={ (e) => setMerit({ ...merit, name: e.target.value }) }
						fullWidth
					/>
					<TextField
						label="Text"
						value={ merit.description ?? '' }
						onChange={ (e) => setMerit({ ...merit, description: e.target.value }) }
						fullWidth multiline
					/>
				</Grid>
			</DialogContent>
			<DialogActions>
				{ meritProp !== true && (
					<Button
						onClick={ () => setDeleting(true) }
						variant="contained"
						color="error"
					>Delete</Button>
				)}
				<div>
					<Button onClick={ onClose }>Close</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={ () => {
							if (meritProp === true) {
								onAdd(merit);
							} else {
								onUpdate(merit);
							}
							onClose();
						}}
					>{ meritProp === true ? 'Add' : 'Update' }</Button>
				</div>
			</DialogActions>
			<ConfirmDialog
				open={ deleting }
				text={ `Are you sure you want to delete "${merit.name}"?` }
				onConfirm={ () => { onDelete(); onClose(); } }
				onClose={ () => setDeleting(false) }
			/>
		</Dialog>
	)
}

export default EditMeritDialog;

import React from 'react';

import { DialogActions as MuiDialogActions } from '@mui/material';

import './dialog-actions.less';

const DialogActions = ({ children }) => {
	return (
		<MuiDialogActions>
			<div className="dialog-actions">
				{ React.Children.toArray(children).length === 1 && <div /> }
				{ children }
			</div>
		</MuiDialogActions>
	)
}

export default DialogActions;
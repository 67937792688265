import React, { useContext, useEffect, useState, useRef } from 'react'

import { Auth, Loom, Firestore } from '@contexts';
import { List as LocaleList } from '@loom/locales';
import { List as CharacterList } from '@loom/characters';
import { List as OrganisationList } from '@loom/organisations';
import { List as StoryList } from '@loom/stories';
import { Grid, Box, Markdown, Notes, UI } from '@loom/ui';
import Map, { maxWidth as mapMaxWidth } from '@loom/map';

import Actions from './actions'

const Chronicle = ({ chronicleId }) => {
	const { user } = useContext(Auth);
	const { chronicles, locales, notes } = useContext(Loom);
	const { db, addExtraContent } = useContext(Firestore)
	const [ mapWidth, setMapWidth ] = useState();
	const map = useRef();

	useEffect(() => {
		if (map.current) {
			setMapWidth(map.current.offsetWidth);
		}
	}, [map]);

	useEffect(() => {
		if (!chronicles) return;
		if (chronicles[chronicleId]?.owner === user?.uid) addExtraContent('notes', chronicleId);
	}, [addExtraContent, chronicles, chronicleId, user]);

	if (!chronicles || !chronicles[chronicleId]) return null;
	const chronicle = chronicles[chronicleId]

	const maxZoom = ((mapWidth - 64) / mapMaxWidth ) * 100;

	const context = {
		collection: 'chronicles',
		id: chronicleId,
		data: chronicle
	};

	return (
		<UI
			back="/chronicle"
			actions={ <Actions
				chronicle={ chronicle }
				chronicleId={ chronicleId }
				notes={ notes && notes[chronicleId]?.text }
			/> }
		>
			<Grid>
				<Box title={ chronicle.name }>
					<Markdown className="copy" source={ chronicle.description } />
				</Box>
				<Box title="Introduced Facts">
					<Markdown className="copy" source={ chronicle.facts } />
				</Box>
				<Notes context={ context } />
			</Grid>
			<Grid size="medium">
				<StoryList stories={ chronicle.stories } context={ context } />
				<LocaleList locales={ chronicle.locales } context={ context } />
				<OrganisationList orgs={ chronicle.organisations } context={ context } />
				<CharacterList pcs={ chronicle.characters } npcs={ chronicle.npcs } context={ context } />
			</Grid>
			<Grid>
				<Box title="Map" className="box" ref={ map }>
					<Map
						markers={ (chronicle.locales || []).filter((lid) => locales && locales[lid]).map((lid) => ({
							label: locales[lid].name,
							...(locales[lid].location || {})
						}))}
						maxZoom={ maxZoom }
						zoom={ chronicle.zoom || maxZoom }
						onZoomChanged={ (zoom) => {
							db.collection('chronicles').doc(chronicleId).update({ zoom });
						}}
					/>
				</Box>
			</Grid>
		</UI>
	)
};

export default Chronicle;

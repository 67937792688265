import React, { useState, useContext } from 'react'
import { v4 as uuid } from 'uuid'
import { useSnackbar } from 'notistack';

import { Button, MenuItem, TextField } from '@mui/material';

import data from '@data'
import { Loom } from '@contexts';
import { Box, ConfirmDialog, Grid } from '@loom/ui';
import { xplog, getMerits, startTraining } from '@loom/characters/functions';
import { Context as EditContext } from '@loom/characters/pc/edit';

import { Edit as Merit } from '../../merits/merit';
import './merit-box.less';

const MeritBox = () => {
    const [ addingMerit, setAddingMerit ] = useState();
	const [ removingMerit, setRemovingMerit ] = useState();
    const { enqueueSnackbar } = useSnackbar();
	const { hearthstones } = useContext(Loom);
    const { character, onChange } = useContext(EditContext);

	const merits = getMerits(character, hearthstones);

    return (
        <div className="merits">
            <Box
                title="Merits"
                actions={
                    <>
                        <TextField
                            value={ addingMerit || '' }
                            onChange={ (e) => setAddingMerit(e.target.value) }
                            select
                        >
                            { Object.keys(data.merits).map((j) => (
                                <MenuItem key={ j } value={ j }>{ j }</MenuItem>
                            ))}
                        </TextField>
                        <Button
                            color="secondary"
                            variant="contained"
                            disabled={ !addingMerit }
                            onClick={ (e) => {
                                if (character.experienced) {
                                    startTraining(character, xplog(character, 'merit', addingMerit, 0, data.merits[addingMerit].dots[0], null, uuid()), onChange, enqueueSnackbar)
                                } else {
                                    onChange('merits')({
                                        ...(character?.merits ?? []),
                                        [uuid()]: { key:addingMerit, value:data.merits[addingMerit].dots[0] },
                                    })
                                }
                            }}
                        >Add Merit</Button>
                    </>
                }
            >
                <Grid nopad>
                    { Object.entries(merits)
                        .map(([i, merit]) => (
                            <Merit
                                key={ i }
                                meritId={ i }
                                merit={ merit }
                                training= {
                                    character.xplog
                                        ? character.xplog.reduce((acc, cur) => (cur.data.type === 'merit' && cur.index === merit.id ? true : acc), false)
                                        : false
                                }
                                setRemovingMerit={ (merit) => setRemovingMerit({ ...merit, id: i}) }
                            />
                        ))
                    }
                </Grid>
            </Box>
            <ConfirmDialog
                text={ removingMerit !== null ? "Do you want to delete " + removingMerit?.key + (removingMerit?.details ? ': ' + removingMerit?.details : '') + '?' : "" }
                open={ Boolean(removingMerit) }
                onConfirm={() => {
                    const { [removingMerit.id]: _, ...merits } = character.merits;

                    onChange()({
                        xplog: character.xplog ? character.xplog.filter((c) => c.data.type !== 'merit' || c.data.index !== removingMerit) : [],
                        merits: merits,
                    })
                    setRemovingMerit(null);
                }}
                onClose={() => setRemovingMerit(null)}
            />
        </div>
    );
};

export default MeritBox;
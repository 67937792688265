import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Checkbox, FormControlLabel, Paper } from '@mui/material';

import { Auth, Firestore, Loom } from '@contexts';
import { ReadOnlyIcon } from '@loom/ui/icons';

import './list.less';

const List = () =>{
	const { user, profile } = useContext(Auth);
	const { pcs } = useContext(Loom);
	const { db } = useContext(Firestore);
	const history = useHistory();

	if (!pcs) return null;

	const toggleFilter = (val) => {
		if (!user || !user.uid) return

		db.collection('users').doc(user.uid).set({
			settings: {
				...(profile.settings || {}),
				'pc.view.filters': [
					...(filters.includes(val)
						? filters.filter((f) => f !== val)
						: [ ...filters, val ])
				],
			}
		}, {
			merge: true
		})
	};

	const filters = profile?.settings?.['pc.view.filters'] || ['owner', 'favourited'];

	return (
		<div className="pc-list">
			<div className="filters">
				<FormControlLabel
					control={<Checkbox label="Owner" checked={ filters.includes('owner') } onChange={ () => toggleFilter('owner') } />}
					label="Owner"
				/>
				<FormControlLabel
					control={<Checkbox checked={ filters.includes('favourited') } onChange={ () => toggleFilter('favourited') } />}
					label="Favourited"
				/>
			</div>
			<div className="characters">{
				Object.keys(pcs)
					.filter((id) => {
						let val = false;
						if (filters.includes('owner')) val = val || (pcs[id].owner === user?.uid);
						if (filters.includes('favourited')) val = val || (pcs[id].owner !== user?.uid);
						return val;
					}).sort((a, b) => {
						const splats = { solar: 1, lunar: 2, sidereal: 3, dragonblooded: 4 }
						const pa = (pcs[a].portrait ? 0 : 1);
						const pb = (pcs[b].portrait ? 0 : 1);
						if (pa !== pb) return pa - pb;
						const sa = splats[pcs[a].splat];
						const sb = splats[pcs[b].splat];
						if (sa !== sb) return sa - sb;
						return pcs[a].name.localeCompare(pcs[b].name);
					}).map((id) => (
						<Paper
							className={ `character ${pcs[id].splat}` }
							onClick={ () => history.push('/pc/' + id) }
							style={{ backgroundImage: pcs[id].portrait ? `url(${pcs[id].portrait})` : undefined }}
							key={ id }
						>
							<h3>{ pcs[id].name }{ pcs[id].owner !== user?.uid ? <ReadOnlyIcon /> : '' }</h3>
						</Paper>
					))
			}</div>
		</div>
	);
};

export default List;

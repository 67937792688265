import React, { useContext } from 'react'

import { MenuItem, TextField } from '@mui/material';

import data from '@data'
import { isSorcerer, sorceryCircles } from '@loom/characters/functions';
import { Context as EditContext } from '@loom/characters/pc/edit';
import { Box, Grid } from '@loom/ui';

import MeritBox from './merit-box.js';
import EquipmentBox from './equipment-box.js';

import './merits.less';

const MeritsTab = () => {
	const { character, onChange } = useContext(EditContext);

	return (<>
		<Grid size="extra-large" nopad>
			<TextField
				label="Native Language"
				value={ character.language ? character.language : '' }
				onChange={ (event) => {
					onChange('language')(event.target.value)
				}}
				fullWidth select
			>
				{ Object.keys(data.languages).map((i) => (
					<MenuItem key={ i } value={ i } className={ "caps" }>{ i }</MenuItem>
				))}
			</TextField>
		</Grid>
		<Grid nopad>
			<MeritBox />
			<EquipmentBox />
			{ isSorcerer(character) &&
				<Box title="Sorcerous Initiations">
					{ sorceryCircles(character).map((circle) => (
						<Grid key={ circle } nopad>
							<TextField
								key={ `control-${circle}` }
								label={ `${circle} Control Spell` }
								value={ character.controlSpells && character.controlSpells[circle] }
								onChange={ onChange('controlSpells', circle) }
								fullWidth
								select
							>{
								Object.keys(data.sorcery.spells[circle]).map((spell) => (
									<MenuItem key={ spell } value={ spell }>{ spell }</MenuItem>
								))
							}</TextField>
							<TextField
								key={ circle }
								label={ `${circle} Shaping Ritual` }
								value={ character.rituals ? character.rituals[circle] : '' }
								onChange={ onChange('rituals', circle) }
								fullWidth
								multiline
							/>
						</Grid>
					))}
				</Box>
			}
		</Grid>
	</>)
}

export default MeritsTab;

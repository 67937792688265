import React, { useContext } from 'react';

import { Box, Essence, Dice, Grid, NameValue, Dots, CharmList, MeritList } from '@loom/ui'
import { getWoundPenalty } from '@loom/characters/functions';

import ViewContext from './context';

import './general.less';

const GeneralTab = () => {
	const { character, onChange } = useContext(ViewContext);
	if (!character) return null;
	
	const penalty = getWoundPenalty(character);

	return (
		<div className="general-tab">
			<Grid size="medium">
				<Box title="Willpower">
					<Dots
						max={ character.willpower }
						size={ 10 }
						value={ character.tmpWillpower }
						onChange={ (value) => {
							if (onChange) onChange({tmpWillpower:value})
						}}
						editable nomax overflow
					/>
				</Box>
				<Box title="Essence">
					<Dots value={ character.essence } size={ 10 } />
				</Box>
				{ character.personalMotes && (
					<Essence
						title="Personal Motes"
						value={ character.tmpPersonalMotes ? character.tmpPersonalMotes : character.personalMotes }
						min={ 0 }
						max={ character.personalMotes }
						onChange={(value) => {
							if (onChange) onChange({tmpPersonalMotes: value})
						}}
					/>
				)}
				{ character.peripheralMotes && (
					<Essence
						title="Peripheral Motes"
						value={ character.tmpPeripheralMotes ? character.tmpPeripheralMotes : character.peripheralMotes }
						min={ 0 }
						max={ character.peripheralMotes }
						onChange={(value) => {
							if (onChange) onChange({tmpPeripheralMotes: value})
						}}
					/>
				)}
			</Grid>
			<Grid className="dicepools" size="medium">
				<Box title="Dice Pools">
					<NameValue name="Senses" value={ <Dice value={ character.senses - penalty } /> } />
					{
						(character?.generalDicepools ?? []).map((d) => (
							<NameValue key={ d.name } name={ d.name } value={ <Dice value={ d.value - penalty } /> } />
						))
					}
				</Box>
				{ character.generalAbilities && character.generalAbilities.length > 0 ?
					<Box title="Charms">
						<CharmList charms={ character.generalAbilities } />
					</Box>
				: '' }
				{ character.generalMerits && character.generalMerits.length > 0 ?
					<Box title="Merits">
						<MeritList merits={ character.generalMerits } />
					</Box>
				: '' }
			</Grid>
		</div>
	);
};

export default GeneralTab;

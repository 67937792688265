import React from 'react';

import { Box, NameValue, Dice } from '@loom/ui';

const renderComponents = (components) => {
	let str = components.map((part) => {
		return `${part[1] >= 0 ? '+' : '-'} ${Math.abs(part[1])} ${part[0]}`
	}).join(' ');
	if (str[0] === '+') str = str.slice(1).trim();
	return str;
}

const StatList = ({
	title, data, children
}) => {
	const getValue = (components, dice) => {
		const value = Array.isArray(components) ? components[0] : components;
		if (dice) return <Dice value={ value } />
		return value;
	}

	return (
		<Box title={ title }>
			{ children }
			{
				data.map(([ name, components, dice ]) => (
					<NameValue
						key={ name }
						name={ name }
						value={ getValue(components, dice === 'dice') }
						tooltip={ Array.isArray(components) && renderComponents(components[1]) }
					/>
				))
			}
		</Box>
	);
};

export default StatList;
import React from 'react';

import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import { Markdown } from '@loom/ui';

const SpellDialog = ({
	spell, onClose
}) =>{
	return <>
		<Dialog
			open={ Boolean(spell) }
			maxWidth="md"
		>
			<DialogTitle disableTypography>
				{ spell ? spell.name : '' }
			</DialogTitle>
			<DialogContent>
				{ spell ? <>
					<div className="grid medium details">
						<p><strong>Cost:</strong> { spell.cost }</p>
						<p><strong>Duration:</strong> { spell.duration }</p>
					</div>
					<Markdown source={ spell.text } />
				</> : '' }
			</DialogContent>
			<DialogActions>
				<Button onClick={ onClose }>Close</Button>
			</DialogActions>
		</Dialog>
	</>
};

export default SpellDialog;

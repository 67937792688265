import React, { useEffect, useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import BottomNavigationAction from '@mui/material/BottomNavigationAction' ;
import { v4 as uuid } from 'uuid';

import { Auth, Firestore } from '@contexts';
import { ActionBase, ConfirmDialog } from '@loom/ui';
import { OrganisationIcon, CharacterIcon, NPCIcon, DeleteIcon, LocaleIcon, EditIcon } from '@loom/ui/icons';
import { AddDialog as AddCharacterDialog } from '@loom/characters';
import { create as createOrganisation } from '@loom/organisations';
import { create as createLocale } from '@loom/locales';

import { Dialog as EditDialog } from './edit';
import CreateNPCDialog from '@loom/characters/npc/create';

const LocaleActions = ({
	localeId,
	locale: initialLocale,
	notes: initialNotes,
}) => {
	const [ locale, setLocale ] = useState();
	const [ edit, setEdit ] = useState(false);
	const [ addChar, setAddChar ] = useState(false);
	const [ deleting, setDeleting ] = useState(false);
	const [ addNPC, setAddNPC ] = useState(false);
	const [ notes, setNotes ] = useState();
	const { db } = useContext(Firestore);
	const { user } = useContext(Auth);
	const history = useHistory();
	const { pathname: path } = useLocation();

	useEffect(() => {
		setLocale({...initialLocale});
	}, [initialLocale, setLocale]);

	useEffect(() => {
		setNotes(initialNotes)
	}, [initialNotes]);
	
	if (user?.uid !== locale?.owner) return <div><ActionBase /></div>

	return (
		<div>
			<ActionBase>
				<BottomNavigationAction
					label="Edit"
					icon={ <EditIcon /> }
					onClick={() => setEdit(true) }
				/>
				<BottomNavigationAction
					label="Add Locale"
					icon={ <LocaleIcon /> }
					onClick={async () => {
						const newLocale = await db.collection('locales').add(createLocale(user));
						db.collection('locales').doc(localeId).update({
							locales:[ ...(locale.locales ?? []), newLocale.id ]
						});
						history.push(`${path}/locale/${newLocale.id}`);
					}}

				/>
				<BottomNavigationAction
					label="Add Organisation"
					icon={ <OrganisationIcon /> }
					onClick={async () => {
						const organisation = await db.collection('organisations').add(createOrganisation(user));
						db.collection('locales').doc(localeId).update({
							organisations:[ ...(locale.organisations ?? []), organisation.id ]
						});
						history.push(`${path}/organisation/${organisation.id}`);
					}}
				/>
				<BottomNavigationAction
					label="Add Character"
					icon={ <CharacterIcon /> }
					onClick={() => setAddChar(true)}
				/>
				<BottomNavigationAction
					label="Add Quick Character"
					icon={ <NPCIcon /> }
					onClick={() => setAddNPC(true)}
				/>
				<BottomNavigationAction
					label="Delete"
					icon={ <DeleteIcon /> }
					onClick={ () => setDeleting(true) }
				/>
			</ActionBase>
			<EditDialog
				open={ edit }
				locale={{ ...locale, notes }}
				onClose={() => {
					setEdit(false);
					setLocale({ ...initialLocale });
				}}
				onChange={ (name) => (event) => {
					if (name === 'notes') {
						setNotes(event.target.value);
					} else {
						setLocale({ ...locale, [name]: event.target.value });
					}
				}}
				onSave={ () => {
					const { notes: _, ..._locale } = locale;
					db.collection('locales').doc(localeId).update(_locale);
					db.collection('notes').doc(localeId).set({ text: notes || '', owner: user?.uid });
					setEdit(false);
				}}
			/>
			<CreateNPCDialog
				open={ addNPC }
				onClose={ () => setAddNPC(false) }
				onCreate={ (npc) => {
					const id = uuid();
					db.collection('locales').doc(localeId).update({
						npcs:{ ...(locale.npcs ?? []), [id]: npc}
					})
				}}
			/>
			<AddCharacterDialog 
				open={ addChar }
				exclude={ locale?.characters }
				onClose={ () => setAddChar(false) }
				onDone={ (character) => {
					db.collection('locales').doc(localeId).update({
						characters: [
							...locale?.characters ?? [],
							character
						]
					});
					setAddChar(false);
				}}
			/>
			<ConfirmDialog
				open={ deleting }
				onClose={ () => setDeleting(false) }
				onConfirm={() => {
					db.collection('locales').doc(localeId).delete()
					history.push('/')
					setDeleting(false);
				}}
				text={ 'Do you want to delete the locale "' + locale?.name + '"?' }
			/>
		</div>
	)
};

export default LocaleActions;

import React from 'react';
import ReactDOM from 'react-dom';
import { unregister } from './registerServiceWorker';

import Loom from '@loom';

ReactDOM.render(
	<Loom />,
	document.getElementById('root')
);
unregister();
window.scrollTo(0,1);

import React, { useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { BottomNavigationAction } from '@mui/material';

import { Firestore, Auth, Loom } from '@contexts';
import BasePage from '@loom/pages';
import { CharacterIcon } from '@loom/ui/icons';

import PC from './view'

const List = () => {
	const { db } = useContext(Firestore);
	const { user } = useContext(Auth);
	const { setTitle } = useContext(Loom);
	const history = useHistory();

	setTitle();

	return (
		<BasePage actions={
			<BottomNavigationAction
				label="Add Character"
				icon={<CharacterIcon />}
				onClick={async () => {
					const doc = await db.collection('characters').add({name:'<Unnamed Character>', type:'pc', owner:user.uid})
					history.push(`/pc/${doc.id}`);
				}}
			/>
		} />
	);
}

const View = () => {
	const { addExtraContent } = useContext(Firestore);
	const { pcs, setTitle } = useContext(Loom);
	const { id } = useParams();

	useEffect(() => {
		if (pcs && !pcs[id]) {
			addExtraContent('characters', id)
			return null;
		}	
	}, [addExtraContent, pcs, id]);

	if (!pcs) return null;

	setTitle(pcs[id]?.name);

	return (
		<PC characterId={ id } />
	);
};

export { View, List }
export default View
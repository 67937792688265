import React, { useState, useContext } from 'react'

import { Loom } from '@contexts';
import { Box, ConfirmDialog } from '@loom/ui';
import { getArtifacts } from '@loom/characters/functions';
import { Context as EditContext } from '@loom/characters/pc/edit';

import Item from './item.js';
import './equipment-box.less';

const EquipmentBox = () => {
    const [ removingGear, setRemovingGear ] = useState(false);
    const { artifacts: allArtifacts } = useContext(Loom);
    const { character, onChange } = useContext(EditContext);
    
    const artifacts = getArtifacts(character, allArtifacts);
    
    return (<div className="equipment-box">
        <Box
            title="Equipment"
            actions={[
                [ "Add Item", () => onChange('gear')([ ...(character.gear ? character.gear : []), {}]) ]
            ]}
        >
            { artifacts.map((arti, i) => <Item
                artifact={ arti }
                key={ i }
                index={ i }
            />) }
            { (character.gear || []).map((item, i) => <Item
                onRemove={ () => setRemovingGear(i) }
                item={ item }
                key={ i }
                index={ i }
            />) }
        </Box>
        <ConfirmDialog
            text={ removingGear !== false ? "Do you want to delete " + character?.gear[removingGear]?.name + '?' : "" }
            open={ removingGear !== false }
            onConfirm={() => {
                onChange('gear')(character.gear.filter((_,i) => i !== removingGear))
                setRemovingGear(false);
            }}
            onClose={() => setRemovingGear(false)}
        />
    </div>)
}

export default EquipmentBox;
import { Autocomplete, TextField } from '@mui/material';
import clsx from 'clsx';

import './select.less'

const Select = ({
	options,
	onChange,
	label,
	value,
	allowCustom,
	...extra
}) => {
	return (
		<Autocomplete
			options={ options }
			onChange={(e, v) => {
				onChange(v?.id  ?? (allowCustom ? v : ''))
			}}
			value={ options.find((o) => o.id === value) ?? null }
			renderInput={ ({ ...params }) => {
				return <TextField {...{ ...extra, ...params }} inputProps={ params.inputProps } label={ label } fullWidth />
			}}
			groupBy={ ({ group }) => group}
			renderOption={ ( props, option ) => {
				props.className = clsx([
					props.className ?? '',
					...(option?.classes ?? [])
				]);
				return <li {...props }>{ option.label }</li>
			}}
			freeSolo={allowCustom}
			autoSelect={allowCustom}
			disableClearable
		/>
	);
}

export default Select;
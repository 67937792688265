import React from 'react';

import { Grid } from '@loom/ui';

import Abilities from './abilities';
import Attributes from './attributes';
import Specialties from './specialties';
import Crafts from './crafts';
import MartialArts from './martial-arts';

import './abilities.less';

const AbilitiesTab = () => {
	return (
		<div className="abilities-tab">
			<Grid size="extra-large" nopad>
				<Attributes />
				<Abilities />
				<Specialties />
				<Crafts />
				<MartialArts />
			</Grid>
		</div>
	);
};

export default AbilitiesTab;

import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { Loom } from '@contexts';
import { UI, Tab } from '@loom/ui';
import { CharacterIcon, DetailsIcon, BattleIcon, SocialIcon } from '@loom/ui/icons';
import ViewContext from './context';

import DescriptionTab from './description';
import GeneralTab from './general';
import CombatTab from './combat';
import SocialTab from './social';
import Actions from './actions';

const NPC = ({ characterId, location }) => {
	const { pathname: path } = useLocation();
	const { npcs } = useContext(Loom);

	const character = npcs[characterId];

	return (
		<ViewContext.Provider value={{ character, characterId }}>
			<UI
				actions={ <Actions location={ location } /> }
				back={ path.split('/').slice(0,-3).join('/') }
			>
				<Tab value="description" label="Description" icon={ <CharacterIcon /> } render={ <DescriptionTab /> } />
				<Tab value="general" label="General" icon={ <DetailsIcon /> } render={ <GeneralTab /> } />
				<Tab value="combat" label="Combat" icon={ <BattleIcon /> } render={ <CombatTab /> } />
				<Tab value="social" label="Social" icon={ <SocialIcon /> } render={ <SocialTab /> } />
			</UI>
		</ViewContext.Provider>
	)
}

export default NPC;

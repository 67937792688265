import React from 'react'

import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material';
import { Column } from '@loom/ui';

const EditBattleDialog = ({ open, onClose, battle, onChange, onSave, fullScreen }) => {

    return (
		<Dialog
			fullScreen={ fullScreen }
			open={ open }
			onClose={ onClose }
		>
			<DialogContent>
				<Column>
					<TextField
						label="Name"
						value={ battle.name }
						onChange={ onChange('name') }
						fullWidth
					/>
					<TextField 
						label="Description"
						value = { battle.description }
						onChange={ onChange('description') }
						multiline
						fullWidth
					/>
					<TextField 
						label="Storyteller's Notes"
						value={ battle.notes }
						onChange={ onChange('notes') }
						multiline
						fullWidth
					/>
				</Column>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={ onClose }
				>
					Cancel
				</Button>
				<Button
					variant="contained"
					color="primary"
					onClick={ onSave }
				>
					Ok
				</Button>
			</DialogActions>
		</Dialog>
	)
};

export default EditBattleDialog;

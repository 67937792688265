import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import BottomNavigationAction from '@mui/material/BottomNavigationAction' 

import { Auth, Firestore } from '@contexts';
import { ConfirmDialog, ActionBase } from '@loom/ui';
import { EditIcon, DeleteIcon } from '@loom/ui/icons';

import EditDialog from '@loom/artifacts';

const ArtifactActions = ({ artifactId, artifact }) => {
	const [ deleting, setDeleting ] = useState(false);
	const [ edit, setEdit ] = useState(false);
	const { db } = useContext(Firestore);
	const { user } = useContext(Auth);
	const history = useHistory();

	return (<>
		<ActionBase>
		{user?.uid === artifact.owner && (
			<BottomNavigationAction
				label="Edit"
				icon={<EditIcon />}
				onClick={() => setEdit(true)}
			/>
		)}
		{user?.uid === artifact.owner && (
			<BottomNavigationAction
				label="Delete"
				icon={ <DeleteIcon /> }
				onClick={ () => setDeleting(true) }
			/>
		)}
		</ActionBase>
		<EditDialog
			open={ edit }
			onClose={ () => setEdit(false) }
			artifactId={ artifactId }
		/>
		<ConfirmDialog
			open={ deleting }
			onClose={ () => setDeleting(false) }
			onConfirm={() => {
				db.collection('artifacts').doc(artifactId).delete()
				setDeleting(false);
				history.push('/homebrew');
			}}
			text={ 'Do you want to delete the artifact "' + artifact.name + '"?' }
		/>
	</>);
}

export default ArtifactActions;

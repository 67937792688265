import "@fontsource/roboto/100.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import firebase from "firebase/app";
import "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from "react-router-dom";
import { SnackbarProvider } from "notistack";

import { AuthListener, QueryListener } from "@components/firebase";
import { Firestore, Auth, Loom as LoomContext } from "@contexts";
import { firebase as firebaseCredentials } from "@credentials";
import { normalize, normalizeArtifact } from "@loom/characters/functions";

import { List as PCListPage, View as PCPage } from "@loom/characters/pc/pages";
import { View as NPCPage } from "@loom/characters/npc/pages";
import BattlePage from "@loom/battle/pages";
import {
	List as ChronicleListPage,
	View as ChroniclePage,
} from "@loom/chronicles/pages";
import StoryPage from "@loom/stories/pages";
import LocalePage from "@loom/locales/pages";
import OrganisationPage from "@loom/organisations/pages";
import HomebrewPage from "@loom/homebrew/pages";
import ArtifactPage from "@loom/artifacts/pages";
import HearthstonePage from "@loom/hearthstones/pages";
import MigratePage from "../util/migrate";

import "./loom.less";

if (!firebase.apps.length) {
	firebase.initializeApp(firebaseCredentials);
}
const db = firebase.firestore();
const storage = firebase.storage();

const Loom = () => {
	const [profile, setProfile] = useState();
	const { user } = useContext(Auth);
	const [extraContent, setExtraContent] = useState({});

	useEffect(() => {
		(async () => {
			if (user) {
				setProfile((await db.collection("users").doc(user.uid).get()).data());
			}
		})();
	}, [setProfile, user]);

	const addExtraContent = (type, key) => {
		if (!key) return;
		const keys = (!Array.isArray(key) ? [key] : key).map((k) => k.id ?? k);
		if (
			keys.filter((k) => !(extraContent[type] || []).includes(k)).length === 0
		)
			return;

		setExtraContent({
			...extraContent,
			[type]: [...(extraContent[type] || []), ...keys],
		});
	};

	return (
		<QueryListener
			context={LoomContext}
			queries={{
				characters: [
					db.collection("characters").where("owner", "==", user?.uid || null),
					db.collection("characters").where("public", "==", true),
					...(profile?.favourites?.characters || []).map((c) =>
						db.collection("characters").where("__name__", "==", c)
					),
					...(extraContent.characters || []).map((c) =>
						db.collection("characters").where("__name__", "==", c)
					),
				],
				hearthstones: [
					db.collection("hearthstones").where("owner", "==", user?.uid || null),
					db.collection("hearthstones").where("public", "==", true),
					...(profile?.favourites?.hearthstones || []).map((h) =>
						db.collection("hearthstones").where("__name__", "==", h)
					),
					...(extraContent.hearthstones || []).map((h) =>
						db.collection("hearthstones").where("__name__", "==", h)
					),
				],
				artifacts: [
					db.collection("artifacts").where("owner", "==", user?.uid || null),
					db.collection("artifacts").where("public", "==", true),
					...(profile?.favourites?.artifacts || []).map((a) =>
						db.collection("artifacts").where("__name__", "==", a)
					),
					...(extraContent.artifacts || []).map((a) =>
						db.collection("artifacts").where("__name__", "==", a)
					),
				],
				chronicles: [
					db.collection("chronicles").where("owner", "==", user?.uid || null),
					...(extraContent.chronicles || []).map((a) =>
						db.collection("chronicles").where("__name__", "==", a)
					),
				],
				organisations: (extraContent.organisations || []).map((a) =>
					db.collection("organisations").where("__name__", "==", a)
				),
				locales: (extraContent.locales || []).map((a) =>
					db.collection("locales").where("__name__", "==", a)
				),
				stories: (extraContent.stories || []).map((a) =>
					db.collection("stories").where("__name__", "==", a)
				),
				battles: (extraContent.battles || []).map((a) =>
					db.collection("battles").where("__name__", "==", a)
				),
				notes: (extraContent.notes || []).map((a) =>
					db
						.collection("notes")
						.where("__name__", "==", a)
						.where("owner", "==", user?.uid || null)
				),
			}}
			transform={{
				characters: {
					pcs: (data) => {
						return Object.fromEntries(
							Object.entries(data.characters)
								.filter(([id, char]) => char.type === "pc" || !char.type)
								.map((c) => normalize(c))
						);
					},
					npcs: (data) =>
						Object.keys(data.characters)
							.filter((char) => data.characters[char].type !== "pc")
							.reduce(
								(acc, val) => ({
									...acc,
									[val]: normalize(data.characters[val], data),
								}),
								{}
							),
				},
				artifacts: {
					artifacts: (data) => {
						return Object.fromEntries(
							Object.entries(data.artifacts ?? {})
								.map(([id, a]) => [id, normalizeArtifact(a)])
						)
					}
				}
			}}
		>
			<SnackbarProvider
				maxSnack={1}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
			>
				<Firestore.Provider
					value={{
						db,
						storage,
						addExtraContent,
						getDbRef: (collection, obj) => {
							const col = db.collection(collection);
							if (!obj) return null;
							if (obj.key) return col.doc(obj.key);
							if (obj.id) return col.doc(obj.id);
							return col.doc(obj);
						},
					}}
				>
					<QueryListener
						context={Auth}
						queries={{
							profile: user
								? db
										.collection("users")
										.where("__name__", "==", user.uid || null)
								: null,
						}}
						transform={{
							profile: {
								profile: (data) => Object.values(data.profile)[0],
							},
						}}
					>
						<Router>
							<Switch>
								<Route path="*/pc" component={PCListPage} exact />
								<Route path="/pc/:id/:tab" component={PCPage} exact />
								<Redirect from="/pc/:id" to="/pc/:id/general" exact />
								<Route path="*/battle/:id" component={BattlePage} exact />
								<Route path="/chronicle" component={ChronicleListPage} exact />
								<Route path="/chronicle/:id" component={ChroniclePage} exact />
								<Route
									path="/chronicle/:chronicle/story/:id"
									component={StoryPage}
									exact
								/>
								<Route path="*/locale/:id" component={LocalePage} exact />
								<Route
									path="*/organisation/:id"
									component={OrganisationPage}
									exact
								/>
								<Route
									path="/homebrew/artifact/:id"
									component={ArtifactPage}
									exact
								/>
								<Route
									path="/homebrew/hearthstone/:id"
									component={HearthstonePage}
									exact
								/>
								<Route
									path="/homebrew/npc/:id/:tab"
									component={NPCPage}
									exact
								/>
								<Redirect
									from="/homebrew/npc/:id"
									to="/homebrew/npc/:id/general"
									exact
								/>
								<Route path="/homebrew" component={HomebrewPage} exact />
								<Route path="/migrate" component={MigratePage} exact />
								<Redirect from="/" to="/pc" />
							</Switch>
						</Router>
					</QueryListener>
				</Firestore.Provider>
			</SnackbarProvider>
		</QueryListener>
	);
};

const Wrapper = () => {
	return (
		<AuthListener context={Auth}>
			<Loom />
		</AuthListener>
	);
};

export default Wrapper;

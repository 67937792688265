import { v4 as uuid } from "uuid";
import capitalize from "capitalize";
import data from "@data";

export const normalize = (char) => {
	//Convert charm dict to array, and separate charm notes field
	if (!char) return {};

	if (char?.rituals?.Terrestrial) {
		char.rituals.terrestrial = char.rituals.Terrestrial;
		delete char.rituals.Terrestrial;
	}

	if (char?.controlSpells?.Terrestrial) {
		char.controlSpells.terrestrial = char.controlSpells.Terrestrial;
		delete char.controlSpells.Terrestrial;
	}

	if (char.charms && !Array.isArray(char.charms)) {
		char.charm_notes = char.charms;
		char.charms = Object.keys(char.charms);
	}

	if (char.supernal && char.favoured?.includes(char.supernal))
		char.favoured = char.favoured.filter((a) => a !== char.supernal);

	char.martialarts?.forEach((ma) => {
		if (ma.style === "Launghing Monster Style")
			ma.style = "Laughing Monster Style";
	});
	if (char.maCharms?.["Launghing Monster Style"]) {
		char.maCharms["Laughing Monster Style"] =
			char.maCharms["Launghing Monster Style"];
		delete char.maCharms["Launghing Monster Style"];
	}

	if (Array.isArray(char.evocations)) {
		char.evocations = {};
	}

	delete char.id;

	return char;
};

export const normalizeArtifact = (artifact) => {
	return {
		...artifact,
		evocations: artifact.evocations?.map((e) => {
			const a = {
				...e,
				reqs: {
					essence: e.reqs?.essence ?? (typeof(e.essence) === 'number' ? e.essence : (parseInt(e.essence ?? '1'))),
					charms: e.reqs?.charms ?? e.prereqs ?? []
				}
			}
			delete a.essence;
			delete a.prerqs;
			return a;
		})
	}
}

export const abilityTypes = {
	reflexive: "Reflexive",
	supplemental: "Supplemental",
	simple: "Simple",
};

export const getAllCharms = (character) => {
	if (character) {
		return getSplatProp(character, 'charms') ?? [];
	} else {
		let charms = {};
		Object.entries(data.splats).forEach(([splat, splatdata]) => {
			charms = {
				...charms,
				...splatdata.charms,
			};
		});
		return charms;
	}
};

export const getWoundPenalty = (character) => {
	if (!character.wounds) return 0;
	return Object.keys(character.wounds).reduce((result, penalty) => {
		if (
			character.wounds[penalty].reduce((ticked, level) => {
				return ticked || level !== null;
			}, false)
		) {
			if (penalty === "I") penalty = 4;
			return Math.max(penalty, result);
		} else {
			return result;
		}
	}, 0);
};

export const getRespiration = (character, battle) => {
	let respire = battle ? character.respireBattle : character.respire;
	let rem = 0;
	let motes = {
		personal: character.tmpPersonalMotes,
		peripheral: character.tmpPeripheralMotes,
	};
	rem = Math.min(respire, character.peripheralMotes - motes.peripheral);
	motes.peripheral += rem;
	rem = respire - rem;
	motes.personal = Math.min(motes.personal + rem, character.personalMotes);

	return motes;
};

export const sorceryCharms = {
	terrestrial: "Terrestrial Circle Sorcery",
	celestial: "Celestial Circle Sorcery",
	solar: "Solar Circle Sorcery",
};

export const getArtifacts = (character, artifacts) => {
	return artifacts && getMerits(character)
		? Object.values(getMerits(character))
				.filter(
					(m) =>
						m.key === "Artifact" &&
						(artifacts[m.details] || artifacts[m.details?.artifact])
				)
				.map((m) => ({
					...(artifacts[m.details] || artifacts[m.details.artifact]),
					id: m.details?.artifact || m.details,
				}))
		: [];
};

export const getHearthstones = (character, hearthstones) => {
	return Object.values(getMerits(character))
		.filter(
			(m) =>
				(m.key === "Hearthstone" &&
					hearthstones[m.details?.hearthstone ?? m.details]) ||
				(m.key === "Manse" && hearthstones[m.details?.hearthstone])
		)
		.map((m) => {
			const id =
				m.key === "Hearthstone"
					? m.details.hearthstone ?? m.details
					: m.details.hearthstone;
			return {
				id,
				...hearthstones[id],
			};
		});
};

export const hasEvocations = (character, artifacts) => {
	return getArtifacts(character, artifacts).reduce((r, v) => {
		if (v && v.evocations && v.evocations.length > 0) return true;
		return r;
	}, false);
};

export const isSorcerer = (character) => {
	return getCharms(character).indexOf(sorceryCharms["terrestrial"]) >= 0;
};

export const sorceryCircles = (character) => {
	return Object.keys(sorceryCharms).filter(
		(circle) => getCharms(character).indexOf(sorceryCharms[circle]) >= 0
	);
};

export const isMartialArtist = (character) => {
	return (
		Object.values(getMerits(character) ?? {}).filter(
			(merit) => merit.key === "Martial Artist"
		).length > 0
	);
};

export const getMAStyles = (character) => {
	return (character.martialarts ? character.martialarts : []).map(
		(ma) => ma.style
	);
};

export const getMACharms = (character, style) => {
	const charms = [
		...(character?.maCharms?.[style] ?? []).filter((c) =>
			Boolean(data.martialarts[style]?.charms[c])
		),
		...(character.completed_training ?? [])
			.filter(
				(t) =>
					t.data.type === "macharm" &&
					Boolean(data.martialarts[style]?.charms[t.data.name]) &&
					t.data.nval === true
			)
			.map((t) => t.data.name),
	];
	return charms;
};

export const getHealth = (character, artifacts) => {
	const base = {
		0: 1,
		1: 2,
		2: 2,
		4: 1,
		I: 1,
	};
	const oxbody = getBonus(character, { artifacts }, "oxbody", "int");
	for (var hl of Object.keys(base)) {
		base[hl] =
			base[hl] +
			(getSplatProp(character, 'oxbody', {})[
				character?.stamina ?? 1
			]?.[hl] ?? 0) * oxbody;
	}
	return base;
};

export const getEssence = (character) => {
	if (!character.splat || !character.chargen) return 1;

	var essence = getSplatProp(character, 'chargen', {})[character.chargen]?.essence ?? 1;
	if (!character.completed_training) return essence;

	const spend =
		parseInt(getSplatProp(character, 'chargen', [])[character.chargen]?.xpoffset) +
		character.completed_training.reduce(
			(acc, cur) =>
				acc + (cur.costs && cur.costs.regular ? cur.costs.regular : 0),
			0
		);

	const ebreaks = getSplatProp(character, 'essence', {});
	for (var k of Object.keys(ebreaks)) {
		if (spend >= ebreaks[k]) essence = parseInt(k, 10);
	}

	return essence;
};

export const getEssencePools = (character) => {
	const pools = getSplatProp(character, 'pools');
	if (!character.splat || !pools) return { personal: 0, peripheral: 0 };

	const essence = getEssence(character);
	return {
		personal: pools.personal.fixed + essence * pools.personal.multiplier,
		peripheral: pools.peripheral.fixed + essence * pools.peripheral.multiplier,
	};
};

// Returns the number of motes from each pool used by commitments
export const getCommitments = (character, artifacts) => {
	var pool;
	var coms = { personal: 0, peripheral: 0 };
	coms = character.commitments
		? character.commitments.reduce((obj, c) => {
				if (!obj[c.pool]) obj[c.pool] = 0;
				obj[c.pool] += parseInt(c.motes, 10);
				return obj;
		  }, coms)
		: coms;
	coms = Object.values(getMerits(character)).reduce((obj, merit) => {
		if (
			merit.key === "Artifact" &&
			character.artifacts?.[merit.details?.artifact]?.attuned
		) {
			pool = character.artifacts[merit.details.artifact].attuned;
			if (!obj[pool]) obj[pool] = 0;
			if (artifacts[merit?.details?.artifact]?.attunement !== undefined) {
				obj[pool] += parseInt(
					artifacts[merit?.details?.artifact]?.attunement,
					10
				);
			} else if (
				data[artifacts[merit?.details?.artifact]?.type]?.categories?.artifact[
					artifacts[merit?.details?.artifact]?.category
				]
			) {
				obj[pool] +=
					data[artifacts[merit.details?.artifact].type].categories.artifact[
						artifacts[merit.details?.artifact].category
					].commit;
			}
		}
		return obj;
	}, coms);
	return coms;
};

export const getSubcharms = (character) => {
	const subcharms = {};
	const all = getAllCharms(character);
	for (var c of getCharms(character)) {
		if (all[c] && all[c].subcharms) {
			subcharms[c] = {
				cost: all[c].subcharmCost,
				charms: Object.keys(all[c].subcharms),
			};
		}
	}
	return subcharms;
};

export const getAllBonuses = (character, weapon, hearthstones) => {
	let c;
	let bonuses = [];
	const charms = getAllCharms(character);

	for (const style in character.maCharms) {
		for (c of character.maCharms[style]) {
			c = data.martialarts[style]?.charms[c];
			if (c?.bonuses) bonuses = bonuses.concat(c.bonuses);
		}
	}
	for (c of getCharms(character)) {
		if (charms[c] && charms[c].bonuses) {
			bonuses = bonuses.concat(charms[c].bonuses);
		}
	}
	if (hearthstones) {
		for (const h of Object.entries(getMerits(character, false))
			.filter(
				([_, m]) =>
					["Manse", "Hearthstone"].includes(m.key) &&
					hearthstones[m.details?.hearthstone]?.bonuses
			)
			.map(([_, m]) => hearthstones[m.details.hearthstone])) {
			bonuses = bonuses.concat(h.bonuses);
		}
	}
	bonuses = bonuses.concat(getSplatProp(character, 'bonuses', []));
	if (weapon && weapon.bonuses) bonuses = bonuses.concat(weapon.bonuses);
	return bonuses;
};

export const getBonus = (character, sources, stat, type, initial) => {
	if (!type) type = "int";

	var total;
	if (type === "int") total = initial ? initial : 0;
	else if (type === "array") total = initial ? initial : [];

	var weapon;
	if (stat !== "weapon") {
		weapon = getWeapons(character, sources.artifacts, false)[character.weapon];
	}

	for (var b of getAllBonuses(character, weapon, sources.hearthstones).filter(
		(o) => o.attr === stat
	)) {
		if (type === "int") {
			if (Number.isInteger(b.value)) total += parseInt(b.value, 10);
			else total += getScore(character, b.value, 0);
		} else if (type === "array") total = total.concat(b.value);
	}

	return total;
};

export const getCharmAbility = (charm) => {
	if (charm.category) return charm.category;
	return Object.keys(charm?.reqs ?? {}).reduce((r, v) => {
		if (r) return r;
		if (v === "essence" || v === "charms" || v === "special" || v === "bridge")
			return r;
		return v;
	}, null);
};

export const getAvailableCharms = (character, sources) => {
	const charms = getAllCharms(character);
	const existingCharms = getCharms(character);
	return filterCharmsForAvailability(charms, existingCharms, character, sources);
}

export const filterCharmsForAvailability = (charms, existingCharms, character, sources) => {
	const groupedCharms = groupCharms(character, existingCharms);
	const essence = getEssence(character);
	const DEBUG = "Forge Anew";
	
	// The "treat your essence as one higher" bonus from Glory Sphere
	const bonusEssence =
		Math.max(0, getBonus(character, sources, "charm-evoc-essence-req") -
			existingCharms.filter(
				(c) => {
					return charms[c]?.reqs?.essence === essence + 1 &&
						(getCharmAbility(charms[c]) !== character.supernal)
				}
			).length);
	
	return Object.keys(charms ?? {}).filter((name) => {
		const debug = (name === DEBUG);

		if (debug) console.log('Debugging', DEBUG);
		const charm = charms[name];
		const abil = getCharmAbility(charm);
		if (existingCharms.indexOf(name) >= 0) {
			if (debug) console.log('Already chosen')
			return false;
		}
		if (
			(charm.reqs?.essence ?? 1) >
			essence +
			([
				"Terrestrial Circle Sorcery",
				"Celestial Circle Sorcery",
				"Solar Circle Sorcery",
				"Ivory Circle Necromancy",
				"Shadow Circle Necromancy",
				"Void Circle Necromancy"
			].includes(name)
				? 0
				: bonusEssence) &&
			character.supernal !== abil
		) {
			if (debug) console.log('Essence requirement', charm.reqs?.essence, essence, bonusEssence, character.supernal)
			return false;
		}

		if (abil === "craft") {
			if (
				getCrafts(character)
					.map((o) => o.value)
					.reduce((a, b) => Math.max(a, b), 0) < charm.reqs?.[abil]
			) {
				if (debug) console.log('No craft dots');
				return false;
			}
		} else if (
			abil !== null &&
			(getScore(character, abil, 0) === undefined ||
				charm.reqs?.[abil] > getScore(character, abil, 0))
		) {
			if (debug) console.log('No dots in ability', abil);
			return false;
		}

		for (var rc of charm.reqs?.charms ?? []) {
			if (!existingCharms.includes(rc)) {
				if (debug) console.log('Missing pre-req', rc);
				return false;
			}
		}

		if (charm.reqs?.special) {
			for (const cond of charm.reqs.special) {
				if (cond.req === "any-attribute-charms") {
					if (
						data.attributes[cond.attribute]
							.map((attr) =>
								groupedCharms[attr] ? groupedCharms[attr].length : 0
							)
							.reduce((acc, cur) => acc + cur, 0) < cond.min
					) {
						if (debug) console.log('Failing any-attribute-charms req');
						return false;
					}
				} else if (cond.req === "any-ability-charms") {
					if (
						data.abilities[cond.ability] ??
						[]
							.map((ability) =>
								groupedCharms[ability] ? groupedCharms[ability].length : 0
							)
							.reduce((acc, cur) => acc + cur, 0) < cond.min
					) {
						if (debug) console.log('Failing any-ability-charms req');
						return false;
					}
				} else if (cond.req === "any-spells") {
					if (
						getSpells(character).filter((s) =>
							Object.keys(
								data.sorcery.spells?.[cond.circle.toLowerCase()] ?? {}
							).includes(s)
						).length < cond.min
					) {
						if (debug) console.log('Failing any-spells-charms req');
						return false;
					}
				} else if (cond.req === "one-of") {
					if (
						cond.charms &&
						cond.charms.reduce(
							(count, charm) =>
								count + (existingCharms.includes(charm) ? 1 : 0),
							0
						) < (cond.qty ?? 1)
					) {
						if (debug) console.log('Failing one-of req');
						return false;
					}
					if (
						cond.abilities &&
						cond.abilities.reduce(
							(max, abil) => Math.max(character[abil] ?? 0, max),
							0
						) < cond.score
					) {
						if (debug) console.log('Failing one-of req');
						return false;
					}
				} else if (cond.req === "charms-from") {
					if (
						cond.abilities &&
						cond.qty >
						cond.abilities.reduce(
							(total, abil) => total + (groupedCharms[abil]?.length ?? 0),
							0
						)
					) {
						if (debug) console.log('Failing charms-from req');
						return false;
					}
					if (
						cond.attributes &&
						cond.qty >
						cond.attributes.reduce(
							(total, attrib) => total + (groupedCharms[attrib]?.length ?? 0),
							0
						)
					) {
						if (debug) console.log('Failing charms-from req');
						return false;
					}
				}
			}
		}

		if (debug) console.log('Should be available');
		return true;
	});
};

export const getAvailableMA = (character, ma) => {
	if (!data.martialarts[ma]) return [];
	return Object.keys(data.martialarts[ma].charms).filter((name) => {
		const charm = data.martialarts[ma].charms[name];
		const existingMa = getMACharms(character, ma);

		if (existingMa.indexOf(name) >= 0) return false;
		if (
			(charm.reqs?.essence ?? 1) > getEssence(character) &&
			character.supernal !== "martialarts"
		)
			return false;
		if (
			(charm.reqs?.[ma] ?? charm.reqs["martial arts"]) > getMAScore(character, ma)
		)
			return false;
		for (var rc of charm.reqs?.charms ?? []) {
			if (existingMa.indexOf(rc) < 0) return false;
		}
		return true;
	});
};

export const getAvailableMAStyles = (character) => {
	const completeMa = Object.keys(data.martialarts).filter(
		(key) =>
			Object.keys(data.martialarts[key]?.charms ?? []).length <=
			getMACharms(character, key).length
	);
	const knownMaCharms = Object.keys(data.martialarts)
		.map((key) => getMACharms(character, key))
		.reduce((all, style) => [...all, ...style], []);
	return Object.keys(data.martialarts).filter((ma) => {
		if (
			!!data.martialarts[ma].sidereal &&
			!["sidereal", "getimian", "solar", "abyssal", "inferal"].includes(character.splat) &&
			(completeMa.length <= 0 && knownMaCharms.length < 10)
		) {
			return false;
		}
		return true;
	});
};

export const getWeapons = (character, artifacts, includeBonuses = true) => {
	let weapons = [
		{
			name: "Unarmed",
			ability: "brawl",
			stats: data.weapon.categories.mortal.light,
			tags: [],
			parry: true,
		},
	];

	weapons = [
		...weapons,
		...(character && character.gear ? character.gear : [])
			.filter((item) => item.type === "weapon")
			.map((weapon) => ({
				name: weapon.name,
				ability: weapon.ability,
				stats: data.weapon.categories.mortal[weapon.category],
				keywords: [],
				parry: weapon.parry,
			})),
	];

	weapons = [
		...weapons,
		...Object.values(getMerits(character))
			.filter(
				(merit) =>
					merit.key === "Artifact" &&
					artifacts?.[merit?.details?.artifact]?.type === "weapon"
			)
			.map((merit) => ({
				name: artifacts[merit.details.artifact].name,
				ability: character.artifacts?.[merit?.details?.artifact]?.ability ?? "",
				stats:
					data.weapon.categories.artifact[
						artifacts[merit?.details?.artifact].category
					],
				keywords: artifacts[merit.details?.artifact]?.tags ?? [],
				parry:
					character.artifacts &&
					character.artifacts[merit?.details?.artifact]?.parry === true,
			})),
	];

	if (includeBonuses) {
		weapons = [
			...weapons,
			...getBonus(character, { artifacts }, "weapon", "array").map(
				(weapon) => ({
					name: weapon.name,
					ability: weapon.name,
					stats:
						data.weapon.categories[weapon.artifact ? "artifact" : "mortal"][
							weapon.category
						],
					keywords: weapon.keywods,
					parry: weapon.parry,
					bonuses: weapon.bonuses,
				})
			),
		];
	}

	return weapons.reduce((obj, weapon) => {
		obj[weapon.name] = weapon;
		return obj;
	}, {});
};

export const getCharms = (character) => {
	const charms = [...(character.charms ? character.charms : [])];
	if (character.completed_training) {
		character.completed_training.map((t) => {
			if (t.data.type === "charm") {
				charms.push(t.data.name);
			}
			return t;
		});
	}
	return charms;
};

export const getSpells = (character) => {
	const spells = [
		...(character.controlSpells ? Object.values(character.controlSpells) : []),
		...(character.spells ? character.spells : []),
	];
	if (character.completed_training) {
		character.completed_training.map((t) => {
			if (t.data.type === "spell") {
				spells.push(t.data.name);
			}
			return t;
		});
	}
	return spells;
};

export const groupCharms = (character, charms) => {
	var o = {};
	if (!data.splats[character.splat]) return o;
	var allcharms = getSplatProp(character, 'charms', {});
	if (!charms) charms = Object.keys(allcharms);
	if (!Array.isArray(charms)) charms = Object.keys(charms);
	for (var name of charms) {
		var charm = allcharms[name];
		if (!charm) {
			o[''] = [o[''] ?? [], name];
			continue;
		}
		var abil = getCharmAbility(charm);
		if (abil === null) abil = "Universal";
		if (o[abil] === undefined) o[abil] = [];
		o[abil].push(name);
	}
	return o;
};

export const removeCharacter = (cid, cset, oref) => {
	cset.splice(cset.indexOf(cid), 1);
	oref.update({ characters: cset });
};

export const removeFromBattle = (db, cid, battle) => {
	removeCharacter(
		cid,
		battle.characters,
		db.collection("battles").doc(battle.id)
	);
};

export const removeFromScene = (db, cid, scene) => {
	removeCharacter(cid, scene.characters, db.collection("scenes").doc(scene.id));
};

export const getEvocations = (character, artifacts, hearthstones) => {
	const evocs = { ...(character.evocations ? character.evocations : {}) };
	(character.completed_training ? character.completed_training : [])
		.filter((log) => log.data.type === "evocation")
		.map((log) => {
			evocs[log.data.extra.artifact] = [
				...(evocs[log.data.extra.artifact]
					? evocs[log.data.extra.artifact]
					: []),
				log.data.name,
			];
			return log;
		});
	return evocs;
};

export const getAvailableEvocations = (character, sources) => {
	const evocations = getEvocations(character, sources);
	const artifacts = getArtifacts(character, sources.artifacts);
	const hearthstones = getHearthstones(character, sources.hearthstones);
	const availableEvocations = {};

	[...artifacts, ...hearthstones].forEach((evoSource) => {
		if (evoSource.evocations) {
			availableEvocations[evoSource.id] = {};
			evoSource.evocations.forEach((evoc) => {
				if (
					!evocations[evoSource.id] ||
					evocations[evoSource.id].indexOf(evoc.name) < 0
				) {
					availableEvocations[evoSource.id][evoc.name] = evoc;
				}
			});
		}
		console.log(availableEvocations[evoSource.id]);
		availableEvocations[evoSource.id] = filterCharmsForAvailability(availableEvocations[evoSource.id], evocations?.[evoSource.id] ?? [], character, sources)
	});

	console.log(availableEvocations)

	return availableEvocations;
}

export const canWrite = (character, user) => {
	return character && user && character.owner === user.uid;
};

export const getXPAvailable = (character) => {
	var xp = {
		regular:
			character.experience && character.experience.regular
				? parseInt(character.experience.regular, 10)
				: 0,
		specialty:
			character.experience && character.experience.specialty
				? parseInt(character.experience.specialty, 10)
				: 0,
	};
	for (const log of character.completed_training
		? character.completed_training
		: []) {
		xp.specialty -= log.costs.specialty ? log.costs.specialty : 0;
		xp.regular -= log.costs.regular ? log.costs.regular : 0;
	}
	return xp;
};

export const getExcellencies = (character) => {
	var excellencies = [];
	if (!character.splat) return excellencies;

	if (character.splat === "solar") {
		excellencies = character.favoured || [];
		Object.keys(groupCharms(character, getCharms(character))).map((abil) => {
			if (excellencies.indexOf(abil) < 0) excellencies.push(abil);
			return abil;
		});
	} else if (character.splat === "dragonblooded") {
		const charms = getAllCharms(character);
		getCharms(character).map((charm) => {
			if (charms[charm].keywords.indexOf("excellency") >= 0)
				excellencies.push(getCharmAbility(charms[charm]));
			return charm;
		});
	} else if (character.splat === "lunar") {
		const charms = groupCharms(character, getCharms(character));
		Object.values(data.attributes)
			.reduce((r, v) => r.concat(Object.keys(v)), [])
			.map((abil) => {
				if (
					character.favoured &&
					character.favoured.indexOf(abil) >= 0 &&
					(getScore(character, abil, 0) >= 3 ||
						(charms[abil] && charms[abil].length >= 1))
				)
					excellencies.push(abil);
				else if (
					getScore(character, abil, 0) >= 5 ||
					(charms[abil] && charms[abil].length >= 2)
				)
					excellencies.push(abil);
				return abil;
			});
	} else if (character.splat === "sidereal") {
		excellencies = getCasteOrFavoured(character).filter(
			(a) => character[a] ?? 0 >= 1
		);
		Object.keys(groupCharms(character, getCharms(character))).map((abil) => {
			if (excellencies.indexOf(abil) < 0) excellencies.push(abil);
			return abil;
		});
	}

	return excellencies;
};

export function getCasteOrFavoured(character) {
	let caste = character.favoured ?? [];
	if (getSplatProp(character, 'favoursAllCaste', false)) {
		caste = [
			...(getSplatProp(character, 'subtypes')?.[character.subtype]?.favours ?? []),
			...(character.favoured ?? []),
		];
	}
	else if (character.supernal)
		caste = [character.supernal, ...(character.favoured ?? [])];
	caste = [...caste, ...getSplatProp(character, 'autoCaste', [])];
	if (caste.includes('brawl') && !caste.includes('ma')) caste.push('ma');
	return caste;
}

export const xplog = (
	character,
	type,
	name,
	oval,
	nval,
	extra,
	index,
	upgradeExisting,
	subcomponent
) => {
	var desc, dispname, regxponly, training;
	const xp = getXPAvailable(character);

	if (!name) name = type;
	if (!subcomponent) subcomponent = null;
	regxponly = false;
	dispname = name;
	var costcalc = type;

	if (type === "ability") {
		dispname = capitalize(name);
	} else if (type === "attribute") {
		if (!oval) oval = 1;
		dispname = capitalize(name);
	} else if (type === "craft") {
		dispname = "Craft: " + capitalize(name);
		name = type;
		costcalc = "ability";
	} else if (type === "ma") {
		dispname = capitalize(name);
		costcalc = "ability";
	} else if (type === "charm") {
		const cability = getCharmAbility(getAllCharms(character)[name]);
		costcalc =
			character.supernal === cability || (character.favoured && character.favoured.indexOf(cability)) >= 0
				? "charm-favoured"
				: "charm-nonfavoured";
		regxponly = true;
	} else if (type === "custom-charm") {
		costcalc = "charm-favoured";
	} else if (type === "spell") {
		costcalc =
			character.favoured && character.favoured.indexOf("occult") >= 0
				? "charm-favoured"
				: "charm-nonfavoured";
	} else if (type === "subcharm") {
		dispname = name + ": " + subcomponent;
	} else if (type === "macharm") {
		costcalc =
			character.favoured && character.favoured.indexOf("brawl") >= 0
				? "charm-favoured"
				: "charm-nonfavoured";
	} else if (type === "working") {
		costcalc = "fixed";
		desc = '"' + name + '" Working';
	}

	if (!desc) {
		if (nval === null || nval === false || nval === undefined)
			desc = "Removed " + dispname;
		else if (oval === null || oval === undefined || oval === false)
			desc =
				"Added " +
				dispname +
				(nval && !nval instanceof Object && nval !== true ? " at " + nval : "");
		else if (nval > oval || !oval)
			desc = "Increase " + dispname + " from " + (oval || 0) + " to " + nval;
		else if (oval > nval)
			desc = "Decrease " + dispname + " from " + oval + " to " + nval;
		else desc = "Null";
	}

	if (!oval) oval = 0;
	const log = {
		description: desc,
		costs: {},
		data: { type, name, oval, nval, subcomponent },
	};

	var cost = 0;
	var category, i;
	if (costcalc === "attribute") {
		if (oval === null || oval === undefined) oval = 1;
		if (getCasteOrFavoured(character).indexOf(name) >= 0)
			category = type + "_favoured";
		else category = type + "_nonfavoured";
		i = oval;
		while (i !== nval) {
			if (i > nval) i--;
			cost +=
				getSplatProp(character, 'costs', {}).xp[category] * (i >= oval ? i : -i);
			if (i < nval) i++;
		}
		training =
			getSplatProp(character, 'costs', {}).training[category] *
			(nval +
				(getSplatProp(character, 'costs', {}).training[category + "_extra"]
					? getSplatProp(character, 'costs', {}).training[category + "_extra"]
					: 0));
	} else if (costcalc === "ability") {
		if (oval === null || oval === undefined || oval === 0) {
			cost += getSplatProp(character, 'costs', {}).xp["ability_new"];
			oval = 1;
		} else if (nval === null || nval === undefined || nval === 0) {
			cost -= getSplatProp(character, 'costs', {}).xp["ability_new"];
			nval = 1;
		}
		var favdisc =
			character.favoured && character.favoured.indexOf(name) >= 0
				? getSplatProp(character, 'costs', {}).xp["ability_favoured_discount"] *
				  (nval >= oval ? 1 : -1)
				: 0;
		i = oval;
		while (i !== nval && nval !== null && nval !== undefined) {
			if (i > nval) i--;
			cost +=
				getSplatProp(character, 'costs', {}).xp["ability"] *
					(i >= oval ? i : -i) -
				favdisc;
			if (i < nval) i++;
		}
		training =
			getSplatProp(character, 'costs', {}).training[
				character.favoured && character.favoured.indexOf(name) >= 0
					? "ability_favoured"
					: "ability_nonfavoured"
			] * nval;
	} else if (costcalc === "willpower") {
		cost = getSplatProp(character, 'costs', {}).xp.willpower * (nval - oval);
		training = getSplatProp(character, 'costs', {}).training["willpower"];
	} else if (costcalc === "specialty") {
		cost = getSplatProp(character, 'costs', {}).xp.specialty;
		if (nval) {
			log.description = "Add new specialty";
		} else {
			cost = -cost;
			log.description =
				"Remove " +
				character.specialties[name].ability +
				": " +
				character.specialties[name].area +
				" specialty";
		}
		training = getSplatProp(character, 'costs', {}).training["specialty"];
	} else if (costcalc === "merit") {
		cost = getSplatProp(character, 'costs', {}).xp.merit * (nval - oval);
		training = getSplatProp(character, 'costs', {}).training["merit"];
	} else if (costcalc === "charm-favoured") {
		cost = getSplatProp(character, 'costs', {}).xp.charm_favoured;
		training = getSplatProp(character, 'costs', {}).training.charm_favoured;
	} else if (costcalc === "charm-nonfavoured") {
		cost = getSplatProp(character, 'costs', {}).xp.charm_nonfavoured;
		training = getSplatProp(character, 'costs', {}).training.charm_nonfavoured;
	} else if (costcalc === "subcharm") {
		var details = getAllCharms(character)[name];
		if (
			character.subcharms &&
			character.subcharms[name] &&
			character.subcharms[name].length < details.subcharmCost.free
		) {
			cost = 0;
		} else {
			cost = details.subcharmCost.xp;
		}
		training = 0;
	} else if (costcalc === "fixed") {
		cost = oval;
		training = 0;
	} else {
		cost = getSplatProp(character, 'costs', {}).xp[costcalc];
		training = getSplatProp(character, 'costs', {}).training[costcalc]
			? getSplatProp(character, 'costs', {}).training[costcalc]
			: 0;
	}

	if (regxponly) {
		log.costs.regular = cost;
	} else if (xp.specialty < cost) {
		log.costs.specialty = xp.specialty;
		log.costs.regular = cost - xp.specialty;
	} else {
		log.costs.specialty = cost;
	}
	log.costs.training = training;
	log.training = 0;
	if (upgradeExisting) log.upgradeExisting = upgradeExisting;
	if (index !== null && index !== undefined) log.index = index;
	if (extra) log.data.extra = extra;

	return log;
};

export const startTraining = (
	character,
	logitem,
	onChange,
	enqueueSnackbar
) => {
	onChange("xplog")([...(character.xplog ? character.xplog : []), logitem]);
	enqueueSnackbar(`Started training "${logitem.description}"`);
};

export const getScore = (character, key, init) => {
	return parseInt(
		(character.completed_training ? character.completed_training : [])
			.filter((l) => l.data.name === key)
			.reduce(
				(acc, cur) => (acc = cur.data.nval),
				character && character[key] ? character[key] : init
			)
	);
};

export const getCraftScore = (character, key, init) => {
	return parseInt(
		(character.completed_training ? character.completed_training : [])
			.filter((l) => l.data.name === "craft" && l.data.subcomponent === key)
			.reduce(
				(acc, cur) => (acc = cur.data.nval),
				character?.crafts?.find((c) => c.name === key)?.value ?? init ?? 0
			)
	);
};

export const getMAScore = (character, key, init) => {
	return parseInt(
		(character.completed_training ? character.completed_training : [])
			.filter((l) => l.data.type === "ma" && l.data.subcomponent === key)
			.reduce(
				(acc, cur) => (acc = cur.data.nval),
				character?.martialarts?.find((ma) => ma.style === key)?.value ??
					init ??
					0
			)
	);
};

export const getMerits = (character, includeBonuses = true) => {
	let merits = {
		...(character.merits ?? {}),
	};

	// Add trainied merits
	for (var item of (character.completed_training
		? character.completed_training
		: []
	)
		.map((item, i) => ({ ...item, trained_index: i }))
		.filter((i) => i.data.type === "merit")) {
		const existing = merits[item.id ?? item.index] ?? {};
		let details = {
			...existing.details,
			...existing.data,
			...(item.data?.details ?? {}),
		};
		if (item.data.name === "Language")
			details.language = item.data.details ?? details.language;
		else if (
			item.data.name === "Familiar" ||
			item.data.name === "Command" ||
			item.data.name === "Allies" ||
			item.data.name === "Followers" ||
			item.data.name === "Retainers"
		)
			details.character = item.data.details?.character ?? item.data.details ?? details.character ?? {};
		else {
			details = { description: item.data.details ?? existing.details?.description }
		}

		merits[item.id ?? item.index ?? uuid()] = {
			...existing,
			key: item.data.name,
			value: item.data.nval,
			id: item.index,
			details: details,
			extra: item.data.extra,
			trained: true,
		};
	}

	if (includeBonuses) {
		for (const { merit, value } of getAllBonuses(character).filter(
			({ type }) => type === "merit"
		)) {
			merits[uuid()] = {
				key: merit,
				value,
			};
		}
	}

	return merits;
};

export const getAvailableCraftSlots = (character, type) => {
	var slots =
		data.craftrules.types[type].slots &&
		data.craftrules.types[type].slots.initial
			? data.craftrules.types[type].slots.initial
			: 0;

	if (character.projects)
		slots -= character.projects
			.filter((p) => p.consumingSlot && p.consumingSlot[type])
			.reduce((acc, cur) => acc + cur.consumingSlot[type], 0);

	return slots;
};

export const getCostForCraftSlot = (character, type, rating) => {
	const costs = {};
	const pdata = data.craftrules.types[type];
	if (pdata.slots.cost_base)
		Object.keys(pdata.cost_base).map(
			(xp) =>
				(costs[xp] = (costs[xp] ? costs[xp] : 0) + pdata.slots.cost_base[xp])
		);
	if (pdata.slots.cost_rating)
		Object.keys(pdata.cost_base).map(
			(xp) =>
				(costs[xp] =
					(costs[xp] ? costs[xp] : 0) + pdata.slots.cost_rating[xp] * rating)
		);
	if (pdata.slots.cost_slots) {
		Object.keys(pdata.slots.cost_slots).map((slot) => {
			var aslots = getAvailableCraftSlots(character, slot);
			if (!costs.slots) costs.slots = {};
			costs.slots[slot] =
				(costs.slots[slot] ? costs.slots[slot] : 0) +
				Math.min(pdata.slots.cost_slots[slot], aslots);
			if (aslots < pdata.slots.cost_slots[slot]) {
				var rslots = pdata.slots.cost_slots[slot] - aslots;
				var rslot_cost = getCostForCraftSlot(character, slot, rating);
				Object.keys(rslot_cost).map((xp) => {
					if (xp === "slots") {
						return Object.keys(costs.slots[xp]).map((rslot) => {
							return (costs.slots[rslot] =
								(costs.slots[rslot] ? costs.slots[rslot] : 0) +
								rslot_cost.slots[rslot] * rslots);
						});
					} else {
						return (costs[xp] =
							(costs[xp] ? costs[xp] : 0) + rslot_cost[xp] * rslots);
					}
				});
			}
			return costs;
		});
	}

	return costs;
};

export const getWillpower = (character) => {
	return (
		character.completed_training ? character.completed_training : []
	).reduce(
		(acc, cur) => {
			if (cur.data.type === "willpower") return cur.data.nval;
			return acc;
		},
		character.willpower
			? character.willpower
			: getSplatProp(character, 'chargen', {})[character.chargen]?.willpower ?? 0
	);
};

export const getArmour = (character, artifacts) =>
	[
		{ name: "None", stats: { soak: 0, hardness: 0, penalty: 0 }, keywords: [] },
		...(character.gear ? character.gear : [])
			.filter((item) => item.type === "armour")
			.map((armour) => ({
				name: armour.name,
				stats: data.armour.categories.mortal[armour.category],
				keywords: [],
			})),
		...Object.values(getMerits(character))
			.filter(
				(merit) =>
					merit.key === "Artifact" &&
					artifacts[merit?.details?.artifact]?.type === "armour"
			)
			.map((merit) => ({
				name: artifacts[merit.details.artifact].name,
				stats:
					data.armour.categories.artifact[
						artifacts[merit.details.artifact].category
					],
				keywords: artifacts[merit.details.artifact].tags
					? artifacts[merit.details.artifact].tags
					: [],
			})),
	].reduce((obj, armour) => {
		obj[armour.name] = armour;
		return obj;
	}, {});

export const getJoinBattle = (character, artifacts) => {
	const components = [
		["Wits", getScore(character, "wits", 1)],
		["Awareness", getScore(character, "awareness", 0)],
		["Wound Penalty", -getWoundPenalty(character)],
		["Join Battle Bonus", getBonus(character, { artifacts }, "joinbattle")],
	];
	return [
		components.reduce((acc, val) => acc + val[1], 0),
		components.filter((comp) => comp[1] !== 0),
	];
};

export const getMovement = (character, artifacts) => {
	const components = [
		["Dexterity", getScore(character, "dexterity", 1)],
		["Athletics", getScore(character, "athletics", 0)],
		["Wound Penalty", -getWoundPenalty(character)],
		["Movement Bonus", getBonus(character, { artifacts }, "movement")],
		["Armour Mobility", -getMobility(character, artifacts)[0]],
	];
	return [
		components.reduce((acc, val) => acc + val[1], 0),
		components.filter((comp) => comp[1] !== 0),
	];
};

export const getMobility = (character, artifacts) => {
	const armour = getArmour(character, artifacts)[character.armour] ?? null;
	const components = [["Armour Mobility", armour?.stats?.penalty]];
	return [
		components.reduce((acc, val) => acc + val[1], 0),
		components.filter((comp) => comp[1] !== 0),
	];
};

export const getGrapple = (character, artifacts) => {
	const components = [
		["Strength", getScore(character, "strength", 1)],
		["Brawl", getScore(character, "brawl", 0)],
		["Wound Penalty", -getWoundPenalty(character)],
		["Grapple Bonus", getBonus(character, { artifacts }, "grapple")],
	];
	return [
		components.reduce((acc, val) => acc + val[1], 0),
		components.filter((comp) => comp[1] !== 0),
	];
};

export const getWitheringAttack = (character, artifacts, range) => {
	var weapon,
		acc = 0;
	if (!character.weapon) weapon = null;
	weapon = getWeapons(character, artifacts)[character.weapon];
	if (!weapon) weapon = null;
	else if (weapon.stats) {
		if (weapon.stats.accuracy) acc = weapon.stats.accuracy;
		if (weapon.stats.range) {
			if (range === undefined) return "-";
			acc = weapon.stats.range[range];
			if (weapon.keywords.indexOf("Flame") >= 0 && range === 0) {
				acc += 2;
			}
		}
	}
	var attr = getBonus(character, { artifacts }, "attackattr", "array", [
		"dexterity",
	]).reduce((acc, cur) => (character[cur] > character[acc] ? cur : acc));
	const components = [
		[capitalize(attr), getScore(character, attr, 1)],
		[
			capitalize(getWeaponAbility(character, weapon), true),
			getWeaponAbilityScore(character, weapon),
		],
		["Weapon Accuracy", acc],
		["Wound Penalty", -getWoundPenalty(character)],
		["Accuracy Bonus", getBonus(character, { artifacts }, "witheringAttack")],
	];
	return [
		components.reduce((acc, val) => acc + val[1], 0),
		components.filter((comp) => comp[1] !== 0),
	];
};

export const getWitheringDamage = (character, artifacts) => {
	var weapon;
	var attr = getScore(character, "strength", 1);

	if (!character.weapon) weapon = null;
	weapon = getWeapons(character, artifacts)[character.weapon];
	if (!weapon) weapon = null;
	else {
		if (weapon?.keywords?.includes("Flame")) {
			attr = 4;
		}
	}
	const components = [
		[weapon?.keywords?.includes("Flame") ? "Flame" : "Strength", attr],
		["Weapon Damage", weapon && weapon.stats ? weapon.stats.damage : 0],
		["Damage Bonus", getBonus(character, { artifacts }, "witheringDamage")],
	];
	return [
		components.reduce((acc, val) => acc + val[1], 0),
		components.filter((comp) => comp[1] !== 0),
	];
};

export const getOverwhelmingDamage = (character, artifacts) => {
	var weapon;

	if (!character.weapon) weapon = null;
	weapon = getWeapons(character, artifacts)[character.weapon];
	if (!weapon) weapon = null;

	const components = [
		["Weapon", weapon && weapon.stats ? weapon.stats.overwhelming : 0],
		["Bonus", getBonus(character, { artifacts }, "overwhelming")],
	];
	return [
		components.reduce((acc, val) => acc + val[1], 0),
		components.filter((comp) => comp[1] !== 0),
	];
};

export const getDecisiveAttack = (character, artifacts) => {
	var weapon;
	if (!character.weapon) weapon = null;
	weapon = getWeapons(character, artifacts)[character.weapon];
	if (!weapon) weapon = null;
	const components = [
		["Dexterity", getScore(character, "dexterity", 1)],
		[
			capitalize(getWeaponAbility(character, weapon), true),
			getWeaponAbilityScore(character, weapon),
		],
		["Wound Penalty", -getWoundPenalty(character)],
		["Damage Bonus", getBonus(character, { artifacts }, "decisiveAttack")],
	];
	return [
		components.reduce((acc, val) => acc + val[1], 0),
		components.filter((comp) => comp[1] !== 0),
	];
};

export const getEvasion = (character, artifacts) => {
	const components = [
		["Dexterity", getScore(character, "dexterity", 1)],
		["Dodge", getScore(character, "dodge", 0)],
		["Wound Penalty", -getWoundPenalty(character)],
		["Armour Mobility", -getMobility(character, artifacts)[0]],
		["Evasion Bonus", getBonus(character, { artifacts }, "evasion")],
		["Onslaught", -(character.onslaught ? character.onslaught : 0)],
	];
	return [
		Math.ceil((components[0][1] + components[1][1]) / 2) +
			components.slice(2).reduce((acc, val) => acc + val[1], 0),
		components.filter((comp) => comp[1] !== 0),
	];
};

export const getParry = (character, artifacts) => {
	if (!character.weapon) return ["-", []];

	var weapon = getWeapons(character, artifacts)[character.weapon];
	if (!weapon || !weapon.parry) return ["-", []];

	var attr = getBonus(character, { artifacts }, "parryattr", "array", [
		"dexterity",
	]).reduce((acc, cur) => (character[cur] > character[acc] ? cur : acc));
	const components = [
		[capitalize(attr), getScore(character, attr, 1)],
		[
			getWeaponAbility(character, weapon),
			getWeaponAbilityScore(character, weapon),
		],
		["Weapon Defence", weapon.stats?.defence ?? 0],
		["Wound Penalty", -getWoundPenalty(character)],
		["Parry Bonus", getBonus(character, { artifacts }, "parry")],
		["Onslaught", -(character.onslaught ? character.onslaught : 0)],
	];
	return [
		Math.ceil((components[0][1] + components[1][1]) / 2) +
			components.slice(2).reduce((acc, val) => acc + val[1], 0),
		components.filter((comp) => comp[1] !== 0),
	];
};

export const getWeaponAbility = (character, weapon) => {
	if (!weapon || !weapon.ability) return "brawl";

	let ma;
	if (character.martialarts)
		ma = character.martialarts.reduce(
			(r, ma) => (ma.style === weapon.ability ? ma.style : r),
			null
		);
	if (Boolean(ma)) return ma;
	return weapon.ability || "melee";
};

export const getWeaponAbilityScore = (character, weapon) => {
	const ability = getWeaponAbility(character, weapon);
	let init = 0;
	if (character[ability]) init = character[ability];
	else if (character.martialarts) {
		const ma = character.martialarts.find((ma) => ma.style === ability);
		if (ma?.value) init = ma.value;
	}

	return getScore(character, ability, parseInt(init));
};

export const getSoak = (character, artifacts) => {
	var armour;
	if (!character.armour) armour = null;
	armour = getArmour(character, artifacts)[character.armour];
	if (!armour) armour = null;
	const components = [
		["Stamina", getScore(character, "stamina", 1)],
		["Armour", armour ? armour.stats.soak : 0],
		["Soak Bonus", getBonus(character, { artifacts }, "soak")],
	];
	return [
		components.reduce((acc, val) => acc + val[1], 0),
		components.filter((comp) => comp[1] !== 0),
	];
};

export const getHardness = (character, artifacts) => {
	var armourVal = 0;
	if (character.armour) {
		armourVal =
			getArmour(character, artifacts)[character.armour]?.stats?.hardness || 0;
	}
	const components = [
		["Armour", armourVal],
		["Hardness Bonus", getBonus(character, { artifacts }, "hardness")],
	];
	return [
		components.reduce((acc, val) => acc + val[1], 0),
		components.filter((comp) => comp[1] !== 0),
	];
};

export const getBaseInitiative = () => {
	return 3;
};

export const getReadIntentions = (character, artifacts) => {
	const components = [
		["Perception", getScore(character, "perception", 1)],
		["Socialize", getScore(character, "socialize", 0)],
		["Wound Penalty", -getWoundPenalty(character)],
		[
			"Read Intentions Bonus",
			getBonus(character, { artifacts }, "readintentions"),
		],
	];
	return [
		components.reduce((acc, val) => acc + val[1], 0),
		components.filter((comp) => comp[1] !== 0),
	];
};

export const getResolve = (character, artifacts) => {
	const components = [
		["Wits", getScore(character, "wits", 1)],
		["Integrity", getScore(character, "integrity", 0)],
		["Wound Penalty", -getWoundPenalty(character)],
		["Resolve Bonus", getBonus(character, { artifacts }, "resolve")],
	];
	return [
		Math.ceil((components[0][1] + components[1][1]) / 2) +
			components.slice(2).reduce((acc, val) => acc + val[1], 0),
		components.filter((comp) => comp[1] !== 0),
	];
};

export const getGuile = (character, artifacts) => {
	const components = [
		["Manipulation", getScore(character, "manipulation", 1)],
		["Socialize", getScore(character, "socialize", 0)],
		["Wound Penalty", -getWoundPenalty(character)],
		["Guild Bonus", getBonus(character, { artifacts }, "guile")],
	];
	return [
		Math.ceil((components[0][1] + components[1][1]) / 2) +
			components.slice(2).reduce((acc, val) => acc + val[1], 0),
		components.filter((comp) => comp[1] !== 0),
	];
};

export const getLanguages = (character) => {
	return Object.keys(data.languages)
		.filter(
			(l) =>
				character.language !== l ||
				getMerits(character, this.props.hearthstones)
					.filter((merit) => merit.key === "Language")
					.map((merit) => merit.details)
					.indexOf(l) < 0
		)
		.reduce((r, language) => {
			r[language] = language;
			return r;
		}, {});
};

export const getSpecialties = (character) => {
	const specialties = [
		...(character.specialties ?? []),
		...(character.completed_training ?? [])
			.filter((t) => t.data.type === "specialty")
			.map((t) => ({
				ability: t.data.ability,
				area: t.data.area,
			})),
	];
	return specialties;
};

export const getSockets = (character, artifacts) => {
	const sockets = Object.fromEntries(
		getArtifacts(character, artifacts).map((a) => {
			const slots = new Array(a.sockets ?? 0).fill(null).map((_, i) => {
				return character.sockets?.[a.id]?.[i] ?? null;
			});
			return [a.id, slots];
		})
	);
	return sockets;
};

export const getCrafts = (character) => {
	const crafts = [...(character.crafts ?? [])];
	(character.completed_training ?? [])
		.filter((t) => t.data.type === "craft")
		.forEach((t) => {
			const craftIdx = crafts.findIndex((c) => c.name === t.data.subcomponent);
			if (craftIdx && crafts[craftIdx]) crafts[craftIdx].value = t.data.nval;
			else crafts.push({ name: t.data.subcomponent, value: t.data.nval });
		});
	return crafts;
};

export const getCustomCharms = (character) => {
	return [
		...Object.entries(character.custom_charms ?? {}).map(([id, charm]) => ({
			id,
			...charm,
		})),
		...(character.completed_training
			?.filter((t) => t.data.type === "custom-charm")
			?.map((t) => t.data.nval ?? []) ?? []),
	];
};


export const getSplatProp = (character, prop, fallback) => {
	if (!character?.splat) return fallback ?? null;
	return data.splats?.[character.splat]?.subtypes?.[character.subtype]?.[prop] ?? data.splats?.[character.splat]?.[prop] ?? fallback ?? null;
}

export const normalizeForExport = (character, npcs, artifacts, hearthstones) => {
	const c = {};
	const allCharms = getAllCharms(character);
	c.name = character.name;
	c.essence = getEssence(character);
	c.description = character.description;
	c.notes = character.notes;
	c.attributes = Object.fromEntries(Object.values(data.attributes).map((o) => Object.keys(o)).flat().map((a) => [a, getScore(character, a) || 1]));
	c.abilities = Object.fromEntries(Object.keys(data.abilities).map((a) => [a, getScore(character, a) || 0]));
	c.charms = getCharms(character).map((c) => ({ name: c, ...allCharms[c] }));
	c.intimacies = character.intimacies;
	c.extra = Object.fromEntries(Object.keys(getSplatProp(character, 'extra', {})).map((p) => [p, character?.extra?.[p]]));
	c.willpower = getWillpower(character);
	c.health = getHealth(character);
	c.motes = getEssencePools(character);
	c.merits = Object.values(getMerits(character)).map((meritdata) => {
		const m = { type: meritdata.key, value: meritdata.value };
		if (!meritdata.details) return m
		if (typeof meritdata.details === 'string') return { ...m, description: meritdata.details };
		
		Object.entries(meritdata.details)
			.filter(([_, v]) => !!v)
			.forEach(([key, value]) => {
				const details = [];
				if (key === 'character') {
					if (typeof value === 'string') m.character = Object.fromEntries(npcs[value]);
					else m.character = value;
					delete m.character.public;
					delete m.character.owner;
					delete m.character.id;
				}
				else if (key === 'artifact') {
					m.artifact = artifacts[value];
					delete m.artifact.public;
					delete m.artifact.owner;
					delete m.artifact.id;
				}
				else if (key === 'hearthstone') {
					m.hearthstone = hearthstones[value];
					delete m.hearthstone.public;
					delete m.hearthstone.owner;
					delete m.hearthstone.id;
				}
				else if (meritdata.details?.type === 'npc') {
					if (!m.character) m.character = {};
					m.character[key] = value;
				}
				else m[key] = value;
				return details;
			});
		
		return m;
	});
	return c;
}
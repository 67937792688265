import React, { useEffect, useState } from 'react';

import { Button, Chip, DialogContent, MenuItem, TextField } from '@mui/material';

import data from '@data';
import { ConfirmDialog, Dialog, DialogActions, Grid, Select } from '@loom/ui';

import { abilityTypes } from '@loom/characters/functions'

const EditCharmDialog = ({ charm: charmProp, peers, groups, isCustom, onClose, onAdd, onUpdate, onDelete }) => {
	const [ charm, setCharm ] = useState({});
	const [ deleting, setDeleting ] = useState(false);

	useEffect(() => {
		setCharm(charmProp ?? {});
	}, [charmProp, setCharm]);

	return (
		<Dialog open={ Boolean(charmProp) } onClose={ onClose }>
			<DialogContent>
				<Grid nopad>
					<TextField
						label="Name"
						value={ charm.name ?? '' }
						onChange={(e) => {
							setCharm({ ...charm, name: e.target.value })
						}}
						fullWidth
					/>
					{isCustom && <Select
						options={groups ?? []}
						allowCustom={ true }
						onChange={(val) => {
							setCharm({ ...charm, group: val })
						}}
						label="Group"
						value={ charm.group ?? '' }
					></Select>}
					<Grid size="small" nopad>
						<TextField
							label="Essence"
							value={ charm.reqs?.essence ?? 1 }
							onChange={(e) => {
								console.log(e.target.value, parseInt(e.target.value))
								setCharm({ ...charm, reqs: { ...(charm.reqs ?? {}), essence: Number(e.target.value) ?? 1 } });
							}}
							inputProps={{ type: 'number' }}
							fullWidth
						/>
						<TextField
							label="Cost"
							value={ charm.cost ?? '' }
							onChange={ (e) => setCharm({ ...charm, cost: e.target.value }) }
							fullWidth
						/>
						<TextField
							label="Type"
							value={ charm.type ? charm.type : '' }
							onChange={ (e) => setCharm({ ...charm, type: e.target.value }) }
							fullWidth select
						>
							{ Object.keys(abilityTypes).map((i) => (
								<MenuItem key={ i } value={ i }>{ abilityTypes[i] }</MenuItem>
							))}
						</TextField>
						<TextField
							label="Duration"
							value={ charm.duration ? charm.duration : '' }
							onChange={ (e) => setCharm({ ...charm, duration: e.target.value }) }
							fullWidth
						/>
					</Grid>
					<TextField
						label="Pre-Requisites"
						value=""
						onChange={ (e) => setCharm({
							...charm,
							reqs: {
								...(charm.reqs),
								charms: [...(charm.reqs.charms ?? []), e.target.value]
							}
						})}
						fullWidth select
					>
						{peers?.map((charm, i) => (
							<MenuItem value={ charm.name } key={ i }>{ charm.name }</MenuItem>
						))}
					</TextField>
					<div>
						{
							charm.reqs?.charms?.map((prereq, j) => (
								<Chip
									key={ j }
									label={ prereq }
									onDelete={() => {
										setCharm({
											...charm,
											prereqs: charm.prereqs.filter((_,k) => j !== k)
										})
									}}
								/>
							))
						}
					</div>
					<TextField
						label="Tags"
						value=""
						onChange={ (e) => setCharm({
							...charm,
							keywords: [ ...(charm.keywords ?? []), e.target.value ]
						})}
						fullWidth select
					>
						{ data.charm.keywords && Object.keys(data.charm.keywords).map((keyword, i) => (
							<MenuItem value={ keyword } key={ i }>{ keyword }</MenuItem>
						))}
					</TextField>
					<div>
						{ charm.keywords ?
							charm.keywords.map((keywords, j) => (
								<Chip
									key={ j }
									label={ keywords }
									onDelete={(e) => {
										setCharm({
											...charm,
											keywords: charm.keywords.filter((_,k) => j !== k)
										})
									}}
								/>
							))
						: '' }
					</div>
					<TextField
						label="Text"
						value={ charm.text ?? '' }
						onChange={ (e) => setCharm({ ...charm, text: e.target.value }) }
						fullWidth multiline
					/>
				</Grid>
			</DialogContent>
			<DialogActions>
				{ charmProp !== true && onDelete && (
					<Button
						onClick={ () => setDeleting(true) }
						variant="contained"
						color="error"
					>Delete</Button>
				)}
				<div>
					<Button onClick={ onClose }>Close</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={ () => {
							if (charmProp === true || charmProp?.isNew) {
								onAdd(charm);
							} else {
								onUpdate(charm);
							}
							onClose();
						}}
					>{ charmProp === true || charmProp?.isNew ? 'Add' : 'Update' }</Button>
				</div>
			</DialogActions>
			<ConfirmDialog
				open={ deleting }
				text={ `Are you sure you want to delete "${charm.name}"?` }
				onConfirm={() => onDelete() }
				onClose={ () => setDeleting(false) }
			/>
		</Dialog>
	)
}

export default EditCharmDialog;

import React, { Component } from 'react'

import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material';

class EditChronicleDialog extends Component {
	render() {
		const { open, onClose, chronicle, onChange, onSave } = this.props
		
		return (
			<Dialog
				open={ open }
				onClose={ onClose }
				maxWidth="md"
				fullWidth
			>
				<DialogContent>
					<TextField
						label="Name"
						value={ chronicle.name }
						onChange={ onChange('name') }
						fullWidth
					/>
					<TextField 
						label="Description"
						value={ chronicle.description }
						onChange={ onChange('description') }
						multiline
						fullWidth
					/>
					<TextField 
						label="Introduced Facts"
						value={ chronicle.facts }
						onChange={ onChange('facts') }
						multiline
						fullWidth
					/>
					<TextField 
						label="Storyteller's Notes"
						value={ chronicle.notes }
						onChange={ onChange('notes') }
						multiline
						fullWidth
					/>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={ onClose }
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={ onSave }
					>
						Ok
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

export default EditChronicleDialog;
import React, { useContext, useState } from 'react'

import { Auth, Firestore, Loom } from '@contexts';
import { StatList, Grid, Box, Dots, NameValue, Essence } from '@loom/ui';
import { getReadIntentions, getGuile, getResolve, getScore, getWillpower, getEssencePools, getCommitments } from '@loom/characters/functions';

import IntimacyDialog from './intimacy';
import ViewContext from '../context';

const SocialTab = () => {
	const { artifacts } = useContext(Loom);
	const { user } = useContext(Auth);
	const { db } = useContext(Firestore);
	const { character, characterId } = useContext(ViewContext);
	const [ editIntimacy, setEditIntimacy ] = useState(false);

	const essence = getEssencePools(character);
	const commitments = getCommitments(character, artifacts);

	return (
		<>
			<Grid size={ 200 }>
				<StatList
					title="Social Pools"
					data={[
						['Appearance', getScore(character, 'appearance', 1)],
						['Resolve', getResolve(character, artifacts)],
						['Guile', getGuile(character, artifacts)],
						['Read Intentions', getReadIntentions(character, artifacts), 'dice'],
					]}
				/>
				<Box title="Willpower" centered>
					<Dots
						value={ character.tmpWillpower !== undefined ? character.tmpWillpower : getWillpower(character) }
						max={ getWillpower(character) }
						size="10"
						onChange={ (val) => db.collection('characters').doc(characterId).update({tmpWillpower: val}) }
						editable={ character.owner === user?.uid }
						overflow
					/>
				</Box>
				<Essence
					title="Personal Essence"
					min={ 0 }
					max={ essence.personal - commitments.personal }
					value={ character.personal !== undefined ? character.personal : essence.personal }
					onChange={ (v) => db.collection('characters').doc(characterId).update({personal:v}) }
					disabled={ character.owner !== user?.uid }
				/>
				<Essence title="Peripheral Essence"
					min={ 0 }
					max={ essence.peripheral - commitments.peripheral }
					value={ character.peripheral !== undefined ? character.peripheral : essence.peripheral }
					onChange={ (v) => db.collection('characters').doc(characterId).update({peripheral:v}) }
					disabled={ character.owner !== user?.uid }
				/>
			</Grid>
			<Grid size={"medium"}>
				{ ['defining', 'major', 'minor'].map((strength) => (
					(character.intimacies ?? []).filter((i) => i.strength === strength).length > 0 && (
						<Box
							title={ strength }
							actions={
								character.owner === user?.uid ? [
								[ 'Add', () => setEditIntimacy({ strength }) ]
							] : null}
						>
							{ character.intimacies
								.map((i, idx) => ({ ...i, index: idx }))
								.filter((i) => i.strength === strength)
								.map((intimacy, i) => (
									<NameValue
										key={ i }
										value={ intimacy.nature }
										name={ intimacy.target }
										onClick={ () => setEditIntimacy(intimacy.index)}
										nocap
									/>
								))
							}
						</Box>
					)
				))}
			</Grid>
			<IntimacyDialog intimacy={ editIntimacy } onClose={ () => setEditIntimacy(false)} />
		</>
	);
};

export default SocialTab;

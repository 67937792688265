import React from 'react';
import clsx from 'clsx';
import { useMediaQuery } from '@mui/material';

import './grid.less';

const Grid = ({ size = 'large', children, nogap, nopad, centered, className = null }) => {
	const sizes = {
		'extra-small': 100,
		'small': 200,
		'medium': 350,
		'large': 500,
		'extra-large': 800,
	}

	const pxSize = sizes[size] ?? size;
	const collapse = useMediaQuery(`(max-width:${pxSize + 24}px)`);

	return (
		<div
			className={ clsx(
				'grid',
				nogap && 'no-gap',
				nopad && 'no-pad',
				centered && 'centered',
				collapse && 'collapsed',
				className
			)}
			style={{
				gridTemplateColumns: `repeat(auto-fit, minmax(${pxSize}px, 1fr))`,
			}}
		>{ children }</div>
	)
}

export default Grid;
import React, { useContext } from "react";
import { useLocation } from "react-router-dom";

import { Auth, Firestore, Loom } from "@contexts";
import { Box, ListTab } from "@loom/ui";
import { StoryIcon } from "@loom/ui/icons";

const List = ({ stories, context }) => {
	const { user } = useContext(Auth);
	const { addExtraContent, db } = useContext(Firestore);
	const { stories: storyData } = useContext(Loom);
	const { pathname: path } = useLocation();

	addExtraContent("stories", stories);

	console.log(context);

	if (!storyData) return null;

	return (
		<Box title="Stories">
			<ListTab
				items={(stories ?? [])
					.filter((id) => storyData[id])
					.map((id) => ({
						id: id,
						link: `${path}/story/${id}`,
						label: storyData[id].name,
						locked: storyData[id].owner !== user?.uid,
					}))}
				icon={<StoryIcon />}
				onRemove={(i, story) => {
					db.collection(context.collection)
						.doc(context.id)
						.update({
							stories: context.data.stories.filter((id) => story.id !== id),
						});
				}}
			/>
		</Box>
	);
};

export default List;

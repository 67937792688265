import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { v4 as uuid } from "uuid";

import BottomNavigationAction from "@mui/material/BottomNavigationAction";

import { Auth, Firestore, Loom } from "@contexts";
import { ConfirmDialog, ActionBase } from "@loom/ui";
import {
	BattleIcon,
	CharacterIcon,
	DeleteIcon,
	EditIcon,
	NPCIcon,
} from "@loom/ui/icons";
import { AddDialog as AddCharacterDialog } from "@loom/characters";
import { CreateDialog as CreateNPCDialog } from "@loom/characters/npc";

import EditStoryDialog from "./edit";

const StoryActions = ({ chronicleId, storyId, notes: originalNotes }) => {
	const { chronicles, stories } = useContext(Loom);
	const { user } = useContext(Auth);
	const { db } = useContext(Firestore);
	const [story, setStory] = useState();
	const [edit, setEdit] = useState(false);
	const [addChar, setAddChar] = useState(false);
	const [addNPC, setAddNPC] = useState(false);
	const [deleting, setDeleting] = useState(false);
	const [notes, setNotes] = useState();
	const history = useHistory();

	useEffect(() => {
		if (!stories || !stories[storyId]) return null;
		setStory({
			...stories[storyId],
		});
	}, [stories, storyId]);

	useEffect(() => {
		setNotes(originalNotes);
	}, [originalNotes]);

	if (!chronicles || !stories || !story || !chronicles[chronicleId])
		return null;

	const chronicle = chronicles[chronicleId];

	if (user?.uid !== chronicle?.owner)
		return (
			<div>
				<ActionBase />
			</div>
		);

	return (
		<div>
			<ActionBase>
				<BottomNavigationAction
					label="Edit"
					icon={<EditIcon />}
					onClick={() => setEdit(true)}
				/>
				<BottomNavigationAction
					label="Add Battle"
					icon={<BattleIcon />}
					onClick={async () => {
						const battle = await db.collection("battles").add({
							owner: user.uid,
							participants: [
								...(chronicle.characters ?? []).map((c) => ({
									id: uuid(),
									character: c,
									initiative: 0,
									onslaught: 0,
								})),
								...(story.characters ?? []).map((c) => ({
									id: uuid(),
									character: c,
									initiative: 0,
									onslaught: 0,
								})),
							],
						});
						db.collection("stories")
							.doc(storyId)
							.update({
								...story,
								battles: [...(story.battles ?? []), battle],
							});
					}}
				/>
				<BottomNavigationAction
					label="Add Character"
					icon={<CharacterIcon />}
					onClick={() => {
						setAddChar(true);
					}}
				/>
				<BottomNavigationAction
					label="Add Quick Character"
					icon={<NPCIcon />}
					onClick={() => {
						setAddNPC(true);
					}}
				/>
				<BottomNavigationAction
					label="Delete"
					icon={<DeleteIcon />}
					onClick={() => setDeleting(true)}
				/>
			</ActionBase>
			<EditStoryDialog
				open={edit}
				story={{ ...story, notes }}
				onClose={() => {
					setEdit(false);
				}}
				onChange={(name) => (event) => {
					if (name === "notes") {
						setNotes(event.target.value);
					} else {
						setStory({
							...story,
							[name]: event.target.value,
						});
					}
				}}
				onSave={() => {
					db.collection("stories").doc(storyId).update(story);
					db.collection("notes")
						.doc(storyId)
						.set({ text: notes || "", owner: user.uid });
					setEdit(false);
				}}
			/>
			<AddCharacterDialog
				open={addChar}
				exclude={story.characters}
				onClose={() => setAddChar(false)}
				onDone={(character) => {
					db.collection("stories")
						.doc(storyId)
						.update({ characters: [...(story.characters ?? []), character] });
					setAddChar(false);
				}}
			/>
			<CreateNPCDialog
				open={addNPC}
				onClose={() => setAddNPC(false)}
				onCreate={(data) => {
					const id = uuid();
					db.collection("stories")
						.doc(storyId)
						.update({
							npcs: {
								...(story.npcs ?? {}),
								[id]: data,
							},
						});
				}}
			/>
			<ConfirmDialog
				open={deleting}
				onClose={() => setDeleting(false)}
				onConfirm={() => {
					db.collection("chronicles")
						.doc(chronicleId)
						.update({
							stories: [...chronicle?.stories?.filter((id) => id !== storyId)],
						});
					db.collection("stories").doc(storyId).delete();
					history.push(`/chronicle/${chronicleId}`);
				}}
				text={'Do you want to delete the story "' + story.name + '" ?'}
			/>
		</div>
	);
};

export default StoryActions;

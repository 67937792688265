import React, { Component } from 'react'

import { HealthLevel } from '@loom/ui'

class Magnitude extends Component {
	render() {
		const { character, data, onChange } = this.props
		const base = character?.health
			? Object.keys(character.health).reduce((i, penalty) => i + parseInt(character.health[penalty] ?? 0, 10), 0)
			: 2;
	
		return (
			[...Array(base + data.size).keys()].map((i) => (
				<HealthLevel
					key={ i }
					damage={ i < data.magDamage ? 'lethal' : '' }
					onClick={ () => {
						if (i >= base + data.size - 1) {
							onChange({
								magDamage: 0,
								size: data.size - 1
							})
						} else if (data.magDamage === i + 1) {
							onChange({magDamage:i})
						} else {
							onChange({magDamage:i + 1})
						}
					}}
				/>
			))
		)
	}
}

export default Magnitude;

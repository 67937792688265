import React from 'react';

import HealthLevel, { levels } from './health-level';

import './wounds.less';

const wound = (penalty, index, data, onChange) => {
	var wounds = data.wounds ? data.wounds : {}
	for (var i = 0; i < index; i ++) {
		if (!wounds[penalty]) wounds[penalty] = []
		if (wounds[penalty][i] === undefined) wounds[penalty][i] = null
	}
	if (!wounds[penalty]) wounds[penalty] = []
	wounds[penalty][index] = levels[levels.indexOf(wounds[penalty][index] ? wounds[penalty][index] : null) + 1]
	if (wounds[penalty][index] === undefined) wounds[penalty][index] = levels[0]

	onChange({wounds: wounds})
}

const Wounds = ({
	classes, character, data, health, onChange, ...extra
}) => {
	if (!data) data = character;
	if (!health) health = character.health ?? { 0: 1, 1: 0, 2: 0, 4: 0 };
	health.I = 1;

	return (
		<div className="health">{
			Object.keys(health ? health: {})
				.filter((p) => parseInt(health[p], 10) > 0)
				.map((penalty) => (
					<div key={ penalty }>
						<strong>{ penalty }</strong>
						<div>{ [...Array(parseInt(health[penalty], 10)).keys()].map((i) => (
							<HealthLevel
								key={i}
								damage={ data.wounds && data.wounds[penalty] ? data.wounds[penalty][i] : null }
								onClick={ () => wound(penalty, i, data, onChange) }
								{ ...extra }
							/>
						))}</div>
					</div>
				))
		}</div>
	);
};

export default Wounds

import React from 'react';
import clsx from 'clsx';

import { Button, Paper } from '@mui/material';
import Markdown from './markdown';

import './box.less';

const Box = ({
    title,
    actions: actionsProp,
    children,
    useMarkdown,
    centered,
    nopad,
    className: classNameProp,
    color
}) => {
    const className = classNameProp ? [ classNameProp ] : [];
    const markdown = typeof children === 'string' && useMarkdown !== false;
    const actions = (Array.isArray(actionsProp))
        ? actionsProp.map(([ text, action ]) => (
            <Button key={ text } onClick={ action } variant="contained" color="secondary">{ text }</Button>
        )) : actionsProp

    return (
        <Paper className={ clsx('box', className, centered && 'centered', nopad && 'no-pad', color) }>
            { title ? <h3>{ title }</h3> : null }
            <div className="content">
                { markdown ? <Markdown className="copy" source={ children || '' } /> : children }
            </div>
            { actions && (
                <div className="actions">{ actions }</div>
            )}
        </Paper>
    )
};

export default Box;
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { DialogTitle, DialogContent, MenuList, MenuItem, ListItemText } from '@mui/material';

import { Markdown } from '@loom/ui';
import { BackIcon } from '@loom/ui/icons';

import toc from './toc.json';
import './details.less';

const HelpDialog = () => {
	const [ title, setTitle ] = useState();
	const [ content, setContent ] = useState();
	const [ topics, setTopics ] = useState();
	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		let newTitle
		let context = toc
		const path = decodeURIComponent(location.hash).split('/').slice(1);

		if (path?.length > 0) {
			for (let part of path) {
				if (context[part]) {
					context = context[part]
					newTitle = part
				} else {
					break
				}
			}
			setTitle(newTitle);
		}

		if (typeof context === 'string') {
			fetch('/help/' + context).then((response) => {
				response.text().then((text) => {
					setTopics([]);
					setContent(text);
				})
			})
		} else {
			setTopics(Object.keys(context).filter((k) => k !== ""));
			if (context['']) {
				fetch('/help/' + context['']).then((response) => {
					response.text().then((text) => {
						setContent(text);
					})
				})
			}
		}
	}, [location.hash]);

	return (
		<>
			<DialogTitle disableTypography>
				{ location.hash !== '#' ? <BackIcon
					className="interactable"
					onClick={() => history.goBack() } 
				/> : null }
				{ title ? title : 'Help' }
			</DialogTitle>
			<DialogContent>
				{ content && <Markdown source={ content } /> }
				{ topics ?
					<MenuList className="grid small">
						{ topics.map((topic, i) => (
							<MenuItem
								key={ i }
								onClick={ () => history.push({
									...location,
									hash: (location.hash || '') + (location.hash.endsWith('/') ? '' : '/') + topic
								})}
							>
								<ListItemText>{ topic }</ListItemText>
							</MenuItem>
						))}
					</MenuList>
				: '' }
			</DialogContent>
		</>
	)
};

export default HelpDialog;

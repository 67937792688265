import React, { useState } from 'react'

import { TextField } from '@mui/material';
import { Grid, NameValue } from '@loom/ui';
import { GenericDialog } from '@loom/help';

export const Edit = ({
	merit, onChange
}) => {
    return (
		<Grid nopad>
            <TextField
                label={ merit.key }
                value={ merit.details?.name }
                onChange={ (e) => onChange({ ...merit.details, name: e.target.value }) }
            />
            <TextField
                label={ merit.key }
                value={ merit.details?.description }
                onChange={ (e) => onChange({ ...merit.details, description: e.target.value }) }
                multiline
            />
        </Grid>
    )
}

export const View = ({
	merit
}) => {
	const [ viewing, setViewing ] = useState(false);

    console.log(merit);

	return (<>
		<NameValue
			name={<>
				<strong>{ merit.details.name }</strong>
			</> }
			value={ merit.value }
			onClick={ () => setViewing(true) }
		/>
		<GenericDialog
			open={ viewing }
			title={ merit.details.name }
			content={ merit?.details?.description }
			onClose={ () => setViewing(false) }
		/>
	</>)
};
import firebase from 'firebase/app';
import 'firebase/auth';
import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { AppBar, Button, Toolbar } from '@mui/material'

import version from '@version';
import { Auth as AuthContext } from '@contexts';
import { UI as UIContext } from '@contexts';

import LoginElement from '@components/firebase/login';
import { TabContainer } from '@loom/ui';
import { BackIcon } from '@loom/ui/icons';

import './ui.less';
import { ErrorDialog, ErrorBoundary } from '@loom/error';

const UI = ({
	actions, back, baseUrl, splat, children
}) => {
	const { pathname } = useLocation();
	const history = useHistory();
	const { user } = useContext(AuthContext);
	const [ showErrorDialog, setShowErrorDialog ] = useState(false);
	
	return (
		<UIContext.Provider value={{ splat: splat }}>
			<TabContainer
				tab={ pathname.substring(1).split('/').slice(-1)[0] }
				tabs={ children || [] }
				onChange={ (e,value) => {
					const base = baseUrl || pathname.split('/').slice(0,-1).join('/');
					history.push(`${base}/${value}`)
				}}
			>{
				(tabs, content) => (
					<div className={ `ui ${splat || ''}` } >
						<AppBar color="primary" className="navigation-bar" position="static">
							<Toolbar>
								{
									<Button onClick={ () => history.push(back || pathname.split('/').slice(0, -2).join('/')) }>
										<BackIcon />
									</Button>
								}
								Loom
								<div className="version">
									v{ version.number }&nbsp;|&nbsp;
									<a href="/privacy.html" target="_blank">Privacy</a>
								</div>
								<Button onClick={() => {
									setShowErrorDialog(true);
								}}>Report Bug</Button>
								{ user ? (
										<Button onClick={ () => {
											firebase.auth().signOut()
												.then((e) => delete window.localStorage.uid)
												.catch((e) => console.log(e))
										}}>Logout</Button>
									) : (
										<LoginElement />
									)
								}
							</Toolbar>
						</AppBar>{ tabs && (
							<AppBar color="secondary" className="toolbar" position="static">
								{ tabs }
							</AppBar>
						)}
						<ErrorBoundary>
							<main>{ content }</main>
							{actions}
						</ErrorBoundary>
						<ErrorDialog open={ showErrorDialog } onClose={ () => setShowErrorDialog(false) } />
					</div>
				)
			}</TabContainer>
		</UIContext.Provider>
	)
}

export default UI
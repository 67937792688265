import React, { useContext } from 'react';

import { Firestore, Loom } from '@contexts';
import { Box, Column, Grid, UI } from '@loom/ui';
import { Actions } from '@loom/battle'

import Participants from './participants'
import { Padding } from '@mui/icons-material';

const Battle = ({ battleId }) => {
	const { battles } = useContext(Loom);
	const { addExtraContent } = useContext(Firestore);

	addExtraContent('battles', battleId);
	const battle = battles[battleId];

	if (!battle) return <UI />
	
	return (
		<UI
			actions={ <Actions
				battleId={ battleId }
			/> }
		>
				<Column>
					<Box title={ battle.name ?? 'Unnamed Battle' }>
						{ battle.description }
					</Box>
					<Participants
						battleId={ battleId }
					/>
				</Column>
		</UI>
	);
};

export default Battle;

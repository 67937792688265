import React, { useContext, useState } from 'react';
import { Button } from '@mui/material'

import data from '@data';
import { Auth, Loom } from '@contexts';
import { Box, NameValue } from '@loom/ui';

import ViewContext from '../context';
import ValueDialog from './commitment-dialog';

const Commitments = () => {
	const { user } = useContext(Auth);
	const { artifacts } = useContext(Loom);
	const { character } = useContext(ViewContext)
	const [ commitment, setCommitment ] = useState();
	const [ index, setIndex ] = useState();

	return (
		<Box
			title="Commitments"
			actions={
				character.owner === user?.uid && (
					<Button
						variant="contained"
						color="secondary"
						onClick={() => {
							setCommitment({ pool: 'peripheral' });
							setIndex();
						}}
					>Add</Button>
				)
			}
		>
			{ character.artifacts && Object.keys(character.artifacts)
				.filter((arti) => character.artifacts[arti].attuned)
				.map((arti, i) => (
					<NameValue
						key={ i }
						name={ artifacts[arti] ? artifacts[arti].name : 'Unnamed' }
						value={<>
							{ artifacts[arti]?.attunement ?? data[artifacts[arti]?.type]?.categories?.artifact[artifacts[arti]?.category]?.commit ?? 0 }
							{ '  ' }
							{ character.artifacts[arti].attuned }
						</>}
					/>
				))
			}{ character.commitments && character.commitments.map((c, i) => (<>
				<NameValue
					key={ i }
					name={ c.subject }
					value={ <>{ c.motes } { c.pool }</> }
					onClick={() => {
						if (character.owner !== user.id) {
							setIndex(i);
							setCommitment(c);
						}
					}}
				/>
			</>))}
			<ValueDialog
				index={ index }
				commitment={ commitment }
				setCommitment={ setCommitment }
				character={ character }
			/>
		</Box>
	);
}

export default Commitments;

import React, { useContext } from 'react';

import { Firestore, Loom } from '@contexts';
import { Box, Grid, Group, Select } from '@loom/ui';
import { getHearthstones, getSockets } from '@loom/characters/functions';

import ViewContext from '../context';

export const SocketsList = () => {
	const { db } = useContext(Firestore);
	const { artifacts, hearthstones } = useContext(Loom);
	const { character, characterId } = useContext(ViewContext);

	const sockets = getSockets(character, artifacts);
	const charHearthstones = getHearthstones(character, hearthstones);

	if (Object.values(sockets).reduce((acc, cur) => acc + cur.length, 0) === 0) return null;

	return <Box title="Hearthstone Sockets">
		<Grid size={ 200 }>{
			Object.entries(sockets)
				.filter(([_, artiSockets]) => artiSockets.length > 0)
				.map(([artifact, artiSockets]) => {
					return (
						<Group key={artifact}  title={ artifacts[artifact].name }>
							<Grid size={ 200 }>{
								artiSockets.map((hearthstone, i) => (
									<Select
										key={i}
										label={ `Socket #${i + 1}` }
										value={ hearthstone ?? null }
										onChange={ (value) => {
											const artiSocks = character.sockets?.[artifact] ?? [];
											while (artiSocks.length < artifacts[artifact].sockets ?? 0) artiSocks.push(null);
											db.collection('characters').doc(characterId).update({
												sockets: {
													...character.sockets,
													[artifact]: artiSocks
														.map((v, j) => i === j ? value : v)
												}
											})
										}}
										options={[
											{ id: null, label: 'Empty', classes: [ 'italic' ] },
											...charHearthstones.map((hs) => (
												{ id: hs.id, label: hs.name }
											))
										]}
									/>
								))
							}</Grid>
						</Group>
					)
				})
		}</Grid>
	</Box>
}

export default SocketsList
import React, { useContext } from 'react';
import { Dialog as MuiDialog, useMediaQuery } from '@mui/material';

import { UI as UIContext } from '@contexts';

const Dialog = ({ children, className, ...props }) => {
    const { splat } = useContext(UIContext)
    const fullScreen = useMediaQuery('(max-width:600px)', { noSsr: true });

    return <MuiDialog className={ `${className || ''} ${splat || ''}` } { ...props } fullScreen={ fullScreen }>{ children }</MuiDialog>
}

export default Dialog;
import React from 'react'

import { Button, DialogTitle, DialogContent, DialogActions } from '@mui/material'

import { Dialog, Markdown } from '@loom/ui'

const GenericDialog = ({
	fullScreen,
	onClose,
	title,
	content,
	actions,
	open
}) => {
	return (
		<Dialog
			open={ open !== undefined ? open : title !== '' || content !== '' }
			onClose={ onClose }
			maxWidth="md"
			fullScreen={ fullScreen }
		>
			<DialogTitle className={ "caps" }>
				{ title }
			</DialogTitle>
			<DialogContent>
				{ content && <Markdown source={ content } /> }
			</DialogContent>
			<DialogActions>
				{ actions }
				<Button onClick={ onClose }>Close</Button>
			</DialogActions>
		</Dialog>
	);
};

export default GenericDialog;



import React, { useContext } from 'react'
import capitalize from 'capitalize';

import { IconButton, MenuItem, TextField } from '@mui/material'

import { Box, Grid, EditCharmList, EditMeritList } from '@loom/ui';
import { CloseIcon } from '@loom/ui/icons';

import DialogContext from './context';

import './social.less';

const intimacyRatings = {
	minor:'Minor',
	major:'Major',
	defining:'Defining',
}

const intimacyTypes = {
	tie:'Tie',
	principle:'Principle',
}

const SocialTab = () => {
	const { character, onChange } = useContext(DialogContext);
		
	return (
		<>
			<Grid size="extra-large">
				<Box title="Social Values">
					<Grid size="extra-small">
						{['resolve', 'guile', 'appearance'].map((f) => (
							<TextField
								label={ capitalize(f) }
								type="number"
								key={ f }
								inputProps={{ min: "0", style: { textAlign: 'center' }}}
								value={ character[f] ? character[f] : 0 }
								onChange={ (e) => onChange(f)(parseInt(e.target.value, 10)) }
								fullWidth
							/>
						))}
					</Grid>
				</Box>
				<Box
					title="Intimacies"
					actions={[
						[ "Add Intimacy", () => onChange('intimacies')([...(character.intimacies ? character.intimacies : []), {}]) ]
					]}
					className="intimacies"
				>{
					Object.keys(character.intimacies ? character.intimacies : {})
						.map((i) => (
							<div key={ i }>
								<TextField label="Name" fullWidth
									value={ character.intimacies[i].name }
									onChange={ onChange('intimacies', i, 'name') }
								/>
								<TextField label="Rating" select fullWidth
									value={ character.intimacies[i].rating ? character.intimacies[i].rating : '' }
									onChange={ onChange('intimacies', i, 'rating') }
								>
									{ Object.keys(intimacyRatings).map((i) => (
										<MenuItem key={ i } value={ i }>{ intimacyRatings[i] }</MenuItem>
									))}
								</TextField>
								<TextField label="Type" select fullWidth
									value={ character.intimacies[i].type }
									onChange={ onChange('intimacies', i, 'type') }
								>
									{ Object.keys(intimacyTypes).map((i) => (
										<MenuItem key={ i } value={ i }>{ intimacyTypes[i] }</MenuItem>
									))}
								</TextField>
								<IconButton
									onClick={ () => onChange('intimacies')(character.intimacies.filter((_,j) => j !== parseInt(i, 10))) }
								><CloseIcon /></IconButton>
							</div>
						))
				}</Box>
			</Grid>
			<Grid size="medium">
				<EditCharmList
					title="Social Abilities"
					charms={ character.socialAbilities }
					onAdd={ () => (value) => onChange('socialAbilities')([...(character.socialAbilities ? character.socialAbilities : []), value ]) }
					onUpdate={ (i) => (value) => onChange('socialAbilities', i)(value) }
					onDelete={ (i) => () => onChange('socialAbilities')(character.socialAbilities.filter((_, j) => j !== i)) }
				/>
				<EditMeritList
					title="Social Merits"
					merits={ character.socialMerits }
					onAdd={ () => (value) => onChange('socialMerits')([...(character.socialMerits ? character.socialMerits : []), value ]) }
					onUpdate={ (i) => (value) => onChange('socialMerits', i)(value) }
					onDelete={ (i) =>() => onChange('socialMerits')(character.socialMerits.filter((_, j) => j !== parseInt(i, 10))) }
				/>
			</Grid>
		</>
	)
}

export default SocialTab

import { useEffect, useState } from 'react';
import { Button, Checkbox, DialogActions, DialogContent, DialogTitle, FormControlLabel, TextField, MenuItem, } from '@mui/material';

import data from '@data';
import { Dialog, Dots, Grid } from '@loom/ui';

import './craft-dialog.less';

const CraftDialog = ({
    project: initialProject, onChange, onDelete, onClose,
}) => {
    const [ project, setProject ] = useState();
    const [ isNew, setIsNew ] = useState(true);

    useEffect(() => {
        setProject({ ...initialProject });
        setIsNew(Object.keys(initialProject || {}).length === 0);
    }, [ initialProject ]);

    if (!project) return null;

    const pdata = data.craftrules.types[project.type] || {};

    const recalcProjectTargets = (project) => {
        const pdata = project?.type ? data.craftrules.types[project.type] : {}
    
        let timereq;
        if (!pdata.time_rating && !pdata.time_base) timereq = 1
        else if (pdata.time_base) timereq = pdata.time_base
        else timereq = 0
        
        if (pdata.time_rating) timereq += pdata.time_rating[project?.dots || 2]
        
        let goalnum;
        if (pdata.roll && pdata.roll.goal_number_base) goalnum = pdata.roll.goal_number_base
        else goalnum = 0
        
        if (pdata.roll && pdata.roll.goal_number_rating) goalnum += pdata.roll.goal_number_rating[project?.dots || 2]
    
        return recalcProjectComplete({
            ...project,
            timereq: timereq,
            goalnum: goalnum
        })
        
    }
    
    const recalcProjectComplete = (project) => {
        const pdata = project?.type ? data.craftrules.types[project.type] : {}
    
        let completeable = (
            project?.type && ((
                !pdata.time_base && !pdata.time_rating && !pdata.time_manual
            ) || (
                project.timespent >= project.timereq
            ))
        )
        
        let complete;
        if (!pdata.time_base && !pdata.time_rating && !pdata.time_manual) {
            complete = true
        } else if (pdata.roll && pdata.roll.terminus) {
            complete = project.goalacc >= project.goalnum
        } else {
            complete = project.timespent >= project.timereq
        }
    
        return {
            ...project,
            completeable,
            complete
        }
    }

    return (
        <Dialog open={ Boolean(initialProject) }>
            <DialogTitle disableTypography><TextField fullWidth
                className={ "white" }
                label="Name"
                value={ project?.name }
                onChange={ (event) => {
                    setProject({
                        ...project,
                        name: event.target.value
                    })
                }}
            /></DialogTitle>
            <DialogContent className="craft-dialog">
                <Grid size="small">
                    { project && (
                        <TextField select fullWidth label="Type" className="type"
                            value={ project?.type || '' }
                            onChange={ (event) => {
                                setProject(
                                    recalcProjectTargets({
                                        ...project,
                                        type: event.target.value
                                    })
                                )
                            }}
                        >
                            { Object.keys(data.craftrules.types).map((t) => <MenuItem key={ t } value={ t }>{ t }</MenuItem> ) }
                        </TextField>
                    )}
                    { project?.type && pdata.dotted && (
                        <Dots editable min={ 2 } max={ 5 } size={ 5 }
                            value={ project.dots || 2 }
                            onChange={ (val) => {
                                setProject(
                                    recalcProjectTargets({
                                        ...project,
                                        dots: val
                                    })
                                )
                            }}
                        />
                    )}
                    { project?.type && (pdata.time_manual || pdata.time_base || pdata.time_rating) && (<>
                        <TextField fullWidth label="Time Spent" type="number"
                            value={ project.timespent || 0 }
                            onChange={ (event) => setProject(
                                recalcProjectComplete({
                                    ...project,
                                    timespent: parseInt(event.target.value, 10)
                                })
                            )}
                        />
                        <TextField fullWidth label="Time Required" type="number"
                            value={ project.timereq || 0 }
                            onChange={ (event) => setProject(
                                recalcProjectComplete({
                                    ...project,
                                    timereq: parseInt(event.target.value, 10)
                                }),
                            )}
                        />
                    </>)}
                    { project?.type && pdata.roll && (<>
                        <TextField fullWidth label="Rolls Attemped" type="number"
                            value={ project.rollsmade || 0 }
                            onChange={ (event) => {
                                setProject(
                                    recalcProjectComplete({
                                        ...project,
                                        rollsmade: parseInt(event.target.value, 10)
                                    })
                                )
                            }}
                        />
                        <TextField fullWidth label="Terminus" type="number"
                            value={ project.terminus || pdata.roll.terminus }
                            onChange={ (event) => {
                                setProject(
                                    recalcProjectComplete({
                                        ...project,
                                        terminus: parseInt(event.target.value, 10)
                                    })
                                )
                            }}
                        />
                        <TextField fullWidth label="Successes Accumulated" type="number"
                            value={ project.goalacc || 0 }
                            onChange={ (event) => {
                                setProject(
                                    recalcProjectComplete({
                                        ...project,
                                        goalacc: parseInt(event.target.value, 10)
                                    })
                                )
                            }}
                        />
                        <TextField fullWidth label="Goal Number" type="number"
                            value={ project?.goalnum || 0 }
                            onChange={ (event) => {
                                setProject(
                                    recalcProjectComplete({
                                        ...project,
                                        goalnum: parseInt(event.target.value, 10)
                                    })
                                )
                            }}
                        />
                    </>)}
                </Grid>
                { data.craftrules.objectives.map((obj) => (
                    <FormControlLabel key={ obj } label={ obj }
                        control={
                            <Checkbox
                                checked={ (project?.objectives && project?.objectives.indexOf(obj) >= 0) || false }
                                onChange={ (event) => {
                                    if (event.target.checked) {
                                        setProject({
                                            ...project,
                                            objectives:[
                                                ...(project.objectives || []),
                                                obj
                                            ]
                                        })
                                    } else {
                                        setProject({
                                            ...project,
                                            objectives:[
                                                ...(project.objectives ? project.objectives.filter((i) => i !== obj) : [])
                                            ]
                                        })
                                    }
                                }}
                            />
                        }
                    />
                )) }
                { project?.type && pdata.reward_exceptional_objective && (
                    <FormControlLabel label="Succeeded with three or more excess successes"
                        control={
                            <Checkbox
                                checked={ project.exceptional ? true : false }
                                onChange={ (event) => {
                                    setProject({
                                        ...project,
                                        exceptional: event.target.checked
                                    })
                                }}
                            />
                        }
                    />
                )}
                { pdata.reward_excellency && (
                    <Grid size="small">
                        <TextField fullWidth label="Reward Excellency Successes" type="number"
                            value={ project.rewardexsux || 0 }
                            onChange={ (event) => {
                                setProject({
                                    ...project,
                                    rewardexsux: parseInt(event.target.value, 10)
                                })
                            }}
                        />
                        <TextField fullWidth label="Reward Excellency Failures" type="number"
                            value={ project.rewardexfail || 0 }
                            onChange={ (event) => {
                                setProject({
                                    ...project,
                                    rewardexfail: parseInt(event.target.value, 10)
                                })
                            }}
                        />
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                { !isNew &&
                    <Button className="dangerous"
                        onClick={() => { onDelete(); onClose(); }}
                    >Remove</Button>
                }
                <Button
                    onClick={() => { onClose() }}
                >Cancel</Button>
                { project?.completeable && pdata.manual_fail && <Button
                    onClick={() => { 
                        onChange(project, true);
                        onClose();
                    }
                }
                >Failed</Button> }
                <Button variant="contained"
                    color={ project?.complete ? 'primary' : 'secondary' } 
                    onClick={() => {
                        onChange(recalcProjectComplete(project), false);
                        onClose()
                    }}
                    
                >{
                    project?.complete ? 'Complete' : (isNew ? 'Begin' : 'Update')
                }</Button>
            </DialogActions>
        </Dialog>
    )
};

export default CraftDialog;
import React, { useEffect, useState, useContext } from 'react'
import { useHistory, useLocation } from 'react-router'

import { Auth as AuthContext, Firestore as FirestoreContext } from '@contexts';
import ConfirmDialog from '@loom/ui/confirm';
import { DeleteIcon, DuplicateIcon, EditIcon } from '@loom/ui/icons';
import EditNPCDialog from '@loom/characters/npc/edit';
import ActionBase from '@loom/ui/actions';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

import ViewContext from './context';

const Actions = ({
	location,
}) => {
	const { db } = useContext(FirestoreContext);
	const { user } = useContext(AuthContext);
	const { characterId, character: initialCharacter } = useContext(ViewContext);

	const [ edit, setEdit ] = useState(false);
	const [ deleting, setDeleting ] = useState(false);
	const [ character, setCharacter ] = useState();
	const { pathname: path } = useLocation();
	const history = useHistory();

	useEffect(() => {
		setCharacter({ ...initialCharacter });
	}, [ setCharacter, initialCharacter ]);

	const resetCharacter = () => {
		setCharacter({ ...initialCharacter });
	}

	const changeCharacter = (name) => (event) => {
		setCharacter({ 
			...character,
			[name]: event.target.value
		})
	}

	if (!character) return null;

	return (
		<React.Fragment>
			<ActionBase>{
				character.owner === user.uid && (
					<BottomNavigationAction
						key="edit"
						label="Edit"
						icon={ <EditIcon /> }
						onClick={() => setEdit(true)}
					/>
				)
			}{
				<BottomNavigationAction
					label="Duplicate"
					icon={ <DuplicateIcon /> }
					onClick={() => {
						db.collection('characters').add({
							...character,
							name: 'Copy of ' + character.name,
							owner: user.uid,
						})
						history.push('/')
					}}
				/>
			}{
				<BottomNavigationAction
					key="delete"
					label="Delete"
					icon={ <DeleteIcon /> }
					onClick={() => setDeleting(true)}
				/>
			}</ActionBase>
			<EditNPCDialog
				open={ edit }
				character={ character }
				onClose={() => {
					setEdit(false);
					resetCharacter();
				}}
				onChange={ changeCharacter }
				onSave={ async (updatedCharacter) => {
					if (!location) {
						db.collection('characters').doc(characterId).update(updatedCharacter);
						return;
					}

					const doc = db.collection(location[0]).doc(location[1]);
					const original = (await doc.get()).data();
					db.collection(location[0]).doc(location[1]).update({
						npcs: {
							...original.npcs ?? {},
							[characterId]: updatedCharacter 
						},
					});
					setEdit(false);
				}}
			/>
			<ConfirmDialog
				open={ deleting }
				onClose={ () => setDeleting(false) }
				onConfirm={async () => {
					if (!location) {
						db.collection('characters').doc(characterId).delete();
						history.push('/homebrew');
						return;
					}

					const doc = db.collection(location[0]).doc(location[1]);
					const original = (await doc.get()).data();
					db.collection(location[0]).doc(location[1]).update({
						npcs: Object.fromEntries(
							Object.entries(original.npcs ?? {})
								.filter(([cid, c]) => cid !== characterId)
						)
					});
					history.push(path.split('/').slice(0,-3).join('/'));
					setDeleting(false);
				}}
				text={ `Do you want to delete the character "${character.name}"?` }
			/>
		</React.Fragment>
	);
};

export default Actions;

import React, { useEffect, useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import BottomNavigationAction from '@mui/material/BottomNavigationAction' ;

import { Firestore, Auth } from '@contexts';
import { ActionBase, ConfirmDialog } from '@loom/ui';
import { OrganisationIcon, CharacterIcon, DeleteIcon, EditIcon, NPCIcon } from '@loom/ui/icons';
import { AddDialog as AddCharacterDialog } from '@loom/characters';
import { CreateDialog as CreateNPCDialog } from '@loom/characters/npc';
import createOrganisation from './create';
import EditDialog from './edit/dialog';

const OrganisationActions = ({ organisationId, organisation: initialOrganisation }) => {
	const [ organisation, setOrganisation ] = useState();
	const [ edit, setEdit ] = useState(false);
	const [ addChar, setAddChar ] = useState(false);
	const [ deleting, setDeleting ] = useState(false);
	const [ addNPC, setAddNPC ] = useState(false);
	const [ notes, setNotes ] = useState('');
	const { db } = useContext(Firestore);
	const { user } = useContext(Auth);
	const { pathname: path } = useLocation();
	const history = useHistory();
	
	useEffect(() => {
		setOrganisation({...initialOrganisation});
	}, [initialOrganisation, setOrganisation]);

	if (user?.uid !== organisation?.owner) return <div><ActionBase /></div>

	return (
		<div>
			<ActionBase>
				<BottomNavigationAction
					label="Edit"
					icon={ <EditIcon /> }
					onClick={ () => setEdit(true) }
				/>
				<BottomNavigationAction
					label="Add Organisation"
					icon={ <OrganisationIcon /> }
					onClick={ async () => {
						const organisation = await db.collection('organisations').add(createOrganisation(user));
						db.collection('organisations').doc(organisationId).update({
							organisations:[ ...(organisation.organisations ?? []), organisation.id ]
						});
						history.push(`${path}/organisation/${organisation.id}`);
					}}
				/>
				<BottomNavigationAction
					label="Add Character"
					icon={ <CharacterIcon /> }
					onClick={ () => setAddChar(true) }
				/>
				<BottomNavigationAction
					label="Add Quick Character"
					icon={ <NPCIcon /> }
					onClick={ () => setAddNPC(true) }
				/>
				<BottomNavigationAction
					label="Delete"
					icon={ <DeleteIcon /> }
					onClick={ () => setDeleting(true) }
				/>
			</ActionBase>
			<AddCharacterDialog 
				open={ addChar }
				exclude={ organisation?.pcs }
				onClose={ () => setAddChar(false) }
				onDone={ (character) => {
					db.collection('organisations').doc(organisationId).update({
						pcs: [...organisation?.pcs ?? [], character]
					});
				}}
			/>
			<CreateNPCDialog
				open={ addNPC }
				onClose={ () => setAddNPC(false) }
				onCreate={ (data) => {
					const newId = uuid();
					db.collection('organisations').doc(organisationId).update({
						npcs: {
							...(organisation?.npcs || {}),
							[newId]: data
						}
					})
				}}
			/>
			<EditDialog
				open={ edit }
				onClose={ () => setEdit(false) }
				organisation={ { ...organisation, notes } }
				onChange={ (name) => (event) => {
					if (name === 'notes') {
						setNotes(event.target.value);
					} else {
						setOrganisation({ ...organisation, [name]: event.target.value });
					}
				}}
				onSave={ () => {
					const { notes: _, ..._organisation } = organisation;
					db.collection('organisations').doc(organisationId).update(_organisation);
					db.collection('notes').doc(organisationId).set({ text: notes || '', owner: user.uid });
					setEdit(false);
				}}
			/>
			<ConfirmDialog
				open={ deleting }
				onClose={ () => setDeleting(false) }
				onConfirm={() => {
					db.collection('organisations').doc(organisationId).delete()
					history.push('/')
					setDeleting(false);
				}}
				text={ 'Do you want to delete the organisation "' + organisation?.name + '"?' }
			/>
		</div>
	)
}

export default OrganisationActions

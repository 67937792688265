import React, { useContext } from 'react'

import { Grid } from '@loom/ui';

import ViewContext from '../context';
import LanguageList from './languages';
import MeritList from './merit-list';
import RitualList from './rituals';
import Commitments from './commitments';
import Sockets from './sockets';

const MeritsTab = () => {
	const { character } = useContext(ViewContext);

	return (
		<Grid size={ 400 }>
			<LanguageList character={ character } />
			<MeritList character={ character } />
			{ character.rituals && Object.keys(character.rituals).length > 0 && <RitualList character={ character } /> }
			<Commitments />
			<Sockets />
		</Grid>
	);
};

export default MeritsTab;
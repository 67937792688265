import { Tab as MuiTab } from '@mui/material';

const Tab = ({
    value,
    label,
    icon,
    render,
    __identifier = 'TAB',
}) => {
    return (
        <MuiTab value={ value } label={ label } icon={ icon } />
    )
};

Tab.defaultProps = {
 __identifier: 'TAB',
};

export default Tab

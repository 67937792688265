import React, { useState } from 'react'

import { MenuList,  MenuItem, ListItemText } from '@mui/material'

import { Dialog, Grid } from '@loom/ui';
import CharmDialog from '@loom/help/charm';

const CharmList = ({
	charms
}) => {
	const [ charm, setCharm ] = useState();

	return (<div>
			<MenuList>
				<Grid size={ 250 } nogap nopad>
				{ (charms ? charms : []).map((c, i) => {
					return (
						<MenuItem
							key={ i }
							onClick={ () => setCharm(c) }
						>
							<ListItemText>{ c.name }</ListItemText>
						</MenuItem>
					)
				})}
				</Grid>
			</MenuList>
			<Dialog
				open={ Boolean(charm) }
				onClose={ () => setCharm(null) }
			>
				<div>
					{ charm &&
						<CharmDialog
							charm={charm}
							peers={charms}
							onClose={ () => setCharm(null) }
						/>
					}
				</div>
			</Dialog>
		</div>
	);
};

export default CharmList;

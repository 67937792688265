import { useParams } from 'react-router-dom';

import Story from './view';

const View = () => {
	const { id, chronicle } = useParams();

	return (
		<Story
			chronicleId={ chronicle }
			storyId={ id }
		/>
	);
};

export { View }
export default View
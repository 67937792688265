import React from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { Button } from '@mui/material';

const Login = () => (
    <Button onClick={ () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithPopup(provider)
    }}>	  
        Sign In
    </Button>
)

export default Login;
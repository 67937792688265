import React, { useState } from 'react'

import { MenuList,  MenuItem, ListItemText } from '@mui/material'

import { Box, Grid } from '@loom/ui';
import Dialog from './dialog';

const CharmList = ({
	title,
	charms,
	onAdd,
	onUpdate,
	onDelete
}) => {
	const [charm, setCharm] = useState();

	return (
		<Box
			title={ title }
			actions={[
				[ 'Add Charm', () => setCharm(true) ]
			]}
		>
			<MenuList>
				<Grid size="medium" nogap nopad>
				{ (charms ? charms : []).map((c, i) => {
					return (
						<MenuItem
							key={ i }
							onClick={ () => setCharm(i) }
						>
							<ListItemText>{ c.name ?? '<Unnamed Evocation>' }</ListItemText>
						</MenuItem>
					)
				})}
				</Grid>
			</MenuList>
			<Dialog
				charm={charm === true ? true : charms?.[charm]}
				peers={charms}
				onClose={ () => setCharm(null) }
				onAdd={ (data) => onAdd(charm)(data) }
				onUpdate={ (data) => onUpdate(charm)(data) }
				onDelete={ () => onDelete(charm)() }
			/>
		</Box>
	);
};

export default CharmList;

import React from "react";
import { uploadBytesResumable } from 'firebase/storage';

import { Button, FormLabel, Paper } from "@mui/material";

import "./styles.less";
class ImageSelector extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			value: this.props.value ? this.props.value : null,
			uploading: false,
		};
		this.file = React.createRef();
		this.storage = props.storage;
		this.path = props.path;
	}

	click() {
		this.file.current.click();
	}

	clear() {
		this.setState({ value: null, filename: "" });
		if (this.props.onChange) this.props.onChange(null);
	}

	change(e) {
		const reader = new FileReader();
		if (e.target.files.length === 0) return;
		this.setState({ uploading: true });

		if (this.props.max_size) {
			reader.onload = (e) => {
				const canvas = document.createElement("canvas");
				canvas.style.display = "none";
				const img = new Image();
				img.style.display = "none";

				document.body.appendChild(canvas);
				img.src = e.target.value;
				document.body.appendChild(img);
				img.onload = () => {
					var ctx = canvas.getContext("2d");
					var canvasCopy = document.createElement("canvas");
					var copyContext = canvasCopy.getContext("2d");
					var ratio = 1;

					if (img.width > this.props.max_size[0]) {
						ratio = this.props.max_size[0] / img.width;
					} else if (img.height > this.props.max_size[1]) {
						ratio = this.props.max_size[1] / img.height;
					}
					canvasCopy.width = img.width;
					canvasCopy.height = img.height;
					copyContext.drawImage(img, 0, 0);

					canvas.width = img.width * ratio;
					canvas.height = img.height * ratio;
					ctx.drawImage(
						canvasCopy,
						0,
						0,
						canvasCopy.width,
						canvasCopy.height,
						0,
						0,
						canvas.width,
						canvas.height
					);
					const resized = canvas.toBlob("image/jpeg", 0.7);
					this._change(resized);
					canvas.parentNode.removeChild(canvas);
					img.parentNode.removeChild(img);
				};
			}
			reader.readAsDataURL(e.target.files[0]);
		} else {
			reader.onload = (e) => {
				this._change(e.target.result);
			}
			reader.readAsArrayBuffer(e.target.files[0]);
		}
		this.setState({ filename: e.target.value });
	}

	async _change(imagedata) {
		const parts = this.state.filename.split('.');
		const storageRef = this.storage.ref(`${this.path}.${parts[parts.length - 1]}`);
		await storageRef.put(imagedata, { cacheControl: 'public, max-age=3600'});
		const url = await storageRef.getDownloadURL();
		this.setState({ value: url, uploading: false });
		if (this.props.onChange) this.props.onChange(url);
	}

	render() {
		const {
			value,
			width,
			height,
			onChange,
			label,
			prompt,
			max_size,
			...extra
		} = this.props;

		return (
			<div className="image-selector" {...extra}>
				{label ? <FormLabel>{label}</FormLabel> : null}
				<Paper
					className={`image ${this.state.value ? "selected" : "empty"}`}
					elevation={9}
				>
					<input
						accept="image/*"
						multiple
						type="file"
						ref={this.file}
						onChange={(e) => this.change(e)}
						value={this.state.filename ? this.state.filename : ""}
					/>
					<div className="clicker" onClick={() => this.file.current.click()}>
						{ this.state.uploading && <div className="uploading">Uploading...</div> }
						<img src={this.state.value} alt="Selected" />
						<FormLabel className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl">
							{this.state.value
								? ""
								: prompt
								? prompt
								: "Click to choose image"}
						</FormLabel>
					</div>
				</Paper>
				<Button onClick={() => this.clear()}>Clear</Button>
			</div>
		);
	}
}

export default ImageSelector;

import React, { useContext } from 'react';
import { useSnackbar } from 'notistack';

import data from '@data';
import { xplog, getScore, startTraining, getSplatProp } from '@loom/characters/functions';
import { Box, Dots, Grid } from '@loom/ui';

import EditContext from '../context';
import { isCaste, isFavoured, inTraining } from './utils';

const Attributes = () => {
	const { character, onChange } = useContext(EditContext)
	const { enqueueSnackbar } = useSnackbar();

	return (
		<Grid size="small" nopad>
			{ Object.keys(data.attributes).map((grp) => (
				<Box key={ grp } title={ grp }>
					<div>
						{ Object.keys(data.attributes[grp]).map((attr) => (
							<div
								key={ attr }
								className={ `
									attribute
									${isCaste(character, attr) ? 'caste' : ''}
									${isFavoured(character, attr) ? 'favoured' : ''}
								`}
							>
								<div
									className="name"
									onClick={ () => {
										if (!character.splat || getSplatProp(character, 'favours-type', 'ability') !== 'attribute') return
										if (character.experienced) return
										if (isFavoured(character, attr)) {
											onChange('favoured')([ ...(character.favoured ? character.favoured : []).filter((a) => a !== attr) ])
										} else {
											onChange('favoured')([...(character.favoured ? character.favoured : []), attr])
										}
									}}
								>{ attr }</div>
								<Dots
									value={ getScore(character, attr) }
									min="1"
									max="5"
									temp={ inTraining(character, 'attribute', attr) }
									onChange={(e) => {
										if (character.experienced) {
											startTraining(character, xplog(character, 'attribute', attr, getScore(character, attr), e), onChange, enqueueSnackbar)
										} else {
											onChange(attr)(e)
										}
									}}
									variant={ isFavoured(character, attr) ? 'light' : 'dark' }
									editable
								/>
							</div>
						))}
					</div>
				</Box>
			))}
		</Grid>
	)
}

export default Attributes;
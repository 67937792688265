import React, { useContext, useEffect, useState } from 'react'

import { Button, Paper, TextField } from '@mui/material';

import data from '@data';
import { Auth as AuthContext, Firestore as FirestoreContext } from '@contexts';
import ViewContext from './context';
import { Dots, Markdown, Grid, Box } from '@loom/ui';
import { getSplatProp, getEssence } from '@loom/characters/functions';
import { CharmListDialog } from '@loom/help';

import './general.less';

const GeneralTab = () => {
	const { character, characterId } = useContext(ViewContext);
	const { user } = useContext(AuthContext);
	const { db } = useContext(FirestoreContext);

	const [ charms, setCharms ] = useState();
	const [notes, setNotes] = useState();
	const [notesChanged, setNotesChanged] = useState(false);

	useEffect(() => {
		setNotes(character.notes);
	}, [character, setNotes])

	if (!character) return null;
		
	const subsplat = data.splats[character?.splat]?.subtypes?.[character?.subtype] ?? null;

	const subtype_title =
		getSplatProp(character, 'subtype_prefix')
			? getSplatProp(character, 'subtype_prefix') + character.subtype
			: character.subtype + ' ' + (getSplatProp(character, 'subtype') ?? '')

	return (
		<div className="general">
			<Grid size="large">
				{ character.portrait && (
					<Grid size="large" nopad>
						{ character.portrait && (
							<Paper className="box portrait">
								<h3>{ character.name }</h3>
								<img src={ character.portrait } alt="Portrait" />
							</Paper>
						)}
						<Box title="Essence" centered>
							<Dots
								value={ getEssence(character) }
								max="5"
							/>
						</Box>
						<Box title="Limit" centered>
							<Dots
								value={ character.limit !== undefined ? character.limit : character.limit }
								size="10"
								onChange={ (val) => db.collection('characters').doc(characterId).update({limit: val}) }
								editable={ character.owner === user?.uid }
							/>
						</Box>
					</Grid>
				)}
				<Grid size="large" nopad>
					<Box title={ character.portrait ? 'Description' : character.name }>
						<Markdown source={ character.description } />
						{ character.iconic && (<>
							<h4>Iconic Anima</h4>
							<Markdown source={ character.iconic } />
						</>)}
						{character.extra && Object.entries(data.splats[character?.splat]?.extra ?? {}).map(([name, type]) => (
							character.extra[name] ? (
							<div key={ name}>
								<h4>{ name }</h4>
								<Markdown source={`${character.extra[name]}${type !== 'text' ? ': ' : ''}${ data[type]?.[character.extra[name]] ?? '' }`} />
								</div>
							): null
						))}
					</Box>
					{ !character.portrait && (<>
						<Box title="Essence" centered>
							<Dots
								value={ getEssence(character) }
								max="5"
							/>
						</Box>
						<Box title="Limit" centered>
							<Dots
								value={ character.limit !== undefined ? character.limit : character.limit }
								size="10"
								onChange={ (val) => db.collection('characters').doc(characterId).update({limit: val}) }
								editable={ character.owner === user?.uid }
							/>
						</Box>
					</>)}
				</Grid>
				{ character.subtype &&
					<Box title={ subtype_title }>
						{ data.splats[character?.splat]?.generalDesc && (
							<Markdown source={ getSplatProp(character, 'generalDesc') } />
						) }
						<h4>Anima</h4>
						<Markdown source={ getSplatProp(character, 'anima-effect', '') } />
						<h4>Role Bonus</h4>
						<Markdown source={ subsplat ? subsplat.rolebonus : '' } />
						<h4 className="caps">{ data.splats[character?.splat]?.subtype ?? '' } { data.splats[character?.splat]?.favours_plural ?? '' }</h4>
						<p className="caps">{ (subsplat?.favours ?? []).map((prop, i) => (
							<React.Fragment key={ i }>
								<span>{ i > 0 ? ', ' : '' }</span>
								<span
									onClick={ () => { setCharms(prop) }}
									className="interactable"
								>{ prop }</span>
							</React.Fragment>
						))}</p>
					</Box>
				}
			</Grid>
			{ user && user.uid === character.owner && (
				<Grid>
					<Box title="Notes">
						<TextField
							multiline
							label="Notes"
							className="notes full-height"
							value={ notes }
							onChange={ (e) => {
								setNotes(e.target.value);
								setNotesChanged(true);
							}}
						/>
					{ notesChanged && (
						<Button variant="contained" color="primary" onClick={() => {
							db.collection('characters').doc(characterId).update({ notes });
							setNotesChanged(false);
							}} style={{ position: 'absolute', bottom: '25px', right: '25px' }}>
							Save Notes
						</Button>
					)}
					</Box>
				</Grid>
			)}			
			<CharmListDialog
				character={ character }
				category={ charms }
				onClose={ () => setCharms(null) }
			/>
		</div>
	)
}

export default GeneralTab;

import React, { useContext, useState } from 'react';
import { Button, Dialog, DialogContent, DialogActions, TextField } from '@mui/material';

import { Loom } from '@contexts';
import { Grid, Select } from '@loom/ui';

const CreateNPCDialog = ({ open, onCreate, onClose }) => {
	const { npcs } = useContext(Loom);
	const [ template, setTemplate ] = useState('');
	const [ name, setName ] = useState('<Unnamed Character>');

	return (
		<Dialog open={ open } onClose={ onClose } maxWidth="sm" fullWidth>
			<DialogContent>
				<Grid size="medium" nopad>
					<Select
						options={ Object.entries(npcs).map(([idx, data]) => ({
							label: data.name,
							id: idx,
						}))}
						onChange={ setTemplate }
						label="Template"
					/>
					<TextField
						value={ name }
						onChange={ (e) => setName(e.target.value) }
						fullWidth
					/>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={ () => onClose() }>Cancel</Button>
				<Button
					variant="contained"
					color="primary"
					onClick={ () => {
						onCreate({ ...(template ? npcs[template] : {}), name });
						onClose();
					}}
				>Add</Button>
			</DialogActions>
		</Dialog>
	)
}

export default CreateNPCDialog;
import React, { useContext, useEffect, useState } from 'react'

import { Button, DialogTitle, DialogContent, DialogActions, Tooltip } from '@mui/material'

import data from '@data'
import { Box, Dialog, Grid, Markdown, NameValue } from '@loom/ui'
import { getAllCharms, getArtifacts } from '@loom/characters/functions'
import { Loom } from '@contexts'

import './charm.less';

const renderSpecial = (data) => {
	console.log(data);
	return 'special';
}

const CharmDialog = ({
	charm: initialCharm,
	peers,
	character,
	notes,
	onClose
}) => {
	const { artifacts: allArtifacts } = useContext(Loom);

	const [ charm, setCharm ] = useState();
	const charms = peers
		? Object.fromEntries(peers.map((charm) => [charm.name, charm]))
		: (character ? {
			...getAllCharms(character),
			...Object.fromEntries(getArtifacts(character, allArtifacts).flatMap((artifact) => artifact.evocations?.map?.((evo) => [ evo.name, evo ]) ?? []))
		} : [])

	
	useEffect(() => {
		setCharm(initialCharm);
	}, [initialCharm]);

	if (!charm) return null;

	const getReqs = (charm, charms, reqs) => {
		console.log(charm);
		if (reqs === undefined) reqs = []
		for (let req of charm?.reqs?.charms ?? []) {
			reqs = [...reqs, req, ...getReqs(charms[req], charms)]
		}
		return [ ...new Set(reqs) ]
	}

	const reqs = getReqs(charm, charms);
	const { charms: _, ...oReqs } = charm.reqs || {};

	return (
		<Dialog
			open={ Boolean(charm) }
			maxWidth="md"
			fullWidth
		>
			<DialogTitle>
				{ charm?.name || '' }
			</DialogTitle>
			<DialogContent>
				{ charm ?
					<>
						<Grid size="medium" nopad nogap>
							<NameValue name="Cost" value={ charm.cost } strong condensed />
							<NameValue name="Type" value={ charm.type } strong condensed />
							<NameValue name="Duration" value={ charm.duration } strong condensed />
							{ charm.essence && <NameValue name="Requires" value={ `Essence ${charm.essence}` } strong condensed /> }
							{ Object.keys(oReqs).length > 0 && (
								<NameValue
									name="Requires"
									value={
										Object.entries(oReqs).map(([ r, v ], i) => (
											<span>{ i !== 0 ? ', ' : ''}{ r === 'special' ? renderSpecial(r) : `${v} ${r}` }</span>
										))
									}
									strong
									condensed
								/>
							)}
						</Grid>
						<Grid size="extra-large" nogap nopad>
							{
								charm.keywords && charm.keywords.length > 0 && (
									<NameValue name="Keywords" value={
										charm.keywords.map((kw, i) => (
											<Tooltip title={ data.charm.keywords[kw] } key={ kw }>
												<span>{ i !== 0 ? ', ' : '' }{ kw }</span>
											</Tooltip>
										))
									}
									strong
									condensed
								/>
							)}
							{ reqs.length > 0 && (
								<NameValue
									name="Pre-requisites"
									value={
										reqs.map((c, i) => (<>
											{ i !== 0 ? ', ' : '' }<a href={ c } key={ i } onClick={ (e) => {
												setCharm({ ...charms[c], name:c });
												e.preventDefault()
											}}>{ c }</a>
										</>))
									}
									strong
									condensed
								/>
							)}
						</Grid>
						<Markdown source={ charm.text } />
						{ notes && (<>
							<hr />
							<Markdown source={ notes.split('\n').join('\n\n') } />
						</>)}
						<Grid nopad>
						{ charm.subcharms && Object.keys(charm.subcharms).map((sub) => (
							<Box
								title={ sub }
								key={ sub }
								color={
									(
										character?.subcharms?.[charm.name]?.includes(sub) ||
										character?.completed_training?.find((t) => (
											t.data.type === 'subcharm' &&
											t.data.name === charm?.name &&
											t.data.subcomponent === sub
										))
									) ? 'primary' : undefined
								}
							>
								<Markdown source={ charm.subcharms[sub] } />
							</Box>
						))}
						</Grid>
					</>
				: '' }
			</DialogContent>
			<DialogActions>
				<Button onClick={ onClose }>Close</Button>
			</DialogActions>
		</Dialog>
	)
}

export default CharmDialog;

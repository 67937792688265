import core from './core'
import db from './db'
import lunars from './lunar'

const merits = {
    ...core,
    ...db,
    ...lunars
}

export default merits

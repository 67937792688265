import React, { useContext, useState } from 'react'

import { MenuList, MenuItem } from '@mui/material';

import data from '@data';
import { Loom as LoomContext } from '@contexts';
import { GenericDialog } from '@loom/help';
import { Box } from '@loom/ui';
import { getMerits } from '@loom/characters/functions';

const LanguageList = ({
	character
}) => {
	const { hearthstones } = useContext(LoomContext);
	const [ language, setLanguage ] = useState();
	const merits = getMerits(character, hearthstones);

	return <>
		<Box title="Languages">
			<MenuList>
				<MenuItem
					className="interactable"
					onClick={ () => setLanguage(character.language) }
				>{ character.language }</MenuItem>
				{ Object.entries(merits)
					.filter(([_idx, merit]) => merit.key === 'Language')
					.map(([idx, merit]) => (
						<MenuItem
							key={ idx }
							className="interactable"
							onClick={ () => setLanguage(character.merits[idx].details) }
						>{ merit.details?.language ?? merit.details }</MenuItem>
					))
				}
			</MenuList>
		</Box>
		<GenericDialog
			open={ Boolean(language) }
			title={ language }
			content={ 
				(data.languages[language]) + 
				('\n\n---\n\n' + data.merits.Language.description)
			}
			onClose={ () => setLanguage(null) }
		/>
	</>;
};

export default LanguageList;
import React, { useState } from 'react'

import data from '@data';
import { TextField } from '@mui/material';
import { NameValue } from '@loom/ui';
import { GenericDialog } from '@loom/help';

export const name = 'Fallback';

export const Edit = ({
	merit, onChange
}) => {
    return (
		<div className="flex">
            <TextField
                label={ merit.key }
                value={ merit.details?.description ?? merit.details }
                onChange={ (e) => onChange(e.target.value) }
                multiline
            />
        </div>
    )
}

export const View = ({
	merit
}) => {
    const MAX_DESC = 50;
	const [ viewing, setViewing ] = useState(false);

    var title, desc;

    desc = data.merits[merit.key]?.description;

    const meritDesc = typeof (merit?.details?.description ?? merit?.details) === 'string' ? merit?.details?.description ?? merit?.details : '';

    if (
        meritDesc.length >= MAX_DESC
        || meritDesc.includes('\n')
    ) {
        desc = `${meritDesc}\n\n----\n\n` + desc;
    } else {
        title = meritDesc;
    }

    return (<>
		<NameValue
			name={<>
				<strong>{ merit.key }</strong>
                { ' '}
				{ title }
			</> }
			value={ merit.value }
			onClick={ () => setViewing(true) }
		/>
		<GenericDialog
			open={ viewing }
			title={ `${merit.key}${title ? `: ${title}` : ''}` }
			content={ desc }
			onClose={ () => setViewing(false) }
		/>
	</>)
};
import React, { useContext, useState } from 'react'

import { Button, Paper, TextField } from '@mui/material'

import { Firestore, Loom, Auth } from '@contexts';
import { ConfirmDialog, Spinner } from '@loom/ui'
import { InitiativeIcon, OnslaughtIcon, CloseIcon, DetailsIcon } from '@loom/ui/icons'

import './participants.less';
import { ViewDialog as CharacterDialog } from '@loom/characters';

const Participants = ({ battleId }) => {
	const [ participant, setParticipant ] = useState();
	const [ removing, setRemoving ] = useState(false);
	const { pcs, npcs, battles } = useContext(Loom);
	const { db, addExtraContent } = useContext(Firestore);
	const { user } = useContext(Auth);

	addExtraContent('battles', battleId);

	const battle = battles[battleId];
	if (!battle) return null;

	addExtraContent('characters', (battle.participants ?? []).map(({ character }) => character));

	const updateParticipant = (participantIdx, data) => {
		db.collection('battles').doc(battleId).update({
			...battle,
			participants: battle.participants.map((p, idx) => {
				if (idx !== participantIdx) return p;
				return {
					...p,
					...data,
				}
			})
		});
	}

	return (
		<Paper className="box">
			<h3>Battle Order</h3>
			<div className="participants">
				<div className="header">
					<OnslaughtIcon color="secondary" />
					<div></div>
					<InitiativeIcon color="secondary" />
				</div>{
					battle.participants.map((p, i) => {
						return (
							<div key={ i }>
								<Spinner
									value={pcs[p.character]?.onslaught ?? p.onslaught ?? 0}
									disabled={ Boolean(pcs[p.character]) && (pcs[p.character]?.owner !== user.uid) }
									onChange={
										pcs[p.character]?.owner === user.uid
											? (value) => {
												db.collection('characters').doc(p.character).update({
														onslaught: value
													})
												}
											: (value) => {
												updateParticipant(i, { onslaught: isNaN(value) ? 0 : value })
											}
									}
								/>
								<TextField fullWidth
									value={ p.name ?? pcs[p.character]?.name ?? npcs[p.character]?.name }
									onChange={ (e) => updateParticipant(i, { name: e.target.value }) }
								/>
								<Button onClick={ () => setParticipant(i) }>
									<DetailsIcon />
								</Button>
								<Spinner
									value={pcs[p.character]?.initiative ?? p.initiative ?? 0}
									disabled={ Boolean(pcs[p.character]) && (pcs[p.character]?.owner !== user.uid) }
									onChange={
										pcs[p.character]?.owner === user.uid
											? (value) => {
												db.collection('characters').doc(p.character).update({
														initiative: value
													})
												}
											: (value) => {
												updateParticipant(i, { initiative: isNaN(value) ? 0 : value })
											}
									}
								/>
								<Button
									onClick={(e) => {
										e.stopPropagation()
										setRemoving(i)
									}}
								>
									<CloseIcon className="remove" />
								</Button>
							</div>
						)
					})
				}
			</div>
			<ConfirmDialog
				open={ Boolean(removing) }
				onClose={ () => setRemoving(null) }
				onConfirm={() => {
					db.collection('battles').doc(battleId).update({
						...battle,
						participants: battle.participants.filter((_, idx) => idx !== removing)
					})
					setRemoving(null);
				}}
				text={ removing ? 'Do you want to remove "' + battle.participants[removing]?.name +  '?' : '' }
			/>
			<CharacterDialog
				open={Boolean(participant)}
				characterId={battle.participants[participant]?.character}
				data={battle.participants[participant]?.data ?? {}}
				onChange={(data) => {
					updateParticipant(participant, {
						data: {
							...(battle.participants[participant].data ?? {}),
							...data
						}
					})
				}}
				onClose={ () => setParticipant(null) }
			/>
		</Paper>
	);
};

export default Participants;

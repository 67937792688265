import React, { useContext, useState } from 'react';
import { useSnackbar } from 'notistack';

import { Button, MenuItem, TextField } from '@mui/material';

import data from '@data';
import { xplog, isMartialArtist, startTraining, getMAScore, getAvailableMAStyles, getSplatProp } from '@loom/characters/functions';
import { Box, ConfirmDialog, Dots } from '@loom/ui';
import { RemoveIcon } from '@loom/ui/icons';

import EditContext from '../context';

const MartialArts = () => {
	const { character, onChange } = useContext(EditContext);
	const { enqueueSnackbar } = useSnackbar();
	const [removingMa, setRemovingMa] = useState(false);
	const isMA = isMartialArtist(character);

	return (
		<Box
			title="Martial Arts"
			className="box"
			actions={<>
				{ getSplatProp(character ,'favours', []).indexOf('brawl') >= 0 && (character?.favoured ?? []).indexOf('brawl') >= 0 && (
					<Button
						color={ character.supernal === 'martialarts' ? 'primary' : 'secondary' }
						variant="contained"
						onClick={ () => onChange('supernal')('martialarts') }
					>
					Supernal</Button>
				)}
				<Button
					color="secondary"
					variant="contained"
					onClick={ () => {
						onChange('martialarts')([ ...(character.martialarts ? character.martialarts : []), {}])
					}}
				>Add</Button>
			</>}
		>
			<div className="martial-arts">
				{ character.martialarts && character.martialarts.map((ma, i) => (
					<React.Fragment key={ i }>
						<TextField
							value={ ma.style ? ma.style : '' }
							onChange={ onChange('martialarts', i, 'style') }
							fullWidth select
						>
							{ getAvailableMAStyles(character).sort()
								.filter((key) => !character.martialarts || character.martialarts[key] === undefined)
								.filter((key) => isMA || data.martialarts[key].noMerit)
								.map((key) => (
									<MenuItem key={ key } value={ key } className="capitalize">{ data.martialarts[key].name }</MenuItem>
								))
							}
						</TextField>
						<Dots
							value={ getMAScore(character, ma.style) }
							max="5"
							className="select-dots"
							onChange={(val) => {
								if (character.experienced) {
									startTraining(character, xplog(character, 'ma', ma.style, ma.value, val, null, null, null, ma.style), onChange, enqueueSnackbar)
								} else {
									onChange('martialarts', i, 'value')(val) 
								}
							}}
							editable
						/>
						<RemoveIcon
							onClick={ () => setRemovingMa(i) }
						/>
					</React.Fragment>
				))}
			</div>
			<ConfirmDialog
				text={ removingMa !== false ? "Do you want to delete " + character.martialarts[removingMa].style + '?' : "" }
				open={ removingMa !== false }
				onConfirm={() => {
					if (character.experienced && character.martialarts[removingMa].value) {
						onChange('xplog')([...(character.xplog ? character.xplog : []), xplog(character, 'ma', character.martialarts[removingMa].style, character.martialarts[removingMa].value, null)])
					}						
					onChange('martialarts')(character.martialarts.filter((_,i) => i !== removingMa))
					setRemovingMa(null);
				}}
				onClose={ () => setRemovingMa(false) }
			/>
		</Box>
	)
}

export default MartialArts;
import { Button } from '@mui/material';

import { Box, Spinner } from '@loom/ui';

const Essence = ({
    title, max, onChange, ...props
}) => {
    return (
        <Box title={ title } centered>
            <Spinner max={ max } onChange={ onChange } { ...props } />
            <Button
                onClick={ () => onChange(max) }
            >Reset</Button>
        </Box>
    );
};

export default Essence;

import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { View as NPC } from '@loom/characters/npc';

import { Firestore, Loom } from '@contexts';

export const View = () => {
	const { id } = useParams();
	const { addExtraContent } = useContext(Firestore);
    const { characters } = useContext(Loom);

	useEffect(() => {
		addExtraContent('characters', id);
	}, [id, addExtraContent]);

	if (!characters?.[id]) return null;

	return (
		<NPC characterId={ id } />
	)
}

export default View;
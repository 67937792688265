import React, { useContext, useEffect, useState } from 'react';

import { Firestore } from '@contexts';
import { Button, DialogContent, MenuItem, TextField } from '@mui/material';
import { ConfirmDialog, Dialog, DialogActions, Grid } from '@loom/ui';

import ViewContext from '../context';

const Intimacy = ({ intimacy: intimacyIndex, onClose }) => {
	const { db } = useContext(Firestore)
	const { character, characterId } = useContext(ViewContext);
	const [ intimacy, setIntimacy ] = useState({});
	const [ deleting, setDeleting ] = useState(false);

	useEffect(() => {
		setIntimacy(character?.intimacies?.[intimacyIndex] ?? { nature: 'tie', ...intimacyIndex });
	}, [intimacyIndex, setIntimacy, character])

	return (
		<Dialog open={ intimacyIndex !== false } maxWidth="md" onClose={ onClose } fullWidth>
			<DialogContent>
				<Grid size="small">
					<TextField
						label="Target"
						value={ intimacy.target ?? '' }
						onChange={ (e) => setIntimacy({ ...intimacy, target: e.target.value })}
						fullWidth
					/>
					<TextField
						label="Strength"
						value={ intimacy.strength ?? 'defining' }
						onChange={ (e) => setIntimacy({ ...intimacy, strength: e.target.value })}
						fullWidth select
					>
						<MenuItem value="defining">Defining</MenuItem>
						<MenuItem value="major">Major</MenuItem>
						<MenuItem value="minor">Minor</MenuItem>
					</TextField>
					<TextField
						label="Nature"
						value={ intimacy.nature ?? 'tie' }
						onChange={ (e) => setIntimacy({ ...intimacy, nature: e.target.value })}
						fullWidth select
					>
						<MenuItem value="principle">Principle</MenuItem>
						<MenuItem value="tie">Tie</MenuItem>
					</TextField>
				</Grid>
			</DialogContent>
			<DialogActions>
				{ intimacyIndex instanceof Object ? null : <Button onClick={ () => setDeleting(true) } variant="contained" color="error">Delete</Button> }
				<div>
					<Button onClick={ onClose }>Cancel</Button>
					<Button onClick={ () => {
						if (intimacyIndex instanceof Object) {
							db.collection('characters').doc(characterId).update({
								intimacies: [
									...character.intimacies,
									intimacy,
								]
							});
						} else {
							db.collection('characters').doc(characterId).update({
								intimacies: [
									...(character.intimacies.map((data, i) => (
										i !== intimacyIndex ? data : intimacy
									)))
								]
							});
						}
						onClose();
					}} variant="contained" color="primary">{ intimacyIndex instanceof Object ? 'Add' : 'Update' }</Button>
				</div>
			</DialogActions>
			<ConfirmDialog
				open={ deleting }
				text={ `Are you sure you want to delete "${intimacy.target}"` }
				onClose={ () => setDeleting(false) }
				onConfirm={ () => {
					db.collection('characters').doc(characterId).update({
						intimacies: character.intimacies.filter((_, idx ) => idx !== intimacyIndex)
					})
					onClose();
				}}
			/>
		</Dialog>
	)
}

export default Intimacy;
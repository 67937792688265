import React, { useContext, useState } from 'react'

import { MenuItem, TextField } from '@mui/material';

import data from '@data';
import { Firestore, Loom } from '@contexts'
import { Grid, NameValue } from '@loom/ui';
import { GenericDialog } from '@loom/help';

export const name = 'Manse';

export const Edit = ({
	merit,
    onChange
}) => {
	const { hearthstones } = useContext(Loom);
    
    return (
		<Grid nopad>
            <TextField
                label={ merit.key }
                value={ merit.details?.name }
                onChange={ (e) => onChange({ ...merit.details, name: e.target.value }) }
            />
			<TextField
				label="Hearthstone"
				value={ merit.details?.hearthstone || '' }
				onChange={ (e) => onChange({ ...merit.details, hearthstone: e.target.value }) }
				select
			>{
                Object.entries(hearthstones)
                .map(([key, value]) => (
					<MenuItem key={ key } value={ key } className="caps">{ value.name }</MenuItem>
				))
			}</TextField>
            <TextField
                label="Description"
                value={ merit.details?.description }
                onChange={ (e) => onChange({ ...merit.details, description: e.target.value }) }
                multiline
            />
        </Grid>
    );
};

export const View = ({
	merit
}) => {
	const [ viewing, setViewing ] = useState(false);
	const { hearthstones } = useContext(Loom);
	const { addExtraContent } = useContext(Firestore);

	addExtraContent('hearthstones', merit.details?.hearthstone);

    const hearthstone = hearthstones[merit.details?.hearthstone];

    const content = merit.details?.description
    + `\n\n### ${hearthstone?.name}`
    + `\n\n${hearthstone?.description}`
    + `\n\n----\n\n${data.merits.Demesne.description}`
    + `\n\n----\n\n${data.merits.Hearthstone.description}`;

	return (<>
		<NameValue
			name={<>
				<strong>{ merit.key }</strong>
				{ ' ' }
				{ merit.details?.name ?? '' }
			</>}
			value={ merit.value }
			onClick={ () => setViewing(true) }
		/>
		<GenericDialog
			open={ viewing }
			title={ `Manse: ${merit.details?.name ?? ''}` }
			content={ content }
			onClose={ () => setViewing(false) }
		/>
	</>)
};
import React, { useRef, useContext, useEffect, useState } from 'react';

import { List as CharacterList } from '@loom/characters';
import { List as OrganisationList } from '@loom/organisations';
import { List as LocaleList } from '@loom/locales';
import { Box, Grid, Markdown, Notes, UI } from '@loom/ui';
import Map, { maxWidth as mapMaxWidth } from '@loom/map';
import { Firestore, Auth, Loom} from '@contexts';

import Actions from './actions'

const Locale = ({ localeId }) => {
	
	const { db, addExtraContent } = useContext(Firestore);
	const { user } = useContext(Auth);
	const { locales, notes } = useContext(Loom);
	const [ mapWidth, setMapWidth ] = useState();
	const map = useRef();

	useEffect(() => {
		if (map.current) {
			setMapWidth(map.current.offsetWidth);
		}
	}, [map?.current?.offsetWidth]);

	const locale = locales?.[localeId];
	if (locale?.owner === user?.uid) addExtraContent('notes', localeId);

	const maxMapZoom = ((mapWidth - 64) / mapMaxWidth) * 100;

	const context = {
		collection: 'locales',
		id: localeId,
		data: locale,
	}

	if (!locale) return <UI />;
	
	return (
		<UI
			actions={ <Actions localeId={ localeId } locale={ locale } notes={ notes?.[localeId]?.text } /> }
		>
			<Grid>
				<Box title={ locale.name }>
					<Markdown className="copy" source={ locale?.description } />
				</Box>
				<Box title="Introduced Facts">
					<Markdown className="copy" source={ locale?.facts } />
				</Box>
				<Notes context={ context } />
			</Grid>
			<Grid size="medium">
				<LocaleList locales={ locale.locales } context={ context } />
				<OrganisationList orgs={ locale.organisations } context={ context } />
				<CharacterList pcs={ locale.characters } npcs={ locale.npcs } context={ context } />
				<Box title="Map">
					<Map
						mode="place-marker"
						markers={[{ label: '', ...(locale.location || {}) }]}
						maxZoom={ maxMapZoom }
						zoom= { locale.zoom || maxMapZoom }
						onMarkerPlaced={(location) => {
							db.collection('locales').doc(localeId).update({ location });
						}}
						onZoomChanged={ (zoom) => {
							db.collection('locales').doc(localeId).update({ zoom });
						}}
					/>
				</Box>
			</Grid>
		</UI>
	)
}

export default Locale;


import React, { useContext } from "react";

import { Firestore, Loom } from "@contexts";
import { List as CharacterList } from "@loom/characters";
import { UI, ListTab, Markdown, Notes, Grid, Box } from "@loom/ui";
import { SceneIcon } from "@loom/ui/icons";

import Actions from "./actions";

const Story = ({ chronicleId, storyId }) => {
	const { chronicles, stories, notes, battles } = useContext(Loom);
	const { addExtraContent } = useContext(Firestore);

	addExtraContent("chronicles", chronicleId);
	addExtraContent("stories", storyId);
	addExtraContent("notes", storyId);

	if (!chronicles || !stories) return null;

	const chronicle = chronicles[chronicleId];
	const story = stories[storyId];

	addExtraContent("battles", story?.battles);

	const context = {
		collection: "stories",
		id: storyId,
		data: story,
	};

	if (!chronicle || !story) return null;

	return (
		<UI
			back={`/chronicle/${chronicleId}`}
			actions={
				<Actions
					chronicleId={chronicleId}
					storyId={storyId}
					notes={notes && notes[storyId]?.text}
				/>
			}
		>
			<Grid>
				<Box title={story.name}>
					<Markdown source={story.description} />
				</Box>
				<Notes context={context} />
				<CharacterList
					pcs={story.characters}
					npcs={story.npcs}
					inheritedPCs={chronicle.characters}
					inheritedNPCs={chronicle.nps}
					context={context}
				/>
				<Box title="Battles">
					<ListTab
						items={(story.battles ?? [])
							.filter((b) => Boolean(battles[b.id]))
							.map((battle) => ({
								id: battle.id,
								link:
									"/chronicle/" +
									chronicleId +
									"/story/" +
									storyId +
									"/battle/" +
									battle.id,
								label: battles[battle.id]?.name ?? "Unnamed Battle",
							}))}
						icon={<SceneIcon />}
					/>
				</Box>
			</Grid>
		</UI>
	);
};

export default Story;

import React from 'react'
import { useLocation } from 'react-router-dom';

import { Button, Dialog, DialogActions } from '@mui/material';

import HelpDetails from './details'

import './help.less';

const HelpDialog = ({
    fullScreen,
    onClose,
}) => {
	const location = useLocation();
    
	return (
		<Dialog
			open={ Boolean(location.hash) }
			className="help"
			onClose={ onClose }
			maxWidth="md"
			fullScreen={ fullScreen }
		>
			<HelpDetails
				key={ location.hash }
				path={ location.hash }
			/>
			<DialogActions>
				<Button onClick={ onClose }>Close</Button>
			</DialogActions>
		</Dialog>
	)
};

export default HelpDialog;

import React, { useContext } from 'react'

import { TextField, MenuItem, Paper } from '@mui/material'

import { Box, Grid, Dice, NameValue, Wounds, CharmList, MeritList, Magnitude, Column } from '@loom/ui';
import { getWoundPenalty } from '@loom/characters/functions';

import ViewContext from './context'

import './combat.less';

const CombatTab = () => {
	const { character, onChange } = useContext(ViewContext)
	if (!character) return null;

	const penalty = getWoundPenalty(character)
		
	const sizeBonus = (character.size && character.size > 0 ? character.size : 0)
	const drillBonus = {"poor":0, "average":1, "elite":2}[character.size && character.size > 0 && character.drill ? character.drill : 'poor']
	const mightBonus = (character.size && character.size > 0 && character.might ? character.might : 0)
	const bgAttackBonus = sizeBonus + mightBonus
	const bgDamageBonus = sizeBonus + mightBonus
	const bgSoakBonus = sizeBonus
	const bgDefenceBonus = drillBonus + (mightBonus > 0 ? 1 : 0) + (mightBonus > 2 ? 1 : 0)
	const onslaught = character.onslaught || 0;
		
	return (
		<div className="view-combat">
			<Grid size="medium">
				<Box title="Dicepools">
					<NameValue name="Join Battle" value={ <Dice value={ character.joinBattle - penalty } /> } />
					<NameValue name="Combat Movement" value={ <Dice value={ character.combatMovement - penalty } /> } />
					<NameValue name="Control Grapple" value={ <Dice value={ character.grappleControl - penalty } /> } />
				</Box>
				<Box title="Defences">
					<NameValue name="Parry" value={ character.parry - penalty - onslaught + bgDefenceBonus } />
					<NameValue name="Evasion" value={ character.evasion - penalty - onslaught + bgDefenceBonus } />
					<NameValue name="Soak" value={ parseInt(character.soak, 10) + bgSoakBonus } />
					<NameValue name="Hardness" value={ parseInt(character.hardness, 10) } />
				</Box>
			</Grid>
			<Grid size="extra-large">
				{ character.attacks && (
					<Box title="Attacks" className="attacks">
						<div></div>
						<div>Attack</div>
						<div>Damage</div>
						<div>Overwhelming</div>
						{ character.attacks.map((attack, i) => (
							<React.Fragment key={ i }>
								<div>{ attack.name }</div>
								<div><Dice value={ attack.accuracy - penalty + bgAttackBonus } /></div>
								<div><Dice value={ isNaN(parseInt(attack.damage, 10)) ? '-' : parseInt(attack.damage, 10) + bgDamageBonus } /></div>
								<div><Dice value={ attack.overwhelming } /></div>
							</React.Fragment>
						))}
					</Box>
				)}
			</Grid>
			<Grid size="large">
				{ character.size && character.size > 0 ?
					<Box title="Magnitude">
						<div style={{ textAlign: 'center' }}>
							<Magnitude character={ character } data={ character } onChange={ onChange } />
						</div>
					</Box>
				:
					<Box title="Health" nopad>
						<Wounds character={ character } data={ character } onChange={ onChange } />
					</Box>
				}
				{ (!character.size || character.size === 0) && character?.combatAbilities?.length > 0 ?
						<Paper className="box nopad">
							<h3>Charms</h3>
							<CharmList charms={ character.combatAbilities } />
						</Paper>
					: '' }
				{ character.combatMerits?.length > 0 ?
					<Paper className="box nopad">
						<h3>Merits</h3>
						<MeritList merits={ character.combatMerits } />
					</Paper>
				: '' }
			</Grid>
			<Grid>
				<Box title="Battle Group">
					<Grid size="small">
						<TextField
							label="Size"
							value={ character.size ? character.size : 0 }
							onChange={ (event) => onChange({size:event.target.value}) }
							select fullWidth
						>
							<MenuItem value={ 0 }>Individual</MenuItem>
							<MenuItem value={ 1 }>Size 1 - A dozen or fewer</MenuItem>
							<MenuItem value={ 2 }>Size 2 - Several dozen</MenuItem>
							<MenuItem value={ 3 }>Size 3 - Over a hundred</MenuItem>
							<MenuItem value={ 4 }>Size 4 - Several hundred</MenuItem>
							<MenuItem value={ 5 }>Size 5 - Over a thousand</MenuItem>
						</TextField>
						{ character.size && character.size > 0 ?
							<>
								<TextField
									label="Drill"
									value={ character.drill ? character.drill : 0 }
									onChange={ (event) => onChange({drill:event.target.value}) }
									select fullWidth
								>
									<MenuItem value="poor">Poor</MenuItem>
									<MenuItem value="average">Average</MenuItem>
									<MenuItem value="elite">Elite</MenuItem>
								</TextField>
								<TextField
									label="Might"
									value={ character.might ? character.might : 0 }
									onChange={ (event) => onChange({might:event.target.value}) }
									select fullWidth
								>
									<MenuItem value={ 0 }>Might 0 - Mortals</MenuItem>
									<MenuItem value={ 1 }>Might 1 - Beastfolk, Divine Blessings</MenuItem>
									<MenuItem value={ 2 }>Might 2 - First Circle Demons, Fair Folk</MenuItem>
									<MenuItem value={ 3 }>Might 3 - Terrestrial Exalted</MenuItem>
								</TextField>
							</>
						: ''}
					</Grid>
				</Box>
			</Grid>
		</div>
	)
}

export default CombatTab

import React, { Component } from 'react'

import { Button, DialogTitle, DialogContent, DialogActions } from '@mui/material'

import { CharmList, Dialog, Markdown } from '@loom/ui'

class Artifact extends Component {
	render() {
		const { fullScreen, onClose, artifact, title, extratext } = this.props

		return (
			<Dialog
				open={ Boolean(artifact) }
				maxWidth="md"
				fullScreen={ fullScreen }
			>
				<DialogTitle>
					{ title }
				</DialogTitle>
				<DialogContent>
					{ artifact ?
						<React.Fragment>
							<Markdown source={ (extratext ? extratext + '\n\n---\n\n' : '') + artifact.text } />
							{ artifact.attunementBenefit && (<>
								<h3>Attunement Benefit</h3>
								<Markdown source={ artifact.attunementBenefit } />
							</>)}
							{ artifact.evocations && (<>
								<h3>Evocations</h3>
								<CharmList charms={ artifact.evocations } />
							</>)}
						</React.Fragment>
					: '' }
				</DialogContent>
				<DialogActions>
					<Button onClick={ onClose }>Close</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

export default Artifact;

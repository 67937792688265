import React, { useContext } from 'react'
import { useSnackbar } from 'notistack';

import data from '@data'
import { Dots } from '@loom/ui';
import { RemoveIcon } from '@loom/ui/icons';
import { xplog, startTraining } from '@loom/characters/functions';
import { Context as EditContext } from '@loom/characters/pc/edit';

import * as Fallback from './fallback';
import mapping from './mapping';
import './merit.less';

export const Edit = ({
	meritId, merit, training, setRemovingMerit
}) => {
    const { character, onChange } = useContext(EditContext);
	const { enqueueSnackbar } = useSnackbar();

	const MeritComponent = mapping[merit.key?.toLowerCase()] ?? Fallback;
	
	return <div className="merit">
		{ <MeritComponent.Edit
			merit={ merit }
			onChange={ (value) => {
				if (merit.trained) {
					onChange(
						'completed_training',
						character.completed_training.map((m) => m.index).indexOf(meritId),
						'data',
						'details'
					)(value)
				} else {
					onChange('merits', meritId, 'details')(value)
				}
			}}
		/> }
		<div>
			<Dots
				value={ merit.value ? merit.value : 0 }
				max={ 5 }
				temp={ merit.trainingDots }
				valid={ data.merits[meritId] ? data.merits[meritId].dots : null }
				onChange={(val) => {
					if (character.experienced) {
						startTraining(character, xplog(character, 'merit', merit.key, merit.value, val, null, meritId), onChange, enqueueSnackbar)
					} else {
						onChange('merits', meritId)({...merit, value:val})
					}
				}}
				variant={ training ? 'light' : 'dark' }
				editable
			/>
			<RemoveIcon
				className="interactable remove"
				onClick={() => setRemovingMerit(merit) }
			/>
		</div>
	</div>
};

export const View = ({
	meritId, merit
}) => {
	const MeritComponent = mapping[merit.key?.toLowerCase()] ?? Fallback;

	return <MeritComponent.View meritId={ meritId } merit={ merit } />
}

import { useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Button, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material/'

import data from '@data';
import { Firestore as FirestoreContext } from '@contexts';
import { xplog } from '@loom/characters/functions';
import { Context as ViewContext } from '@loom/characters/pc/view';
import { GenericDialog } from '@loom/help';
import { Box } from '@loom/ui';

import WorkingDialog from './working-dialog';

const WorkingTable = () => {
    const { db } = useContext(FirestoreContext);
    const { character, characterId } = useContext(ViewContext);
    const [ working, setWorking ] = useState();
    const { enqueueSnackbar } = useSnackbar();

    const completed_workings = character.completed_training ? character.completed_training.filter((tr) => tr.data.type === 'working').map((tr) => tr.index) : []

	const saveWorking = (working, index) => {
		let log;
		
		if (working.completeable) {
			let xpcost = data.sorcery.workings.cost[working.ambition || 1]
			log = [
				...(character.completed_training ? character.completed_training : []),
				xplog(character, 'working', working.name, xpcost, null, null, index !== null ? index : character.workings.length)
			]
			enqueueSnackbar('Spent ' + xpcost +' xp completing a working')
		} else {
			log = character.completed_training ? character.completed_training : []
		}
		
        const doc = db.collection('characters').doc(characterId);
		if (index >= 0) {
			doc.update({
				workings: [
					...character.workings.map((w, i) => i === index ? {
						...working
					} : w),
				],
				completed_training: log
			})
		} else {
			doc.update({
				workings: [
					...(character.workings ? character.workings : []),
                    working
				],
				completed_training: log
			})
		}
	}
    
    return (<>
        <Box
            title="Workings"
            actions={[
                [ 'Begin Working', () => setWorking([{}]) ]
            ]}
        >{
            character.workings?.length > 0 && (
                <Table>
                    <TableHead>
                        <TableRow><TableCell>Name</TableCell><TableCell>Circle</TableCell><TableCell>Remaining</TableCell></TableRow>
                    </TableHead>
                    <TableBody>{ character.workings.map((w,i) => ({
                        ...w,
                        index: i,
                        complete: completed_workings.indexOf(i) >= 0
                    })).sort((w) => w.complete).map((work, index) => (
                        <TableRow key={ index } className="interactable"
                            onClick={ () => {
                                setWorking([work, index]);
                            }}
                        >
                            <TableCell>{ work.name }</TableCell>
                            <TableCell>{ work.circle }</TableCell>
                            <TableCell>{ work.complete ? 'Complete' : (work.goalnum - (work.goalacc ? work.goalacc : 0)) }</TableCell>
                        </TableRow>
                    ))}</TableBody>
                </Table>
            )
        }</Box>
        <WorkingDialog
            working={ working && working[0] }
            onChange={ (work) => saveWorking(work, working[1]) }
            onDelete={ () => {
                db.collection('characters').doc(characterId).update({
                    workings: character.workings.filter((w, idx) => idx !== working[1])
                })
            }}
            onClose={ () => setWorking(null) }
        />
        { working && working[0]?.complete  && (
            <GenericDialog
                title={ working?.complete ? working.name : '' }
                content={ working?.complete ? working.details : '' }
                onClose={ () => {
                    setWorking(null);
                    console.log(working);
                }}
                actions={
                    <Button
                        onClick={() => {
                            db.collection('characters').doc(characterId).update({
                                workings: character.workings.filter((w, idx) => idx !== working[1])
                            });
                            setWorking(null);
                        }}
                    >Remove</Button>
                }
            />
        )}
    </>)
}

export default WorkingTable;
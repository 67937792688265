import { useParams } from 'react-router-dom';

import Locale from './view';

const View = () => {
	const { chronicle, id } = useParams();

	return (
		<Locale chronicleId={ chronicle } localeId={ id } />
	);
};

export { View }
export default View
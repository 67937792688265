import React, { useContext } from 'react'

import { getWoundPenalty } from '@loom/characters/functions';
import { Grid, Box, NameValue, CharmList, MeritList } from '@loom/ui';

import ViewContext from './context';

const SocialTab = () => {
	const { character } = useContext(ViewContext);
	const penalty = getWoundPenalty(character)
		
	const intimacies = character.intimacies ? character.intimacies.reduce((acc, x) => {
		if (!acc[x.rating]) acc[x.rating] = []
		acc[x.rating].push(x)
		return acc
	}, {}) : {}

	return (
		<Grid size="medium">
			<Box title="Values">
				<NameValue name="Resolve" value={ character.resolve - penalty } />
				<NameValue name="Guile" value={ character.guile - penalty } />
				<NameValue name="Appearance" value={ character.appearance } />
			</Box>
			{ Object.keys(intimacies).map((i, j) => (
				<Box title={ `${i.charAt(0).toUpperCase() + i.substr(1)} Intimacies` }>{
					intimacies[i].map((k, l) => (
						<NameValue
							key={ l }
							name={ k.name }
							value={ k.type?.charAt(0)?.toUpperCase() + k.type?.substr(1) }
						/>
					))
				}</Box>
			))}
			{ character.socialAbilities && character.socialAbilities.length > 0 && (
				<Box title="Charms">
					<CharmList charms={ character.socialAbilities } />
				</Box>
			)}
			{ character.socialMerits && character.socialMerits.length > 0 && (
				<Box title="Merits">
					<MeritList merits={ character.socialMerits } />
				</Box>
			)}
		</Grid>
	)
};

export default SocialTab;

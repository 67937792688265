import React, { useState } from 'react'

import { MenuList, MenuItem, ListItemText } from '@mui/material'

import { Grid } from '@loom/ui';
import { GenericDialog } from '@loom/help';

const MeritList = ({ merits }) => {
	const [ merit, setMerit ] = useState();

	console.log(merit);

	return (
		<div>
			<MenuList>
				<Grid size="small" nogap nopad>
				{ (merits ? merits : []).map((m, i) => {
					return (
						<MenuItem
							key={ i }
							onClick={ () => setMerit(m) }
						>
							<ListItemText>{ m?.name }</ListItemText>
						</MenuItem>
					)
				})}
				</Grid>
			</MenuList>
			<GenericDialog
				open={ Boolean(merit) }
				onClose={ () => setMerit(null) }
				title={ merit?.name }
				content={ merit?.description }
			/>
		</div>
	)
}

export default MeritList

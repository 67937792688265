import React from 'react';

import './group.less';

const Group = ({ children, title }) => {
	return (
		<div className="group">
			{ title && <h4>{ title }</h4> }
			<div className="content">
				{ children }
			</div>
		</div>
	)
}

export default Group;
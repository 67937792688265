import React from 'react';

import { List as ChronicleList } from '@loom/chronicles'
import { List as PCList } from '@loom/characters/pc'
import { List as HomebrewList } from '@loom/homebrew';
import { ActionBase, UI, Tab } from '@loom/ui';
import { CharacterIcon, ChronicleIcon, HomebrewIcon } from '@loom/ui/icons';

const Base = ({ actions }) => {
	return (
		<UI actions={ <ActionBase>{ actions }</ActionBase> }>
        	<Tab value="pc" label="Characters" icon={ <CharacterIcon /> } render={ <PCList key="pcs" /> } />
			<Tab value="chronicle" label="Chronicles" icon={ <ChronicleIcon /> } render={ <ChronicleList key="chronicles" /> } />
        	<Tab value="homebrew" label="Homebrew" icon={ <HomebrewIcon /> } render={ <HomebrewList key="homebrew" /> } />
		</UI>
	);
}

export { Base }
export default Base;
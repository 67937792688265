import * as Artifact from './artifact';
import * as Character from './character';
import * as Custom from './custom';
import * as Hearthstone from './hearthstone';
import * as Language from './language';
import * as Manse from './manse';

export const mapping = {
	familiar: Character,
	retainer: Character,
	command: Character,
	allies: Character,
	retainers: Character,
	followers: Character,
	artifact: Artifact,
	manse: Manse,
	hearthstone: Hearthstone,
	language: Language,
	custom: Custom,
}

export default mapping;
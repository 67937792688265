import React, { useContext } from 'react'

import { FormControlLabel, Checkbox, IconButton, TextField } from '@mui/material'

import { Auth } from '@contexts';
import ImageSelector from '@components/ImageSelector';
import { Box, Dots, Grid, EditCharmList, EditMeritList } from '@loom/ui';
import { CloseIcon } from '@loom/ui/icons';

import DialogContext from './context';

import './general.less';

const GeneralTab = () => {
	const { character, onChange } = useContext(DialogContext);
	const { user } = useContext(Auth);

	return (
	<>
		<Grid size="medium">
			<Grid size="medium" nopad>
				<TextField label="Name" fullWidth
					value={ character.name ?? '' }
					onChange={ onChange('name') }
				/>
				<TextField label="Description" fullWidth multiline
					value = { character.description ?? '' }
					onChange={ onChange('description') }
				/>
			</Grid>
			<Grid size="medium" nopad>
				<ImageSelector value={ character.portrait } onChange={ onChange('portrait') } max_size={ [460,500] } />
				{ user.uid === 'L6SlwrT3KtVWw94GFGvkcAkIMIv1' && (
					<FormControlLabel control={
						<Checkbox value={ character.public } onChange={ onChange('public') } />
					} label="Public" />
				)}
			</Grid>
		</Grid>
		<Grid size="extra-large">
			<Box title="Essence">
				<Dots min={ 1 } max={ 10 } editable
					value={ character.essence }
					onChange={(e) => {
						onChange('essence')(e)
					}}
				/>
				<Grid size="extra-small">
					<TextField label="Personal" type="number"
						inputProps={{ min: "0" }}
						value={ character.personalMotes ?? 0 }
						onChange={ onChange('personalMotes') }
					/>
					<TextField label="Peripheral" type="number"
						inputProps={{ min: "0" }}
						value={ character.peripheralMotes ?? 0 }
						onChange={ onChange('peripheralMotes') }
					/>
					<TextField label="Respire" type="number"
						inputProps={{ min: "0" }}
						value={ character.respire ?? 0 }
						onChange={ onChange('respire') }
					/>
					<TextField label="Battle Respire" type="number"
						inputProps={{ min: "0" }}
						value={ character.respireBattle ?? 5 }
						onChange={ onChange('respireBattle') }
					/>
				</Grid>
			</Box>
			<Box title="Willpower">
				<Dots min={ 1 } max={ 10 } editable
					value={ character.willpower }
					onChange={(e) => {
						onChange('willpower')(e)
					}}
				/>
			</Box>
			<Box
				title="Dice Pools"
				actions={[
					[ 'Add Dice Pool', () => onChange('generalDicepools')([...(character.generalDicepools ? character.generalDicepools : []), {}]) ]
				]}
				className="dicepools"
			>
				<div>Senses</div>
				<TextField label="Dice" type="number" fullWidth
					inputProps={{ min: "0" }}
					value={ character.senses ?? 1 }
					onChange={ onChange('senses') }
				/>
				<div />
				{ [...Object.keys(character.generalDicepools ? character.generalDicepools : {})].map((i,) => (
					<React.Fragment key={ i }>
						<TextField label="Name" fullWidth
							value={ character.generalDicepools[i].name }
							onChange={ onChange('generalDicepools', i, 'name') }
						/>
						<TextField label="Dice" type="number" fullWidth
							inputProps={{ min: "0" }}
							value={ character.generalDicepools[i].value }
							onChange={ onChange('generalDicepools', i, 'value') }
						/>
						<IconButton
							onClick={ () => onChange('generalDicepools')(character.generalDicepools.filter((_,j) => j !== parseInt(i, 10))) }
						><CloseIcon /></IconButton>
					</React.Fragment>
				))}
			</Box>
		</Grid>
		<Grid size="medium">
			<EditCharmList
			 	title="General Abilities"
				charms={ character.generalAbilities }
				onUpdate={ (i) => (value) => onChange('generalAbilities', i)(value) }
				onDelete={ (i) => () => onChange('generalAbilities')(character.generalAbilities.filter((_, j) => j !== i)) }
				onAdd={ () => (value) => {
					onChange('generalAbilities')([...(character.generalAbilities ? character.generalAbilities : []), value ])
				}}
			/>
			<EditMeritList
				title="General Merits"
				merits={ character.generalMerits }
				onUpdate={ (i) => (value) => onChange('generalMerits', i)(value) }
				onDelete={ (i) => () => onChange('generalMerits')(character.generalMerits.filter((_, j) => j !== parseInt(i, 10))) }
				onAdd={ () => (value) => onChange('generalMerits')([...(character.generalMerits ? character.generalMerits : []), value ]) }
			/>
		</Grid>
	</>
)}

export default GeneralTab

import React, { useContext } from 'react'
import capitalize from 'capitalize';

import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import { Select } from '@loom/ui';
import { RemoveIcon } from '@loom/ui/icons';
import { Context as EditContext } from '@loom/characters/pc/edit';

import data from '@data';
import './item.less';

const Item = ({
	artifact, item, onRemove, index
}) => {
    const { character, onChange } = useContext(EditContext);

	const weaponabilities = [
		...Object.keys(data.abilities),
		...(character.martialarts ? character.martialarts.map((ma) => ma.style) : [])
	].filter(
		(abil) => abil !== 'martial Arts' && abil !== 'craft'
	)

	return (<div className={ `item ${artifact ? 'artifact' : ''} ${artifact?.type || item?.type || ''}` }>
		{ artifact && <>
            <div className="name">{ artifact.name }</div>
			<Select
				value={ character.artifacts && character.artifacts[artifact.id] ? character.artifacts[artifact.id].attuned : '' }
				onChange={ onChange('artifacts', artifact.id, 'attuned') }
				options={[
					{ id: 'personal', label: 'Personal' },
					{ id: 'peripheral', label: 'Peripheral' },
					{ id: '', label: 'Unattuned' },
				]}
				fullWidth
			/>
			{ artifact && artifact.type === 'weapon' && <>
				<Select
					value={ (character.artifacts && character.artifacts[artifact.id]?.ability) || '' }
					onChange={ onChange('artifacts', artifact.id, 'ability') }
					options={
						weaponabilities.map((abil) => ({ id: abil, label: capitalize(abil) }))
					}
					fullWidth
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={ character.artifacts && character.artifacts[artifact.id]?.parry === true }
							onChange={ (val) => {
								onChange('artifacts', artifact.id, 'parry')(val.target.checked)
							}}
						/>
					}
					label="Can Parry"
				/>
			</> }
		</>}
		{ item && <>
			<TextField  fullWidth
				label="Name"
				onChange={ onChange('gear', index, 'name') }
				value={ item.name }
			/>
			<Select
				label="Type"
				value={ item.type ? item.type : '' }
				onChange={ onChange('gear', index, 'type') }
				options={[
					{ id: "weapon", label: "Weapon" },
					{ id: "armour", label: "Armour" }
				]}
			/>
            { item.type && (
                <Select
                    label="Category"
                    value={ item.category ? item.category : '' }
                    onChange={ onChange('gear', index, 'category') }
					options={
						Object.keys(data[item.type].categories.mortal).map((cat, i) => (
							{ id: cat, label: capitalize(cat) }
						))
					}
                />
            )}
			{ item.type === 'weapon' && <>
				<Select
					label="Ability"
					value={ item.ability ||'' }
					onChange={ onChange('gear', index, 'ability') }
					options={
						weaponabilities
							.map((abil, j) => (
								{ id: abil, label: capitalize(abil) }
							))
					}
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={ item.parry === true }
							onChange={ (event) => onChange('gear', index, 'parry')(event.target.checked) }
						/>
					}
					label="Can Parry"
				/>
			</> }
		</>}
		{ onRemove && (
			<RemoveIcon
				className="interactable remove"
				onClick={() => onRemove() }
			/>
		)}
	</div>)
}


export default Item;
import { useEffect, useState } from 'react';
import { Button, DialogActions, DialogContent, DialogTitle, TextField, MenuItem, } from '@mui/material';

import data from '@data';
import { Dialog } from '@loom/ui';

import './working-dialog.less';

const WorkingDialog = ({
    working: initialWorking, onChange, onDelete, onClose
}) => {
    const [ working, setWorking ] = useState();
    const [ isNew, setIsNew ] = useState();

    useEffect(() => {
        if (initialWorking) {
            setWorking({ ...initialWorking });
            setIsNew(Object.keys(initialWorking || {}).length === 0);
        }
    }, [initialWorking])

	const recalcWorkingTarget = (working) => {
		return recalcWorkingComplete({
			...working,
			goalnum: getWorkingGoalNumber(working.circle, working.ambition),
			terminus: data.sorcery.workings.defaultTerminus + (working.means ? parseInt(working.means, 10) : 0)
		})
	}
	
	const recalcWorkingComplete = (working) => {
		return {
			...working,
			completeable: working &&  (
				working.goalacc >= working.goalnum ||
				working.intervals >= working.terminus
			)
		};
	}
	
	const getWorkingGoalNumber = (circle, ambition) => {
		circle = circle || (working?.circle || Object.keys(data.sorcery.workings.circles)[0]);
		ambition = ambition ||  (working?.ambition || 1 );
		return data.sorcery.workings.circles[circle][ambition]
	}

    return (
        <Dialog open={ Boolean(initialWorking) && !initialWorking.complete }>
            <DialogTitle disableTypography><TextField fullWidth label="Name"
                className={ "white" }
                value={ working?.name || '' }
                onChange={ (event) => setWorking({
                    ...working,
                    name: event.target.value
                })}
            /></DialogTitle>
            <DialogContent className="working-dialog">
                <div>
                    <TextField fullWidth label="Circle" className="circle" select
                        value={ working?.circle || '' }
                        onChange={ (event) => setWorking(
                            recalcWorkingTarget({
                                ...working,
                                circle: event.target.value
                            })
                        )}
                    >{ Object.keys(data.sorcery.workings.circles).map((circle) => <MenuItem value={ circle } key={ circle }>{ circle }</MenuItem>) }</TextField>
                </div><div className="columns triple">
                    <TextField fullWidth label="Ambition" type="number" min={ 1 } max={ 3 }
                        value={ working?.ambition || 1 }
                        onChange={ (event) => setWorking(
                            recalcWorkingTarget({
                                ...working,
                                ambition: event.target.value,
                            })
                        )}
                    />
                    <TextField fullWidth label="Finesse" select
                        value={ working?.finesse || 1 }
                        onChange={ (event) => setWorking({
                            ...working,
                            finesse: event.target.value
                        })}
                    >{ data.sorcery.workings.finesse.map((f) => <MenuItem key={ f } value={ f }>{ f }</MenuItem>) }</TextField>
                    <TextField fullWidth label="Means" type="number" min={ 0 }
                        value={ working?.means || 0 }
                        onChange={ (event) => setWorking(
                            recalcWorkingTarget({
                                ...working,
                                means: event.target.value
                            })
                        )}
                    />
                </div>
                <div className="columns quadruple">
                    <TextField fullWidth label="Successes" type="number"
                        value={ working?.goalacc || 0 }
                        onChange={ (event) => setWorking(
                            recalcWorkingComplete({
                                ...working,
                                goalacc: parseInt(event.target.value, 10)
                            })
                        )}
                    />
                    <TextField fullWidth label="Goal Number" type="number"
                        value={ working?.goalnum || 0 }
                        onChange={ (event) => setWorking(
                            recalcWorkingComplete({
                                ...working,
                                goalnum: parseInt(event.target.value, 10)
                            })
                        )}
                    />
                    <TextField fullWidth label="Intervals" type="number"
                        value={ working?.intervals || 0 }
                        onChange={ (event) => setWorking(
                            recalcWorkingComplete({
                                ...working,
                                intervals: parseInt(event.target.value, 10)
                            })
                        )}
                    />
                    <TextField fullWidth label="Terminus" type="number"
                        value={ working?.terminus || 0 }
                        onChange={ (event) => setWorking(
                            recalcWorkingComplete({
                                ...working,
                                terminus: parseInt(event.target.value, 10)
                            })
                        )}
                    />
                </div><div>
                    <TextField fullWidth label="Details" multiline
                        value={ working?.details || '' }
                        onChange={ (event) => setWorking({
                            ...working,
                            details: event.target.value
                        })}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                { !isNew &&
                    <Button
                        className="dangerous"
                        onClick={() => {
                            onDelete();
                            onClose();
                        }}
                    >Remove</Button>
                }
                <Button
                    onClick={() => {
                        onClose();
                    }}
                >Cancel</Button>
                <Button
                    variant="contained"
                    color={ working?.completeable ? 'primary' : 'secondary' }
                    onClick={() => {
                        onChange(working);
                        onClose();
                    }}
                >{
                    working?.completeable ? 'Complete' : (isNew ? 'Begin' : 'Update')
                }</Button>
            </DialogActions>
        </Dialog>
    );
};

export default WorkingDialog;
import React, { Component } from 'react'

import { Button, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import Dialog from './dialog';

class ConfirmDialog extends Component {
	render() {
		const { open, fullScreen, onClose, onConfirm, text } = this.props

		return (
			<Dialog
				open={ open }
				fullScreen={ fullScreen }
				onClose={ onClose }
			>
				<DialogContent>
					<DialogContentText>
						{ text }
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={ onClose }
					>No</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={ () => {
							onConfirm();
							onClose();
						 }}
					>Yes</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

export default ConfirmDialog
import React from 'react';
import clsx from 'clsx';

import { MenuItem, Tooltip } from '@mui/material';

import './name-value.less';

const NameValue = ({ name, value: valueProp, tooltip, onClick, condensed, strong, emphasize='name', nocap, ...props }) => {
	const className = props.className ? [props.className] : [];
	className.push('name-value');
	if (onClick) className.push('interactive');
	if (condensed) className.push('condensed');
	if (strong) className.push('strong');
	if (emphasize === 'value') className.push('emphasize-value');

	const value = tooltip
	? <Tooltip title={ tooltip }><div className="value tooltipped">{ Number.isNaN(valueProp) ? '-' : valueProp }</div></Tooltip>
	: <div className="value">{ Number.isNaN(valueProp) ? '-' : valueProp }</div>;

	return (
		<div className={ clsx(...className, nocap && 'no-cap') }>{
			(onClick
				? (
					<MenuItem onClick={ onClick } className="content">
						<div className="name">{ name }</div>
						{ value }
					</MenuItem>
				) : (
					<div className="content"><div className="name">{ name }</div>{ value }</div>
				)
			)
		}</div>
	)
}

export default NameValue;
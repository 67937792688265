import React, { useContext, useState } from 'react';

import { MenuItem, Button, TextField } from '@mui/material';

import { Auth, Firestore, Loom } from '@contexts';
import { Box, Dots, Grid, Spinner, StatList, Wounds, Essence, NameValue } from '@loom/ui';
import {
	getArmour, getWeapons, getWitheringAttack, getWitheringDamage, getDecisiveAttack, getParry, getEvasion, getMovement, getSoak, getHardness,
	getJoinBattle, getGrapple, getBaseInitiative, getEssencePools, getCommitments, getHealth, getWillpower, getMobility, getOverwhelmingDamage
} from '@loom/characters/functions'

import ViewContext from './context';

const CombatTab = () => {
	const { db } = useContext(Firestore);
	const { artifacts } = useContext(Loom);
	const { user } = useContext(Auth);
	const { character, characterId } = useContext(ViewContext);
	const [ range, setRange ] = useState(0);
	const essence = getEssencePools(character)
	const commitments = getCommitments(character, artifacts);

	const weapons = getWeapons(character, artifacts);
	const armour = getArmour(character, artifacts);

	if (!artifacts) return null;

	return (
		<>
			<Grid title="Initiative" size={ 200 }>
				<Box title="Initiative" centered>
					<Spinner
						value={ character.initiative !== undefined ? character.initiative : 0 }
						onChange={ (v) => db.collection('characters').doc(characterId).update({initiative:v}) }
						disabled={ character.owner !== user?.uid }
					/>
					<Button
						onClick={ () => db.collection('characters').doc(characterId).update({initiative:getBaseInitiative()}) }
						disabled={ character.owner !== user?.uid }
					>
						Reset
					</Button>
				</Box>
				<Box title="Onslaught" centered>
					<Spinner
						min={ 0 }
						value={ character.onslaught !== undefined ? character.onslaught : 0 }
						onChange={ (v) => db.collection('characters').doc(characterId).update({onslaught:v}) }
						disabled={ character.owner !== user?.uid }
					/>
					<Button
						onClick={ () => db.collection('characters').doc(characterId).update({onslaught:0}) }
						disabled={ character.owner !== user?.uid }
					>
						Reset
					</Button>
				</Box>
				<Essence
					title="Personal Essence"
					min={ 0 }
					max={ essence.personal - commitments.personal }
					value={ character.personal !== undefined ? character.personal : essence.personal }
					onChange={ (v) => db.collection('characters').doc(characterId).update({personal:v}) }
					disabled={ character.owner !== user?.uid }
				/>
				<Essence title="Peripheral Essence"
					min={ 0 }
					max={ essence.peripheral - commitments.peripheral }
					value={ character.peripheral !== undefined ? character.peripheral : essence.peripheral }
					onChange={ (v) => db.collection('characters').doc(characterId).update({peripheral:v}) }
					disabled={ character.owner !== user?.uid }
				/>
			</Grid>
			<Grid size="large">
				<Box title="Armament" className="armaments" centered>
					<Grid size="small">
						<TextField
							label="Weapon"
							value={ character.weapon ? character.weapon : '' }
							onChange={ (event) => db.collection('characters').doc(characterId).update({weapon:event.target.value}) }
							fullWidth select
						>
							{ Object.keys(weapons).map((weapon) => (
								<MenuItem key={ weapon } value={ weapon }>{ weapon }</MenuItem>
							))}
						</TextField>
						<TextField
							label="Armour"
							value={ character.armour ? character.armour : '' }
							onChange={ (event) => db.collection('characters').doc(characterId).update({armour:event.target.value}) }
							fullWidth select
						>
							{ Object.keys(armour).map((armour) => (
								<MenuItem key={ armour } value={ armour }>{ armour }</MenuItem>
							))}
						</TextField>
					</Grid>
				</Box>
				<Box title="Wounds" nopad>
					<Wounds
						character={ character }
						health={ getHealth(character, artifacts) }
						onChange={ (changes) => db.collection('characters').doc(characterId).update(changes) }
						disabled={ character.owner !== user?.uid }
					/>
				</Box>
				<Box title="Willpower" centered>
					<Dots
						value={ character.tmpWillpower !== undefined ? character.tmpWillpower : getWillpower(character) }
						max={ getWillpower(character) }
						size="10"
						onChange={ (val) => db.collection('characters').doc(characterId).update({tmpWillpower: val}) }
						editable={ character.owner === user?.uid }
						overflow
					/>
				</Box>
			</Grid>
			<Grid size="medium">
				<StatList
					title="Offense"
					data={[
						['Withering Attack', getWitheringAttack(character, artifacts, range), 'dice' ],
						['Withering Damage', getWitheringDamage(character, artifacts), 'dice' ],
						['Overwhelming', getOverwhelmingDamage(character, artifacts), 'dice' ],
						['Decisive Attack', getDecisiveAttack(character, artifacts), 'dice' ],
						['Decisive Damage', character.initiative, 'dice' ],
					]}
				>
					{ weapons[character.weapon] && weapons[character.weapon].stats && weapons[character.weapon].stats.range &&
						<NameValue
							name="Range"
							value={
								<TextField
									value={ range }
									variant="standard"
									onChange={ (event) => setRange(event.target.value) }
									fullWidth select
								>
									{ ['Close','Short','Medium','Long','Extreme'].map((name, i) => (
										<MenuItem key={ i } value={ i }>{ name }</MenuItem>
									))}
								</TextField>
							}
						/>
					}
				</StatList>
				<StatList
					title="Defence"
					data={[
						['Parry', getParry(character, artifacts) ],
						['Evasion', getEvasion(character, artifacts) ],
						['Soak', getSoak(character, artifacts) ],
						['Hardness', getHardness(character, artifacts) ],
					]}
				/>
				<StatList
					title="General"
					data={[
						['Join Battle', getJoinBattle(character, artifacts), 'dice' ],
						['Rush/Disengage', getMovement(character, artifacts), 'dice' ],
						['Control Grapple', getGrapple(character, artifacts), 'dice' ],
						['Movement Penalty', getMobility(character, artifacts), 'dice'],
					]}
				/>
			</Grid>
		</>
	)
}

export default CombatTab;

import React, { useContext, useState } from "react";
import { useSnackbar } from "notistack";

import { MenuItem, TextField } from "@mui/material";

import data from "@data";
import { xplog, startTraining } from "@loom/characters/functions";
import { Box, ConfirmDialog } from "@loom/ui";
import { RemoveIcon } from "@loom/ui/icons";

import EditContext from "../context";
import "./abilities.less";

const Specialties = ({ specialties, onChange, setRemoving }) => {
	const { character } = useContext(EditContext);

	return (
		<>
			{(specialties ?? {}).map(([i, spec]) => (
				<React.Fragment key={i}>
					<TextField
						value={spec.ability ?? ""}
						onChange={({ target }) => onChange(i, "ability")(target.value)}
						fullWidth
						select
					>
						{Object.keys(data.abilities).map((ability) => (
							<MenuItem key={ability} value={ability} className="capitalize">
								{ability}
							</MenuItem>
						))}
					</TextField>
					<TextField
						className="field"
						value={spec.area}
						onChange={onChange(i, "area")}
						fullWidth
					/>
					<RemoveIcon
						className="interactable"
						onClick={() => {
							if (character.experienced) {
								onChange("xplog")([
									...(character.xplog ? character.xplog : []),
									xplog(character, "specialty", i, true, false),
								]);
							}
							return setRemoving(i);
						}}
					/>
				</React.Fragment>
			))}
		</>
	);
};

const SpecialtiesBox = () => {
	const { character, onChange } = useContext(EditContext);
	const [removing, setRemoving] = useState();
	const { enqueueSnackbar } = useSnackbar();

	return (
		<Box
			title="Specialties"
			actions={[
				[
					"Add",
					() => {
						if (character.experienced) {
							startTraining(
								character,
								xplog(character, "specialty", null, false, true),
								onChange,
								enqueueSnackbar
							);
						} else {
							onChange("specialties")([...(character.specialties ?? []), {}]);
						}
					},
				],
			]}
		>
			<div className="specialties">
				<Specialties
					specialties={Object.entries(character.specialties ?? {})}
					onChange={(i, key) => onChange("specialties", i, key)}
					setRemoving={setRemoving}
				/>
				<Specialties
					specialties={(character.completed_training || [])
						.map((log, i) => [i, log.data])
						.filter(([_, log]) => log?.type === "specialty")}
					onChange={(i, key) => onChange("completed_training", i, "data", key)}
				/>
				<Specialties
					specialties={Object(character.xplog || [])
						.map((log, i) => [i, log.data])
						.filter(([_, log]) => log?.type === "specialty")}
					onChange={(i, key) => onChange("xplog", i, "data", key)}
				/>
			</div>
			<ConfirmDialog
				text={
					removing
						? "Do you want to delete " +
						  character.specialties[removing].ability +
						  ": " +
						  character.specialties[removing].area +
						  "?"
						: ""
				}
				open={Boolean(removing)}
				onConfirm={() => {
					onChange("specialties")(
						character.specialties.filter((_, i) => i !== parseInt(removing))
					);
					setRemoving(null);
				}}
				onClose={() => setRemoving(null)}
			/>
		</Box>
	);
};

export default SpecialtiesBox;

import React, { useContext, useState } from 'react'

import { MenuList, MenuItem } from '@mui/material'

import data from '@data'
import { Loom as LoomContext, Firestore as FirestoreContext } from '@contexts';
import { CharmDialog, CharmListDialog, SpellDialog, ArtifactDialog } from '@loom/help';
import { Box, Grid } from '@loom/ui';
import { getMACharms, getMAStyles, getCharms, getSpells, groupCharms, getEvocations, getCustomCharms, getSplatProp } from '@loom/characters/functions';

import ViewContext from './context';
import './charms.less';

const CharmList = ({
	title,
	onTitleClick,
	charms,
	highlighted,
	onClick
}) => {
	return (
		<Box
			title={
				<div
					className="interactable"
					onClick={ () => onTitleClick() }
				>{ title }</div>
			}
			key={ title }
		>
			<MenuList className="charm-list">{
				(charms ?? []).map((charm, i) => {
					return (
						<MenuItem
							key={ i }
							className={ `interactable ${(highlighted || []).indexOf(charm) >= 0 ? 'highlighted': ''}` }
							onClick={ () => onClick(charm, i) }
						>{ charm.name ?? charm }</MenuItem>
					);
				})
			}</MenuList>
		</Box>
	);
}

const CharmsTab = () => {
	const [ artifact, setArtifact ] = useState(null);
	const [ artifactTitle, setArtifactTitle ] = useState();
	const [ activeCharms, setActiveCharms ] = useState(null);
	const [ charm, setCharm ] = useState(null);
	const [ spell, setSpell ] = useState(null);
	const [ notes, setNotes ] = useState('');

	const { addExtraContent } = useContext(FirestoreContext);
	const { artifacts, hearthstones } = useContext(LoomContext);
	const { character } = useContext(ViewContext);
	const charms = groupCharms(character, getCharms(character))
	const evocations = getEvocations(character, artifacts)
	const evocationProviders = { ...artifacts, ...hearthstones }
	const spells = getSpells(character);
	const customCharms = getCustomCharms(character);
	const customCharmGroups = customCharms.reduce((groups, charm) => !groups.includes(charm.group) ? [...groups, charm.group] : groups, []);

	addExtraContent('artifacts', Object.keys(character.artifacts || {}));	

	return (
		<div>
			<Grid size="large">
				{ Object.keys(charms).sort((a,b) => charms[b].length - charms[a].length).map((group) => (
					<CharmList
						key={ group }
						title={ group }
						onTitleClick={ () => setActiveCharms(group) }
						charms={ charms[group] }
						onClick={ (charm) => {
							setCharm({ ...getSplatProp(character, 'charms', {})[charm], name:charm });
							setNotes(character.charm_notes ? character.charm_notes[charm] : '' );
						}}
					/>
				))}
				{ evocations && Object.keys(evocations)
					.map((parent, i) => (
						<CharmList
							key={parent}
							title={evocationProviders[parent]?.name}
							onTitleClick={() => {
								setArtifactTitle(evocationProviders[parent].name);
								setArtifact(evocationProviders[parent]);
							}}
							charms={evocations[parent]}
							onClick={(charm) => {
								setCharm(
									evocationProviders[parent].evocations.find(({ name }) => name === charm)
								)
							}}
						/>
					))
				}
				{ getMAStyles(character)
					.filter((style) => getMACharms(character, style).length > 0 )
					.map((style) => (
						<CharmList
							key={ style }
							title={ style }
							onTitleClick={ () => setActiveCharms(style) }
							charms={ getMACharms(character, style) }
							onClick={ (charm) => setCharm({ ...data.martialarts[style].charms[charm], name:charm }) }
						/>
					))
				}
				{ spells.length > 0 && (
					<CharmList
						title="Spells"
						onTitleClick={ () => setActiveCharms('spells') }
						charms={ spells }
						highlighted={ Object.values(character.controlSpells || {}) }
						onClick={ (spell) => {
							setSpell({
								...(Object.keys(data.sorcery.spells).reduce(
									(r, circle) => ({
										...r,
										...data.sorcery.spells[circle]
									}), {}
								)[spell]),
								name:spell
							})
						}}
					/>
				)}
				{
					customCharms.length > 0 && customCharmGroups.map((group) => (
						<CharmList
							title={group}
							charms={customCharms.filter((charm) => charm.group === group) }
							onClick={ (charm) => setCharm(charm) }
						/>
					))
				}
			</Grid>
			<CharmDialog
				key={ charm?.id ?? charm }
				charm={ charm }
				notes={ notes }
				character={ character }
				onClose={ () => setCharm(null) }
			/>
			<SpellDialog
				spell={ spell }
				onClose={ () => setSpell(null) }
			/>
			<CharmListDialog
				character={ character }
				category={ activeCharms }
				onClose={ () => setActiveCharms(null) }
			/>
			<ArtifactDialog
				title={ artifactTitle }
				artifact={ artifact }
				onClose={ () => setArtifact(null) }
			/>
		</div>
	);
}

export default CharmsTab;

import React, { useContext, useState } from 'react';

import data from '@data';
import { Box, ConfirmDialog, NameValue } from '@loom/ui';

import EditContext from '../context';
import { getSplatProp } from '@loom/characters/functions';

const Expenditure = () => {
	const { character, onChange } = useContext(EditContext);
	const [ deleteLog, setDeleteLog ] = useState(false);

	if (!character.completed_training) return null;
	
	return (
		<Box title="Experience Expenditure">
			{ character.completed_training && character.completed_training.map((item, i) => ({...item, _index:i})).reverse().map((item, i) => (
				<NameValue
					key={ item._index }
					onClick={ () => setDeleteLog(item._index) }
					name={ item.description }
					value={<>
						{ item.costs.specialty ? item.costs.specialty + ' ' + getSplatProp(character, 'xpsymbol', 'XXP') : '' }
						{ item.costs.regular ? item.costs.regular + ' XP' : '' }
						{ !item.costs.specialty && !item.costs.regular && '-' }
					</>}
				/>
			))}
			<ConfirmDialog
				text={ 'Do you want to remove "' + (deleteLog ? character.completed_training[deleteLog].description : '') + '"?' }
				open={ deleteLog !== false }
				onClose={ () => setDeleteLog(false) }
				onConfirm={ () => {
					onChange('completed_training')(character.completed_training.filter((_,i) => i !== deleteLog));
					setDeleteLog(false);
				}}
			/>
		</Box>
	)
}

export default Expenditure;
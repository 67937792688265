import React, { useState, useEffect, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import BottomNavigationAction from '@mui/material/BottomNavigationAction' 

import { AddDialog as AddCharacterDialog } from '@loom/characters';
import { create as createOrganisation } from '@loom/organisations';

import { Auth, Firestore } from '@contexts';
import { ActionBase, ConfirmDialog } from '@loom/ui';
import { CharacterIcon, EditIcon, DeleteIcon, LocaleIcon, StoryIcon, OrganisationIcon, NPCIcon } from '@loom/ui/icons'
import { CreateDialog as CreateNPCDialog } from '@loom/characters/npc';
import { create as createStory } from '@loom/stories';
import { create as createLocale } from '@loom/locales';

import EditChronicleDialog from './edit'

const ChronicleActions= ({
	chronicleId,
	chronicle: originalChronicle,
	notes: originalNotes
}) => {
	const [ addPC, setAddPC ] = useState(false);
	const [ deleting, setDeleting ] = useState(false);
	const [ edit, setEdit ] = useState(false);
	const [ chronicle, setChronicle ] = useState();
	const [ notes, setNotes ] = useState();
	const [ addNPC, setAddNPC ] = useState(false);
	const { db } = useContext(Firestore);
	const { user } = useContext(Auth);
	const { pathname: path } = useLocation();
	const history = useHistory();

	useEffect(() => {
		setChronicle({ ...originalChronicle })
	}, [ originalChronicle ]);

	useEffect(() => {
		setNotes(originalNotes)
	}, [ originalNotes ]);

	if (user?.uid !== chronicle?.owner) return <div><ActionBase /></div>

	return (
		<div>
			<ActionBase>
				<BottomNavigationAction
					label="Edit"
					icon={ <EditIcon /> }
					onClick={() => setEdit(true) }
				/>
				<BottomNavigationAction
					label="Add Story"
					icon={ <StoryIcon /> }
					onClick={async () => {
						const story = await db.collection('stories').add(createStory(user));
						db.collection('chronicles').doc(chronicleId).update({
							stories:[ ...(chronicle.stories ? chronicle.stories : []), story.id ]
						});
						history.push(`${path}/story/${story.id}`);
					}}
				/>
				<BottomNavigationAction
					label="Add Locale"
					icon={ <LocaleIcon /> }
					onClick={async () => {
						const locale = await db.collection('locales').add(createLocale(user));
						db.collection('chronicles').doc(chronicleId).update({
							locales:[ ...(chronicle.locales ? chronicle.locales : []), locale.id ]
						});
						history.push(`${path}/locale/${locale.id}`);
					}}
				/>
				<BottomNavigationAction
					label="Add Organisation"
					icon={ <OrganisationIcon /> }
					onClick={async () => {
						const organisation = await db.collection('organisations').add(createOrganisation(user));
						console.log(organisation);
						db.collection('chronicles').doc(chronicleId).update({
							organisations:[ ...(chronicle.organisations ? chronicle.organisations : []), organisation.id ]
						});
						history.push(`${path}/organisation/${organisation.id}`);
					}}
				/>
				<BottomNavigationAction
					label="Add Character"
					icon={ <CharacterIcon /> }
					onClick={() => { setAddPC(true) }}
				/>
				<BottomNavigationAction
					label="Add Quick Character"
					icon={ <NPCIcon /> }
					onClick={ () => setAddNPC(true) }
				/>
				<BottomNavigationAction
					label="Delete"
					icon={ <DeleteIcon /> }
					onClick={ () => setDeleting(true) }
				/>
			</ActionBase>
			<EditChronicleDialog
				open={ edit }
				chronicle={ { ...chronicle, notes } }
				onClose={() => { setEdit(false); }}
				onChange={ (name) => (event) => {
					if (name === 'notes') {
						setNotes(event.target.value);
					} else {
						setChronicle({ ...chronicle, [name]: event.target.value })
					}
				}}
				onSave={ () => {
					const { notes: _, ..._chronicle } = chronicle;
					db.collection('chronicles').doc(chronicleId).update(_chronicle);
					db.collection('notes').doc(chronicleId).set({ text: notes || '', owner: user.uid });
					setEdit(false);
				}}
			/>
			<CreateNPCDialog
				open={ addNPC }
				onClose={ () => setAddNPC(false) }
				onCreate={ (npc) => {
					const newId = uuid();
					db.collection('chronicles').doc(chronicleId).update({
						npcs: {
							...chronicle.npcs ?? {},
							[newId]: npc
						}
					});
				 }}
			/>			
			<AddCharacterDialog
				open={ addPC }
				exclude={ chronicle?.characters || [] }
				onClose={ () => setAddPC(false) }
				onDone= { (character) => {
					db.collection('chronicles').doc(chronicleId).update({characters:[...chronicle?.characters || [], character]})
					setAddPC(false);
				}}
			/>
			<ConfirmDialog
				open={ deleting }
				onClose={ () => setDeleting(false) }
				onConfirm={() => {
					db.collection('chronicles').doc(chronicleId).delete()
					setDeleting(false);
					history.push('/chronicle')
				}}
				text={ 'Do you want to delete the chronicle "' + chronicle?.name + '"?' }
			/>
		</div>
	)
}

export default ChronicleActions
